import React, { useContext } from 'react'
import { ExportContext } from "../projects/exportContext";
import LoadingSpinner from "./loadingSpinner";
import ExportPathButtons from "./exportPathButtons"
import SidebarButton from "./sidebarButton"
import { DemoLinks } from "./demo"

const SideBar = ({ changeWidget }) => {
  const { routes, project, notesCount, localNotesCount } = useContext(ExportContext)

  return (
    project ? (
      <div className='lg:w-1/4 p-8 w-full bg-gray-200 lg:h-screen flex-col lg:flex'>
        <div>
          <p className='text-3xl '>{project.name}</p>
          <div className='uppercase text-xl py-1'>
            {(localNotesCount !== null && notesCount !== null) ? `${localNotesCount}/${notesCount} Notes Downloaded` : 'Loading...'}
          </div>
        </div>
        <div className='flex flex-col w-full mt-6 overflow-scroll'>
          <div className={'flex flex-col gap-y-2 justify-between w-full'}>
            <DemoLinks changeWidget={changeWidget} />
            <SidebarButton text={'View Notes'} href={`/projects/${project.id}/notes`} />
            {routes && <ExportPathButtons routes={routes} />}
          </div>
        </div>
      </div>

    ) : <LoadingSpinner />

  )
}

export default SideBar
