import React from "react"
import { useTrackerContext } from "../../contexts/trackerContext"
import { TrackerFilterProvider } from "../../contexts/trackerFilterContext"
import { useParams } from "react-router-dom";
import ProjectTracker from "./projectTracker";
import PortfolioTracker from "./portfolioTracker";
import TablePageBreadcrumbs from "../../layouts/tablePage/tablePageBreadcrumbs"

const Tracker = ({ routes }) => {
  const { projectId, portfolioId } = useParams()
  const { trackerTemplate } = useTrackerContext()

  return (
    <div className="h-full">
      <TablePageBreadcrumbs tableName={trackerTemplate?.name} tableSlug={trackerTemplate?.slug} scopeType={"trackers"} />
      <TrackerFilterProvider>
        <div className='flex flex-col lg:flex-row w-full' style={{ height: "calc(100% - 49px)" }}>
          {projectId && <ProjectTracker routes={routes} />}
          {portfolioId && <PortfolioTracker />}
        </div>
      </TrackerFilterProvider>
    </div>
  )
}

export default Tracker
