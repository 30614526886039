import React, { useRef } from 'react'
import { useSafetyTicketApi, useSafetyTicketContext } from '../../../contexts/safetyTicketContext'
import ModalWrapper from '../../modal/modalWrapper'
import { inputClass } from "../../../utilities/stylesHelpers"
import ImageSelect from '../../shared/imageSelect'
import DatePicker from "react-datepicker"

const UserTicketModal = () => {
  const { userTicket } = useSafetyTicketContext()
  const { toggleField, updateNestedState, onSend } = useSafetyTicketApi()
  const formRef = useRef()
  const { expiry } = userTicket
  const handleDateChange = date => updateNestedState('userTicket', 'expiry')(date)

  return (
    <ModalWrapper
      heading="Ticket Modal"
      closeModal={() => toggleField('userTicketModal')}
      formName="user-ticket-form"
      submitButtonText='Submit'
      modalContainerClasses="max-h-[90%] my-6 w-7/12"
    >
      <form ref={formRef} className="gap-y-6 flex flex-col" onSubmit={e => onSend(formRef, e)} id="user-ticket-form">
      <input type="hidden" name="user_id" value={userTicket.user_id} />
      <input type="hidden" name="safety_ticket_id" value={userTicket.safety_ticket_id} />
        <label htmlFor="expiry">
          Expiry Date &nbsp;
          <DatePicker
            name="expiry"
            className={inputClass}
            selected={expiry ? new Date(expiry) : null}
            onChange={handleDateChange}
            dateFormat="yyyy/MM/dd"
            placeholderText="Select expiry date"
            required={true}
          />
        </label>
        <div className="grid grid-cols-2 items-center">
          <ImageSelect originalImage={userTicket.front_ticket_image_path} setImage={updateNestedState('userTicket', 'front_ticket_image_path')} htmlFor={'front_ticket_image'} required={true} title={'Front of Ticket'} />
          <ImageSelect originalImage={userTicket.back_ticket_image_path} setImage={updateNestedState('userTicket', 'back_ticket_image_path')} htmlFor={'back_ticket_image'} required={false} title ={'Back of Ticket'} />
        </div>
        <h3><strong>Expiry and Front of Image is required</strong></h3>
      </form>
    </ModalWrapper>)
}

export default UserTicketModal
