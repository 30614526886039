import React from "react"
import axios from "axios"

const DownloadTemplate = () => {
  const getCsvTemplate = () => {
    axios.get('/admin/projects/export_csv_template.json').then(res => {
      const blob = new Blob([res.data], { type: 'text/csv' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'project_import.csv'
      link.click()
    })
  }

  return <>
    <button
      onClick={getCsvTemplate}
      className="btn btn-primary cursor-pointer"
    >
      Download Template</button>
  </>
}

export default DownloadTemplate
