import { tableStyles } from "./historicalAssessmentHelper"
import RadioButton from "./radioButton"
import React, { useState } from "react"

const RadioButtonsGroup = ({ fieldName, options, initialValue = null }) => {
  const [checked, setChecked] = useState( initialValue )

  return (
    <fieldset className={tableStyles.tdContent}>
      {options?.map((o, index) => (
        <RadioButton key={index} label={o.label} name={fieldName} value={o.value} checked={checked} onChange={setChecked} />
      ))}
    </fieldset>
  )
}

export default RadioButtonsGroup
