import React from "react";
import Portfolios from "./portfolios";
import Projects from "./projects";

const Api = () => {
  return <>
    <div className='m-10'>
      <h1 className='text-3xl m-4'>API Overview</h1>
      <Projects />
      <Portfolios />
    </div>
  </>
}

export default Api
