import React, { useEffect } from "react"

const modalContainerStyles = "relative mx-auto border-0 rounded-lg shadow-lg relative flex flex-col bg-white overflow-scroll"
const modalHeaderStyles = "flex justify-center p-4 rounded-t bg-gray-700 text-white sticky top-0 z-10"
const buttonClasses = ["w-full inline-flex justify-center rounded-md border border-transparent shadow-sm py-2 text-base",
  "font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:text-sm"].join(" ")

export default function ModalWrapper(props) {
  const {
    heading,
    closeModal,
    submitButtonText,
    onSend,
    formName,
    children,
    modalContainerClasses = "max-h-full w-5/12 my-6"
  } = props

  useEffect(() => {
    const keyDownHandler = e => e.key === 'Escape' && closeModal()
    document.addEventListener('keydown', keyDownHandler)

    return () => document.removeEventListener('keydown', keyDownHandler)
  }, [closeModal])

  return (
    <div className="inset-0 z-50 flex justify-center items-center fixed">
      <div className="bg-black/25 inset-0 fixed" onClick={ closeModal }></div>
      <div className={ [modalContainerStyles, modalContainerClasses].join(" ") }>
        <div className={ modalHeaderStyles }>
          <h3 className='font-semibold uppercase truncate'>{ heading }</h3>
        </div>
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          { children }
        </div>
        <div className="flex items-center justify-end p-4 border-t border-solid border-blueGray-200 rounded-b w-full">
          { !!closeModal && (
            <button
              type="button"
              className={ [buttonClasses, "bg-red-600 hover:bg-red-700"].join(" ") }
              onClick={ () => closeModal() }
            >
              Close
            </button>
          ) }
          { !!submitButtonText && (
            <button
              { ...(
                formName ?
                  { type: "submit", form: formName } :
                  { type: "button", onClick: () => onSend() }
              ) }
              className={ [buttonClasses, "bg-blue-600 hover:bg-green-500"].join(" ") }
            >
              { submitButtonText }
            </button>
          ) }
        </div>
      </div>
    </div>
  )
}
