import React from "react"
import StandardButton from "../../shared/standardButton"
import { useDocumentBuilderApiContext } from "../../../contexts/documentBuilderContext"

const ActionsNav = () => {
  const { saveDocument } = useDocumentBuilderApiContext()

  return (
    <div className="flex justify-end w-full sticky top-0 z-10 shadow-lg bg-white">
      <StandardButton buttonText="Save Document" onClickHandler={ saveDocument }/>
    </div>
  )
}

export default ActionsNav
