import React, { useEffect } from "react"
import Select from "react-select";
import { useSiteInspectionReports, useSiteInspectionReportsApi } from "../../contexts/siteInspectionReports";

  const ExportSelect = ({ notes }) => {
  const { onInitializeOptions, onNoteSelect, onInputChange } = useSiteInspectionReportsApi()
  const { options } = useSiteInspectionReports()

  useEffect(() => {
    if (notes) {
      const formattedOptions = notes.map(note => {
        const displayName = note.date ? note.title + ' - Date: ' + note.date: note.title
        return { label: displayName, value: note }
      })
      onInitializeOptions(formattedOptions)
    }
  }, [notes])

  const handleSelect = (selectedOption) => {
    onNoteSelect(selectedOption)
    onInputChange('siteLocation', selectedOption.value.title + '.' + selectedOption.value.description)
    onInputChange('assessedBy', selectedOption.value.created_by || '')
    onInputChange('date', selectedOption.value.date)
  }

  return<>
    {options && <>
      <h1>Select a note below:</h1>
      <Select
        options={options}
        onChange={selectedOption => handleSelect(selectedOption)}
      />
    </>}
  </>
}

export default ExportSelect
