import { tableStyles } from "../../../utilities/stylesHelpers"
import React from "react"

const SimpleCell = ({ content, styler, collapsed = false  }) => {
  const tdTextStyles = collapsed ? tableStyles.tdTextTruncate : tableStyles.tdText
  const tdStyles = collapsed ? tableStyles.tdCollapsed : tableStyles.td
  return (
    <td className={tdStyles} style={{ minWidth: "120px", ...styler }} >
      <p className={tdTextStyles}>{content}</p>
    </td>
  )
}

export default SimpleCell
