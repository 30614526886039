import React, { useState, useEffect } from 'react'
import ExportOptions from "./ExportOptions"
import AddChemical from "./AddChemical"
import Header from "./header/index"
import CocExportHistory from './cocExportHistory'
import { getExports } from '../../api/cocApi'
import { useCocExportApi } from '../../contexts/cocExport'
import ExportCoc from './ExportCoc'

const CocExportBody = ({ exportPath, project, boreholes, filterValues }) => {
  const [toggleHeader, setToggleHeader] = useState(false)
  const [exportFormat, setExportFormat] = useState('pdf')
  const { updateExportHistory } = useCocExportApi()
  const onFormatSelect = (e) => {
    setExportFormat(e.value)
  }

  const toggleHeaderVisibility = () => {
    setToggleHeader(!toggleHeader)
  }

  useEffect(() => { getExports(project.id).then(res => { updateExportHistory(res.data) }) }, [])

  const onModalToggle = ({ modalType }) => {
    if (modalType === 'email') toggleEmailModal()
    if (modalType === 'history') toggleHistoryModal()
  }
  return (
    <div className='mb-8 w-full'>
      <button onClick={toggleHeaderVisibility} className='btn btn-primary cursor-pointer text-xl font-bold w-64 text-center mx-auto my-4'>
        {toggleHeader ? 'Hide Billing Info' : 'Show Billing Info'}
      </button>
      {toggleHeader && <Header project={project} />}
      <AddChemical />
      <ExportOptions boreholes={boreholes} filterValues={filterValues} />
      <ExportCoc onFormatSelect={onFormatSelect} exportPath={exportPath} exportFormat={exportFormat} projectId={project.id} />
      <CocExportHistory project={project} />
    </div>
  )
}

export default CocExportBody
