import React, { useEffect, useState } from 'react'
import SiteRow from "./siteRow"
import { pipe } from "../../../utilities/utils"
import { formatAdditionalFields } from "../../../utilities/projectHelpers"

const SiteAdditionalFields = ({ project }) => {
  const [siteAdditionalFields, setSiteAdditionalFields] = useState(null)

  useEffect(() => {
    if (project.site?.additional_fields) {
      pipe(formatAdditionalFields, setSiteAdditionalFields)(project.site?.additional_fields)
    }
  }, [project])

  return (
    <div className={`lg:w-1/2 m-8 pb-4`}>
      {siteAdditionalFields && <>
        <h2 className="text-2xl font-bold">Site Additional Attributes</h2>
        <table className='w-full text-left mt-3'>
          <thead>
            <SiteRow attributePairs={['Key', 'Value']} TableCellTag='th' />
          </thead>
          <tbody>
            {siteAdditionalFields?.map((attributePairs, index) => <SiteRow key={index} attributePairs={attributePairs} />)}
          </tbody>
        </table>
      </>
      }
    </div>
  )
}

export default SiteAdditionalFields
