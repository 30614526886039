import React from 'react'

const ActionsSidebar = ({ actions }) => {
  return (
    <div className="h-full w-1/4 p-4 flex flex-col gap-4">
      <h1>Actions</h1>
      {actions && actions.map((action, index) => {
        return (
          <button key={index} className="p-2 bg-blue-500 rounded" onClick={action.action}>
            {action.name}
          </button>
        )
      }
      )}
    </div>
  )
}

export default ActionsSidebar
