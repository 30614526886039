import React, { useContext, useMemo } from "react"
import { useParams } from "react-router-dom"
import { ExportContext } from "./exportContext"
import Breadcrumb from "../../layouts/nav/breadcrumb"

const ExportBreadCrumbs = () => {
  const { projectId } = useParams()
  const { project, notes, localNotesCount, notesCount } = useContext(ExportContext)

  const notesLinkText = useMemo(() => {
    return `${project.name}${notes ? ` (${localNotesCount}/${notesCount} Notes)` : ''}`
  }, [project, notes, localNotesCount, notesCount])

  return (
    <nav className='breadcrumbs' aria-label='Breadcrumb'>
      <ol className='list-none p-0 inline-flex'>
        <Breadcrumb linkPath='/projects' linkName={project?.name} firstLink={true} />
        <Breadcrumb linkPath={`/projects/${projectId}/notes`} linkStyle='' linkName={notesLinkText} routerLink={true} />
      </ol>
    </nav>
  )
}

export default ExportBreadCrumbs
