import React, { useState, useEffect } from "react"
import TrackerTemplate from "./trackerTemplate";
import { getPortfolioTrackerTemplates, getAdminPortfolioTrackerTemplates } from "../../../api/portfoliosApi"

const Trackers = ({ portfolio }) => {
  const [trackerTemplates, setTrackerTemplates] = useState([])
  const trackerApiCall = window.location.pathname.includes("admin") ? getAdminPortfolioTrackerTemplates : getPortfolioTrackerTemplates

  useEffect(() => {
    if (portfolio) {
      trackerApiCall(portfolio.id)
        .then(res => setTrackerTemplates(res))
        .catch(err => console.log(err))
    }
  }, [portfolio])

  return <>
    {trackerTemplates.length > 0 && <>
      <tr className='bg-regular-blue text-white'>
        <td className='font-semibold px-2 py-3 w-1/5'>Tracker Name</td>
        <td className='font-semibold px-2 py-3'>GeoJSON URL</td>
        <td className='font-semibold px-2 py-3'>Data Connection URL</td>
      </tr>
      {trackerTemplates.map(template => <TrackerTemplate key={template.id} portfolio={portfolio} trackerTemplate={template} />)}
      <tr className='border-t border-gray-400 '><td className='py-6'></td></tr>
    </>}
  </>
}

export default Trackers
