import React, { useMemo } from 'react'
import { byteToMB, openInNewTab } from "../../../utilities/helpers"

const ActionButtons = ({
                         document,
                         toggleModal,
                         generateTables,
                         uploadToOpenAi,
                         onGetBasicSummary,
                         onDelete,
                         children,
                       }) => {
  const { id: documentId, status } = useMemo(() => document, [document])

  return (
    <div className="col-span-2 w-full flex justify-end items-center gap-4 flex-wrap">
      { children }
      { status === 'uploaded' && !document?.json_file_path && <button
        className='btn btn-green cursor-pointer text-center w-fit h-fit font-semibold'
        onClick={ () => toggleModal('json') }
      >
        Upload Json
      </button> }
      <button
        className='btn btn-primary truncate cursor-pointer w-fit h-fit font-semibold'
        onClick={ () => openInNewTab(document.file_path) }
        rel="noreferrer noopener"
      >
        Download Original Document
      </button>
      { document?.json_file_path && <a
        className='btn btn-primary truncate cursor-pointer w-fit h-fit font-semibold'
        href={ document.json_file_path }
        target="_blank"
        rel="noreferrer noopener"
      >
        Download JSON
      </a> }
      { document?.json_file_path && status === 'ready' && <button
        className='btn btn-green cursor-pointer'
        onClick={ generateTables }>
        Generate Table Data CSV
      </button> }
      { document?.csv_file_path && <a
        className='btn btn-primary truncate cursor-pointer w-fit h-fit font-semibold'
        href={ document.csv_file_path }
        target="_blank"
        rel="noreferrer noopener"
      >
        Download CSV
      </a> }
      { !document?.openai_id && <button
        className='btn btn-green cursor-pointer'
        onClick={ uploadToOpenAi }>
        Upload for AI
      </button> }
      <button
        className={ `btn btn-green cursor-pointer w-fit h-fit ${ byteToMB(document.file_size) >= 15 ? 'opacity-50 cursor-not-allowed' : '' }` }
        onClick={ () => onGetBasicSummary(documentId) }
        disabled={ byteToMB(document.file_size) >= 15 }
        title={ byteToMB(document.file_size) >= 15 ? "Summary not available for files over 15MB" : "Get Summary" }
      >
        { !!document?.summary ? "Regenerate Summary" : "Get Summary" }
      </button>
      <button
        className='btn btn-orange cursor-pointer text-center w-fit h-fit font-semibold'
        onClick={ () => toggleModal('status') }
      >
        Change Status
      </button>
      <button
        className='btn btn-danger cursor-pointer text-center w-fit h-fit font-semibold'
        onClick={ onDelete }
      >
        Delete
      </button>
    </div>
  )
}

export default ActionButtons
