import axios from "axios"

export const getLabs = () => {
  try {
    return axios({
      method: 'GET',
      url: `/labs.json`,
    })
  }
  catch (e) {
    console.error(e)
  }
}
