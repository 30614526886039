import React, { forwardRef } from 'react'
import DropdownInput from "./dropdownInput"
import { optionize } from "../../../utilities/utils"
import { usePortfolioContext } from "../../../contexts/portfolioContext"

const ProjectDropdown = forwardRef(({}, inputRef) => {
  const { projects } = usePortfolioContext()
  const options = optionize('name', 'id')(projects)

  return (
    <>
      {options && <DropdownInput
        paramOptions={options}
        ref={inputRef}
        displayOtherOption={false}
      />}
    </>
  )
})

export default ProjectDropdown
