import React, { useEffect, useRef, useState } from 'react'
import Modal from "../../shared/modal"
import { isValid, parseISO } from "date-fns"
import DatePicker from "react-datepicker"
import Select from "react-select";
import { notifyAlert, notifyError } from "../../shared/notice"
import { createProjectChecklist, buildChecklistPath } from "../../../api/checklist"
import { useChecklistAPI, useChecklistContext } from '../../../contexts/checklistContext'
import { checklistStatusOptions } from '../../../utilities/checklistHelpers'

const ChecklistCreationModal = ({ projectId, checklistSlug, show, onClose }) => {

  const { updateState } = useChecklistAPI()
  const { entriesByProjectChecklistId, projectChecklists } = useChecklistContext()
  const inputRef = useRef()
  const [selectedDate, setSelectedDate] = useState(new Date)
  const [selectedOption, setSelectedOption] = useState(undefined)
  const [description, setDescription] = useState('')

  const onChangeHandler = (date) => setSelectedDate(date)

  const onDropdownSelect = (event) => setSelectedOption(event)

  const onSave = async () => {

    if (!selectedDate || !selectedOption) {
      notifyError("Please select a valid date and status option.")
      return
    }
    if (!description) {
      notifyError("Please add a description!")
      return
    }
    try {
      const response = await createProjectChecklist(
        buildChecklistPath({ projectId, checklistSlug }),
        { status: selectedOption.value, date: selectedDate, description: description }
      )
      const newEntriesByChecklist = response.entries_by_project_checklist_id 

      updateState('entriesByProjectChecklistId', {
        ...entriesByProjectChecklistId,
        ...newEntriesByChecklist
      })

      updateState('projectChecklists', [
        ...projectChecklists,
        response.project_checklist
      ])
    }
    catch (error) {
      console.error("Error creating checklist:", error)
      notifyError("Error in creating a checklist", error)
      onClose()
    }

    notifyAlert("A new checklist has been created")
    setSelectedDate(new Date)
    setSelectedOption(undefined)
    setDescription('')
    onClose()
  }

  useEffect(() => {
    const parsedContent = parseISO(selectedDate)
    if (isValid(parsedContent)) { setSelectedDate(parsedContent) }
  }, [])

  useEffect(() => { if (inputRef.current) inputRef.current.focus() }, [inputRef.current])

  return (
    <> {show && (
      <Modal
        heading='Checklist Creation'
        onSave={onSave}
        closeModal={onClose}
      >
        <div className='flex flex-col lg:justify-between gap-y-4 lg:gap-y-0 lg:gap-x-4'>
          <div className='w-full'>
            DATE:
            <DatePicker
              className='block w-full p-1 text-xs text-gray-700 appearance-none focus:outline-none border border-gray-200 focus:shadow-inner m-0 rounded z-1000'
              selected={selectedDate}
              onChange={onChangeHandler}
            />
            STATUS:
            <Select
              options={checklistStatusOptions}
              onChange={event => onDropdownSelect(event)}
              className='block w-full p-1 text-xs text-gray-700 appearance-none focus:outline-none border border-gray-200 focus:shadow-inner rounded'
              value={selectedOption}
            />
            DESCRIPTION:
            <textarea
              className='block w-full p-1 text-xs text-gray-700 appearance-none focus:outline-none border border-gray-200 focus:shadow-inner m-0 rounded'
              type='text'
              ref={inputRef}
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
          </div>
        </div>
      </Modal>
    )}
    </>
  )
}

export default ChecklistCreationModal
