import React from 'react'
import Widget from "../../shared/widget"
import ProjectSideBar from "../project/projectSideBar"
import NoteFilters from "../shared/NoteFilters"

const ConstructionLayout = ({ children, project, routes, portfolio, showMap, showFormNameFilter }) => {
  return (
    <div className={'flex flex-col flex-grow overflow-hidden lg:flex-row'} style={{ maxHeight: 'calc(100vh - 105px)' } }>
      <ProjectSideBar project={project} routes={routes} />
      <div className={`flex flex-col w-full h-2/3 ${showMap && 'lg:h-full lg:w-3/4'}  flex-grow overflow-scroll gap-y-1`}>
        <Widget project={project} widgetProp={project?.default_widget?.full_url} />
        <div>
          { showFormNameFilter() && <NoteFilters />}
          { children }
        </div>
      </div>
    </div>
  )
}

export default ConstructionLayout
