import React from "react"
import { faSquareRss } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BottomNav = () => {
  return <>
    <div className="sidenav order-last w-full flex flex-row items-center bg-gray-900 shadow-lg print:hidden lg:order-first border-2">
      <div className="items-center h-auto hidden lg:flex">
        <a href="/projects">
          <img src="/field_dms_logo.png" alt="Prodelta Logo" className="h-16" />
        </a>
      </div>
      <nav className="flex flex-row lg:justify-between">
        <div className="flex flex-row items-center justify-between lg:justify-start lg:gap-x-4 lg:pl-4 w-1/2">
          <a href={'https://prodelta.ca/'} data-method="delete" rel="nofollow noopener noreferrer" target="_blank">
            © 2024 ProDelta. All rights reserved.
          </a>
        </div>
        <div className="flex flex-row items-center gap-x-2">
          <a href='/dev-blog' className="show-svg" target="_blank"><FontAwesomeIcon icon={faSquareRss} className="mr-2" /> Development Blog
          </a>
        </div>
      </nav>
    </div>
  </>
}

export default BottomNav
