import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useTestResultsFilterApi } from '../../../../contexts/testResultsFilterContext';

const ApecFilter = ({ options }) => {
  const { filter } = useTestResultsFilterApi()
  const [selectedOptions, setSelectedOptions] = useState([])
  
  useEffect(() => {
    setSelectedOptions(options?.map(option => ({ label: option, value: option })))
  }, [options])

  const handleChange = (newSelectedOptions) => {
    filter('apecs', newSelectedOptions.map(option => option.value) )
  }

  return (<>
    <h3  className="font-semibold text-blue-950">APECs</h3>
    {selectedOptions.length !== 0 && <Select
      isMulti
      closeMenuOnSelect={false}
      options={selectedOptions}
      defaultValue={selectedOptions}
      onChange={handleChange}
    />}
  </>)
}

export default ApecFilter
