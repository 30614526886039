import React, { useEffect, useMemo, useState } from 'react'
import Content from "../content"
import { useParams } from "react-router-dom"
import { getSectionLayout } from "../../../api/documentLogApi"
import SimpleTrackerTable from "../../simpleTrackerTable"

const TrackerTableLayout = ({ sectionLayout, ContentComponent = Content }) => {
  const { projectId } = useParams()
  const isVertical = useMemo(() => sectionLayout?.vertical, [sectionLayout])
  const [trackerTemplate, setTrackerTemplate] = useState({})
  const [trackerHeaders, setTrackerHeaders] = useState([])
  const [trackerEntries, setTrackerEntries] = useState([])

  useEffect(() => {
    void getSectionLayout(projectId, sectionLayout.id)
      .then(trackerResponse => {
        const { tracker_template, tracker_headers, tracker_entries, } = trackerResponse

        setTrackerTemplate(tracker_template)
        setTrackerHeaders(tracker_headers)
        setTrackerEntries(tracker_entries)
      })
  }, [sectionLayout])

  return <div className="w-full h-full">
    <div>
      <a href={ `/projects/${ projectId }/trackers/${ trackerTemplate.slug }` }>
        <h3 className="text-xl font-bold">{ trackerTemplate.name } Tracker</h3>
      </a>
    </div>
    <SimpleTrackerTable trackerHeaders={ trackerHeaders } trackerEntries={ trackerEntries } isVertical={ isVertical }/>
  </div>
}

export default TrackerTableLayout
