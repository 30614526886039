import React, { useMemo } from "react"

const ChatBubble = ({ message: { content, role } }) => {
  const placingStyle = useMemo(() => role === 'user' ? 'items-end' : 'items-start', [role])
  const backgroundColor = useMemo(() => role === 'user' ? 'bg-[#DDD]' : 'bg-[#BBB]', [role])

  return (
    <div className={ `w-full flex flex-col items-start overflow-anchor-none ${ placingStyle }` }>
      <div className={ `${ backgroundColor } w-fit max-w-[55%] whitespace-normal p-6 rounded h-full` }>
        <p>{ content }&nbsp;</p>
      </div>
    </div>
  )
}

export default ChatBubble
