import React, { useState } from "react"
import { useChecklistAPI } from "../../../contexts/checklistContext"

const HideChecklistButton = ({ projectChecklistId }) => {
  const { updateCollapsedChecklists } = useChecklistAPI()
  const [hide, setHide] = useState(false)
  const onClickHandler = () => {
    updateCollapsedChecklists(projectChecklistId)
    setHide((hide) => !hide)
  }
  const buttonText = hide ? "Show Checklists" : "Hide Checklists"

  return (
    <button
      className="btn btn-primary font-semibold mx-4 my-4 relative cursor-pointer z-10 "
      id="hide-checklist-btn"
      name="hide-checklist-btn"
      onClick={onClickHandler}
    >
      {buttonText}
    </button>
  )
}

export default HideChecklistButton
