import React, { createContext, useContext, useEffect, useMemo, useReducer } from "react"
import { getUserLandingPage } from "../api/userApi"
import { formatWidgetOption } from "../utilities/widgetHelpers"
import { userProfileCreator } from "../utilities/landingPageHelpers"
import { simplePut } from "../api/apiConfig"

/* Actions */
const INIT = "INIT"
const SELECT_WIDGET = "SELECT_WIDGET"
const FULL_REFRESH = 'FULL_REFRESH'
const NESTED_FIELD = 'NESTED_FIELD'
const TOGGLE = 'TOGGLE'

/* Initial States */
const initialState = {
  modal: false,
  extendedView: true,
  allProvinces: [],
  cities: [],
  id: "",
  email: "",
  firstName: "",
  lastName: "",
  userName: "",
  headshot: "",
  originalHeadshot: "",
  userProfile: {
    id: "",
    homeAddress: "",
    employeeStatus: "",
    city: null,
    province: null,
    postalCode: "",
    dateOfBirth: "",
    mailT4: false,
    cellPhone1: "",
    cellPhone2: "",
    division: "",
    position: "",
    office: "",
    emergencyContactFirstName: "",
    emergencyContactLastName: "",
    emergencyContactRelationship: "",
    emergencyContactCellPhone: "",
  },
  user: null,
  widgets: [],
  selectedWidget: null,
}

/* Reducer */
const reducer = (state, action) => {
  switch (action.type) {
    case INIT:
      const user = action.initState.user
      const provinces = (action.initState.provinces.length === 0) ? state.allProvinces : action.initState.provinces
      const cities = provinces.flatMap(prov => prov.cities)
      const widgets = (action.initState.widgets.length === 0) ? state.widgets : action.initState.widgets.map(widget => formatWidgetOption(widget))
      const userProfile = userProfileCreator(user, provinces)
      return {
        ...state,
        allProvinces: provinces,
        cities: cities,
        user: user,
        widgets: widgets,
        selectedWidget: widgets[0]?.value,
        id: user?.id,
        email: user?.email || "",
        firstName: user?.first_name || "",
        lastName: user?.last_name || "",
        userName: [user?.first_name, user?.last_name].filter(i => i).join(' ') || user?.email,
        headshot: user?.headshot_path || "",
        originalHeadshot: user?.headshot_path || "",
        userProfile: userProfile
      }
    case NESTED_FIELD:
      return { ...state, [action.field]: { ...state[action.field], [action.nestedField]: action.value } }
    case TOGGLE:
      return { ...state, [action.field]: !state[action.field] }
    case SELECT_WIDGET:
      return { ...state, selectedWidget: action.widget }
    default:
      return { ...state, [action.field]: action.value }
  }
}

/* Contexts */
const UserLandingPageContext = createContext(initialState)
const UserLandingPageApiContext = createContext({
  selectWidget: () => {},
  updateUserState: () => {},
  updateNestedUserState: () => {},
  updateUserHeadshot: () => {},
  toggleField: () => {},
  onSend: () => {},
})

/* Provider */
export const UserLandingPageProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const api = useMemo(() => {
    const selectWidget = widget => dispatch({ type: SELECT_WIDGET, widget })
    const refreshProfile = profile => dispatch({ type: INIT, initState: { user: profile, provinces: [], widgets: [] } })
    const updateUserState = field => event => dispatch({ field, value: event.target.value })
    const updateNestedUserState = (field, nestedField) => value => dispatch({ type: NESTED_FIELD, nestedField, field, value })
    const toggleField = field => () => { dispatch({ type: TOGGLE, field }) }
    const updateUserHeadshot = value => dispatch({ field: 'headshot', value })

    return { selectWidget, updateUserState, updateNestedUserState, toggleField, updateUserHeadshot, refreshProfile }

  }, [])

  api.onSend = (formRef, event) => {
    event.preventDefault()
    const formDataFromRef = new FormData(formRef.current)
    simplePut(`/users/${state.id}`, formDataFromRef).then(response => {
      api.refreshProfile(response)
      api.toggleField('modal')()
    })
  }

  // Initialize state
  useEffect(() => void getUserLandingPage().then(res => {
    dispatch({ type: INIT, initState: res })
  }), [])


  return (
    <UserLandingPageApiContext.Provider value={api}>
      <UserLandingPageContext.Provider value={state}>
        {children}
      </UserLandingPageContext.Provider>
    </UserLandingPageApiContext.Provider>
  )
}

/* Custom Context Hooks */
export const useUserLandingPageContext = () => useContext(UserLandingPageContext)
export const useUserLandingPageApi = () => useContext(UserLandingPageApiContext)
