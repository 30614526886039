import React from 'react'
import StandardButton from './standardButton'

const ConfirmationModal = ({ title, children, onCancel, onConfirm, confirm_text }) => {
  return <div className="fixed z-30 inset-0 overflow-y-auto" onClick={(e) => e.stopPropagation()}>
    <div className="flex items-end justify-center min-h-screen backdrop-filter backdrop-blur-sm pt-4 px-4 pb-20 text-center sm:block sm:p-0">

      <div className="fixed inset-0 transition-opacity" aria-hidden="true">
        <div className="absolute inset-0 opacity-75" />
      </div>

      <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
      <div
        className="inline-block self-center bg-white border border-gray-200 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full"
        role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div className="flex items-center">
            <div className="mt-3 text-center w-full">
              <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                {title}
              </h3>
              <div className="mt-2">
                {children}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <StandardButton
            onClickHandler={() => onConfirm()}
            buttonText={confirm_text}
          />
          <StandardButton
            onClickHandler={() => onCancel()}
            buttonText="Cancel"
          />
        </div>
      </div>
    </div>
  </div>
}

export default ConfirmationModal
