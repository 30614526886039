import React, { createContext, useContext, useMemo, useReducer } from "react";
import * as Actions from "../actions/cocExport"
import { initialCocExportState, initialCocExportApiState, cocExportReducer } from "../reducers/cocExport";

/* Contexts */
const CocExportContext = createContext(initialCocExportState)
const CocExportApiContext = createContext(initialCocExportApiState)

export const CocExportProvider = ({ children, allSamples, labs }) => {
  const [state, dispatch] = useReducer(cocExportReducer, initialCocExportState(allSamples, labs))

  const api = useMemo(() => {
    const toggleChangeOfControl = () => {
      dispatch({ type: Actions.TOGGLE_CHANGE_OF_CONTROL })
    }

    const updateSelectedChemicals = (chemicals, update_chemical) => {
      dispatch({ type: Actions.UPDATE_SELECTED_CHEMICALS, chemicals, update_chemical })
    }

    const updateSelectedSamples = (samples) => {
      dispatch({ type: Actions.UPDATE_SELECTED_SAMPLES, samples })
    }

    const updateProjectInformation = (field, value) => {
      dispatch({ type: Actions.UPDATE_PROJECT_INFORMATION, field, value })
    }

    const updateInvoiceTo = (field, value) => {
      dispatch({ type: Actions.UPDATE_INVOICE_TO, field, value })
    }

    const updateReportTo = (field, value) => {
      dispatch({ type: Actions.UPDATE_REPORT_TO, field, value })
    }

    const updateLabInformation = (field, value) => {
      dispatch({ type: Actions.UPDATE_LAB_INFORMATION, field, value })
    }

    const updateAdditionalReportTo = (field, value) => {
      dispatch({ type: Actions.UPDATE_ADDITIONAL_REPORT_TO, field, value })
    }

    const updateSampleCustody = (field, value) => {
      dispatch({ type: Actions.UPDATE_SAMPLE_CUSTODY, field, value })
    }

    const updateNotes = (notes) => {
      dispatch({ type: Actions.UPDATE_NOTES, notes })
    }

    const updateRushPriority = (field, value) => {
      dispatch({ type: Actions.UPDATE_RUSH_PRIORITY, field, value })
    }

    const updateReportResults = (field, value) => {
      dispatch({ type: Actions.UPDATE_REPORT_RESULTS, field, value })
    }

    const updateRequirements = (field, value) => {
      dispatch({ type: Actions.UPDATE_REQUIREMENTS, field, value })
    }

    const setAllSamples = (allSamples) => {
      dispatch({ type: Actions.SET_ALL_SAMPLES, allSamples })
    }

    const updateExportHistory = (exportHistory) => {
      dispatch({ type: Actions.UPDATE_EXPORT_HISTORY, exportHistory })
    }

    const filterSamples = (filters) => {
      dispatch({ type: Actions.FILTER_SAMPLES, filters })
    }

    const updateLabFilters = (filters) => {
      dispatch({ type: Actions.UPDATE_LAB_FILTERS, filters })
    }
    const setNumberOfCoolers = (numberOfCoolers) => {
      dispatch({ type: Actions.SET_NUMBER_OF_COOLERS, numberOfCoolers })
    }

    const updateEmailInfo = (subject, body) => {
      dispatch({ type: Actions.UPDATE_EMAIL_INFO, subject, body })
    }

    const updateHeaderDetails = (headerDetails) => {
      dispatch({ type: Actions.UPDATE_HEADER_DETAILS, headerDetails })
    }
    return {
      toggleChangeOfControl, updateSelectedSamples, updateSelectedChemicals, updateProjectInformation,
      updateInvoiceTo, updateReportTo, updateLabInformation, updateAdditionalReportTo, updateSampleCustody,
      updateNotes, updateRushPriority, updateReportResults, updateRequirements, setAllSamples,
      updateExportHistory, filterSamples, updateLabFilters, setNumberOfCoolers, updateEmailInfo, updateHeaderDetails
    }
  }, [])

  return (
    <CocExportApiContext.Provider value={api}>
      <CocExportContext.Provider value={state}>
        {children}
      </CocExportContext.Provider>
    </CocExportApiContext.Provider>
  )
}

/* Custom Context Hooks */
export const useCocExport = () => useContext(CocExportContext)
export const useCocExportApi = () => useContext(CocExportApiContext)

