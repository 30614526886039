import React, { useContext } from 'react'
import { ExportButton, ExportContext } from "../projects/exportContext";


const ExportPathButtons = ({ routes }) => {
  const { hasSampleData } = useContext(ExportContext)

  return (
    <>
      {routes.exportForemanReportPath &&
        <ExportButton link={routes.exportForemanReportPath}>Foreman Daily</ExportButton>}
      {routes.exportUpiReportPath &&
        <ExportButton link={routes.exportUpiReportPath}>Upi</ExportButton>}
      {routes.exportGradingReportPath &&
        <ExportButton link={routes.exportGradingReportPath}>Grading</ExportButton>}
      {routes.exportWeldingProgressReportPath &&
        <ExportButton link={routes.exportWeldingProgressReportPath}>Welding Progress</ExportButton>}
      {routes.exportTrenchingReportPath &&
        <ExportButton link={routes.exportTrenchingReportPath}>Trenching</ExportButton>}
      {routes.exportForeignFacilityCrossingBackfillReportPath &&
        <ExportButton link={routes.exportForeignFacilityCrossingBackfillReportPath}>Foreign Facility Crossing
          Backfill</ExportButton>}
      {routes.exportLoweringInReportPath &&
        <ExportButton link={routes.exportLoweringInReportPath}>Lowering In</ExportButton>}
      {routes.exportFieldCoatingDftHolidayReportPath &&
        <ExportButton link={routes.exportFieldCoatingDftHolidayReportPath}>Field Coating DFT Holiday</ExportButton>}
      {routes.exportHydrovacLocateReportPath &&
        <ExportButton link={routes.exportHydrovacLocateReportPath}>Hydrovac Locate</ExportButton>}
      {routes.exportCsvPath && hasSampleData &&
        <ExportButton link={routes.exportCsvPath}>CoC</ExportButton>}
      {routes.exportFieldCoatingMaterialsAndInspectionReportPath &&
        <ExportButton link={routes.exportFieldCoatingMaterialsAndInspectionReportPath}>Field Coating Materials and
          Inspection</ExportButton>}
      {routes.exportBackfillReportPath &&
        <ExportButton link={routes.exportBackfillReportPath}>Backfill</ExportButton>}
      {routes.exportDemoReportPath &&
        <ExportButton link={routes.exportDemoReportPath}>Demo</ExportButton>}
      {routes.boreholeLogExportPath &&
        <ExportButton link={routes.boreholeLogExportPath}>Borehole Log</ExportButton>}
    </>
  )
}
export default ExportPathButtons
