// List of widgets
export const WidgetList = [
  { label: 'ProDelta DMS Demo Map', value: 'ProDelta DMS Demo Map' },
  { label: 'PowerBI Report Section', value: 'powerbi report section' },
  { label: 'PowerBI ProDelta DMS DEMO', value: 'powerbi prodelta dms demo' },
  { label: 'Power BI', value: 'power bI' },
  { label: 'Construction Map', value: 'construction demo' },
  { label: 'Direct Georeferencing', value: 'direct georeferencing demo' },
  { label: 'Grading', value: 'grading demo' },
]

export const widgetOptions = (defaultLabel, defaultValue) => {
  if (!defaultValue) return WidgetList
  return [
    { label: `${defaultLabel} Map`, value: defaultValue },
    ...WidgetList
  ]
}
