import React from 'react'
import { useChecklistAPI, useChecklistContext } from "../../contexts/checklistContext"
import { useChecklistFilterContext } from '../../contexts/checklistFilterContext'
import ProjectView from "../../layouts/tablePage/projectView"
import CollapsedStyleButton from './checklistButtons/collapsedStyleButton'
import HideAllChecklistButton from './checklistButtons/hideAllChecklistButton'
import NewChecklistButton from './checklistButtons/newChecklistButton'
import ChecklistFilters from './checklistFilters'
import ChecklistModal from "./checklistModal"
import ChecklistCreationModal from './checklistModal/checklistCreationModal'
import InfoModal from './checklistModal/infoModal'
import ChecklistToolTip from './checklistToolTip'
import ProjectChecklist from "./projectChecklist"

function ProjectChecklistView({ routes }) {
  const { template, projectChecklists, project, modals } = useChecklistContext()
  const { toggleModal } = useChecklistAPI()
  const { checklistIds } = useChecklistFilterContext()

  const filteredProjectChecklists = projectChecklists.filter(checklist => checklistIds.flat().includes(checklist.id))
  const checklists = filteredProjectChecklists.length > 0 ? filteredProjectChecklists : projectChecklists
  const projectChecklistIds = checklists.map((projectChecklist) => projectChecklist.id)

  const projectChecklistComponent = checklists?.map((projectChecklist) => {
    if (projectChecklist.status !== 'Deactivate_Checklist') {
      return <ProjectChecklist key={projectChecklist.id} projectChecklist={projectChecklist} />
    }
    return null
  })

  return (
    <>
      <ProjectView routes={routes}>
        <div className='flex flex-col w-full h-full overflow-scroll'>
          <ChecklistFilters />
          <div className="m-4 sticky left-4 flex flex-row items-center">
            <h1 className="text-3xl font-bold"> {project?.name}- {template.name}</h1>
            <HideAllChecklistButton projectChecklistIds={projectChecklistIds} />
            <CollapsedStyleButton />
          </div>
          {projectChecklistComponent}
          <div className="m-4 sticky left-4">
            <NewChecklistButton />
          </div>
          <ChecklistModal />
          <InfoModal />
          <ChecklistToolTip />
        </div>
        <ChecklistCreationModal
          projectId={project?.id}
          checklistSlug={template?.slug}
          show={modals.projectChecklistCreationModal}
          onClose={() => toggleModal(('projectChecklistCreationModal'))}
        />
      </ProjectView>
    </>
  )
}

export default ProjectChecklistView
