import React from 'react'

const UnavailableFeature = ({featureName}) => {
  return (
    <div className="p-8 w-full flex justify-center">
      <h1>{ featureName } are currently not available for your organization. Please contact ProDelta to learn more and enable this feature.</h1>
    </div>
  )
}

export default UnavailableFeature
