import { isEmpty } from "lodash"
import React, { useEffect, useState } from "react"
import Select from "react-select"
import { useSafetyTicketApi, useSafetyTicketContext } from "../../contexts/safetyTicketContext"

const UserSelect = () => {
  const { users } = useSafetyTicketContext()
  const { updateSelectedUsers } = useSafetyTicketApi()
  const [userOptions, setUserOptions] = useState([])

  useEffect(() => {
    const formattedOptions = users?.map((user) => {
      const userLabel = <span className='font-bold'>{user?.human_identifier}</span>
      return { value: user, label: userLabel }
    })
    setUserOptions(formattedOptions)
  }, [users])
  
  return (!isEmpty(users) && <div>
    <h1 className='font-semibold mx-3'>Select Other User:</h1>
    <div className='grid grid-cols-4 mx-3 my-3'>
      <Select
        isMulti
        options={userOptions}
        onChange={(selectedOption) => {
          const users = selectedOption.map(option => option.value)
          updateSelectedUsers(users)
        }}
        className={"w-4/5"}
      />
    </div>
  </div>)
}

export default UserSelect
