import API from "./apiConfig"

const csrfToken = document.querySelector('[name=csrf-token]').content

const exportPath = ({ projectId }, { portfolioId }) => {
  return projectId ? `/projects/${projectId}/photo_exports` : `/portfolios/${portfolioId}/photo_exports`
}

const photoPath = ({ projectId }, { portfolioId }) => {
  return projectId ? `/projects/${projectId}/photo_gallery` : `/portfolios/${portfolioId}/photo_gallery`
}

export const getNotePhotos = (noteId) => {
  return API
    .get(`/notes/${noteId}/photo_gallery.json`)
    .then(res => res.data)
}

export const getPhotos = ({ projectId, portfolioId }) => {
  return API
    .get(photoPath({ projectId }, { portfolioId }) + '.json')
    .then(res => res.data)
}

export const exportPhotos = ({ projectId, portfolioId, photoExports }) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API
    .post(exportPath({ projectId }, { portfolioId }) + '.json', { photo_export: photoExports })
    .then(res => res.data)
}

export const getPhotoExports = ({ projectId, portfolioId }) => {
  return API
    .get(exportPath({ projectId }, { portfolioId }))
    .then(res => res.data)
}


