import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom"
import { usePortfolioAPI, usePortfolioContext } from "../../contexts/portfolioContext"
import PortfolioSideBar from "../../components/portfolios/portfolio/portfolioSideBar"
import { formatWidgetOption, portfolioWidgetOptions } from "../../utilities/widgetHelpers"
import WidgetContainer from "./widgetContainer"
import ToggleWidgetButtons from "./toggleWidgetButtons"

const PortfolioView = ({ children, WidgetComponentReplacement, showWidget = true }) => {
  const { portfolioId } = useParams()
  const { portfolio, widget, defaultWidget } = usePortfolioContext()
  const { updateWidget, toggleWidget } = usePortfolioAPI()

  const [widgetOptions, setWidgetOptions] = useState()
  const [defaultWidgetOption, setDefaultWidgetOption] = useState()

  useEffect(() => {
    if (defaultWidget) {
      updateWidget(defaultWidget?.full_url)
      setDefaultWidgetOption(formatWidgetOption(defaultWidget))
    }
  }, [defaultWidget])

  useEffect(() => {
    if (portfolio && defaultWidgetOption) portfolioWidgetOptions(portfolioId, defaultWidgetOption).then(res => setWidgetOptions(res))
  }, [portfolio, defaultWidgetOption])

  return <>
    <PortfolioSideBar
      defaultWidget={ defaultWidgetOption }
      changeWidget={ updateWidget }
      widgetOptions={ widgetOptions }
      classNames={ 'lg:w-1/4 lg:h-full overflow-y-scroll' }
      portfolio={ portfolio }
    />
    <div className='flex flex-col lg:w-3/4 h-full w-full'>
      { showWidget && <WidgetContainer WidgetComponentReplacement={ WidgetComponentReplacement }/> }
      { showWidget && <ToggleWidgetButtons toggleWidget={ toggleWidget } widget={ widget }/> }
      { children }
    </div>
  </>
}

export default PortfolioView
