import React, { useMemo, useEffect, useState, useReducer } from "react"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import ReportExportBreadcrumbs from "./breadcrumbs"
import PortfolioView from "../../../layouts/tablePage/portfolioView"
import ProjectView from "../../../layouts/tablePage/projectView"
import SelectDropdown from "./selectDropdown"
import ExportTable from "./exportTable"
import { getTrackerReports } from "../../../api/reportsApi"
import { emailReport } from "../../../api/emailApi"
import HistoryModal from "./historyModal"
import EmailModal from "../../modal/emailModal"

const ReportsExport = ({ routes }) => {
  const [emailModal, toggleEmailModal] = useReducer(state => !state, false)
  const [historyModal, toggleHistoryModal] = useReducer(state => !state, false)
  const { portfolioId, projectId } = useParams()
  const ScopeWrapper = useMemo(() => projectId ? ProjectView : PortfolioView, [projectId])
  const [trackerReports, setTrackerReports] = useState([])
  const [currentTrackerReport, setCurrentTrackerReport] = useState(null)

  const onSend = async ({ selectedEmailOptions, subject, body }) => {
    const params = {
      recipients: selectedEmailOptions.map(email => email.value),
      subject,
      body,
      tracker_report_id: currentTrackerReport?.id
    }

    try {
      await emailReport({ portfolioId, projectId, params })

      const trackerReports = await getTrackerReports({ portfolioId, projectId })
      setTrackerReports(trackerReports)
  
      resetCurrentTrackerReport('email')

      toast.success('Tracker report sent to recipients')
    } catch (e) {
      toast.error('Tracker report failed to send')
    }
  }

  useEffect(() => {
    getTrackerReports({ portfolioId, projectId }).then(trackerReports => setTrackerReports(trackerReports))
  }, [])

  const onModalToggle = ({ report, modalType }) => {
    if (report) setCurrentTrackerReport(report)
    if (modalType === 'email') toggleEmailModal()
    if (modalType === 'history') toggleHistoryModal()
  }

  const resetCurrentTrackerReport = (modalType) => {
    setCurrentTrackerReport(null)
    if (modalType === 'email') toggleEmailModal()
  }

  return (
    <div className="overflow-x-scroll h-full">
      <ReportExportBreadcrumbs />
      <div className='flex flex-col lg:flex-row w-full' style={{ height: "calc(100% - 49px)" }}>
        <ScopeWrapper routes={routes} showWidget={false}>
          <SelectDropdown setTrackerReports={setTrackerReports} />
          <ExportTable
            toggleModal={onModalToggle}
            trackerReports={trackerReports}
            setTrackerReports={setTrackerReports}
          />
          {historyModal && (
            <HistoryModal
              modal={historyModal}
              toggleModal={onModalToggle}
              trackerReport={currentTrackerReport}
            />
          )}
          {emailModal && (
            <EmailModal
              modal={emailModal}
              fileName={currentTrackerReport.report_export_filename}
              title={'report'}
              onClose={() => onModalToggle({ modalType: 'email' })}
              onSend={onSend} />
          )}
        </ScopeWrapper>
      </div>
    </div>
  )
}

export default ReportsExport
