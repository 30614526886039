import React, { useState, useEffect } from "react"
import { tableStyles } from "../../../utilities/stylesHelpers"
import { useTrackerAPI, useTrackerContext } from "../../../contexts/trackerContext"
import { truthConverter } from "../../../utilities/helpers";

const BooleanCell = ({ content, styler, header, entryId }) => {
  const { editCellContent } = useTrackerContext()
  const { onContentChange, onContentInit, onSave, updateAttribute } = useTrackerAPI()
  const isChecked = truthConverter[content?.value]
  const [checkState, setCheckState] = useState(false)

  const handleChange = (e) => {
    onContentInit({
      content: content?.value,
      header: header,
      entryId: entryId,
    })
    onContentChange(!truthConverter[content?.value])
    setCheckState(!checkState)
  }

  useEffect(() => {
    if (!(editCellContent === "")) {
      onSave().then((res) => { updateAttribute(res) })
        .then(() => onContentChange(''))
    }
  }, [checkState])

  return (
    <td className={tableStyles.td} style={{ ...styler }}>
      <div className="flex items-center justify-center gap-2 pb-2">
        <label className="flex flex-col items-center gap-2 cursor-pointer">
          Yes
          <input type="checkbox" checked={ isChecked } onChange={ handleChange } className="cursor-pointer"/>
        </label>
        <label className="flex flex-col items-center gap-2 cursor-pointer">
          No
          <input type="checkbox" checked={ !isChecked } onChange={ handleChange } className="cursor-pointer"/>
        </label>
      </div>
    </td>
  )
}

export default BooleanCell
