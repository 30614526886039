import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import Select from "react-select"
import { useDocumentBuilderApiContext } from "../../../contexts/documentBuilderContext"
import { contentDropdownDefaultOption, contentDropdownOptions } from "../../../utilities/documentBuilderHelpers"

const Dropdown = ({ contentId, content }) => {
  const { updateContent } = useDocumentBuilderApiContext()
  const isInitialSetRef = useRef(false)
  const [currentValue, setCurrentValue] = useState(content?.documentContent || content?.defaultContent)
  const [currentOption, setCurrentOption] = useState({})

  const options = useMemo(() => contentDropdownOptions(content), [content])
  const displayOther = useMemo(() => content?.allowOther && currentOption.value === 'other', [content, currentOption])

  const onSelectHandler = useCallback((event) => {
    setCurrentOption(event)
    setCurrentValue((event.value === "other") ? "" : event.value)
  }, [])

  const onOtherHandler = useCallback((event) => setCurrentValue(event.target.value), [])

  useEffect(() => updateContent(contentId, 'documentContent', currentValue), [updateContent, contentId, currentValue])

  useEffect(() => {
    if (isInitialSetRef.current) return
    const defaultOption = contentDropdownDefaultOption(options, content)
    setCurrentOption(defaultOption)
    updateContent(contentId, 'documentContent', defaultOption.value)
    isInitialSetRef.current = true
  }, [content, options])

  return (
    <div className="w-full flex flex-col gap-2">
      <Select value={ currentOption } options={ options } onChange={ onSelectHandler }/>
      { displayOther && <input
        type="text"
        placeholder="Enter other content"
        onChange={ onOtherHandler }
        value={ currentValue }
      /> }
    </div>
  )
}

export default Dropdown
