import React from 'react'

const Onboarding = ({ title }) => {
  return (
    <div className='w-full rounded-lg bg-gray-100 border border-gray-200 p-20'>
      <p className='text-center text-gray-600 text-xl'>{title}</p>
    </div>
  )
}

export default Onboarding
