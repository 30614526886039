import React, { useEffect, useRef, useState } from "react"
import { Marker, Popup, Tooltip } from "react-leaflet"

const PermitMarker = ({ parseCoordinates, permit }) => {
  const [position, setPosition] = useState({ lat: null, lng: null })
  const markerRef = useRef(null)

  useEffect(() => {
    setPosition(parseCoordinates(permit))
  }, [permit])

  return (
    <>
      {(position?.lat && position?.lng) &&(
        <Marker draggable={false} key={permit.id} position={position} ref={markerRef} eventHandlers={null}>
          <Popup>
            <div className='flex flex-col gap-y-1'>
              <div className='font-bold text-lg'>{permit.payload.id}</div>
              <div>{`(${Number(position?.lat).toFixed(2)}, ${Number(position?.lng).toFixed(2)})`}</div>
            </div>
          </Popup>
          <Tooltip permanent>
            {permit.payload.id}
          </Tooltip>
        </Marker>
      )}
    </>
  )
}

export default PermitMarker
