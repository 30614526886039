import React from "react"
import { inputClass } from "../../../utilities/stylesHelpers"
import { useTimeTableApi } from "../../../contexts/timeTableContext"
import { classNames } from "../../../utilities/utils"

const TimesheetModalHourInput = ({ field, value }) => {
  const { updateEditEntryState } = useTimeTableApi()

  const onChangeHandler = event => updateEditEntryState(field, event.target.value)

  return <>
    <label htmlFor={ field } className="flex justify-end items-center w-full">
      <p>{ field[0].toUpperCase() + field.slice(1) }</p>
    </label>
    <input
      type='number'
      name={ field }
      min="0"
      max="24"
      step="0.25"
      className={ classNames(inputClass, "col-span-2") }
      value={ value }
      onChange={ onChangeHandler }
    />
  </>
}

export default TimesheetModalHourInput
