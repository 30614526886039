import React, { useState, useEffect } from "react"
import { useChecklistAPI } from "../../../contexts/checklistContext"

const HideAllChecklistButton = ({ projectChecklistIds }) => {
  const { collapseAll } = useChecklistAPI()
  const [hide, setHide] = useState(false)
  const hideAll = projectChecklistIds
  const showAll = []

  useEffect(() => {
    hide ? collapseAll(projectChecklistIds) : collapseAll([])
  }, [hide])

  const onClickHandler = () => {
    setHide((hide) => !hide)
  }

  const buttonText = hide ? "Show All Checklists" : "Hide All Checklists"

  return (
    <button
      className="btn btn-primary font-semibold mx-4 my-4 relative cursor-pointer z-10"
      id="add-permit-row-btn"
      name="add-permit-row-btn"
      onClick={onClickHandler}
    >
      {buttonText}
    </button>
  )
}

export default HideAllChecklistButton
