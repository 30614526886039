import React, { useEffect, useMemo, useRef, useState } from 'react'
import Modal from "../../shared/modal"
import { useAiDocumentsAPI, useAiDocumentsContext } from '../../../contexts/aiDocumentsContext'
import FileUploadInput from './inputs/fileUploadInput'

const DocumentModal = () => {
  const { modals } = useAiDocumentsContext()
  const { toggleModal, onUpload, onUpdate } = useAiDocumentsAPI()
  const inputRef = useRef()
  const [initJson, setInitJson] = useState(true)
  const modalType = useMemo(() => modals.upload ? 'upload' : 'update', [modals])
  const modal = useMemo(() => modals.upload || modals.update, [modals])

  const onSave = () => {
    if (modals.upload) {
      onUpload(initJson)
    } else if (modals.update) {
      onUpdate()
    }
  }

  useEffect(() => {
    if (modal && inputRef.current) inputRef.current.focus()
  }, [modal, inputRef.current])

  return (
    <>
      { modal && (
        <Modal
          heading={ 'AI Document' }
          closeModal={ () => toggleModal(modalType) }
          onSave={ onSave }
        >
          <div className='flex flex-col lg:justify-between gap-y-4 lg:gap-y-0 lg:gap-x-4'>
            <div className='w-full'>
              <FileUploadInput/>
              <div className="flex flex-row w-full justify-between">
                <label className='text-lg font-medium cursor-pointer' htmlFor='convert-to-json'>
                  Convert to Json?
                </label>
                <input
                  className="cursor-pointer border border-gray-400 bg-gray-200 rounded-md outline-none relative w-6 h-6"
                  id='convert-to-json'
                  type="checkbox"
                  checked={ initJson }
                  value={ initJson }
                  onChange={ () => setInitJson(state => !state) }
                />
              </div>
            </div>
          </div>
        </Modal>
      ) }
    </>
  )
}

export default DocumentModal
