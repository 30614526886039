import React, { useEffect, useRef, useMemo } from "react"
import { tableStyles } from "../../utilities/stylesHelpers"
import ChecklistEntry from "./projectChecklistEntry"
import InformationCell from "./checklistCell/informationCell.js"
import StandardButton from "../shared/standardButton.js"
import { useChecklistAPI, useChecklistContext } from "../../contexts/checklistContext.js"

const ProjectChecklistRow = ({ entry, task, description, checklistId }) => {
  const { toggleModal, setSelectedEntry } = useChecklistAPI()
  const { lastTask, entriesByProjectChecklistId, collapsed } = useChecklistContext()
  const mounted = useRef(false)
  const entryState = useMemo(() => entriesByProjectChecklistId[entry.project_checklist_id].find(checklistEntry => checklistEntry?.checklist_row_id === entry.checklist_row_id), [entry])

  const openEmail = () => {
    setSelectedEntry({ ...entry, task })
    toggleModal('emailModal')
  }

  useEffect(() => {
    if (entry?.checklist_row_id === lastTask.id) {
      if (mounted.current) {
        if (entryState.checklist_attributes[0].value === "Complete") {
          openEmail()
        }
      }
      mounted.current = true
    }
  }, [entryState.checklist_attributes[0]?.value])


  return (
    <tr>
      <InformationCell content={description} task={task} entry={entry} />
      <td className={collapsed ? tableStyles.tdCollapsed : tableStyles.td}>
        <p className={collapsed ? tableStyles.tdTextTruncate : tableStyles.tdText}>{task}</p>
      </td>
      <ChecklistEntry entry={entry} checklistId={checklistId} />
      <td className={collapsed ? tableStyles.tdCollapsed : tableStyles.td}>
        <StandardButton onClickHandler={openEmail} buttonText="Email" />
      </td>
    </tr>
  )
}

export default ProjectChecklistRow
