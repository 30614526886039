import React, { useState, useEffect } from "react"
import { tableStyles } from "../../../utilities/stylesHelpers"
import { useChecklistAPI, useChecklistContext } from "../../../contexts/checklistContext"
import { truthConverter } from "../../../utilities/helpers";

const BooleanCell = ({ content, column, entryId, projectChecklistId, collapsed }) => {
  const { editCellContent } = useChecklistContext()
  const { onContentChange, onContentInit, onSave, updateAttribute } = useChecklistAPI()
  const isChecked = truthConverter[content?.value]
  const [checkState, setCheckState] = useState(false)
  const tdStyles = collapsed ? tableStyles.tdCollapsed : tableStyles.td

  const handleChange = (e) => {
    onContentInit({
      content: content?.value,
      column: column,
      entryId: entryId,
      projectChecklistId: projectChecklistId,
    })
    onContentChange(!truthConverter[content?.value])
    setCheckState(!checkState)
  }

  useEffect(() => {
    if (!(editCellContent === "")) {
      onSave().then((res) => { updateAttribute(res) })
        .then(() => onContentChange(''))
    }
  }, [checkState])

  return (
    <td className={tdStyles}>
      {!collapsed && <>Yes<br /> </>}
      <input type="checkbox" checked={isChecked} onChange={handleChange} />
    </td>
  )
}

export default BooleanCell
