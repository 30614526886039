const getAverage = (array) => {
  return array.reduce( ( p, c ) => p + c, 0 ) / array.length
}

export const getMapCenter = (boreholes) => {
  const latValues = boreholes.map(borehole => borehole.lat)
  const lngValues = boreholes.map(borehole => borehole.lng)
  const centerLat = getAverage(latValues)
  const centerLng = getAverage(lngValues)

  return [centerLat, centerLng]
}
