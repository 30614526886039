import React from "react";
import PortfolioRow from "./portfolioRow";

const Portfolios = ({ portfolios }) => {
  return <>
    <div className='mx-20 my-10'>
      <h1 className='my-2 text-xl'>Admin Portfolios Index</h1>
      <h2 className='my-2'>Click on a portfolio name below to view or assign mobile templates to the projects associated with the portfolio</h2>
      <table className='text-left w-1/2 text-lg'>
        <thead>
        <tr>
          <th className='w-1/3'>Portfolio Name</th>
          <th className='w-1/12'>ID</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        {portfolios.map(portfolio => <PortfolioRow portfolio={portfolio} />)}
        </tbody>
      </table>
    </div>
  </>
}

export default Portfolios
