import React from 'react'
import StandardButton from '../shared/standardButton'
import { useSafetyTicketApi } from '../../contexts/safetyTicketContext'

const AddUpdateTicketButton = ({ ticket, user, userTicket }) => {
  const addTicket = !userTicket
  const { toggleField, updateState } = useSafetyTicketApi()

  const onClick = () => {
    updateState('userTicket', {
      id: userTicket?.id,
      user_id: user.id,
      safety_ticket_id: ticket.id,
      front_ticket_image_path: userTicket?.front_ticket_image_path,
      back_ticket_image_path: userTicket?.back_ticket_image_path,
      expiry: userTicket?.expiry,
    })
    toggleField('userTicketModal')
  }

  return (
    <>
      <StandardButton buttonText={addTicket ? 'Add Ticket' : 'Update Ticket'} onClickHandler={onClick} />
    </>
  )
}

export default AddUpdateTicketButton
