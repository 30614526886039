import React from "react"
import { useTrackerAPI, useTrackerContext } from "../../../contexts/trackerContext"
import * as cell from '../../tableTemplates/tableCells'
import { isHyperlink } from "../../../utilities/utils"
import BooleanCell from "./booleanCell"
import CollapseCell from "./collapseCell"
import EmailCell from "./emailCell"
import { deleteTrackerAttribute } from "../../../api/trackers"

const hyperlinkType = value => isHyperlink(value) ? 'hyperlink' : ''

const TrackerCell = ({ content, typeParam = '', header = {}, entryId = null, renderPosition }) => {
  const { frozenStyles, collapsedHeaders } = useTrackerContext()
  const { toggleModal, onContentInit, deleteEntryAttribute } = useTrackerAPI()
  const type = typeParam || header?.type || hyperlinkType(content?.value)
  const styler = frozenStyles[header.renderPosition]?.entryStyles || {}
  const collapsed = collapsedHeaders.includes(header?.renderPosition || renderPosition)

  const onAttributeDelete = () => {
    deleteTrackerAttribute(content?.id)
    deleteEntryAttribute(entryId, content?.id)
  }

  const onClickHandler = () => {
    onContentInit({
      content: content?.value,
      cellColor: content?.cell_color,
      header: header,
      entryId: entryId,
      cellId: content?.id,
      revisions: content?.versions,
    })
    toggleModal()
  }

  return (
    <>
      {collapsed ? <CollapseCell styler={styler} /> :
        <>
          {type === 'simple' && <cell.SimpleCell content={content} styler={styler} />}
          {type === 'BooleanHeader' && <BooleanCell content={content} styler={styler} header={header} entryId={entryId} />}
          {type === 'Project' && <cell.DefaultCell content={content} styler={styler} onClickHandler={onClickHandler} />}
          {type === 'hyperlink' && <cell.HyperlinkCell content={content} styler={styler} onClickHandler={onClickHandler} />}
          {type === 'DateHeader' && <cell.DateCell content={content} styler={styler} onClickHandler={onClickHandler} />}
          {type === 'CurrencyHeader' && <cell.CurrencyCell content={content} styler={styler} onClickHandler={onClickHandler} />}
          {type === 'FileUploadHeader' && <cell.FileUploadCell content={content} styler={styler} onClickHandler={onClickHandler} onDeleteFile={onAttributeDelete} />}
          {(type === 'DropdownHeader' || !type) && <cell.DefaultCell content={content?.value} styler={styler} cellColor={content?.cell_color} onClickHandler={onClickHandler} />}
          {type === 'NumberHeader' && <cell.NumberCell content={content} styler={styler} onClickHandler={onClickHandler} />}
          {type === 'WholeNumberHeader' && <cell.NumberCell content={content} styler={styler} onClickHandler={onClickHandler} />}
          {type === 'EmailHeader' && <EmailCell header={header} styler={styler} />}
          {type === 'MathHeader' && <cell.SimpleCell content={content} styler={styler} />}
        </>
      }
    </>
  )
}

export default TrackerCell
