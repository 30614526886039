import React from 'react'
import { AnalyticSummaryProvider } from '../../../contexts/analyticSummaryContext'
import SummaryTable from './summaryTable'

const AnalyticSummary = ({ project, routes }) => {
  return (
    <div className="px-4 w-full h-full">
      <div className='w-full h-full flex flex-row'>
        <div className='flex-grow px-2 py-4'>
          <div className={`w-full px-2 py-4 `}>
            <h2 className="text-2xl font-bold">PROJECT: {project?.name} </h2>
          </div>
          <AnalyticSummaryProvider>
            <SummaryTable />
          </AnalyticSummaryProvider>
        </div>
      </div>
    </div>
  )
}

export default AnalyticSummary
