import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilePdf } from "@fortawesome/free-solid-svg-icons"

const ExportButton = ({ link, children }) => {
  return (
    <a className="btn btn-primary" href={link}>
      <FontAwesomeIcon icon={faFilePdf} className="mr-2" />
      Export {children} Reports
    </a>
  );
}

export default ExportButton
