import React, { useMemo } from "react"
import BreadCrumbs from "../components/projects/nav/breadCrumbs"
import LoadingSpinner from "../components/shared/loadingSpinner"
import { useProjectContext } from "../contexts/projectContext"
import { statusOptions } from "../components/historicalAssessment/historicalAssessmentHelper"
import { isEmpty } from "lodash"
import ProjectSideBar from "../components/projects/project/projectSideBar"

const ProjectLayout = ({ children, nestedBreadcrumbs, pageTitle }) => {
  const { project, portfolio, routes } = useProjectContext()
  const projectStatus = useMemo(() => statusOptions.filter(option => option.value == project?.status)[0], [project])
  const loading = useMemo(() => !!isEmpty(project), [project])

  const pageDisplayTitle = useMemo(() => {
    return pageTitle || (
      <div className='pl-8 pb-5'>
        <h1 className='text-3xl font-bold'>{ project?.name }</h1>
        <h3>{ projectStatus && projectStatus?.label }</h3>
      </div>
    )
  }, [pageTitle, project])

  return (
    <div>
      <BreadCrumbs portfolio={ portfolio } project={ project }>
        { nestedBreadcrumbs }
      </BreadCrumbs>
      { pageDisplayTitle }
      <div className='flex flex-col lg:flex-row h-full'>
        { loading && <div className="w-full py-10 flex items-center justify-center" style={ { maxHeight: '500px' } }>
          <LoadingSpinner/>
        </div> }
        { !loading && (
          <>
            <ProjectSideBar project={ project } routes={ routes } containerClasses='md:w-1/4'/>
            { children }
          </>
        ) }
      </div>
    </div>
  )
}

export default ProjectLayout