export const yesNoOptions = [
  {
    label: 'Yes',
    value: 'true',
  },
  {
    label: 'No',
    value: 'false',
  }
]

export const sitesList = [
  { label: "Well Centre", value: "Well Centre" },
  { label: "Drilling Sump", value: "Drilling Sump" },
  { label: "Flare Pit", value: "Flare Pit" },
  { label: "Flaring Area", value: "Flaring Area" },
  { label: "Above Ground Storage Tank (AST)", value: "Above Ground Storage Tank (AST)" },
  { label: "Under Ground Storage Tank (UST)", value: "Under Ground Storage Tank (UST)" },
  { label: "EM Anomaly", value: "EM Anomaly" },
  { label: "Background", value: "Background" },
  { label: "Historical Spill", value: "Historical Spill" },
  { label: "Compressor Station", value: "Compressor Station" },
  { label: "Dehydrator", value: "Dehydrator" },
  { label: "Amine", value: "Amine" },
  { label: "Riser", value: "Riser" },
  { label: "Generator", value: "Generator" },
  { label: "Other", value: "Other" },
]

const sharedCellStyles = "border border-gray-400 py-4 px-8"

export const tableStyles = {
  table: "border-separate table-fixed my-4 border-spacing-0",
  th: `${sharedCellStyles} whitespace-nowrap`,
  td: sharedCellStyles,
  tdContent: "w-full flex flex-row space-x-6 justify-center",
  radioButton: "p-2.5 outline-solid outline-1 outline-offset-1 rounded-sm appearance-none",
  radioButtonChecked: "inline-block bg-regular-blue",
  radioButtonLabel: "flex flex-row space-x-2 items-center relative",
  radioButtonCheck: "text-xs text-white absolute -left-1 z-10",
}

export const statusOptions = [
  {label: "Historical Site Assessment (Stage 1)", value: 0 },
  {label: "Site Assessment (Stage 2)", value: 1 },
  {label: "Detailed Site Investigation (DSI)", value: 2 },
  {label: "Remediation", value: 3 },
  {label: "Reclamation", value: 4 },
  {label: "Vegetation", value: 5 },
  {label: "Planning", value: 6},
  {label: "Active", value: 7},
  {label: "Completed", value: 8}
]

export const singleOptionize = ( value = '' ) => ({ label: value, value })

export const cycleResponse = (response, func) => {
  for (const key in response) {
    func(response[key])
  }
}
