import React from 'react'
import { useCocExportApi, useCocExport } from "../../../contexts/cocExport"

const Coolers = () => {
  const { numberOfCoolers } = useCocExport()
  const { setNumberOfCoolers } = useCocExportApi()
  const handleChange = (event) => {
    const value = parseInt(event.target.value, 10)
    if (!isNaN(value)) {
      setNumberOfCoolers(value)
    }
  }

  return (
    <>
      <h1>Number of Coolers</h1>
      <input
        type="number"
        value={numberOfCoolers}
        onChange={handleChange}
        min="0"
        className="border border-gray-400 bg-gray-200 focus:bg-white rounded-md outline-none text-black p-2 w-full"
      />
    </>
  )
}

export default Coolers
