import React, { useEffect, useState } from 'react'
import { useProjectApiContext, useProjectContext } from "../../../contexts/projectContext"
import { PermitTableProvider } from "../../../contexts/permitTableContext"
import { columnNames, tableStyles } from '../../../utilities/permitHelpers'
import { pipe } from "../../../utilities/utils"

import PermitHeader from "./permitHeader"
import LoadingSpinner from "../../shared/loadingSpinner"
import PermitModal from "./permitModal"
import TableRow from "./tableRow"
import TableCell from "./tableCell"
import NewPermitButton from "./newPermitButton"
import Onboarding from "../../shared/onboarding"
import Map from "../../admin/projects/map"

const flattenPayloadArray = array => array.reduce((rows, row) => [ ...rows, row['payload'] ], [])

const Permits = () => {
  const { loading, permits, routes } = useProjectContext()
  const { createPermit } = useProjectApiContext()
  const [columnHeaders, setColumnHeaders] = useState([])

  useEffect(() => {
    pipe(flattenPayloadArray, columnNames, setColumnHeaders)(permits)
  }, [permits])

  return (
    <PermitTableProvider routes={routes}>
      <PermitHeader />
      {loading && <LoadingSpinner />}
      {!loading && permits?.length === 0 && (
        <div className='flex flex-col lg:flex-row lg:gap-x-2 flex-grow overflow-hidden'>
          <div className='flex flex-col w-full h-2/3 lg:h-auto lg:w-1/2 flex-grow overflow-scroll'>
            <Onboarding title='No permits found.' />
          </div>
        </div>
      )}
      <div className={`flex flex-col flex-grow overflow-hidden lg:flex-row`}>
        <div className='flex flex-col lg:w-1/4 h-1/3 lg:h-auto'>
          <Map permits={permits} />
        </div>
        <div className={`flex flex-col w-full h-2/3 lg:h-auto lg:w-3/4 flex-grow overflow-scroll gap-y-1`}>
          {columnHeaders?.length > 0 && (
            <table className={tableStyles.table}>
              <thead className={tableStyles.tableHeaderRow}>
              <tr className={tableStyles.tableHeaderRow}>
                {columnHeaders.map((header, index) => (
                  <TableCell key={`${index}-header-key`} content={header} tag={"th"} />
                ))}
                <th className={tableStyles.th}>
                  <div className={tableStyles.thContent}>
                    Link to Permit
                  </div>
                </th>
              </tr>
              </thead>
              <tbody>
              {permits.map((permit, index) => (
                <TableRow
                  key={index}
                  content={permit.payload}
                  headers={columnHeaders}
                  permit={permit} routes={routes}
                >
                  <td className={tableStyles.tdNoHover} style={{minWidth: "120px"}} >
                    <a
                      className={`font-bold hover:underline`}
                      href={routes.permitsPath + "/" + permit.id}
                    >
                      Link
                    </a>
                  </td>
                </TableRow>
              ))}
              </tbody>
            </table>
          )}
          <NewPermitButton headers={columnHeaders} createPermit={createPermit} />
        </div>
      </div>
      <PermitModal/>
    </PermitTableProvider>
  )
}

export default Permits
