import React from "react";

const PortfolioRow = ({ portfolio }) => {
  const urlHost = window.location.origin
  const portfolioUrl = urlHost + `/admin/portfolios/${portfolio.id}`

  return <>
    <tr>
      <td className='py-4'>{portfolio.name}</td>
      <td>{portfolio.id}</td>
      <td>
        <a
          href={portfolioUrl}
          target='_blank'
          className={`my-6 border text-white bg-regular-blue hover:bg-hover-blue border-regular-blue rounded-md py-2 px-3 transition duration-500 select-none font-bold w-40 text-center`}
        >
          View
        </a>
      </td>
    </tr>
  </>
}

export default PortfolioRow
