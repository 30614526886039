import React, { useMemo } from "react";
import { isEmpty } from "lodash";
import { useTrackerContext } from "../../contexts/trackerContext";
import { usePortfolioContext } from "../../contexts/portfolioContext"
import TrackerTable from "./trackerTable";
import Chart from "./chart";
import ProjectView from "../../layouts/tablePage/projectView"

const ProjectTracker = ({ routes }) => {
  const { project } = usePortfolioContext()
  const { chartData } = useTrackerContext()

  const topWidget = useMemo(() => !isEmpty(chartData) && <Chart chartData={chartData} />, [chartData])

  return (
    <ProjectView WidgetComponentReplacement={topWidget} routes={routes}>
      <TrackerTable scopeName={project?.name} />
    </ProjectView>
  )
}

export default ProjectTracker
