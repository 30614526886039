import React from 'react'
import SideBar from "./sideBar"
import Widget from "../../shared/widget";
const widget = 'https://www.maps4free.ca/lizmap/lizmap/www/index.php/view/map/?repository=webmaps&project=Basemap_BingAerial'

const Portfolios = () => {
  return (
    <div className="h-full">
      <div className='pt-8 pl-8 pb-2'>
        <h1 className='text-3xl font-bold'>Portfolios</h1>
      </div>
      <div className='grid grid-cols-12 h-screen'>
        <SideBar
          classNames={'col-span-4 lg:col-span-4 xl:col-span-3 2xl:col-span-2'}
        />
        <div className='col-span-8 lg:col-span-8 xl:col-span-9 2xl:col-span-10 lg:h-full '>
          <Widget widgetProp={widget} widgetStyles="h-screen" />
        </div>
      </div>
    </div>
  )
}

export default Portfolios
