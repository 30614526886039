import React from 'react'
import { useUserLandingPageContext } from "../../contexts/userLandingPageContext"
import Details from './details'
import Widget from "../shared/widget"
import SideBar from './sidebar'
import BreadCrumbs from './breadCrumbs'

const Display = () => {
  const { user, selectedWidget } = useUserLandingPageContext()
  return (
    <>
        <BreadCrumbs />
        <div className='pl-8 pb-5'>
          <h1 className='text-3xl font-bold'>Welcome {user?.human_identifier}</h1>
        </div>
        <div className='flex flex-col lg:flex-row h-full'>
          <SideBar containerClasses='md:w-1/4' />
          <div className='flex flex-col w-full md:w-3/4 p-8 pt-0  h-full overflow-auto'>
            <Widget widgetProp={selectedWidget} widgetStyles="h-screen" buttonName={'Widget'} />
            <div className='flex flex-row justify-between'>
              <Details user={user} containerStyles="my-8 mx-4" />
            </div>
          </div>
        </div>
    </>
  )
}

export default Display
