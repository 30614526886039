import React, { useEffect, useState } from "react"
import { isValid, parseISO } from "date-fns"
import DatePicker from "react-datepicker"
import { useTrackerAPI, useTrackerContext } from "../../../contexts/trackerContext"

const DateInput = () => {
  const { editCellContent } = useTrackerContext()
  const { onContentChange } = useTrackerAPI()
  const [selectedDate, setSelectedDate] = useState(new Date)

  useEffect(() => {
    const parsedContent = parseISO(editCellContent)
    if (isValid(parsedContent)) {
      setSelectedDate(parsedContent)
    } else {
      onContentChange(selectedDate)
    }
  }, [])

  const onChangeHandler = (date) => {
    setSelectedDate(date)
    onContentChange(date)
  }

  return (
    <DatePicker
      className='block w-full p-1 text-xs text-gray-700 appearance-none focus:outline-none border border-gray-200 focus:shadow-inner m-0 rounded z-1000'
      selected={selectedDate}
      onChange={onChangeHandler}
    />
  )
}

export default DateInput
