import React from "react"
import { isEmpty } from "lodash"
import { usePortfolioContext } from "../../contexts/portfolioContext"
import { useTrackerContext } from "../../contexts/trackerContext"
import TrackerTable from "./trackerTable";
import PortfolioView from "../../layouts/tablePage/portfolioView"
import Chart from "./chart"

const PortfolioTracker = () => {
  const { portfolio } = usePortfolioContext()
  const { chartData } = useTrackerContext()

  return <>
    <PortfolioView WidgetComponentReplacement={!isEmpty(chartData) && <Chart chartData={chartData} />}>
      <TrackerTable scopeName={portfolio?.name} />
    </PortfolioView>
  </>
}

export default PortfolioTracker
