import React, { createContext, useCallback, useContext, useEffect, useRef, useMemo, useReducer } from "react"
import { queryAi, streamAiQuery } from "../api/aiPluginApi"
import openaiSocket from "../sockets/openaiSocket"

/* Actions */
const ACTIONS = {
  ADD_MESSAGE: 'ADD_MESSAGE',
  UPDATE_EDITOR_STATE: 'UPDATE_EDITOR_STATE',
  TOGGLE_LOADING_MESSAGE: 'TOGGLE_LOADING_MESSAGE',
  UPDATE_MESSAGE: 'UPDATE_MESSAGE',
}

/* Initial States */
const initialState = {
  messages: [],
  editorState: '',
  loadingMessage: false,
}

const messageState = (content, role) => ({ content, role })

/* Reducer */
const aiPluginReducer = (state, action) => {
  switch (action?.type) {
    case ACTIONS.ADD_MESSAGE:
      const newMessage = messageState(action.message, action.role)
      return { ...state, messages: [...state.messages, newMessage] }
    case ACTIONS.UPDATE_MESSAGE:
      const messageIndex = state.messages.findIndex(message => message?.id === action.message.id)
      const focusMessage = messageIndex > -1 ? state.messages[messageIndex] :
        { id: action.message.id, content: "", role: "assistant" }
      focusMessage.content += action.message.content
      if (messageIndex > -1) {
        return state
      } else {
        return { ...state, messages: [...state.messages, focusMessage] }
      }
    case ACTIONS.UPDATE_EDITOR_STATE:
      return { ...state, editorState: action.content }
    case ACTIONS.TOGGLE_LOADING_MESSAGE:
      return { ...state, loadingMessage: action?.loadingStatus || !state.loadingMessage }
    default:
      return state
  }
}

/* Contexts */
const AiPluginContext = createContext(initialState)
const AiPluginApiContext = createContext({
  addMessage: () => {},
  updateMessage: () => {},
  clearEditor: () => {},
  updateEditor: () => {},
  toggleLoadingMessage: () => {},
  aiResponse: () => {},
  aiStream: () => {},
  submitMessage: () => {},
})

/* Providers */
export const AiPluginProvider = ({ user, children }) => {
  const [state, dispatch] = useReducer(aiPluginReducer, initialState)
  const socket = useRef(null)

  const editorApi = useMemo(() => {
    const addMessage = ( message, role = "user" ) => dispatch({ type: ACTIONS.ADD_MESSAGE, message, role })
    const updateMessage = ( message ) => {
      const newMessage = { id: message.id, content: message.choices[0].delta.content || "" }
      dispatch({ type: ACTIONS.UPDATE_MESSAGE, message: newMessage })
    }

    const clearEditor = () => dispatch({ type: ACTIONS.UPDATE_EDITOR_STATE, content: '' })

    const updateEditor = (content) => dispatch({ type: ACTIONS.UPDATE_EDITOR_STATE, content })

    const toggleLoadingMessage = (loadingStatus = undefined) => dispatch({ type: ACTIONS.TOGGLE_LOADING_MESSAGE, loadingStatus })

    const aiResponse = async (query) => {
      return await queryAi(query)
        .then(message => editorApi.addMessage(message.content, message.role))
    }

    const aiStream = async (query) => await streamAiQuery(query)

    return { addMessage, updateMessage, clearEditor, updateEditor, toggleLoadingMessage, aiResponse, aiStream }
  }, [])

  const submitMessage = useCallback(async () => {
    editorApi.toggleLoadingMessage(true)
    if (state.editorState) {
      const newMessage = { role: "user", content: state.editorState }
      const queryThread = [ ...state.messages, newMessage ]
      await editorApi.addMessage(newMessage.content, newMessage.role)
      editorApi.clearEditor()
      await editorApi.aiStream(queryThread)
    }
    editorApi.toggleLoadingMessage(false)
  }, [state])

  useEffect(() => {
    socket.current = openaiSocket({ onMessage: editorApi.updateMessage, id: user.id })
  }, [])

  return (
    <AiPluginApiContext.Provider value={{ ...editorApi, submitMessage }}>
      <AiPluginContext.Provider value={state}>
        {children}
      </AiPluginContext.Provider>
    </AiPluginApiContext.Provider>
  )
}

/* Hooks */
export const useAiPluginContext = () => useContext(AiPluginContext)
export const useAiPluginApi = () => useContext(AiPluginApiContext)
