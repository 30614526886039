import React from 'react'
import VerticalTable from "./verticalTable"
import HorizontalTable from "./horizontalTable"

const SimpleTrackerTable = ({ trackerHeaders, trackerEntries, isVertical = false }) => {
  return <>
    { isVertical && <VerticalTable trackerHeaders={ trackerHeaders } trackerEntries={ trackerEntries }/> }
    { !isVertical && <HorizontalTable trackerHeaders={ trackerHeaders } trackerEntries={ trackerEntries }/> }
  </>
}

export default SimpleTrackerTable
