import React, { createContext, useContext, useEffect, useMemo, useReducer } from 'react'
import { useParams } from 'react-router-dom'
import {
  assignUserToEntry,
  buildChecklistPath,
  getChecklist,
  updateChecklistAttribute,
  uploadChecklistAttributeFile
} from "../api/checklist"
import { getPortfolio } from "../api/portfoliosApi"
import { getProject } from "../api/projectApi"
import {
  deleteChecklistAttribute,
  updateCollapsedArray,
  updateEntry,
  updateEntryAttribute,
  updateProjectChecklists,
  updateToggleModal
} from '../utilities/checklistHelpers'

/* ACTIONS */
const INIT_CHECKLIST_INFO = 'INIT_CHECKLIST_INFO'
const UPDATE_STATE = 'UPDATE_STATE'
const UPDATE_WIDGET = 'UPDATE_WIDGET'
const INIT_EDIT_CELL = 'INIT_EDIT_CELL'
const UPDATE_MODAL_CONTENT = 'UPDATE_MODAL_CONTENT'
const TOGGLE_MODAL = 'TOGGLE_MODAL'
const UPDATE_ATTRIBUTE = 'UPDATE_ATTRIBUTE'
const UPDATE_CHECKLISTS = 'UPDATE_CHECKLISTS'
const UPDATE_COLLAPSED_CHECKLISTS = 'UPDATE_COLLAPSED_CHECKLISTS'
const COLLAPSE_ALL = 'COLLAPSE_ALL'
const UPDATE_FILE = 'UPDATE_FILE'
const TOGGLE_TOOL_TIP = 'TOGGLE_TOOL_TIP'
const INIT_TOOL_TIP_INFO = 'INIT_TOOL_TIP_INFO'
const DELETE_ATTRIBUTE = 'DELETE_ATTRIBUTE'
const UPDATE_ENTRY = 'UPDATE_ENTRY'
const TOGGLE_COLLAPSED = 'TOGGLE_COLLAPSED'

/* INITIAL STATES */
const initialState = {
  portfolio: null,
  project: null,
  projects: [],
  template: {},
  projectChecklists: [],
  rows: [],
  columns: [],
  entriesByProjectChecklistId: {},
  widget: '',
  editCellColor: '',
  editCellContent: '',
  editCellColumn: '',
  editProjectChecklistId: '',
  editCellFile: null,
  editCellRevisions: [],
  editEntryId: '',
  modals: { checklistModal: false, taskInfoModal: false, infoModal: false, emailModal: false, projectChecklistCreationModal: false },
  chartData: null,
  collapsedChecklists: [],
  taskDescription: '',
  modalTitle: '',
  entry: null,
  toolTip: false,
  toolTipMessage: '',
  users: [],
  selectedEntry: null,
  lastTask: null,
  collapsed: null
}

/* REDUCER */
const checklistReducer = (state, action) => {
  switch (action.type) {
    case INIT_CHECKLIST_INFO:
      return {
        ...state,
        template: action.payload.template,
        projectChecklists: action.payload.projectChecklists,
        rows: action.payload.rows,
        columns: action.payload.columns,
        entriesByProjectChecklistId: action.payload.entriesByProjectChecklistId,
        editCellRevisions: action.payload?.revisions,
        users: action.payload?.users,
        lastTask: action.payload.lastTask,
        collapsed: action.payload.collapsed
      }
    case INIT_EDIT_CELL:
      return {
        ...state,
        editEntryId: action.payload.entryId,
        editCellId: action.payload?.cellId,
        editCellColor: action.payload?.cellColor || '',
        editCellColumn: action.payload.column,
        editCellContent: action.payload?.content || '',
        editCellRevisions: action.payload?.revisions,
        editProjectChecklistId: action.payload?.projectChecklistId,
        modalTitle: action.payload?.modalTitle,
        taskDescription: action.payload?.taskDescription,
        entry: action.payload?.entry,
      }
    case UPDATE_STATE:
      return { ...state, [action.field]: action.value }
    case UPDATE_WIDGET:
      return { ...state, widget: action.payload }
    case UPDATE_MODAL_CONTENT:
      return { ...state, editCellContent: action.payload.value, editCellColor: action.payload.cellColor }
    case TOGGLE_MODAL:
      const updatedToggle = updateToggleModal(state.modals, action.payload)
      return { ...state, modals: updatedToggle }
    case UPDATE_ATTRIBUTE:
      const updatedEntries = updateEntryAttribute(state.entriesByProjectChecklistId, state.editProjectChecklistId, state.editCellColumn, action.payload)
      return {
        ...state,
        entriesByProjectChecklistId: updatedEntries,
        editCellColor: '',
        editCellContent: '',
        editCellColumn: '',
        editProjectChecklistId: '',
        editCellFile: null,
        editCellRevisions: [],
        editEntryId: '',
      }
    case UPDATE_CHECKLISTS:
      const updatedChecklists = updateProjectChecklists(state.projectChecklists, action.payload)
      return {
        ...state,
        projectChecklists: updatedChecklists
      }
    case UPDATE_COLLAPSED_CHECKLISTS:
      const updatedCollapsedChecklists = updateCollapsedArray(action.payload, state.collapsedChecklists)
      return {
        ...state,
        collapsedChecklists: updatedCollapsedChecklists
      }
    case COLLAPSE_ALL:
      return { ...state, collapsedChecklists: action.payload }
    case UPDATE_FILE:
      return { ...state, editCellFile: action.payload }
    case TOGGLE_TOOL_TIP:
      return { ...state, toolTip: action.payload }
    case INIT_TOOL_TIP_INFO:
      return {
        ...state,
        toolTipMessage: action.payload.toolTipMessage,
      }
    case DELETE_ATTRIBUTE:
      const updatedEntriesAfterDelete = deleteChecklistAttribute(state.entriesByProjectChecklistId, action.payload.projectChecklistId, action.payload.attributeId)
      return {
        ...state,
        entriesByProjectChecklistId: updatedEntriesAfterDelete,
      }
    case UPDATE_ENTRY:
      const updatedValues = updateEntry(state.entriesByProjectChecklistId, state.editProjectChecklistId, action.payload)
      return {
        ...state,
        entriesByProjectChecklistId: updatedValues,
        editCellColor: '',
        editCellContent: '',
        editCellColumn: '',
        editProjectChecklistId: '',
        editCellFile: null,
        editCellRevisions: [],
        editEntryId: '',
      }
    case TOGGLE_COLLAPSED:
      return {
        ...state, collapsed: !state.collapsed
      }
    default:
  }
}

/* CONTEXT */
const ChecklistContext = createContext(initialState)
const ChecklistAPIContext = createContext({
  updateWidget: () => {},
  onSave: () => {},
  toggleModal: () => {},
  updateAttribute: () => {},
  updateState: () => {},
  updateChecklists: () => {},
  updateCollapsedChecklists: () => {},
  collapseAll: () => {},
  updateFile: () => {},
  toggleToolTip: () => {},
  updateToolTipInfo: () => {},
  deleteAttribute: () => {},
  setSelectedEntry: () => {},
  updateEntry: () => {},
  toggleCollapsed: () => {}
})

/* PROVIDERS */
export const ChecklistProvider = ({ children }) => {
  const { portfolioId, projectId, checklistSlug, projectChecklistId } = useParams()

  const checklistPath = buildChecklistPath({ portfolioId, projectId, checklistSlug, projectChecklistId })

  const [state, dispatch] = useReducer(checklistReducer, initialState)

  const api = useMemo(() => {

    const updateState = (field, value) => {
      dispatch({ type: UPDATE_STATE, field, value })
    }

    const updateWidget = (widget) => {
      dispatch({ type: UPDATE_WIDGET, payload: widget })
    }

    const onContentInit = ({ content, cellColor, column, entryId, cellId, revisions, projectChecklistId, modalTitle, taskDescription, entry }) => {
      dispatch({ type: INIT_EDIT_CELL, payload: { content, cellColor, column, entryId, cellId, revisions, projectChecklistId, modalTitle, taskDescription, entry } })
    }

    const toggleModal = (type) => dispatch({ type: TOGGLE_MODAL, payload: type })

    const toggleToolTip = (status) => dispatch({ type: TOGGLE_TOOL_TIP, payload: status })

    const updateAttribute = (attribute) => {
      dispatch({ type: UPDATE_ATTRIBUTE, payload: attribute })
    }

    const onContentChange = (value, cellColor) => {
      dispatch({ type: UPDATE_MODAL_CONTENT, payload: { value, cellColor } })
    }

    const updateChecklists = (projectChecklist) => {
      dispatch({ type: UPDATE_CHECKLISTS, payload: projectChecklist })
    }

    const updateCollapsedChecklists = (projectChecklistId) => {
      dispatch({ type: UPDATE_COLLAPSED_CHECKLISTS, payload: projectChecklistId })
    }

    const collapseAll = (projectChecklistIds) => {
      dispatch({ type: COLLAPSE_ALL, payload: projectChecklistIds })
    }
    const updateFile = (file) => {
      dispatch({ type: UPDATE_FILE, payload: file })
    }
    const updateToolTipInfo = (toolTipMessage) => {
      dispatch({ type: INIT_TOOL_TIP_INFO, payload: { toolTipMessage } })
    }
    const setSelectedEntry = (entry) => {
      dispatch({ type: UPDATE_STATE, field: 'selectedEntry', value: entry })
    }
    const deleteAttribute = (projectChecklistId, attributeId) => {
      dispatch({ type: DELETE_ATTRIBUTE, payload: { projectChecklistId, attributeId } })
    }
    const updateEntry = (entry) => {
      dispatch({ type: UPDATE_ENTRY, payload: entry })
    }

    const toggleCollapsed = () => {
      dispatch({ type: TOGGLE_COLLAPSED })
    }

    return {
      updateState,
      updateWidget,
      onContentInit,
      toggleModal,
      toggleToolTip,
      updateAttribute,
      onContentChange,
      updateChecklists,
      updateCollapsedChecklists,
      collapseAll,
      updateFile,
      updateToolTipInfo,
      setSelectedEntry,
      deleteAttribute,
      updateEntry,
      toggleCollapsed
    }
  }, [])

  api.onSave = () => {
    if (state.editCellFile) {
      const params = {
        checklist_column_id: state.editCellColumn.id,
        checklist_entry_id: state.editEntryId
      }
      const file = state.editCellFile
      return uploadChecklistAttributeFile(file, params)
    }
    else if (state.editCellColumn.type === 'UserColumn') {
      return assignUserToEntry(state.editEntryId, state.editCellContent)
    }
    else {
      const specificChecklistPath = buildChecklistPath({ portfolioId, projectId, checklistSlug, projectChecklistId: state.editProjectChecklistId })
      const updateData = {
        entry_id: state.editEntryId,
        checklist_attribute: {
          value: state.editCellContent,
          cell_color: state.editCellColor,
          checklist_column_id: state.editCellColumn.id,
          file: state.editCellFile,
        }
      }
      return updateChecklistAttribute(specificChecklistPath, updateData)
    }
  }

  /* INITALIZE PROJECT AND PORTFOLIO */
  useEffect(() => {
    const fetchData = async () => {
      try {
        void getChecklist(checklistPath)
          .then(res => {
            dispatch({ type: INIT_CHECKLIST_INFO, payload: { template: res.template, projectChecklists: res.project_checklists, projects: res.projects, entriesByProjectChecklistId: res.entries_by_project_checklist_id, rows: res.template.checklist_rows, columns: res.template.checklist_columns, lastTask: res.template.last_task, users: res.users, collapsed: res.template.collapsed } })
          })
        if (portfolioId) {
          getPortfolio(portfolioId)
            .then(res => api.updateState('portfolio', res))
        } else {
          getProject(projectId)
            .then(res => {
              api.updateState('project', res.data)
              getPortfolio(res.data.portfolio_id)
                .then(res => api.updateState('portfolio', res))
            })
        }
      }
      catch (error) {
        console.error('Error fetching the Portfolio or Project info', error);
      }
    }
    fetchData()
  }, [])

  return (
    <ChecklistAPIContext.Provider value={api}>
      <ChecklistContext.Provider value={state}>
        {children}
      </ChecklistContext.Provider>
    </ChecklistAPIContext.Provider>
  )
}

/* Custom Context Hooks */
export const useChecklistContext = () => useContext(ChecklistContext)
export const useChecklistAPI = () => useContext(ChecklistAPIContext)

