import React from "react"
import { tableStyles } from "../../../utilities/stylesHelpers"
import ReportsExportRow from "./reportsExportRow"

const ExportTable = ({ trackerReports, setTrackerReports, toggleModal }) => {
  return (
    <div className="m-4 w-full overflow-x-auto">
      <table className={ tableStyles.table + " min-w-full" }>
        <thead className={ tableStyles.tableHeaderRow + " bg-gray-700 text-white" }>
        <tr className={ tableStyles.tableHeaderRow }>
          <th className={ "border-gray-200 border border-b-2 align-top sticky top-0 p-4" }>Report Name</th>
          <th className={ "border-gray-200 border border-b-2 align-top sticky top-0 p-4" }>Date Generated</th>
          <th className={ "border-gray-200 border border-b-2 align-top sticky top-0 p-4 w-[500px]" }>Actions</th>
        </tr>
        </thead>
        <tbody>
        { trackerReports?.map(trackerReport =>
          <ReportsExportRow
            key={ `tracker-report-${ trackerReport.id }` }
            reportName={ trackerReport.report.name }
            reportId={ trackerReport.id }
            reportDate={ new Date(trackerReport.created_at).toDateString() }
            setTrackerReports={ setTrackerReports }
            trackerReport={ trackerReport }
            toggleModal={ toggleModal }
          />
        ) }
        </tbody>
      </table>
    </div>
  )
}

export default ExportTable
