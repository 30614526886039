import React from 'react'

import AiTabs from './aiTabs'
import DocumentGrid from './documentGrid'
import DocumentModal from './documentModal'
import PromptCollections from '../promptCollections'
import TrackerTable from '../../trackers/trackerTable'

import { usePortfolioContext } from "../../../contexts/portfolioContext"
import { TrackerProvider } from '../../../contexts/trackerContext'
import { TrackerFilterProvider } from '../../../contexts/trackerFilterContext'
import { useAiDocumentsContext } from '../../../contexts/aiDocumentsContext'

const AiDisplays = () => {
  const { project } = usePortfolioContext()
  const { activeTab } = useAiDocumentsContext()
  const trackerTab = activeTab?.slug ? activeTab.slug : null

  return (
    <>
      <AiTabs/>
      { !trackerTab && (activeTab?.name === 'Prompt Collection') && (<>
        <PromptCollections/>
      </>) }
      { !trackerTab && (activeTab?.name === 'Documents') && (
        <>
          <DocumentGrid/>
          <DocumentModal/>
        </>
      ) }
      { trackerTab && <TrackerProvider providedTrackerSlug={ activeTab?.slug } key={ activeTab?.slug }>
        <TrackerFilterProvider>
          <TrackerTable scopeName={ project?.name }/>
        </TrackerFilterProvider>
      </TrackerProvider> }
    </>
  )
}

export default AiDisplays
