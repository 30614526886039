import React, { useMemo } from "react"
import Breadcrumb from "../../layouts/nav/breadcrumb"
import { pathBuilder } from "../../api/documentLogApi"
import { useLocation, useParams } from "react-router-dom"
import { useDocumentBuilderContext } from "../../contexts/documentBuilderContext"

const Breadcrumbs = () => {
  const { projectId, templateSlug, documentId } = useParams()
  const { pathname } = useLocation()
  const { template } = useDocumentBuilderContext()

  const pathAction = useMemo(() => pathname.split("/").slice(-1)[0], [pathname])

  return (<>
    <Breadcrumb linkPath={ `/projects/${ projectId }/document-log` } linkName="Document Log" routerLink={ true }/>
    <Breadcrumb linkPath={ `/projects/${ projectId }/document-log` } linkName={ template.name } routerLink={ true }/>
    { documentId && <Breadcrumb linkPath={ pathBuilder({ projectId, templateSlug, documentId }) } linkName={ documentId } routerLink={ true }/> }
    { (pathAction !== documentId) && (
      <Breadcrumb linkPath={ pathBuilder({ projectId, templateSlug, documentId, action: pathAction }) } linkName={ pathAction } routerLink={ true }/>
    ) }
  </>)
}

export default Breadcrumbs
