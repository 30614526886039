import React, { useMemo, useState } from 'react'
import { tableStyles } from '../../../utilities/stylesHelpers'
import StandardButton from '../../shared/standardButton'
import { openInNewTab } from '../../../utilities/helpers'
import { formatDateTimeStr } from '../../../utilities/dateHelpers'
import { emailSubject, emailBody, getExportDetails } from '../../../utilities/cocExportHelpers'
import { useCocExportApi, useCocExport } from '../../../contexts/cocExport'
import LoadingSpinner from '../../shared/loadingSpinner'

const ExportHistoryRow = ({ exportObject, toggleModal, project }) => {
  const { updateSelectedChemicals, updateSelectedSamples, updateHeaderDetails } = useCocExportApi()
  const { allSamples, headerDetails } = useCocExport()
  const [loading, setLoading] = useState(false)
  const [checkAbove, setCheckAbove] = useState(false)
  const { updateEmailInfo } = useCocExportApi()
  const dateValue = useMemo(() => formatDateTimeStr(exportObject.created_at), [exportObject])
  const draftStatus = exportObject.draft_status
  const numAttachments = exportObject.coc_export_filename.length
  const name = numAttachments <= 1 ? exportObject.coc_export_filename[0] : `LAB PACKAGE ${dateValue}`
  const draftName = "DRAFT: " + dateValue
  const previewPath = numAttachments <= 1 ? exportObject.coc_export_file_path[0] : exportObject.coc_export_file_path[numAttachments - 1]

  const onLoadCoc = () => { setLoading(true), getExportDetails(exportObject.id, project, setLoading, updateSelectedSamples, updateSelectedChemicals, updateHeaderDetails, allSamples), setCheckAbove(true) }
  const onPreview = () => openInNewTab(`${previewPath}`)

  const onEmailClick = () => {
    const subject = emailSubject(exportObject, project.name)
    const body = emailBody(exportObject)
    updateEmailInfo(subject, body)
    toggleModal({ exportObject: exportObject, modalType: 'email' })
  }

  return (
    <tr>
      <td className={tableStyles.td}>
        <span className={draftStatus ? 'opacity-75' : ''}>{draftStatus ? draftName : name}</span>
      </td>
      <td className={tableStyles.td}>{dateValue}</td>
      <td className={tableStyles.td}>
        {!draftStatus && <StandardButton onClickHandler={onEmailClick} buttonText="Email" />}
        {!draftStatus && <StandardButton onClickHandler={onPreview} buttonText="Preview" />}
        <StandardButton onClickHandler={() => toggleModal({ exportObject: exportObject, modalType: 'history' })} buttonText="View History" btnClass='btn-orange' />
        <StandardButton onClickHandler={onLoadCoc} buttonText="Load CoC" btnClass='btn-green' />
        {loading && <LoadingSpinner />}
        {checkAbove && (loading ? <p className={"text-red-500"}>CoC Selection Loading Above</p> : <p className={"text-green-500"}>CoC Selection Loaded</p>)}
      </td>

    </tr>)
}

export default ExportHistoryRow
