import React from "react"
import { statusOptions } from "../../../historicalAssessment/historicalAssessmentHelper";
import StatusList from "./statusList";

const ProjectStatuses = () => {
  const assessmentOptions = statusOptions.filter(option => option.value < 6)
  const constructionOptions = statusOptions.filter(option => option.value >= 6)

  return <div>
    <h1>Project Statuses:</h1>
    <p className='italic'>* Please use the integer value of the status and not the description</p>
    <h2 className='mt-3'>Assessment Statuses:</h2>
    <StatusList statuses={assessmentOptions} />
    <h2 className='mt-3'>Construction Statuses:</h2>
    <StatusList statuses={constructionOptions} />
  </div>
}

export default ProjectStatuses
