import React from "react"
import ChecklistCell from "./checklistCell"
import { useChecklistContext } from "../../contexts/checklistContext"


const ChecklistEntry = ({ entry, checklistId }) => {
  const { columns } = useChecklistContext()

  const checklistCells = columns?.map(column => {
    const attribute = entry?.checklist_attributes?.find(attribute => attribute.checklist_column_id === column.id);
    return (
      <ChecklistCell
        key={attribute?.id || `column-${column.id}`}
        attribute={attribute}
        entry={entry}
        column={column}
        checklistId={checklistId}
      />
    )
  })

  return (
    <>
      {checklistCells}
    </>
  )
}

export default ChecklistEntry
