import React, { useMemo } from "react"
import { isEmpty } from "lodash"
import { useTrackerContext } from "../../contexts/trackerContext"
import { usePortfolioAPI } from "../../contexts/portfolioContext"
import TrackerCell from "./trackerCell"
import DeleteCell from "./trackerCell/deleteCell"
import SelectionCell from "./trackerCell/selectionCell"
import AutoCell from "./trackerCell/autoCell"
import ZoomButton from "./zoomButton"
import { cellContent } from "../../utilities/trackerHelpers"
import { useTrackerEntryApi, useTrackerEntryContext } from "../../contexts/trackerEntryContext";
import EmailModal from "../modal/emailModal";
import RenderModal from "../modal/renderModal";

const TrackerRow = ({ children, entry, headers }) => {
  const { trackerTemplate, autoHeaders, chartData, showExtraData } = useTrackerContext()
  const { isPortfolio } = usePortfolioAPI()
  const { project, emailModal, attachments, emailSubject } = useTrackerEntryContext()
  const { toggleEmailModal, sendEmail } = useTrackerEntryApi()

  const filename = useMemo(() => attachments.map(att => att.filename), [attachments])
  const rowCount = headers?.length
  const autoHeaderCount = autoHeaders?.length
  return (
    <tr>
      {headers?.map((header, index) => (
        <TrackerCell key={index} content={cellContent(header, entry.tracker_attributes)} header={header}
          entryId={entry.id} />))}
      {showExtraData && isPortfolio &&
        <TrackerCell entryId={entry.id} content={project?.name || ''}
          header={{ "type": "Project", "title": "Project" }} renderPosition={rowCount} />}
      {showExtraData && autoHeaders?.map((header, index) => (
        <AutoCell key={index} content={cellContent(header, entry.tracker_attributes)} header={header}
          entry={entry} renderPosition={rowCount + index + 1} />))}

      {trackerTemplate?.zoom_to_button && isEmpty(chartData) && <ZoomButton renderPosition={rowCount + autoHeaderCount + 1} />}
      {children}
      {trackerTemplate?.enable_entry_deletion && <DeleteCell entryId={entry?.id} renderPosition={rowCount + autoHeaderCount + 2} />}
      {trackerTemplate?.enable_entry_duplication && <SelectionCell entryId={entry?.id} renderPosition={rowCount + autoHeaderCount + 3} />}
      <RenderModal>
        {emailModal && <EmailModal modal={emailModal} fileName={filename} title={'Tracker Entry Attachments'}
          emailSubject={emailSubject} onClose={toggleEmailModal} onSend={sendEmail} />}
      </RenderModal>
    </tr>
  )
}

export default TrackerRow
