import React from "react"

let idNum = 0
const classNames = (...classes) => classes.filter(Boolean).join(' ')

const labelClass = classNames("w-5 h-5 min-w-5 max-w-5 min-h-5 max-h-5 block bg-transparent rounded-md",
  "border-solid border-2 relative cursor-pointer group/animated-box has-[:focus]:outline outline-stone-400 border-stone-400",
  "has-[:checked]:border-green-500 has-[:disabled]:cursor-default")

const sharedClasses = classNames(
  "absolute w-1 bg-green-500 origin-top-left rounded-md h-0 duration-300 transition-none",
  "group-has-[:checked]/animated-box:opacity-100 opacity-0")

const checkClass = classNames(sharedClasses, "top-[5.92px] left-[0.8px] rotate-[-45deg] group-has-[:checked]/animated-box:h-2")
const checkStemClass = classNames(sharedClasses,
  "top-[11.52px] left-[6.56px] rotate-[-135deg] group-has-[:checked]/animated-box:h-6 delay-300 ease-in")

const AnimatedCheckbox = ({ id = `animated-checkbox-${ ++idNum }`, checked, value, onClick, children }) => {
  return (
    <label className={ labelClass } htmlFor={ id }>
      <input id={ id } name={ id } type="checkbox" checked={ checked } value={ value } onChange={ onClick }
             disabled={ !onClick } className="opacity-0 w-0 h-0 origin-top-left top-0 left-0 absolute peer"/>
      <div className={ checkClass } style={{ transitionProperty: 'height' }}></div>
      <div className={ checkStemClass } style={{ transitionProperty: 'height' }}></div>
      { children }
    </label>
  )
}

export default AnimatedCheckbox
