import React from "react"
import { $generateHtmlFromNodes } from '@lexical/html'
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"

const StoreAsHtmlPlugin = ({ onChange }) => {
  const [editor] = useLexicalComposerContext()

  const onChangeHandler = () => {
    editor.update(() => {
      const rawHtml = $generateHtmlFromNodes(editor, null)
      onChange(rawHtml)
    })
  }

  return <OnChangePlugin onChange={ onChangeHandler } ignoreSelectionChange/>
}

export default StoreAsHtmlPlugin
