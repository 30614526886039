import API, { simpleGet, simplePost, simplePut } from "./apiConfig"
const csrfToken = document.querySelector('[name=csrf-token]').content

export const getTimesheet = () => simpleGet('/timesheets.json')

export const postTimesheetEntry = formData => simplePost('/timesheet_entries', formData)

export const updateTimesheetEntry = (entryId, formData) => simplePut(`/timesheet_entries/${ entryId }`, formData)

export const getTimesheetEntries = (userIds) => simpleGet('/timesheet_entries.json', { params: { users_ids: userIds} })

export const deleteTimesheetEntry = entryId => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API.delete(`/timesheet_entries/${ entryId }`)
}
