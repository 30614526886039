import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight, faExclamation } from "@fortawesome/free-solid-svg-icons";
import { Link, useParams } from "react-router-dom";

const NoteRow = ({ note }) => {
  let [isSelected, setIsSelected] = useState(false)

  let { noteId } = useParams()
  
  useEffect(() => {
    setIsSelected(noteId && note.id === parseInt(noteId))
  },[noteId])

  return <div className='divide-x divide-gray-200 w-32'>
    <div
      className={`bg-gray-50 px-6 py-3 text-left text-sm font-medium uppercase tracking-wider flex flex-row justify-between items-center ${isSelected ? 'bg-gray-100' : 'bg-white'}`}>
      <Link to={note.path} className='flex flex-row gap-x-2 items-center'>
        <FontAwesomeIcon className='cursor-pointer w-8' icon={isSelected ? faChevronDown : faChevronRight} />
        {note.title}
      </Link>
      {note.newPosition ?
        <div className='flex flex-row text-yellow-500 items-center' title='Coordinates are not saved'>
          <FontAwesomeIcon icon={faExclamation} className='mx-1' />
        </div>
        : <></>
      }
    </div>
  </div>
}

export default NoteRow
