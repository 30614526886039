import React, { useEffect } from 'react'
import { useCocExport, useCocExportApi } from "../../../contexts/cocExport";
import HeaderInput from "./HeaderInput";

const ProjectInformation = ({ project }) => {

  const { headerDetails } = useCocExport()
  const { updateProjectInformation } = useCocExportApi()

  useEffect(() => {
    if (project) {
      updateProjectInformation('project_id', project.id)
      updateProjectInformation('project_name', project.name)
    }
  }, [project])

  return(
    <div className='flex flex-col gap-y-2 py-4'>
      <h1 className="pb-2">Project Information</h1>
      <HeaderInput title={'Project ID'} field={'project_id'} value={headerDetails.project_information.project_id} update={updateProjectInformation} />
      <HeaderInput title={'Project Name'} field={'project_name'} value={headerDetails.project_information.project_name} update={updateProjectInformation} />
      <HeaderInput title={'Project Location'} field={'project_location'} value={headerDetails.project_information.project_location} update={updateProjectInformation} />
      <HeaderInput title={'Legal Location'} field={'legal_location'} value={headerDetails.project_information.legal_location} update={updateProjectInformation} />
      <HeaderInput title={'PO/AFE#'} field={'po'} value={headerDetails.project_information.po} update={updateProjectInformation} />
      <HeaderInput title={'Project/Acct Code'} field={'account_code'} value={headerDetails.project_information.account_code} update={updateProjectInformation} />
      <HeaderInput title={'Quote #'} field={'quote'} value={headerDetails.project_information.quote} update={updateProjectInformation} />
    </div>
  )
}

export default ProjectInformation
