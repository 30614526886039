import React from "react"
import { SiteInspectionReportsProvider } from "../../contexts/siteInspectionReports";
import HeaderInformation from "./headerInformation";
import ExportSelect from "./exportSelect";
import ExportButton from "./exportButton";
import ExportHistory from "./exportHistory";

const SiteInspectionReports = ({ notes, project }) => {
  return<>
    <SiteInspectionReportsProvider>
      <div className='flex flex-row'>
        <div className='mx-10 my-10 w-1/2'>
          <ExportSelect notes={notes} />
          <HeaderInformation />
          <ExportButton project={project} />
        </div>
        <ExportHistory project={project} />
      </div>
    </SiteInspectionReportsProvider>
  </>
}

export default SiteInspectionReports
