import React, { useEffect, useState } from "react"
import Select from "react-select"

const OptionFilter = ({ options, onSelect, name }) => {
  const [selectOptions, setSelectOptions] = useState([])

  useEffect(() => {
    const formattedOptions = options?.map((option) => {
      const optionLabel = <div><span className='font-bold'>{option}</span></div>

      return { value: option, label: optionLabel }
    })

    setSelectOptions(formattedOptions)
  }, [options])

  return (<>
    <h3 className='font-semibold'>Select specific {name} below:</h3>
    <Select
      isMulti
      options={selectOptions}
      onChange={(selectedOption) => {
        const options = selectedOption.map(option => option.value)
        onSelect(options)
      }}
      className={"w-2/5"}
    />
  </>)
}

export default OptionFilter
