import API from "./apiConfig"

const csrfToken = document.querySelector('[name=csrf-token]').content

export const deleteOpenaiObjects = (projectId) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API
    .delete(`/projects/${projectId}/ai/openai`)
    .then(res => res.data)
}
