import React from 'react'
import BreadCrumbs from '../projects/nav/breadCrumbs'
import BreadCrumb from '../../layouts/nav/breadcrumb'

const LabBreadCrumbs = ({project, routes, extension}) => {
  const linkName = extension.split('-').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
  return (
    <BreadCrumbs portfolio={project.portfolio} project={project} routes={routes} >
      <BreadCrumb linkPath={`/projects/${project.id}/${extension}`} linkName={linkName} />
  </BreadCrumbs>  )
}

export default LabBreadCrumbs
