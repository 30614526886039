import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { isEmpty } from "lodash"
import DropDown from "../../shared/dropdown"
import SidebarDropdown from "../../shared/sidebarDropdown"
import MobileDataDropdown from "./mobileDataDropdown"
import { getPortfolioProjects } from "../../../api/portfoliosApi"
import { getTracker, buildTrackerPath } from "../../../api/trackers"
import { getChecklist, buildChecklistPath } from "../../../api/checklist"
import { getReportTemplates } from "../../../api/reportsApi"
import { filterTrackerString, mapProjectToLabelValue, mapTrackerToLabelValue, mapChecklistToLabelValue, mapReportToLabelValue } from "../../../utilities/portfolioHelpers"
import { pipe } from "../../../utilities/utils"
import UploadCsv from "../../trackers/uploadCsv"
import { getOrganization } from "../../../api/organizationApi"

const PortfolioSideBar = ({ changeWidget, widgetOptions, classNames, portfolio, defaultWidget }) => {
  const [organization, setOrganization] = useState({})
  const [portfolioProjects, setPortfolioProjects] = useState([])
  const [checklists, setChecklists] = useState([])
  const [reportTemplates, setReportTemplates] = useState([])
  const [defaultTrackers, setDefaultTrackers] = useState([])
  const [permitTrackers, setPermitTrackers] = useState([])
  const [historicalTracker, setHistoricalTracker] = useState()
  const [interviewTracker, setInterviewTracker] = useState()
  const { portfolioId, trackerSlug, reportSlug, checklistSlug } = useParams()
  const trackerPath = buildTrackerPath({ portfolioId: portfolioId })
  const checklistPath = buildChecklistPath({ portfolioId: portfolioId })
  const portfolioTypeConstruction = portfolio?.type === 'Construction'
  const priority_checklist_slug = portfolio?.priority_checklist_slug

  useEffect(() => {
    if (trackerSlug) getOrganization().then(res => setOrganization(res))
    if (!isEmpty(portfolio)) {
      getPortfolioProjects(portfolioId)
        .then(projects => {
          pipe(filterTrackerString(false), mapProjectToLabelValue(), setPortfolioProjects)(projects)
        })
      getTracker(trackerPath)
        .then(response => {
          pipe(mapTrackerToLabelValue('portfolios', portfolioId), setDefaultTrackers)(response.default_trackers)
          pipe(mapTrackerToLabelValue('portfolios', portfolioId), setPermitTrackers)(response.permit_trackers)
          setHistoricalTracker(response.historical_tracker)
          setInterviewTracker(response.interview_tracker)
        })
      getChecklist(checklistPath)
        .then(response =>
          pipe(mapChecklistToLabelValue('portfolios', portfolioId), setChecklists)(response.checklists)
        )
      getReportTemplates({ portfolioId })
        .then(response => {
          pipe(mapReportToLabelValue('portfolios', portfolioId), setReportTemplates)(response)
        })
    }
  }, [portfolio])

  return (
    <div className={`p-8 bg-gray-200 lg:h-full min-h-fit flex-col lg:flex justify-between ${classNames}`}>
      <div className='w-full flex-col lg:flex' style={{ minHeight: "calc(100vh - 160px)" }}>
        <Link className="btn btn-outline truncate w-full text-center mb-8 min-h-[37px]" to="/portfolios/">
          View All My Portfolios
        </Link>
        <div className='w-full flex-col flex'>
          <div className='mb-7'>
            {(trackerSlug || reportSlug || checklistSlug) ?
              <button
                className='btn btn-primary truncate w-full text-center mb-4'
                onClick={() => changeWidget(defaultWidget.value)}
              >
                View Default Widget
              </button>
              :
              <>
                <p className='text-sm'>Power BI or Map select</p>
                <DropDown defaultWidget={defaultWidget} options={widgetOptions} styles='mb-7' handleChange={changeWidget} />
                <hr className='border-black mb-7' />
              </>
            }
            <SidebarDropdown options={portfolioProjects} selectionType={"Project"} />
            {!isEmpty(checklists) && <SidebarDropdown options={checklists} selectionType={"Checklist"} />}
            {!isEmpty(defaultTrackers) && <SidebarDropdown options={defaultTrackers} selectionType={"Tracker"} />}
            {!isEmpty(permitTrackers) && <SidebarDropdown options={permitTrackers} selectionType={"Permit"} />}
            {!isEmpty(reportTemplates) && <SidebarDropdown options={reportTemplates} selectionType={"Report"} />}
            {portfolioTypeConstruction && <MobileDataDropdown portfolio={portfolio} />}
          </div>
          {historicalTracker && (
            <a
              href={`/portfolios/${portfolioId}/trackers/${historicalTracker.slug}`}
              className="btn btn-outline truncate w-full text-center mb-6"
              target="_blank" rel="noreferrer noopener"
            >
              Historical Document Tracker
            </a>
          )}{interviewTracker && (
            <a
              href={`/portfolios/${portfolioId}/trackers/${interviewTracker.slug}`}
              className="btn btn-outline truncate w-full text-center mb-6"
              target="_blank" rel="noreferrer noopener"
            >
              Interview Log
            </a>
          )}
          {priority_checklist_slug && (
            <a
              href={`/portfolios/${portfolioId}/checklists/${priority_checklist_slug}`}
              className="btn btn-outline truncate w-full text-center mb-6"
              target="_blank" rel="noreferrer noopener"
            >
              Stage 1 Checklist
            </a>
          )}
        </div>
        <div className="flex flex-col gap-y-8 mt-28">
          {trackerSlug && organization?.tracker_upload_permission && <UploadCsv />}
          {trackerSlug && <Link className="btn btn-outline truncate w-full text-center"
            to={`/portfolios/${portfolioId}/trackers/${trackerSlug}/change-log`}>
            Tracker Change Log
          </Link>}
          {!isEmpty(reportTemplates) &&
            <a
              href={`/portfolios/${portfolioId}/reports-export`}
              target='_blank'
              className="btn btn-primary truncate w-full text-center"
            >
              Export Portfolio Reports
            </a>
          }
          <a
            href={`/portfolios/${portfolioId}/form-templates`}
            target='_blank'
            className="btn btn-outline truncate w-full text-center"
          >
            Data Connections
          </a>
        </div>
      </div>
    </div>
  )
}

export default PortfolioSideBar
