import React from "react"
import { AiPluginProvider } from "../../../contexts/aiPluginContext"
import ChatBox from "./chatBox"
import PlainTextEditor from "./plainTextEditor"

const AiPlugin = ({ user }) => {
  return (
    <div className="h-full w-full p-10 flex flex-col justify-end items-center gap-8">
      <AiPluginProvider user={ user }>
        <ChatBox/>
        <PlainTextEditor/>
      </AiPluginProvider>
      <img src="/powered-by-openai-badge-outlined-on-light.svg" alt="powered by openai" className="w-60"/>
    </div>
  )
}

export default AiPlugin
