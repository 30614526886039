import React, { useState, useEffect, useReducer } from "react"
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilePdf, faCircle } from "@fortawesome/free-solid-svg-icons"
import { toolTipFormatter, scatterOptions } from "../../../../utilities/boreholeChartHelpers"
import { getChartData } from "../../../../api/boreholeChartApi"
import { useParams } from 'react-router-dom'
import ChartFilters from "./chartFilters"
import { notifyError } from '../../../shared/notice'
import ShowExtraDataButton from "./buttons/showExtraDataButton"
import LoadingSpinner from "../../../shared/loadingSpinner"

const BoreholeScatterChart = ({ routes }) => {
  const [boreholeIds, setBoreholeIds] = useState([])
  const { projectId } = useParams()
  const [chartData, setChartData] = useState({ data: null })
  const [filters, setFilters] = useState({ boreholeIds: [], apecs: [], date_range: {} })
  const [errorStatus, setErrorStatus] = useState(false)

  const [showExtraData, toggleShowExtraData] = useReducer(state => !state, false)

  const onUpdateData = (chartData, borehole_ids) => {
    setChartData({ data: [...chartData] })
    setBoreholeIds(borehole_ids)
  }

  useEffect(() => {
    getChartData(projectId)
      .then(response => {
        onUpdateData(response.data, response.borehole_ids)
        setFilters({ boreholeIds: response.borehole_ids, apecs: response.apecs, date_range: response.date_range })
      })
      .catch((error) => {
        notifyError('Error: ' + error.response.data.exception)
        setErrorStatus(true)
      })
  }, [])

  const settings = scatterOptions(chartData?.data, boreholeIds, toolTipFormatter, showExtraData)

  return (
    <>
      {errorStatus && <div className='text-center text-9xl'>There was an error loading the chart </div>}
      {(!chartData?.data && !errorStatus) &&
        <div className='text-center text-9xl'>
          Loading...<LoadingSpinner />
        </div>}
      {chartData?.data &&
        <>
          <HighchartsReact
            highcharts={Highcharts}
            options={settings}
            containerProps={{ style: { height: '80%' } }}
          />
        </>
      }
      <div className="flex items-center justify-center">
        <FontAwesomeIcon icon={faCircle} className="text-yellow-400 mr-2" />
        <span>Average Sample Depth</span>
      </div>
      <div className='flex flex-row justify-between'>
        {chartData?.data &&
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
            <ShowExtraDataButton toggleShowExtraData={toggleShowExtraData} />
          </div>
        }
        <a href={routes.exportCsvPath} className='btn btn-primary m-4' target="_blank" >
          <FontAwesomeIcon icon={faFilePdf} className='mr-2' />
          Export CoC
        </a>
      </div>
      {chartData?.data &&
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <ChartFilters boreholeIds={boreholeIds} onUpdateData={onUpdateData} filters={filters} />
        </div>
      }
    </>
  )
}

export default BoreholeScatterChart
