import React from "react";
import { ImportModal } from "../shared/importModal";
import LoadingSpinner from "../shared/loadingSpinner";

const ExportButton = ({ loading, selectedDate, setLoading, getPdfExport, title }) => {
  return (<>
      {loading ? <ImportModal title={`Exporting ${title} Report`}>
        <LoadingSpinner />
      </ImportModal> : <button className={`border ${selectedDate ? 'text-white bg-regular-blue hover:bg-hover-blue border-regular-blue' : 'bg-gray-200 cursor-default'}  rounded-md px-2 py-1 lg:px-4 lg:py-2 transition duration-500 select-none text-xl font-bold w-64 text-center mx-auto`}
        disabled={!selectedDate}
        onClick={() => {
         setLoading(true)
         getPdfExport()
        }}
      >
        Export!
      </button>}
  </>)
}

export default ExportButton
