import API from "./apiConfig"

export const updateFormEntry = (updatePath, newValue) => {
  return API
    .put(updatePath, { value: newValue })
    .then(res => res.data)
}

export const getFormEntry = (getFormEntryPath) => {
  return API
    .get(getFormEntryPath)
    .then(res => res.data)
}
