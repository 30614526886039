import React from 'react'

// From https://tailwindui.com/components/application-ui/overlays/modals
export const ImportModal = ({ title, children }) => {

  return (

      <div
        className='fixed z-10 inset-0 overflow-y-auto'
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 backdrop-filter  backdrop-blur-sm '>
          <div className='fixed inset-0 transition-opacity' aria-hidden='true'>
            <div className='absolute inset-0  opacity-25 ' />
          </div>

          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          >
          &#8203;
          </span>
          <div
            className='inline-block self-center rounded-lg text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full'
            role='dialog'
            aria-modal='true'
            aria-labelledby='modal-headline'
          >
            <div className=' px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
              <div className='flex items-center'>
                <div className='mt-3 text-center w-full'>
                  <h3
                    className='text-2xl leading-6 font-medium text-regular-blue'
                    id='modal-headline'
                  >
                    {title}
                  </h3>
                  <div className='mt-2'>{children}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

  )
}
