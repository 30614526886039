import React, { createContext, useContext, useEffect, useMemo, useReducer } from "react"
import { useParams } from "react-router-dom"
import {
  updateTrackerEntry,
  getTracker,
  buildTrackerPath,
  createTrackerEntry,
  uploadTrackerAttributeFile,
  deleteTrackerEntries,
  duplicateTrackerEntry,
  updateTrackerEntryProject
} from "../api/trackers"
import {
  updateEntryAttribute,
  updateEntryProject,
  updateSelectedEntryIds,
  formattedChartData,
  updateFrozenStylesObject,
  addRenderOrder,
  updateCollapsedHeaders,
  deleteEntryAttribute
} from "../utilities/trackerHelpers"

/* Action */
const INIT_TRACKER_INFO = 'INIT_TRACKER_INFO'
const INIT_EDIT_CELL = 'INIT_EDIT_CELL'
const UPDATE_MODAL_CONTENT = 'UPDATE_MODAL_CONTENT'
const TOGGLE_MODAL = 'TOGGLE_MODAL'
const UPDATE_ATTRIBUTE = 'UPDATE_ATTRIBUTE'
const UPDATE_ENTRY_PROJECT = 'UPDATE_ENTRY_PROJECT'
const UPDATE_FILE = 'UPDATE_FILE'
const ADD_TRACKER_ENTRIES = 'ADD_TRACKER_ENTRIES'
const UPDATE_STATE = 'UPDATE_STATE'
const UPDATE_SELECTED_ENTRY_IDS = 'UPDATE_SELECTED_ENTRY_IDS'
const RESET_SELECTED_ENTRY_IDS = 'RESET_SELECTED_ENTRY_IDS'
const ADD_FROZEN_STYLES = 'ADD_FROZEN_STYLES'
const UPDATE_COLLAPSED_HEADERS = 'UPDATE_COLLAPSED_HEADERS'
const DELETE_ATTRIBUTE = 'DELETE_ATTRIBUTE'
const TOGGLE_EXTRA_DATA = 'TOGGLE_EXTRA_DATA'
const TOGGLE_TOOL_TIP = 'TOGGLE_TOOL_TIP'
const UPDATE_TOOL_TIP_MESSAGE = 'UPDATE_TOOL_TIP_MESSAGE'
const SET_LOADING = 'SET_LOADING'

/* Initial States */
const initialState = {
  trackerTemplate: {},
  headers: [],
  autoHeaders: [],
  trackerEntries: [],
  editEntryId: '',
  editCellId: '',
  editCellHeader: '',
  editCellContent: '',
  editCellColor: '',
  editCellFile: null,
  editCellRevisions: [],
  modal: false,
  selectedEntryIds: [],
  chartData: null,
  frozenStyles: {},
  frozenCellDistance: 0,
  collapsedHeaders: [],
  showExtraData: false,
  toolTip: false,
  toolTipMessage: '',
  loading: false,
}

const emptyTrackerEntry = (id, projectId) => ({ id: id, project_id: Number(projectId) || null, tracker_attributes: [] })
const duplicateTrackerEntryData = (id, projectId, tracker_attributes) => ({ id: id, project_id: Number(projectId) || null, tracker_attributes: tracker_attributes })

/* Reducer */
const trackerReducer = (state, action) => {
  switch (action.type) {
    case INIT_TRACKER_INFO:
      const renderOrderHeaders = addRenderOrder(action.payload.headers)
      return {
        ...state,
        trackerTemplate: action.payload.template,
        headers: renderOrderHeaders,
        autoHeaders: action.payload.autoHeaders,
        trackerEntries: action.payload.entries,
      }
    case ADD_TRACKER_ENTRIES:
      return { ...state, trackerEntries: [...state.trackerEntries, ...action.payload] }
    case INIT_EDIT_CELL:
      return {
        ...state,
        editEntryId: action.payload.entryId,
        editCellId: action.payload?.cellId,
        editCellColor: action.payload?.cellColor || '',
        editCellHeader: action.payload.header,
        editCellContent: action.payload?.content || '',
        editCellRevisions: action.payload?.revisions,
      }
    case UPDATE_ATTRIBUTE:
      const newTrackerArray = updateEntryAttribute(state.trackerEntries, state.editEntryId, action.payload)
      return {
        ...state,
        trackerEntries: newTrackerArray,
        editEntryId: '',
        editCellId: '',
        editCellHeader: '',
        editCellContent: '',
        editCellColor: '',
        editCellFile: null,
        editCellRevisions: [],
      }
    case UPDATE_ENTRY_PROJECT:
      const updatedTrackerEntries = updateEntryProject(state.trackerEntries, action.payload)
      return { ...state, trackerEntries: updatedTrackerEntries }
    case UPDATE_STATE:
      return { ...state, [action.field]: action.value }
    case UPDATE_MODAL_CONTENT:
      return { ...state, editCellContent: action.payload.value, editCellColor: action.payload.cellColor }
    case TOGGLE_MODAL:
      return { ...state, modal: !state.modal }
    case UPDATE_FILE:
      return { ...state, editCellFile: action.payload }
    case UPDATE_SELECTED_ENTRY_IDS:
      let newSelectedIdsArray = updateSelectedEntryIds(state.selectedEntryIds, action.payload.entryId, action.payload.isChecked)
      return { ...state, selectedEntryIds: newSelectedIdsArray }
    case RESET_SELECTED_ENTRY_IDS:
      return { ...state, selectedEntryIds: [] }
    case ADD_FROZEN_STYLES:
      return {
        ...state,
        frozenStyles: updateFrozenStylesObject(state.frozenStyles, action.payload.headerCellWidth, action.payload.renderPosition, action.payload.isChecked) }
    case UPDATE_COLLAPSED_HEADERS:
      const newCollapasedHeaders = updateCollapsedHeaders(state.collapsedHeaders, action.payload.renderPosition, action.payload.isChecked)
      return { ...state, collapsedHeaders: newCollapasedHeaders }
    case DELETE_ATTRIBUTE:
      const newTrackerEntries = deleteEntryAttribute(state.trackerEntries, action.payload.entryId, action.payload.attributeId)
      return { ...state, trackerEntries: newTrackerEntries }
    case TOGGLE_EXTRA_DATA:
      return { ...state, showExtraData: !state.showExtraData }
    case TOGGLE_TOOL_TIP:
      return { ...state, toolTip: action.payload }
    case UPDATE_TOOL_TIP_MESSAGE:
      return {
        ...state,
        toolTipMessage: action.payload.toolTipMessage,
      }
    case SET_LOADING:
      return { ...state, loading: action.payload }
    default:
      return state
  }
}

/* Contexts */
const TrackerContext = createContext(initialState)

const TrackerAPIContext = createContext({
  addNewEntry: () => {},
  addTrackerEntries: () => {},
  updateAttribute: () => {},
  onContentInit: () => {},
  onSave: () => {},
  toggleModal: () => {},
  updateFile: () => {},
  deleteEntry: () => {},
  duplicateEntries: () => {},
  updateFrozenStyles: () => {},
  updateState: () => {},
  updateCollapsedHeaders: () => {},
  deleteEntryAttribute: () => {},
  toggleExtraData: () => {},
  toggleToolTip: () => {},
  updateToolTipMessage: () => {},
  setLoading: () => {},
  deleteMultipleEntries: () => {},
})

/* Providers */
export const TrackerProvider = ({ children, providedTrackerSlug }) => {
  const { portfolioId, projectId, trackerSlug } = useParams()
  const trackerPath = buildTrackerPath({ portfolioId, projectId, trackerSlug: providedTrackerSlug || trackerSlug })
  const [state, dispatch] = useReducer(trackerReducer, initialState)

  const api = useMemo(() => {
    const addNewEntry = (createTrackerPath, projectId) => {
      createTrackerEntry(createTrackerPath)
        .then(res => dispatch({ type: ADD_TRACKER_ENTRIES, payload: [emptyTrackerEntry(res.id, projectId)] }))
    }

    const addTrackerEntries = (entries) => dispatch({ type: ADD_TRACKER_ENTRIES, payload: entries })

    const updateAttribute = (attribute) => {
      dispatch({ type: UPDATE_ATTRIBUTE, payload: attribute })
    }

    const onContentInit = ({ content, cellColor, header, entryId, cellId, revisions }) => {
      dispatch({ type: INIT_EDIT_CELL, payload: { content, cellColor, header, entryId, cellId, revisions } })
    }

    const onContentChange = (value, cellColor) => {
      dispatch({ type: UPDATE_MODAL_CONTENT, payload: { value, cellColor } })
    }

    const updateFile = (file) => {
      dispatch({ type: UPDATE_FILE, payload: file })
    }

    const updateState = (field, value) => {
      dispatch({ type: UPDATE_STATE, field, value })
    }

    const updateEntryProject = (entryId, projectId) => {
      return updateTrackerEntryProject(entryId, projectId)
        .then(response => dispatch({ type: UPDATE_ENTRY_PROJECT, payload: response }))
    }

    const toggleModal = () => dispatch({ type: TOGGLE_MODAL })

    const toggleSelected = (entryId, isChecked) => dispatch({ type: UPDATE_SELECTED_ENTRY_IDS, payload: { entryId, isChecked } })

    const duplicateEntries = (selectedEntryIds) => {
      const entries = []
      duplicateTrackerEntry(selectedEntryIds)
        .then((res) => {
          res.data.forEach(data => {
            entries.push(duplicateTrackerEntryData(data.tracker_entry.id, data.tracker_entry.project_id, data.tracker_attributes))
          })
          dispatch({
            type: ADD_TRACKER_ENTRIES,
            payload: entries
          })
          dispatch({
            type: RESET_SELECTED_ENTRY_IDS
          })
        }).catch((error) => {
          console.error("Error occurred while duplicating entries:", error)
        })
    }

    const updateFrozenStyles = (headerCellWidth, renderPosition, isChecked) => dispatch({ type: ADD_FROZEN_STYLES, payload: { headerCellWidth, renderPosition, isChecked } })

    const updateCollapsedHeaders = (renderPosition, isChecked) => dispatch({ type: UPDATE_COLLAPSED_HEADERS, payload: { renderPosition, isChecked } })

    const deleteEntryAttribute = (entryId, attributeId) => dispatch({ type: DELETE_ATTRIBUTE, payload: { entryId, attributeId } })

    const toggleExtraData = () => dispatch({ type: TOGGLE_EXTRA_DATA })

    const toggleToolTip = (status) => dispatch({ type: TOGGLE_TOOL_TIP, payload: status })

    const updateToolTipMessage = (toolTipMessage) => dispatch({ type: UPDATE_TOOL_TIP_MESSAGE, payload: { toolTipMessage } })

    const setLoading = (loading) => dispatch({ type: SET_LOADING, payload: loading })

    return {
      addNewEntry,
      updateAttribute,
      onContentInit,
      onContentChange,
      updateState,
      updateEntryProject,
      toggleModal,
      updateFile,
      addTrackerEntries,
      toggleSelected,
      duplicateEntries,
      updateFrozenStyles,
      updateCollapsedHeaders,
      deleteEntryAttribute,
      toggleExtraData,
      toggleToolTip,
      updateToolTipMessage,
      setLoading,
    }
  }, [])

  api.deleteEntry = useMemo(() => {
    return entryId => deleteTrackerEntries([entryId])
      .then(() => api.updateState('trackerEntries', state.trackerEntries.filter(entry => entry.id !== entryId)))
      .then(() => {
        if (state.selectedEntryIds.includes(entryId)) {
          api.toggleSelected(entryId, false)
        }
      }
      )

  }, [state.trackerEntries, state.selectedEntryIds])

  api.deleteMultipleEntries = (selectedEntryIds) => {
    deleteTrackerEntries(selectedEntryIds)
      .then(() => {
        api.updateState('trackerEntries', state.trackerEntries.filter(entry => !selectedEntryIds.includes(entry.id)))
        dispatch({
          type: RESET_SELECTED_ENTRY_IDS
        })
      }).catch((error) => {
        console.error("Error occurred while deleting entries:", error)
      })
  }

  api.onSave = () => {
    if (state.editCellFile) {
      const params = {
        tracker_header_id: state.editCellHeader.id,
        tracker_entry_id: state.editEntryId
      }
      const file = state.editCellFile
      return uploadTrackerAttributeFile(file, params)
    } else {
      const updateData = {
        entry_id: state.editEntryId,
        tracker_attribute: {
          value: state.editCellContent,
          cell_color: state.editCellColor,
          tracker_header_id: state.editCellHeader.id,
          file: state.editCellFile,
        }
      }
      return updateTrackerEntry(trackerPath, updateData)
    }
  }

  /* Initialize tracker */
  useEffect(() => {
    void getTracker(trackerPath)
      .then(res => {
        const { template, headers, auto_populated_headers: autoHeaders, entries } = res
        dispatch({ type: INIT_TRACKER_INFO, payload: { template, headers, autoHeaders, entries } })
      })
  }, [])

  /* Initialize chart data */
  useEffect(() => {
    const formattedData = formattedChartData(state.headers, state.trackerEntries)
    api.updateState('chartData', formattedData)
  }, [state.trackerTemplate, state.trackerEntries])

  return (
    <TrackerAPIContext.Provider value={api}>
      <TrackerContext.Provider value={state}>
        {children}
      </TrackerContext.Provider>
    </TrackerAPIContext.Provider>
  )
}

/* Custom Context Hooks */
export const useTrackerContext = () => useContext(TrackerContext)
export const useTrackerAPI = () => useContext(TrackerAPIContext)
