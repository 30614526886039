import React, { forwardRef, useMemo } from "react"
import DropdownInput from "./dropdownInput"

import {defaultFormatDropdownOptions} from "../../../utilities/tableHelpers";

const DefaultDropdown = forwardRef(({ trackerHeader, optionsParam = null }, inputRef) => {
  const options = useMemo(() => {
    return optionsParam || trackerHeader?.tracker_dropdown_options?.map(defaultFormatDropdownOptions) || []
  }, [optionsParam, trackerHeader])

  return <>
    {options && <DropdownInput
      paramOptions={options}
      ref={inputRef}
    />}
  </>
})

export default DefaultDropdown
