import React from 'react'
import ExportSampleSelect from "./ExportSampleSelect"
import { useCocExport } from "../../contexts/cocExport"

const ExportBoreholeSelect = ({ borehole }) => {
  const { selectedChemicals } = useCocExport()
  const boreholeKey = Object.keys(borehole)[0]

  const keyLength = Object.keys(borehole[boreholeKey]).length
  const sampleList = Object.keys(borehole[boreholeKey]).map((sampleKey, index) => {
    const position = {location: (index + 1), total: keyLength}
    const isLast = index === keyLength - 1
    const sampleInfo = {
      sampleId: sampleKey,
      ...borehole[boreholeKey][sampleKey]
    }

    return (
      <ExportSampleSelect
        sample={sampleInfo}
        key={sampleKey}
        chemicals={selectedChemicals}
        hasBlackBorderBottom={isLast}
        position={position}
        title={boreholeKey}
      />
    )
  })
  return <>{sampleList}</>
}

export default ExportBoreholeSelect
