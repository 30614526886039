import React, { useMemo } from "react"

const ContentStyling = () => (
  <style>
    { ".doc-builder-content blockquote { padding: 5px 10px; border-left: 3px solid grey; }" }
  </style>
)


const StaticContent = ({ content }) => {
  const htmlContent = useMemo(() => {
    return { __html: content?.documentContent || content?.defaultContent }
  }, [content])
  return (<>
    <ContentStyling/>
    <div className="doc-builder-content" dangerouslySetInnerHTML={ htmlContent }/>
  </>)
}

export default StaticContent
