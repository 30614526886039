import React, { useState, useCallback } from "react"
import { readAsDataURL } from "../../../../utilities/utils"
import { buttonClass } from "../../../../utilities/profileHelpers"

const FileSelect = ({ originalFile, setFile, htmlFor, required = false, title = '', accepts='*' }) => {
  const [filePreview, setFilePreview] = useState(originalFile)
  const onChangeHandler = useCallback((event) => {
    setFile(event.target.files[0])
    readAsDataURL(event.target.files[0])
      .catch(() => null)
      .then(preview => preview && setFilePreview(event.target.files[0]))      
  }, [setFile])

  return (
    <label htmlFor={htmlFor} className="flex flex-row items-center justify-start w-full">
      { title && <span className="mr-2"><strong>{title}: </strong></span> }
      <input
        onChange={onChangeHandler}
        className="opacity-0 w-0 h-0 peer"
        name={htmlFor}
        accept={accepts}
        type="file"
        id={htmlFor}
        required={required && !originalFile}
      />
      <button type="button" className={buttonClass}>
        <label htmlFor={htmlFor}>Choose File</label>
      </button>
      <span className="ml-2">{filePreview ? filePreview.name : ''}</span>
      {required && !originalFile && <span className="text-red-500 ml-2">*Required</span>}
    </label>
  )
}

export default FileSelect
