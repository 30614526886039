import React from "react"
import { cellColors } from "../../../utilities/trackerHelpers"
import { tableStyles } from "../../../utilities/stylesHelpers"
import HoverIcon from "../checklistIcons/hoverIcon"
import { faInfo, faSyncAlt } from "@fortawesome/free-solid-svg-icons"
import { useChecklistAPI, useChecklistContext } from "../../../contexts/checklistContext"
const InformationCell = ({ content, task, entry, styler, cellColor = 'white' }) => {

  const { toggleModal, onContentInit } = useChecklistAPI()
  const { collapsed } = useChecklistContext()

  const taskClickHandler = () => {
    onContentInit({
      modalTitle: task,
      taskDescription: content || 'N/A',
    })
    toggleModal('infoModal')
  }
  const updateClickHandler = () => {
    onContentInit({
      entry: entry,
      modalTitle: 'Update',
    })
    toggleModal('infoModal')
  }

  const taskMessage = content ? ("Please click to see more information on the task.") : ('N/A')
  const updateMessage = entry ? ("Click to see recent update Information") : ('N/A')

  return (
    <td className={collapsed ? tableStyles.tdCollapsed : tableStyles.td} style={{ backgroundColor: cellColors[cellColor], ...styler, position: "relative" }}  >
      <div className={collapsed && "justify-center flex gap-x-1"}>
        <HoverIcon icon={faInfo} message={taskMessage} onClickHandler={() => taskClickHandler(task, content)} />
        <br />
        <HoverIcon icon={faSyncAlt} message={updateMessage} onClickHandler={() => updateClickHandler(entry)} />
      </div>
    </td>
  )
}

export default InformationCell
