import React from 'react'
import { useCocExport, useCocExportApi } from "../../../contexts/cocExport";
import HeaderInput from "./HeaderInput";

const AdditionalReportTo = () => {

  const { headerDetails } = useCocExport()
  const { updateAdditionalReportTo } = useCocExportApi()

  return(
    <div className='flex flex-col gap-y-2 py-2'>
      <h1 className="pb-4">Additional Reports To:</h1>
      <HeaderInput title={'Name'} field={'first_name'} value={headerDetails.additional_report_to.first_name} update={updateAdditionalReportTo} />
      <HeaderInput title={'Email'} field={'first_email'} value={headerDetails.additional_report_to.first_email} update={updateAdditionalReportTo} />
      <HeaderInput title={'Name'} field={'second_name'} value={headerDetails.additional_report_to.second_name} update={updateAdditionalReportTo} />
      <HeaderInput title={'Email'} field={'second_email'} value={headerDetails.additional_report_to.second_email} update={updateAdditionalReportTo} />
    </div>
  )
}

export default AdditionalReportTo
