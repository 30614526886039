import React, { useMemo } from 'react'
import { useAiDocumentsContext } from '../../../contexts/aiDocumentsContext'
import PromptCollectionView from './promptCollectionView'

const PromptCollections = () => {
  const { promptCollections } = useAiDocumentsContext()

  const numberOfHeaders = useMemo(() => {
    return Math.max(...promptCollections.map(collection => collection.prompts.length))
  }, [promptCollections])

  const headers = ['Collection Name', ...Array.from({ length: numberOfHeaders }, (_, i) => `Prompt ${ i + 1 }`), 'Actions']

  return (
    <div className='grid grid-cols-10 mx-3 my-3'>
      { headers.map((header, index) => (
        <div key={ index } className='border border-gray-300 p-2 row-start-1'><strong>{ header }</strong></div>
      )) }
      { promptCollections.map((promptCollection) => (
        <PromptCollectionView key={ promptCollection.id } promptCollection={ promptCollection }
                              numberOfHeaders={ numberOfHeaders }/>
      )) }
    </div>
  )
}

export default PromptCollections
