import React from 'react'
import Photo from './photo/photo'
import { formatDateString, stringToUTCDate} from '../../utilities/dateHelpers'

const DateGroup = ({ photoDateGroup }) => {
  return (
    <>
      <div className='header'>
        <h2>Date: {photoDateGroup.length !== 0 ? formatDateString(stringToUTCDate(photoDateGroup[0].details.date)) : ''}</h2>
      </div>
      <div className='grid grid-cols-4 gap-x-1 mr-2'>
        {photoDateGroup && photoDateGroup.map((importPhoto, i) => <Photo image={importPhoto} key={i} />)}
      </div>
    </>
  )
}

export default DateGroup
