import React, { useEffect, useMemo, useState } from 'react'
import Select from "react-select";
import { parameterizeString } from "../../../utilities/utils"
import useSearch from "../../../hooks/useSearch"

const NoteFilter = ({ options, label, isDisabled, onChange }) => {
  const [selectedFilter, setSelectedFilter] = useState()

  const parameterizedLabel = useMemo(() => parameterizeString(label), [label])
  const urlSearchParams = useSearch()

  const onChangeHandler = (event) => {
    setSelectedFilter(event)
    onChange(event)
  }

  useEffect(() => {
    if (urlSearchParams.has(parameterizedLabel)) {
      const filteredOption = options?.filter(option => option.label === urlSearchParams.get(parameterizedLabel))[0]
      setSelectedFilter(filteredOption)
      onChange(filteredOption)
    }
  }, [options])

  return (
    <div className='flex flex-col w-full p-2'>
      <p className='px-1 capitalize'>{label}</p>
      <Select
        options={options}
        onChange={onChangeHandler}
        className='w-full'
        isClearable
        isDisabled={isDisabled}
        placeholder={'All'}
        value={selectedFilter}
      />
    </div>
  )
}

export default NoteFilter
