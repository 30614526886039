import React from 'react'
import { useTestResultsApi, useTestResultsContext } from '../../../contexts/testResultsContext'
import LoadingSpinner from '../../shared/loadingSpinner'
import StandardButton from '../../shared/standardButton'
import GuidelineDisplay from './guidelineDisplay'
import GuidelineModal from './guidelineModal'
import TableFilters from './tableFilters'
import TestResultsTable from './testResultsTable'

const LayoutTable = () => {
  const { loading, chemicalUnits, modal, exportPdf } = useTestResultsContext()
  const { toggleField, generateExportPdf } = useTestResultsApi()
  return (
    <>
      {!loading.init && <>
        <div className="w-full px-2 py-4 bg-gray-200 flex justify-center">
          <h2 className="text-2xl font-bold">Test Results</h2>
        </div>
        <div className="flex space-x-2">
          <div className="m-4 bg-red-200 flex items-center justify-center p-2"><strong> Does Not Meet Requirements </strong></div>
          <div className="m-4 bg-yellow-200 flex items-center justify-center p-2"><strong> Background </strong></div>
        </div>
        {modal.guidelineModal && <GuidelineModal />}
        <GuidelineDisplay />
        <div className="text-left">
          {!loading.export && <>
            {<StandardButton buttonText={'Add Guideline'} onClickHandler={() => toggleField('modal.guidelineModal')} />}
            <StandardButton buttonText={'Export PDF'} btnClass={'btn-danger'} onClickHandler={() => generateExportPdf()} />
            {exportPdf && <StandardButton buttonText={'Download PDF'} btnClass={'btn-primary'} onClickHandler={() => window.open(exportPdf)} />}
          </>}
          {loading.export && <LoadingSpinner />}
        </div>
        <TableFilters />
        {!chemicalUnits.length == 0 && <TestResultsTable />}
      </>}
      {loading.init && <LoadingSpinner />}
    </>
  )
}

export default LayoutTable
