import API from "./apiConfig"

const csrfToken = document.querySelector('[name=csrf-token]').content

export const buildChecklistPath = ({ portfolioId, projectId, checklistSlug, projectChecklistId }) => {
  const baseUrl = portfolioId ? `/portfolios/${portfolioId}/checklists` : `/projects/${projectId}/checklists`
  const checklistTemplatePath = checklistSlug ? `/${checklistSlug}` : ''
  const projectChecklistPath = projectChecklistId ? `/${projectChecklistId}.json` : '.json'

  return `${baseUrl}${checklistTemplatePath}${projectChecklistPath}`
}

export const getChecklist = (checklistPath) => {
  return API
    .get(checklistPath)
    .then(res => res.data)
}

export const updateChecklistAttribute = (checklistPath, formData) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API
    .put(checklistPath, formData)
    .then(res => res.data)
}

export const createProjectChecklist = (checklistPath, payload) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API
    .post(checklistPath, payload)
    .then(res => res.data)
}

export const updateChecklist = (projectChecklistId, { status, date, description }) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  const updatePath = `/checklists/${projectChecklistId}`
  return API
    .put(updatePath, { status, date, description })
    .then(res => res.data)
}

export const uploadChecklistAttributeFile = (file, params) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API.post(`/checklist_attributes/file_upload`, file, { params: params })
    .then(res => res.data)
}

export const deleteChecklistAttribute = (attributeId) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API
    .delete(`/checklist_attributes/${attributeId}`)
    .then(res => res.data)
}

export const assignUserToEntry = (checklist_entry_id, userId) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API
    .post(`/checklist_entries/${checklist_entry_id}/assign_user`, { user_id: userId })
    .then(res => res.data)
}
