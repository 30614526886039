import React, { useEffect, useState } from "react"
import { getOrganization } from "../../../api/organizationApi"
import { getPortfolios } from "../../../api/portfoliosApi";
import PortfolioSelect from "./portfolioSelect";

const SideBar = ({ classNames }) => {
  const [organizationName, setOrganizationName] = useState('')
  const [portfolioOptions, setPortfolioOptions] = useState()

  useEffect(() => {
    getOrganization()
      .then(res => setOrganizationName(res.name))
    getPortfolios()
      .then((res) => {
        const formattedPortfolioOptions = res.map(portfolio => {
          return { label: portfolio.name, value: portfolio.id}
        })
        setPortfolioOptions(formattedPortfolioOptions)
      })
  }, [])

  return (
    <div className={`p-8 bg-gray-200 lg:h-full min-h-fit flex-col lg:flex justify-between ${classNames}`}>
      <div>
        <h1 className='text-3xl'>{organizationName}</h1>
      </div>
      <PortfolioSelect portfolioOptions={portfolioOptions} />
    </div>
  )
}

export default SideBar
