import React, { useRef } from "react"
import ModalWrapper from "../../modal/modalWrapper"
import ImageSelect from "../../shared/imageSelect"
import { useUserLandingPageContext, useUserLandingPageApi } from "../../../contexts/userLandingPageContext"
import UserProfile from "./userProfile"
import { inputClass } from "../../../utilities/stylesHelpers"

const ProfileModal = () => {
  const { firstName, lastName, email, originalHeadshot } = useUserLandingPageContext()
  const { updateUserState, updateUserHeadshot, toggleField, onSend } = useUserLandingPageApi()
  const formRef = useRef()

  return <ModalWrapper
    heading="Profile Modal"
    closeModal={toggleField('modal')}
    formName="profile-form"
    submitButtonText='Submit'
    modalContainerClasses="max-h-[90%] my-6 w-7/12"
  >
    <form ref={formRef} className="gap-y-6 flex flex-col" onSubmit={e => onSend(formRef, e)} id="profile-form">
      <label htmlFor="first_name">
        First Name
        <input
          className={inputClass}
          type='text'
          name="first_name"
          placeholder='First Name'
          autoComplete="given-name"
          value={firstName}
          onChange={updateUserState('firstName')}
        />
      </label>
      <label htmlFor="last_name">
        Last Name
        <input
          className={inputClass}
          type='text'
          name="last_name"
          placeholder='Last Name'
          autoComplete="family-name"
          value={lastName}
          onChange={updateUserState('lastName')}
        />
      </label>
      <label htmlFor="email">
        Email
        <input
          className={inputClass}
          type='email'
          name="email"
          placeholder='Email'
          value={email}
          pattern='^[^\s@]+@[^\s@]+\.[^\s@]+$'
          onChange={updateUserState('email')}
        />
      </label>
      <div className="grid grid-cols-2 items-center">
        <ImageSelect originalImage={originalHeadshot} setImage={updateUserHeadshot} htmlFor={'headshot'} />
      </div>
      <UserProfile />
    </form>
  </ModalWrapper>
}

export default ProfileModal
