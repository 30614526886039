import React, { useState } from 'react'
import { formatDateYearMonthDay } from '../../utilities/dateHelpers'
import Lightbox from "yet-another-react-lightbox"
import { Captions, Download } from "yet-another-react-lightbox/plugins"
import { faSquarePlus, faSquareMinus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import "yet-another-react-lightbox/styles.css"
import "yet-another-react-lightbox/plugins/captions.css"

const Image = ({ userTicket, user, ticket }) => {
  const [showLightBox, setShowLightBox] = useState(false)
  const [isAccordionOpen, setIsAccordionOpen] = useState(false)
  const toggleLightBox = () => setShowLightBox(!showLightBox)
  const toggleAccordion = () => setIsAccordionOpen(!isAccordionOpen)
  const title = (frontOrBack) => <strong>{user?.human_identifier} - {ticket?.name} - {frontOrBack} </strong>
  const description = `Expiry: ${formatDateYearMonthDay(new Date(userTicket?.expiry))}`

  return (
    <div>
      <button
        onClick={toggleAccordion}
        className="w-full p-2 bg-gray-200 hover:bg-gray-300 flex justify-between items-center"
      >
        {isAccordionOpen ? 'Hide Images' : 'Show Images'}
        <FontAwesomeIcon icon={isAccordionOpen ? faSquareMinus : faSquarePlus} />
      </button>
      {isAccordionOpen && (
        <div className="p-2">
          {userTicket?.front_ticket_image_path && <strong>Front of Ticket</strong>}
          <img src={userTicket?.front_ticket_image_path} className="object-cover max-h-40 mb-2 mx-auto" onClick={toggleLightBox} />
          {userTicket?.back_ticket_image_path && <strong>Back of Ticket</strong>}
          <img src={userTicket?.back_ticket_image_path} className="object-cover max-h-40 mx-auto" onClick={toggleLightBox} />
        </div>
      )}
      <Lightbox
        styles={{ container: { backgroundColor: "rgba(0, 0, 0, .8)" } }}
        plugins={[Captions, Download]}
        slides={[
          { src: userTicket?.front_ticket_image_path, title: title('Front'), description: description },
          { src: userTicket?.back_ticket_image_path, title: title('Back'), description: description }
        ]}
        open={showLightBox}
        close={toggleLightBox}
        captions={{ descriptionTextAlign: 'center' }}
      />
    </div>
  )
}

export default Image
