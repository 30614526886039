import React, { useMemo } from "react"

const AiPluginLink = ({ user }) => {
  const userAccess = useMemo(() => user.organization?.name?.toLowerCase() === "prodelta", [user])
  return <>
    { userAccess && <a href="/ai-plugin">Chat GPT</a> }
  </>
}

export default AiPluginLink
