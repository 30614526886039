import React from "react"
import { useChecklistAPI, useChecklistContext } from "../../../contexts/checklistContext"
import * as cell from '../../tableTemplates/tableCells'
import BooleanCell from "./booleanCell"
import { deleteChecklistAttribute } from "../../../api/checklist"

const ChecklistCell = ({ attribute, column = {}, entry, checklistId }) => {
  const { toggleModal, onContentInit, deleteAttribute } = useChecklistAPI()
  const { users, collapsed } = useChecklistContext()
  const type = column?.type

  const onAttributeDelete = () => {
    deleteChecklistAttribute(attribute.id)
    deleteAttribute(checklistId, attribute.id)
  }

  const onClickHandler = () => {
    onContentInit({
      content: attribute?.value,
      cellColor: attribute?.cell_color,
      column: column,
      entryId: entry.id,
      cellId: attribute?.id,
      revisions: attribute?.versions,
      projectChecklistId: checklistId
    })
    toggleModal(('checklistModal'))
  }

  return (
    <>
      {type === 'simple' && <cell.SimpleCell content={attribute} collapsed={collapsed} />}
      {type === 'Project' && <cell.DefaultCell content={attribute} onClickHandler={onClickHandler} collapsed={collapsed} />}
      {type === 'hyperlink' && <cell.HyperlinkCell content={attribute} onClickHandler={onClickHandler} />}
      {type === 'DateColumn' && <cell.DateCell content={attribute} onClickHandler={onClickHandler} collapsed={collapsed} />}
      {type === 'CurrencyColumn' && <cell.CurrencyCell content={attribute} onClickHandler={onClickHandler} collapsed={collapsed} />}
      {type === 'FileUploadColumn' && <cell.FileUploadCell content={attribute} onClickHandler={onClickHandler} onDeleteFile={onAttributeDelete} />}
      {(type === 'DropdownColumn' || type === 'StatusColumn' || !type) && <cell.DefaultCell content={attribute?.value} cellColor={attribute?.cell_color} onClickHandler={onClickHandler} collapsed={collapsed} />}
      {type === 'UserColumn' && <cell.UserCell content={entry?.user_id} users={users} onClickHandler={onClickHandler} collapsed={collapsed} />}
      {type === 'NumberColumn' && <cell.NumberCell content={attribute} onClickHandler={onClickHandler} collapsed={collapsed} />}
      {type === 'WholeNumberColumn' && <cell.NumberCell content={attribute} onClickHandler={onClickHandler} collapsed={collapsed} />}
      {type === 'BooleanColumn' && <BooleanCell content={attribute} column={column} onClickHandler={onClickHandler} entryId={entry.id} projectChecklistId={checklistId} collapsed={collapsed} />}
    </>
  )
}

export default ChecklistCell
