import React, { useEffect, useRef } from "react"
import { isEmpty } from "lodash"
import { useTrackerAPI, useTrackerContext } from "../../../contexts/trackerContext"
import Modal from "../../shared/modal"
import AttributeRevision from "../../modal/attributeRevision"
import TextInput from "./textInput"
import DateInput from "./dateInput"
import DefaultDropdown from "./defaultDropdown";
import FileUploadInput from "./fileUploadInput";
import NumberInput from "./numberInput";
import ProjectDropdown from "./projectDropdown"
import WholeNumberInput from "./wholeNumberInput"

const TrackerModal = () => {
  const { modal, editCellHeader, editCellRevisions, editEntryId, editCellContent } = useTrackerContext()
  const { onSave, toggleModal, updateAttribute, updateEntryProject } = useTrackerAPI()
  const inputRef = useRef()
  const onSaveHandler = () => {
    if (editCellHeader.type === 'Project') {
      updateEntryProject(editEntryId, editCellContent)
        .then(() => toggleModal())
    } else {
      onSave()
        .then(res => {
          updateAttribute(res)
          toggleModal()
        })
    }
  }

  useEffect(() => {
    if (modal && inputRef.current) inputRef.current.focus()
  }, [modal, inputRef.current])

  return (
    <>
      {modal && (
        <Modal
          heading={editCellHeader.title}
          closeModal={toggleModal}
          onSave={onSaveHandler}
        >
          <div className='flex flex-col lg:justify-between gap-y-4 lg:gap-y-0 lg:gap-x-4'>
            {!isEmpty(editCellRevisions) && (
              <div className='w-full py-4'>
                <h3>Revision History</h3>
                <div className="max-h-50vh overflow-y-scroll">
                  {editCellRevisions.map((revision, index) => <AttributeRevision key={index} revision={revision} />)}
                </div>
              </div>
            )}
            <div className='w-full'>
              {editCellHeader.type === 'DateHeader' && <DateInput />}
              {editCellHeader.type === 'CurrencyHeader' && <NumberInput placeholder={'$0.00'} />}
              {editCellHeader.type === 'DropdownHeader' && <DefaultDropdown trackerHeader={editCellHeader} ref={inputRef} />}
              {editCellHeader.type === 'NumberHeader' && <NumberInput/>}
              {editCellHeader.type === 'WholeNumberHeader' && <WholeNumberInput/>}
              {editCellHeader.type === 'FileUploadHeader' && <FileUploadInput />}
              {editCellHeader.type === 'AutoHeader' && <NumberInput step={'0.0001'} />}
              {editCellHeader.type === 'Project' && <ProjectDropdown ref={inputRef} />}
              {!editCellHeader.type && <TextInput ref={inputRef} />}
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

export default TrackerModal
