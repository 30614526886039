import React, { createContext, useContext, useEffect, useMemo, useReducer } from "react"
import { getDocuments, getTemplates } from "../api/documentLogApi"
import { useParams } from "react-router-dom"
import { updateDocument } from "../utilities/documentLogHelpers"

/* ACTIONS */
const ACTIONS = {
  UPDATE_STATE: 'UPDATE_STATE',
  UPDATE_DOCUMENT: 'UPDATE_DOCUMENT',
}

/* INITIAL STATES */
const initialState = {
  templates: [],
  documents: {},
}

/* REDUCER */
const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_STATE:
      return { ...state, [action.field]: action.value }
    case ACTIONS.UPDATE_DOCUMENT:
      return { ...state, documents: updateDocument(state.documents, action.document) }
    default:
      return state
  }
}

/* CONTEXTS */
const DocumentLogContext = createContext(initialState)
const DocumentLogApiContext = createContext({
  updateState: () => {},
  updateDocument: () => {},
})

/* PROVIDER */
export const DocumentLogProvider = ({ children }) => {
  const { projectId } = useParams()
  const [state, dispatch] = useReducer(reducer, initialState)

  const api = useMemo(() => {
    const updateState = (field, value) => dispatch({ type: ACTIONS.UPDATE_STATE, field, value })

    const updateDocument = (document) => dispatch({ type: ACTIONS.UPDATE_DOCUMENT, document })

    return { updateState, updateDocument }
  }, [])

  // Setup initial state
  useEffect(() => {
    getTemplates(projectId).then(res => api.updateState('templates', res))
    getDocuments(projectId).then(res => api.updateState('documents', res))
  }, [])

  return (
    <DocumentLogApiContext.Provider value={ api }>
      <DocumentLogContext.Provider value={ state }>
        { children }
      </DocumentLogContext.Provider>
    </DocumentLogApiContext.Provider>
  )
}

/* CUSTOM HOOKS */
export const useDocumentLogContext = () => useContext(DocumentLogContext)
export const useDocumentLogApiContext = () => useContext(DocumentLogApiContext)
