import React, { createContext, useContext, useMemo, useEffect, useReducer } from "react"
import { useParams } from "react-router-dom"
import { initPortfolioAndProject } from "../utilities/contextHelpers"

/* Actions */
const ACTIONS = {
  INIT_TRACKER_INFO: "INIT_TRACKER_INFO",
  UPDATE_STATE: "UPDATE_STATE",
  UPDATE_WIDGET: "UPDATE_WIDGET",
  TOGGLE_WIDGET: "TOGGLE_WIDGET"
}

/* Initial States */
const initialState = {
  portfolio: null,
  project: null,
  projects: [],
  defaultWidget: '',
  widget: '',
  showWidget: true,
}

/* Reducer */
const portfolioReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.INIT_TRACKER_INFO:
      return {
        ...state,
        portfolio: action.payload.portfolio,
        project: action.payload.project,
        projects: action.payload.projects,
        defaultWidget: action.payload.defaultWidget,
        widget: action.payload.defaultWidget
      }
    case ACTIONS.UPDATE_STATE:
      return { ...state, [action.field]: action.value }
    case ACTIONS.UPDATE_WIDGET:
      return { ...state, widget: action.payload }
    case ACTIONS.TOGGLE_WIDGET:
      return { ...state, showWidget: !state.showWidget }
    default:
      return state
  }
}

/* Contexts */
const PortfolioContext = createContext(initialState)

const PortfolioAPIContext = createContext({
  updateState: () => { },
  updateWidget: () => { },
  toggleWidget: () => {},
})

/* Providers */
export const PortfolioProvider = ({ children }) => {
  const { portfolioId, projectId } = useParams()

  const [state, dispatch] = useReducer(portfolioReducer, initialState)

  const api = useMemo(() => {
    const initState = ({ portfolio, project, projects, defaultWidget }) => {
      dispatch({ type: ACTIONS.INIT_TRACKER_INFO, payload: { portfolio, project, projects, defaultWidget } })
    }

    const updateState = (field, value) => dispatch({ type: ACTIONS.UPDATE_STATE, field, value })

    const updateWidget = (widget) => dispatch({ type: ACTIONS.UPDATE_WIDGET, payload: widget })

    const toggleWidget = () => dispatch({ type: ACTIONS.TOGGLE_WIDGET })

    return { initState, updateState, updateWidget, toggleWidget }
  }, [])

  api.isPortfolio = useMemo(() => !!portfolioId, [portfolioId])

  useEffect(() => {
    initPortfolioAndProject({ portfolioId, projectId, callback: api.initState })
  }, [portfolioId, projectId])

  return (
    <PortfolioAPIContext.Provider value={api}>
      <PortfolioContext.Provider value={state}>
        {children}
      </PortfolioContext.Provider>
    </PortfolioAPIContext.Provider>
  )
}

/* Hooks */
export const usePortfolioContext = () => useContext(PortfolioContext)
export const usePortfolioAPI = () => useContext(PortfolioAPIContext)
