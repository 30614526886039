import React, { useEffect, useState } from 'react'
import { useCocExport, useCocExportApi } from "../../../contexts/cocExport"
import DropdownInput from './DropdownInput'
import HeaderInput from './HeaderInput'

const LabInformation = ({ title, update, field }) => {
  const { updateLabFilters } = useCocExportApi()
  const { labFilters, headerDetails, labOptions, loadedLabSelection } = useCocExport()
  const [manualDisplay, setManualDisplay] = useState(false)

  const toggleManualDisplay = () => {
    setManualDisplay(!manualDisplay)
  }

  const buttonMessage = manualDisplay ? 'Switch to Dropdown Input' : 'Switch to Manual Input'
  const otherSelected = (labFilters.company === 'Other')

  useEffect(() => {
    if (otherSelected) {
      toggleManualDisplay()
      updateLabFilters({ company: '', address: '', phone: '', fax: '', email: '' })
    }
  }, [toggleManualDisplay])

  return (
    <div className='flex flex-col gap-y-2 py-4'>
      <h1 className="pb-2">{title}:</h1>
      {!manualDisplay ? (
        <>
          <DropdownInput title={'Company'} field={'company'} options={labOptions.companies} value={loadedLabSelection.company} update={update} updateFilters={updateLabFilters} />
          <DropdownInput title={'City'} field={'city'} options={labOptions.cities} value={loadedLabSelection.city} updateFilters={updateLabFilters} />
          <DropdownInput title={'Address'} field={'address'} options={labOptions.addresses} value={loadedLabSelection.address} update={update} updateFilters={updateLabFilters} />
          <DropdownInput title={'Phone'} field={'phone'} options={labOptions.phones} value={loadedLabSelection.phone} update={update} updateFilters={updateLabFilters} />
          <DropdownInput title={'Fax'} field={'fax'} options={labOptions.faxes} value={loadedLabSelection.fax} update={update} updateFilters={updateLabFilters} />
          <DropdownInput title={'Email'} field={'email'} options={labOptions.emails} value={loadedLabSelection.email} update={update} updateFilters={updateLabFilters} />
        </>
      ) : (
        <>
          <HeaderInput title={'Company'} field={'company'} update={update} value={headerDetails[field].company} />
          <HeaderInput title={'Address'} field={'address'} update={update} value={headerDetails[field].address} />
          <HeaderInput title={'Phone'} field={'phone'} update={update} value={headerDetails[field].phone} />
          <HeaderInput title={'Fax'} field={'fax'} update={update} value={headerDetails[field].fax} />
          <HeaderInput title={'Email'} field={'email'} update={update} value={headerDetails[field].email} />
        </>
      )}
      <button className='btn btn-primary w-2/3 ml-auto' onClick={toggleManualDisplay}>{buttonMessage}</button>
    </div>
  )
}

export default LabInformation
