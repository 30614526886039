import React, { createContext, useCallback, useContext, useMemo, useReducer } from "react"
import { queryAi, csvClient } from "../api/aiPluginApi"
import { useParams } from "react-router-dom"

/* Actions */
const ACTIONS = {
  UPDATE_STATE: 'UPDATE_STATE',
  ADD_MESSAGE: 'ADD_MESSAGE',
  UPDATE_EDITOR_STATE: 'UPDATE_EDITOR_STATE',
  TOGGLE_LOADING_MESSAGE: 'TOGGLE_LOADING_MESSAGE',
  RESET_MESSAGES: 'RESET_MESSAGES',
}

/* Initial States */
const initialState = {
  messages: [],
  editorState: '',
  loadingMessage: false,
  selectedPromptCollection: {},
}

const messageState = (content, isResponse) => ({ content, isResponse })

/* Reducer */
const aiChatbotReducer = (state, action) => {
  switch (action?.type) {
    case ACTIONS.UPDATE_STATE:
      return { ...state, [action.field]: action.value }
    case ACTIONS.ADD_MESSAGE:
      const newMessage = messageState(action.message, action.isResponse)
      return { ...state, messages: [...state.messages, newMessage] }
    case ACTIONS.UPDATE_EDITOR_STATE:
      return { ...state, editorState: action.content }
    case ACTIONS.TOGGLE_LOADING_MESSAGE:
      return { ...state, loadingMessage: action?.loadingStatus || !state.loadingMessage }
    case ACTIONS.RESET_MESSAGES:
      return { ...state, messages: [] }
    default:
      return state
  }
}

/* Contexts */
const AiChatbotContext = createContext(initialState)
const AiChatbotApiContext = createContext({
  addMessage: () => {},
  submitMessage: () => {},
  updateEditor: () => {},
  updateSelectedPromptCollection: () => {},
})

/* Providers */
export const AiChatbotProvider = ({ children }) => {
  const [state, dispatch] = useReducer(aiChatbotReducer, initialState)
  const { projectId } = useParams()


  const editorApi = useMemo(() => {
    const addMessage = (message, isResponse = false) => dispatch({ type: ACTIONS.ADD_MESSAGE, message, isResponse })

    const clearEditor = () => dispatch({ type: ACTIONS.UPDATE_EDITOR_STATE, content: '' })

    const updateEditor = (content) => dispatch({ type: ACTIONS.UPDATE_EDITOR_STATE, content })

    const toggleLoadingMessage = (loadingStatus = undefined) => dispatch({ type: ACTIONS.TOGGLE_LOADING_MESSAGE, loadingStatus })

    const updateSelectedPromptCollection = (selectedPromptCollection) => dispatch({ type: ACTIONS.UPDATE_STATE, field: 'selectedPromptCollection', value: selectedPromptCollection })

    const aiResponse = async (query, selectedPromptCollection) => {
      toggleLoadingMessage(true)
      // if selectedPromptCollection is not empty, then use csv_client_query
      if (Object.keys(selectedPromptCollection).length > 0) {
        return await csvClient(query, projectId)
          .then(content => {
            toggleLoadingMessage(false)
            editorApi.addMessage(content, true)
          })
      }
      else {
        return await queryAi(query)
          .then(content => {
            toggleLoadingMessage(false)
            editorApi.addMessage(content, true)
          })
      }
    }

    const resetMessages = () => dispatch({ type: ACTIONS.RESET_MESSAGES })

    return { addMessage, clearEditor, updateEditor, toggleLoadingMessage, aiResponse, updateSelectedPromptCollection, resetMessages }
  }, [])

  const submitMessage = useCallback(async () => {
    if (state.editorState) {
      const queryContent = state.editorState
      await editorApi.addMessage(queryContent, false)
      editorApi.clearEditor()
      await editorApi.aiResponse(queryContent, state.selectedPromptCollection)
    }
  }, [state])

  return (
    <AiChatbotApiContext.Provider value={{ ...editorApi, submitMessage }}>
      <AiChatbotContext.Provider value={state}>
        {children}
      </AiChatbotContext.Provider>
    </AiChatbotApiContext.Provider>
  )
}

/* Hooks */
export const useAiChatbotContext = () => useContext(AiChatbotContext)
export const useAiChatbotApi = () => useContext(AiChatbotApiContext)
