import DropDown from "../../shared/dropdown"
import React, { useEffect, useState } from "react"

const formatReportLabel = key => key.replace('export', '').replace('Path', '').split(/(?=[A-Z])/).join(' ')

const ExportDropDown = ({ routes }) => {
  const [selectedHref, setSelectedHref] = useState('')
  const [options, setOptions] = useState([])
  const mousePointer = selectedHref ? {} : {pointerEvents: 'none'}

  useEffect(() => {
    const exportRoutes = Object.entries(routes)
      .filter(([key, _]) => key.includes('export'))
      .reduce((accumulator, [key, value]) => [ ...accumulator, {label: formatReportLabel(key), value: value} ], [])

    setOptions(exportRoutes)
  }, [])

  return (
    <div>
      <p className="py-2">Mobile Exports</p>
      <DropDown options={options} styles='mb-5' handleChange={setSelectedHref} />
      <div className="w-full flex justify-end">
        <a
          href={selectedHref}
          className='btn btn-primary truncate w-full text-center w-min text-sm py-1.5 px-3'
          style={mousePointer}
          rel="noopener noreferrer"
          target="_blank"
        >
          Mobile PDF Exports
        </a>
      </div>
    </div>
  )
}

export default ExportDropDown
