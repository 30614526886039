import React, { useMemo } from 'react'
import Map from "../../admin/projects/map"
import Widget from "../../shared/widget"
import NoteFilters from "../shared/NoteFilters"
import useNoteQuery from "../../../hooks/useNoteQuery"

const AssessmentLayout = ({ children, project, routes, portfolio, showMap, showFormNameFilter }) => {
  const noteQuery = useNoteQuery()
  const { notesToView } = noteQuery

  const leafletMap = useMemo(() => portfolio?.site_assessment_map === 'leaflet', [portfolio])
  const leafletRender = useMemo(() => !showMap && leafletMap, [showMap, leafletMap])

  return (
    <div className={`flex flex-row flex-grow overflow-hidden ${leafletRender && 'flex-col'}`} >
      <div className={`flex flex-col h-1/3 lg:h-auto mr-4 ${leafletRender ? 'w-full' : 'w-1/4'}`}>
        {leafletMap ?
          (showMap ?
            <Map notes={notesToView} project={project} /> :
            <div className='m-4'><h3>There is not enough geolocation data to render a map on this project</h3></div>)
          : <Widget widgetProp={project?.default_widget?.full_url} project={project} widgetStyles="h-full sm:p-0" />
        }
      </div>
      <div className={`flex flex-col w-full h-2/3 ${showMap && 'lg:h-full lg:w-3/4'}  flex-grow overflow-scroll gap-y-1`}>
        <div>
          {showFormNameFilter() && <NoteFilters />}
          { children }
        </div>
      </div>
    </div>
  )
}

export default AssessmentLayout
