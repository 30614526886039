import React from "react";
import FormTemplateRow from "./formTemplateRow";
import { assignTemplates } from "../../../api/portfoliosApi";
import { notifyAlert } from "../../shared/notice";

const FormTemplateTable = ({ portfolio, formTemplates, attachedTemplates, setAttachedTemplates }) => {
  const clickHandler = () => {
    assignTemplates(portfolio.id, attachedTemplates)
      .then(res => {
        setAttachedTemplates(res.form_template_ids)
        notifyAlert('Form templates have been successfully assigned!')
      })
  }

  return <>
    <table className='text-left w-full text-lg'>
      <thead>
      <tr className='border-b border-gray-800'>
        <th className='w-1/4 text-right pr-4'>Assign To Portfolio</th>
        <th className='w-3/4'>Template Name:</th>
      </tr>
      </thead>
      <tbody>
      {formTemplates?.map(formTemplate => <FormTemplateRow key={formTemplate.id} formTemplate={formTemplate} attachedTemplates={attachedTemplates}  setAttachedTemplates={setAttachedTemplates} />)}
      </tbody>
    </table>
    <button
      className={`my-6 border text-white bg-regular-blue hover:bg-hover-blue border-regular-blue rounded-md py-3 px-2 transition duration-500 select-none text-lg font-bold text-center float-right`}
      onClick={clickHandler}
    >
      Assign Templates
    </button>
  </>
}

export default FormTemplateTable
