import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSVLink } from "react-csv";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons/faFileCsv";
import { faSync } from "@fortawesome/free-solid-svg-icons/faSync";
import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload";
import { notifyAlert } from "../../shared/notice";

const ExportCsvData = ({ routes, project }) => {
  const [exporting, setExporting] = useState(false)
  const [csvData, setCsvData] = useState(null)

  const clickHandler = (e) => {
    e.preventDefault()
    setExporting(true)
  }

  const addedExportToQueue = (status) => {
    return status === 202;
  }

  const successfullyDownloadedCSV = (status) => {
    return status === 200;
  }

  useEffect(() => {
    if (exporting) {
      axios.get(`${routes.exportCsvPath}.csv`).then(({ status, data }) => {

        if (addedExportToQueue(status)) {
          const time_estimate = Math.round(data["est_time"] / 60)
          notifyAlert(`It'll take us about ${time_estimate} minutes to prepare your file. We will email you the export when it's ready!`)
        }

        if (successfullyDownloadedCSV(status)) {
          setCsvData(data)
        }

        setExporting(false)
      })
    }
  }, [exporting])

  const renderActionButton = () => {
    if (csvData) {
      return (
        <CSVLink filename={`${project.name}.csv`} data={csvData} target="_blank" className='btn btn-primary pr-2'>
          <FontAwesomeIcon icon={faDownload} className='mr-2 animate-bounce' />
          Download CSV
        </CSVLink>
      )
    }

    return (
      <div onClick={clickHandler} className='btn btn-primary cursor-pointer pr-2'>
        <FontAwesomeIcon icon={faFileCsv} className='mr-2' />
        Export CSV
      </div>
    )
  }

  return (
    <>
      {exporting &&
      <div className='flex flex-row items-center rounded-2xl pr-2 py-1'>
        <FontAwesomeIcon icon={faSync} spin className='text-indigo-500 mr-2' />
        <p className='ml-3 -mr-2'>Exporting CSV</p>
      </div>
      }

      {!exporting &&
        renderActionButton()
      }
    </>
  )
}

export default ExportCsvData
