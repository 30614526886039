import React, { createContext, useContext, useMemo, useEffect, useReducer } from "react"
import { useParams } from "react-router-dom"
import { simpleGet } from "../api/apiConfig"
import { buildReportPath } from "../api/reportsApi"

/* Actions */
const ACTIONS = {
  INIT_TRACKER_INFO: "INIT_TRACKER_INFO",
  UPDATE_STATE: "UPDATE_STATE",
  UPDATE_ACTIVE_TRACKER: "UPDATE_ACTIVE_TRACKER"
}

/* Initial States */
const initialState = {
  report: {},
  trackerTemplates: [],
  activeTracker: {},
}

/* Reducer */
const reportReducer = (state, action) => {
  switch(action.type) {
    case ACTIONS.INIT_TRACKER_INFO:
      return {
        ...state,
        report: action.payload.report,
        trackerTemplates: action.payload.trackerTemplates,
      }
    case ACTIONS.UPDATE_ACTIVE_TRACKER:
      const newActiveTracker = state.trackerTemplates.find(template => template.id === action.activeTrackerId)
      return { ...state, activeTracker: newActiveTracker }
    case ACTIONS.UPDATE_STATE:
      return { ...state, [action.field]: action.value }
    default:
      return state
  }
}

/* Contexts */
const ReportContext = createContext(initialState)

const ReportAPIContext = createContext({
  updateState: () => {},
  updateActiveTrackerById: () => {}
})

/* Providers */
export const ReportProvider = ({ children }) => {
  const { portfolioId, projectId, reportSlug } = useParams()
  const reportPath = useMemo(() => {
    return buildReportPath({ portfolioId, projectId, reportSlug })
  },[portfolioId, projectId, reportSlug])

  const [state, dispatch] = useReducer(reportReducer, initialState, i=>i)

  const api = useMemo(() => {
    const updateState = (field, value) => dispatch({ type: ACTIONS.UPDATE_STATE, field, value })

    const updateActiveTrackerById = (activeTrackerId) => dispatch({ type: ACTIONS.UPDATE_ACTIVE_TRACKER, activeTrackerId })

    return { updateState, updateActiveTrackerById }
  }, [])

  useEffect(() => {
    void simpleGet(reportPath)
      .then(res => {
        api.updateState('report', res)
        if (res?.tracker_templates) {
          api.updateState('trackerTemplates', res.tracker_templates)
          api.updateActiveTrackerById(res.tracker_templates[0]?.id)
        }
      })
  }, [])

  return (
    <ReportAPIContext.Provider value={api}>
      <ReportContext.Provider value={state}>
        {children}
      </ReportContext.Provider>
    </ReportAPIContext.Provider>
  )
}

/* Hooks */
export const useReportContext = () => useContext(ReportContext)
export const useReportAPI = () => useContext(ReportAPIContext)
