import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useDocumentBuilderApiContext } from "../../../contexts/documentBuilderContext"
import { faRotateLeft } from "@fortawesome/free-solid-svg-icons"
import { styles } from "../../../utilities/richTextEditorHelpers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const UserInput = ({ contentId, content }) => {
  const [inputValue, setInputValue] = useState(content?.documentContent || content?.defaultContent)
  const { updateContent } = useDocumentBuilderApiContext()

  const onReset = useCallback(() => setInputValue(content?.defaultContent), [])
  const resetActive = useMemo(() => inputValue === content.defaultContent, [content, inputValue])

  useEffect(() => updateContent(contentId, 'documentContent', inputValue), [updateContent, contentId, inputValue])

  return <div className="flex flex-row w-full justify-start items-center gap-1 border border-gray-500 rounded-lg">
    <input
      type='text'
      onChange={ event => setInputValue(event.target.value) }
      value={ inputValue }
      className="rounded-l-lg border-0 border-r w-full"
    />
    <button className="disabled:cursor-not-allowed group p-2 w-8" disabled={ resetActive } onClick={ onReset }>
      <FontAwesomeIcon icon={ faRotateLeft } className={ styles.formatIcon } disabled={ resetActive }/>
    </button>
  </div>
}

export default UserInput
