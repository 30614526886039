import React, { useMemo } from "react"
import { isValid, parseISO } from "date-fns"

const DevBlogCard = ({ post, indexView = false }) => {
  const TitleTag = useMemo(() => indexView ? 'h3' : 'h1', [indexView])
  const dateToShow = useMemo(() => {
    const parsedContent = parseISO(post.published_at)

    if (isValid(parsedContent)) {
      return parsedContent.toDateString()
    } else {
      return post?.published_at
    }
  }, [post])

  return (
    <div className="w-full p-4 flex flex-col items-center gap-6">
      <TitleTag className="font-bold text-5xl text-center">{ post.title }</TitleTag>
      { indexView && <p>{ post.summary }</p> }
      <p className="text-sm text-slate-600 text-right">{ dateToShow }</p>
    </div>
  )
}

export default DevBlogCard
