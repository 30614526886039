import React, { useMemo } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { useTrackerAPI, useTrackerContext } from "../../../contexts/trackerContext"
import { usePortfolioContext } from "../../../contexts/portfolioContext"
import { tableStyles } from "../../../utilities/stylesHelpers"

const AutoCell = ({ content, header = {}, entry = null, renderPosition }) => {
  const { project, projects } = usePortfolioContext()
  const { toggleModal, onContentInit } = useTrackerAPI()
  const { collapsedHeaders } = useTrackerContext()
  const coordinateMeta = useMemo(() => header?.tracker_header_metas.find(meta => meta.meta_type === 'Coordinate'), [header])
  const collapsed = useMemo(() => collapsedHeaders.includes(renderPosition), [collapsedHeaders, header])

  const rowProject = useMemo(() => {
    if (project?.id === entry?.project_id) return project
    return projects.find(project => project?.id === entry?.project_id)
  }, [entry, project, projects])

  const determinedContent = useMemo(() => {
    return content?.value || rowProject?.[coordinateMeta.origin_type]?.[coordinateMeta?.meta_value]
  }, [content, rowProject])

  const onClickHandler = () => {
    onContentInit({
      content: determinedContent,
      cellColor: content?.cell_color,
      header: header,
      entryId: entry?.id,
      cellId: content?.id,
      revisions: content?.versions,
    })
    toggleModal()
  }

  return (
    <>
      {collapsed ?
        <td className={tableStyles.td}>
          <p className={tableStyles.tdText} style={{ textAlign: 'center' }}>...</p>
        </td> :
        <td className={tableStyles.td} onClick={onClickHandler}>
          <p className={tableStyles.tdText}>{determinedContent}</p>
          <FontAwesomeIcon
            className={tableStyles.hoverIcon}
            icon={faEdit}
          />
        </td>
      }
    </>
  )
}

export default AutoCell
