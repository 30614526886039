import React from 'react'
import DatePicker from "react-datepicker"
import { useSampleCollectionApi,useSampleCollectionContext } from '../../../../contexts/sampleCollectionContext'
import { inputClass } from "../../../../utilities/stylesHelpers"

const DateSelect = () => {
  const { updateNestedState } = useSampleCollectionApi()
  const { result_date } = useSampleCollectionContext().sampleCollection

  const handleDateChange = date => updateNestedState('sampleCollection', 'result_date')(date)
  return (
    <label htmlFor="result_date">
      <DatePicker
        name="result_date"
        className={inputClass}
        selected={result_date ? new Date(result_date) : null}
        onChange={handleDateChange}
        dateFormat="yyyy/MM/dd"
        placeholderText="Recieved Date"
        required={true}
      />
      <span className="text-red-500 ml-2">*Required</span>
    </label>)
}

export default DateSelect
