import React from "react"
import DevBlogCard from "./devBlogCard"

const DevBlogShow = ({ post }) => {
  return (
    <div className="mt-8 p-8 m-auto max-w-screen-md">
      <DevBlogCard post={ post }/>
      <div dangerouslySetInnerHTML={ { __html: post.body } }>
      </div>
    </div>
  )
}

export default DevBlogShow
