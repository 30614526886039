import React, { useState } from "react"
import Select from "react-select";
import { isEmpty } from "lodash"

const PortfolioSelect = ({ portfolioOptions }) => {
  const [portfolioLink, setPortfolioLink] = useState('')
  const mousePointer = isEmpty(portfolioLink) ? {pointerEvents: 'none'} : {}

  return (
    <div className='w-full h-full flex-col flex'>
      <div className='my-4'>
        {portfolioOptions && <>
          <p>Portfolio select</p>
          <Select
            className={`capitalize mb-5`}
            placeholder={'Select'}
            options={portfolioOptions}
            onChange={(selectedOption) => setPortfolioLink(`/portfolios/${selectedOption.value}`)}
          />
          <div className="w-full flex justify-end">
            <a
              href={portfolioLink}
              className='btn btn-primary truncate w-full text-center w-min text-sm py-1.5 px-3'
              style={mousePointer}
            >
              View Portfolio
            </a>
          </div>
        </>}
      </div>
    </div>
  )
}

export default PortfolioSelect
