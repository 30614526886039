import { classNames } from "./utils"

export const styles = (() => {
  const formatIconBase = 'bg-contain inline-block h-5 w-5 mt-0.5 align-[-0.25rem] flex opacity-60 group-disabled:opacity-20 group-disabled:cursor-not-allowed cursor-pointer transition-all'
  const formatIconHover = 'hover:opacity-100'

  return {
    toolbar: 'flex bg-white p-1 pb-0 align-middle rounded-t-lg border border-slate-800',
    divider: 'w-px bg-slate-100 mx-1',
    toolbarItem: 'border-0 flex bg-none rounded-xl p-2 cursor-pointer align-middle disabled:cursor-not-allowed group',
    spaced: 'mr-0.5',
    active: 'bg-slate-200',
    formatIcon: classNames(formatIconBase, formatIconHover),
  }
})()
