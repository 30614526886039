import React, { useState, useEffect } from 'react'
import { toggleChemical } from "../../utilities/cocExportHelpers";
import AnimatedCheckbox from "./animatedCheckbox"

const SelectChemical = ({ selected, chemical, selectedChemicals, setSelectedChemicals }) => {
  let [checked, setChecked] = useState(selected)

  useEffect(() => {
    setChecked(selected)
  }, [selected])

  return <div className='text-center'>
    <AnimatedCheckbox
      checked={ checked }
      onClick={ () => {
        setChecked(!checked)
        toggleChemical(chemical, selectedChemicals, setSelectedChemicals)
      } }
    />
  </div>
}

export default SelectChemical
