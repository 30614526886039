import React, { useState } from 'react'
import { ImportModal } from '../shared/importModal'
import LoadingSpinner from '../shared/loadingSpinner'
import { getExportFile } from "../../utilities/cocExportHelpers"
import { useCocExport, useCocExportApi } from "../../contexts/cocExport"
import { getExports } from '../../api/cocApi'
import { notifyError } from '../shared/notice'

const ExportButton = ({ exportPath, exportFormat, projectId }) => {
  let [loading, setLoading] = useState(false);
  const { selectedSamples, includeChangeOfControl, headerDetails, selectedChemicalsCount, selectedApecsCount, numberOfCoolers, selectedSamplesCount } = useCocExport()
  const { updateExportHistory } = useCocExportApi()

  const onClick = async (draftStatus) => {
    setLoading(true)
    const stats = { apecs: selectedApecsCount, chemicals: selectedChemicalsCount, coolers: numberOfCoolers, total: selectedSamplesCount }

    try {
      await getExportFile(exportPath, exportFormat, includeChangeOfControl, headerDetails, selectedSamples, stats, draftStatus)
      const getExportsResult = await getExports(projectId)
      updateExportHistory(getExportsResult.data)
    } catch (error) {
      notifyError(`There was a error exporting the files: ${error}`)
      console.log(error)
    }
    finally {
      setLoading(false)
    }
  }

  return (
    loading ?
      <ImportModal title='Exporting CoC'>
        <LoadingSpinner />
      </ImportModal>
      :
      (
        <div className="flex w-1/2">
          <div className="flex-1">
            <div className='btn btn-green cursor-pointer text-xl font-bold w-40 text-center mx-4 mt-7'
              onClick={() => onClick(false)}>
              Export!
            </div>
          </div>
          <div className="flex-1">
            <div className='btn btn-orange cursor-pointer text-xl font-bold w-56 text-center mx-4 my-7'
              onClick={() => onClick(true)}>
              Save as Draft
            </div>
          </div>
        </div>
      )
  )
}

export default ExportButton
