import React from "react"
import DevBlogCard from "./devBlogCard"

const DevBlogIndex = ({ posts }) => {
  return (
    <div className="p-8 mt-8 mx-auto max-w-screen-md flex flex-col items-center gap-8">
      { posts.map(post => (
        <a key={ post.id } className="flex flex-col gap-3 w-full p-4 shadow-lg rounded" href={ `/dev-blog/${ post.slug }` }>
          <DevBlogCard post={ post } indexView={ true }/>
        </a>
      )) }
    </div>
  )
}

export default DevBlogIndex
