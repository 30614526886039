import React, { useEffect, useState, useMemo } from 'react'
import FormEntry from "./formEntry"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import axios from "axios"
import { notifyError, notifyAlert } from "../shared/notice";

const FormCell = ({ formField, formEntry }) => {
  if (formField && formEntry) {
    return <td>
      <FormEntry formField={formField} originalFormEntry={formEntry} routes={formEntry.routes} />
    </td>
  } else {
    return <td />
  }
}

const FormDatumRow = ({ formDatum, formField }) => {
  const [formFields, setFormFields] = useState()
  const [formEntries, setFormEntries] = useState(formDatum.form_entries)
  const groupRepeatableCounts = useMemo(() => {
    return [...new Set(formDatum.form_entries.map(entry => entry.group_repeatable_count))].sort((a, b) => a - b)
  }, [])

  const findFormEntry = (formField, groupRepeatableCount) => {
    return formEntries.find(item => item?.form_field_id === formField.id && item.group_repeatable_count === groupRepeatableCount)
  }

  useEffect(() => {
    if (formField.children.length > 0) {
      setFormFields(formField.children)
    } else {
      setFormFields([formField])
    }
  }, [])

  const createFormEntry = () => {
    notifyAlert("Creating new data row...")
    const token =
      document.querySelector('[name=csrf-token]').content
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token
    formFields?.map((formField) => {
      axios.post(formDatum?.routes.createFormEntryPath, {
        form_entry: {
          group_repeatable_count: groupRepeatableCounts.length + 1,
          form_field_id: formField.id,
          value: ''
        }
      }).then(res => {
        setFormEntries([...formEntries, res.data])
        window.location.reload()
      }).catch(e => {
        notifyError('Something went wrong...')
      })
    })
  }
  return (
    <>
      {groupRepeatableCounts.map((groupRepeatableCount, index) => <tr key={index}>
        {formFields && formFields.map((formField) => {
          let formEntry = findFormEntry(formField, groupRepeatableCount)
          return <FormCell key={`formEntry_${formField.id}_${formEntry?.id}`} formField={formField}
            formEntry={formEntry} />
        })}
      </tr>)}
      {formField.label === "Sample Details" && formDatum.is_admin &&
        <tr><FontAwesomeIcon
          onClick={() => createFormEntry()}
          className='text-lg cursor-pointer text-gray-600'
          icon={faPlusCircle} /></tr>}
    </>
  )
}

export default FormDatumRow
