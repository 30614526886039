import React, { useReducer, useState } from 'react'
import { tableStyles } from '../../../utilities/stylesHelpers'
import ExportHistoryRow from './exportHistoryRow'
import HistoryModal from './historyModal'
import { toast } from 'react-toastify'
import { emailCocExport } from '../../../api/emailApi'
import { getExports } from '../../../api/cocApi'
import EmailModal from '../../modal/emailModal'
import { useCocExportApi, useCocExport } from '../../../contexts/cocExport'

const CocExportHistory = ({ project }) => {
  const [emailModal, toggleEmailModal] = useReducer(state => !state, false)
  const [historyModal, toggleHistoryModal] = useReducer(state => !state, false)
  const [currentExport, setCurrentExport] = useState(null)
  const { exportHistory, emailInfo } = useCocExport()
  const { updateExportHistory } = useCocExportApi()

  const onModalToggle = ({ exportObject, modalType }) => {
    if (exportObject) setCurrentExport(exportObject)
    if (modalType === 'email') toggleEmailModal()
    if (modalType === 'history') toggleHistoryModal()
  }
  const resetCurrentExport = (modalType) => {
    setCurrentExport(null)
    if (modalType === 'email') toggleEmailModal()
  }
  const onSendEmail = async ({ selectedEmailOptions, subject, body }) => {
    const params = {
      recipients: selectedEmailOptions.map(email => email.value),
      subject,
      body,
      coc_export_id: currentExport?.id
    }

    try {
      await emailCocExport({ params }).
        then(getExports(project.id).then(res => updateExportHistory(res.data)))

      resetCurrentExport('email')

      toast.success('Chain of Custody export sent to recipients')
    } catch (e) {
      toast.error('Chain of Custody export failed to send')
    }
  }

  return (
    <>
      <h1 className="text-2xl font-bold text-center mt-4">Export History</h1>
      <table className={tableStyles.table}>
        <thead className={tableStyles.tableHeaderRow + " bg-gray-700 text-white"}>
          <tr className={tableStyles.tableHeaderRow}>
            <th className={"border-gray-200 border border-b-2 align-top sticky top-0 p-4"}>CoC Name</th>
            <th className={"border-gray-200 border border-b-2 align-top sticky top-0 p-4"}>Date Generated</th>
            <th className={"border-gray-200 border border-b-2 align-top sticky top-0 p-4"}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {exportHistory?.slice().reverse().map(exportObject =>
            <ExportHistoryRow exportObject={exportObject} toggleModal={onModalToggle} project={project} key={exportObject.id} />
          )}
        </tbody>
      </table>
      {historyModal && (
        <HistoryModal
          modal={historyModal}
          toggleModal={onModalToggle}
          cocExport={currentExport}
        />
      )}
      {emailModal && (
        <EmailModal
          modal={emailModal}
          title={'CoC Export'}
          onSend={onSendEmail}
          fileName={currentExport?.coc_export_filename}
          onClose={() => onModalToggle({ modalType: 'email' })}
          project={project}
          emailBody={emailInfo.body}
          emailSubject={emailInfo.subject}
        />
      )}
    </>
  )
}

export default CocExportHistory
