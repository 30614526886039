import React, { createContext, useContext, useMemo, useReducer } from "react"
import axios from "axios"
import { notifyAlert, notifyError } from "../components/shared/notice"

/* Cell Data Actions */
const INIT_EDIT_CELL = 'INIT_EDIT_CELL'
const UPDATE_CONTENT = 'UPDATE_CONTENT'

/* Modal Actions */
const TOGGLE_MODAL = 'TOGGLE_MODAL'

/* Reducer & State */
const initialState = {
  editCellContent: '',
  editCellHeader: '',
  editCellHeaderFormatted: '',
  editCellId: null,
  modal: false
}

const permitTableReducer = (state, action) => {
  switch (action.type) {
    case INIT_EDIT_CELL:
      return {
        ...state,
        editCellContent: action.payload.content,
        editCellHeader: action.payload.header,
        editCellHeaderFormatted: action.payload.header,
        editCellId: action.payload.id
      }
    case UPDATE_CONTENT:
      return { ...state, editCellContent: action.payload }
    case TOGGLE_MODAL:
      return { ...state, modal: !state.modal }
    default:
      return state
  }
}

/* Contexts */
const PermitTableContext = createContext(initialState)

const PermitTableAPIContext = createContext({
  onContentChange: () => {},
  onContentInit: () => {},
  onSave: () => {},
  toggleModal: () => {},
})

/* Provider Component */
export const PermitTableProvider = ({ children, routes: { permitsPath } }) => {
  const [state, dispatch] = useReducer(permitTableReducer, initialState)

  const api = useMemo(() => {
    const onContentChange = (value) => {
      dispatch({ type: UPDATE_CONTENT, payload: value })
    }

    const onContentInit = (content, header, id) => {
      dispatch({ type: INIT_EDIT_CELL, payload: { content, header, id } })
    }

    const onSave = async (id, key, value) => {
      try {
        const token = document.querySelector('[name=csrf-token]').content
        axios.defaults.headers.common['X-CSRF-TOKEN'] = token

        const res = await axios.put(`${permitsPath}/${id}.json`, { payload: { [key]: value }})

        notifyAlert(`Successfully updated ${key} to: \n${value}`)

        return res
      }
      catch (e) {
        console.error(e)
        notifyError('Error: ' + e.message)
      }
    }

    const toggleModal = () => {
      dispatch({ type: TOGGLE_MODAL })
    }

    return { onContentChange, onContentInit, onSave, toggleModal }
  }, [])

  return (
    <PermitTableAPIContext.Provider value={api}>
      <PermitTableContext.Provider value={state}>
        {children}
      </PermitTableContext.Provider>
    </PermitTableAPIContext.Provider>
  )
}

/* Custom Context Hooks */
export const usePermitTable = () => useContext(PermitTableContext)
export const usePermitTableAPI = () => useContext(PermitTableAPIContext)
