import React from 'react'

const Provinces = ({ provinces }) => {
  return (
    <div>
      <h1>Site Provinces:</h1>
      <p className='italic'>* Must be spelt exactly as listed below</p>
      <ul className="list-disc mx-6">
        {provinces?.map((province, index) => {
          return <li key={index}>{province}</li>
        })}
      </ul>
    </div>
  )
}

export default Provinces
