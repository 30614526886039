import React from "react"
import { useParams } from "react-router-dom"
import Breadcrumb from "../../../layouts/nav/breadcrumb"

const AiDocumentBreadcrumbs = ({ documentName }) => {
  const { documentId } = useParams()

  return (
    <nav className='flex flex-row font-bold pb-2' aria-label='Breadcrumb'>
      <ol className='list-none p-0 inline-flex'>
        <Breadcrumb linkPath={ '/ai/documents' } linkName="Documents" routerLink={ true } firstLink={ true }/>
        { documentId && <Breadcrumb linkPath={ `/ai/documents/${ documentId }` } linkName={ documentName || documentId }
                                    routerLink={ true }/> }
      </ol>
    </nav>
  )
}

export default AiDocumentBreadcrumbs
