export const defaultFormatDropdownOptions = option => ({
  value: option.name,
  label: option.name,
  cell_color: option?.cell_color
});

export const userFormatDropdownOptions = user => ({
  value: user.id,
  label: user.first_name + ' ' + user.last_name,
})

export const columnSizes = { Small: '65px', Medium: '130px', Large: '260px', ExtraLarge: '390px' };

