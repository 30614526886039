import React from "react"
import { tableStyles } from "../../utilities/stylesHelpers"
import { faInfo, faSyncAlt } from "@fortawesome/free-solid-svg-icons"
import HoverIcon from "./checklistIcons/hoverIcon"
import { columnSizes } from "../../utilities/tableHelpers"
import { useChecklistContext } from "../../contexts/checklistContext"

const ProjectChecklistColumn = ({ columns }) => {
  const { collapsed } = useChecklistContext()
  const thStyles = collapsed ? tableStyles.thTextTruncate : tableStyles.th
  return (
    <thead className={tableStyles.tableHeaderRow}>
      <tr className={tableStyles.tableHeaderRow}>
        <th className={thStyles} style={{ backgroundColor: '#22c55e' }}>
          <div className={collapsed && "justify-center flex gap-x-1"}>
          <HoverIcon icon={faInfo} message="This Icon tells you more about the Task" />
          <br />
          <HoverIcon icon={faSyncAlt} message="This Icon tells you info about the last task update" />
          </div>
        </th>
        <th className={thStyles} style={{ backgroundColor: '#bae6fd', minWidth: columnSizes["Medium"] }}>
          Tasks
        </th>
        {columns?.map((column) =>
          <th key={column.id} className={thStyles} style={{ minWidth: columnSizes[column.cell_width] }}>
            {column.title}
          </th>
        )}
        <th className={tableStyles.th} style={{ backgroundColor: '#bae6fd', minWidth: columnSizes["Small"] }}>
          Email
        </th>
      </tr>
    </thead>
  )
}

export default ProjectChecklistColumn
