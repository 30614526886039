import React from "react"
import Breadcrumb from "../../layouts/nav/breadcrumb"

const AssessmentBreadCrumbs = ({ project, portfolio }) => {
  return (
    <nav className='breadcrumbs' aria-label='Breadcrumb'>
      <ol className='list-none p-0 inline-flex'>
        <li className='flex items-center'>
          <a href={`/portfolios/${portfolio.id}`} className='text-regular-blue'>
            {portfolio.name}
          </a>
        </li>
        <Breadcrumb linkPath={`/projects/${project.id}`} linkName={project.name} routerLink={true} />
        <Breadcrumb linkPath={`/projects/${project.id}/historical-assessment`} linkName="Historical Assessment" routerLink={true} linkStyle='' />
      </ol>
    </nav>
  )
}

export default AssessmentBreadCrumbs
