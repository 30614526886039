import React, { useEffect, useState } from 'react'

const DepthInput = ({ depthType, depth }) => {
  const [depthValue, setdepthValue] = useState('')
  const name = `${depthType}_depth`
  const placeholder = `${depthType.charAt(0).toUpperCase() + depthType.slice(1)} Depth (m)`

  const handleChange = (e) => {
    const inputValue = e.target.value;
    // Regular expression to allow up to 2 digits before and 2 digits after a decimal point
    const regex = /^\d{0,2}(\.\d{0,2})?$/
    if (inputValue === '' || regex.test(inputValue)) {
      setdepthValue(inputValue);
    }
  }

  useEffect(() => { depth ? setdepthValue(depth) : setdepthValue('') }, [depth])

  return (
    <label htmlFor={name} className="flex flex-row items-center justify-start w-full">
      <span className="text-sm mx-2">{placeholder}</span>
      <input
        type="number"
        value={depthValue}
        onChange={handleChange}
        name={name}
        className="input"
        placeholder={placeholder}
        required={true}
        min="0"
      />
      <span className="text-red-500 ml-2">*Required</span>
    </label>
  )
}

export default DepthInput
