import React, { useEffect, useState } from "react"
import { useTrackerContext } from "../../../contexts/trackerContext"
import TablePageBreadcrumbs from "../../../layouts/tablePage/tablePageBreadcrumbs"
import Breadcrumb from "../../../layouts/nav/breadcrumb"
import RevisionEntry from "./revisionEntry"
import { pipe, directedFlatten } from "../../../utilities/utils"
import { sortEntries, mapEntries, formatDate } from "./changeLogHelpers"

const TrackerChangeLog = () => {
  const { trackerTemplate, trackerEntries } = useTrackerContext()
  const [revisions, setRevisions] = useState([])

  useEffect(() => {
    pipe(
      directedFlatten('tracker_attributes'),
      directedFlatten('versions'),
      sortEntries,
      mapEntries(formatDate),
      setRevisions,
    )(trackerEntries)
  }, [trackerEntries])

  return (
    <div className="overflow-x-scroll h-full">
      <TablePageBreadcrumbs tableName={trackerTemplate?.name} tableSlug={trackerTemplate?.slug} scopeType={"trackers"}>
        <Breadcrumb linkPath="#" linkName="Change Log" />
      </TablePageBreadcrumbs>
      <div className='flex flex-col w-full p-8'>
        <h1 className="mb-4">Tracker Change Log</h1>
        <div>
          {revisions.map((revision, index) => <RevisionEntry revision={revision} revisionNumber={revisions.length - index} key={index} />)}
        </div>
      </div>
    </div>
  )
}

export default TrackerChangeLog
