import { isEmpty } from "lodash";
import React from "react";
import { usePortfolioAPI } from "../../contexts/portfolioContext";
import { useTrackerContext } from "../../contexts/trackerContext";
import { tableStyles } from "../../utilities/stylesHelpers";
import TableHeader from "./trackerCell/tableHeader";

const TableHeaderRow = () => {
  const { trackerTemplate, headers, autoHeaders, chartData, showExtraData } = useTrackerContext()
  const { isPortfolio } = usePortfolioAPI()

  const headerCount = headers?.length
  const autoHeaderCount = autoHeaders?.length

  return (
    <thead className={tableStyles.tableHeaderRow}>
      <tr className={tableStyles.tableHeaderRow}>
        {headers?.map((header, index) => (
          <TableHeader key={`${index}-header-key`} headerId={header.id} content={header.title}
            headerCellWidth={header.cell_width} renderPosition={header.renderPosition}
            description={header.description} />
        ))}
        {showExtraData && isPortfolio &&
          <TableHeader key={'project-header-key'} content="Project" renderPosition={headerCount}
            description="The project entry is assigned to" />}
        {showExtraData && autoHeaders?.map((header, index) => (
          <TableHeader key={`${index}-header-key`} headerId={header.id} content={header.title}
            renderPosition={headerCount + 1 + index} description={header.description} />
        ))}
        {trackerTemplate?.zoom_to_button && isEmpty(chartData) &&
          <TableHeader key={'zoom-header-key'} content="Zoom To Location" headerCellWidth="Medium"
            renderPosition={headerCount + autoHeaderCount + 1} description="Zoom map to location of entry" />}
        {trackerTemplate?.enable_entry_deletion &&
          <TableHeader key={'delete-header-key'} content="Delete" renderPosition={headerCount + autoHeaderCount + 2}
            description="Delete Entry" />}
        {trackerTemplate?.enable_entry_duplication &&
          <TableHeader key={'select-header-key'} content="Select" renderPosition={headerCount + autoHeaderCount + 3}
            description="Select Entry to be duplicated or deleted" />}
      </tr>
    </thead>
  )
}

export default TableHeaderRow
