export const UTM_ZONE_OPTIONS = [
  {label: '7U', value: '7U'},
  {label: '8U', value: '8U'},
  {label: '9U', value: '9U'},
  {label: '10U', value: '10U'},
  {label: '11U', value: '11U'},
  {label: '12U', value: '12U'},
  {label: '13U', value: '13U'},
  {label: '14U', value: '14U'},
  {label: '15U', value: '15U'},
  {label: '16U', value: '16U'},
  {label: '17U', value: '17U'},
  {label: '18U', value: '18U'},
  {label: '19U', value: '19U'},
  {label: '20U', value: '20U'},
  {label: '21U', value: '21U'},
  {label: '22U', value: '22U'},
]
