import React, { useMemo } from 'react'
import { testResultsHeaders } from '../../../constants/headers'
import { useTestResultsContext } from '../../../contexts/testResultsContext'
import { useTestResultsFilterContext } from '../../../contexts/testResultsFilterContext'
import { tableStyles } from '../../../utilities/stylesHelpers'
import GuidelineRow from './guidelineRow'
import TableHeader from './tableHeader'

const TableHeaderRows = ({ filteredContaminantGroups }) => {
  const blanks = Array.from({ length: 7 }, (_, i) => i)
  const { activeGuidelines, units } = useTestResultsContext()
  const { availableChemicalUnits } = useTestResultsFilterContext()

  const availableUnits = useMemo(() => {
    const availableUnitIds = new Set(availableChemicalUnits.map((chemicalUnit) => chemicalUnit.unit_id))
    return units.filter((unit) => availableUnitIds.has(unit.id))
  }, [units, availableChemicalUnits])

  const resultBlanks = Array.from({ length: availableChemicalUnits.length + 1 }, (_, i) => i)

  return (
    <thead className={tableStyles.tableHeaderRow}>
      <tr className={tableStyles.tableHeaderRow}>
        {blanks.map((index) => (
          <TableHeader style={tableStyles.bottomTh} key={`${index}-blank-chemical`} content={''} />
        ))}
        <TableHeader content={'CHEMICAL'} style={tableStyles.bottomTh} />
        {filteredContaminantGroups.map((group, index) => {
          const groupName = Object.keys(group)[0]
          const chemicals = group[groupName]
          return chemicals.map((chemical, chemIndex) => (
            <TableHeader
              key={`${index}-${chemIndex}-chemical`}
              style={tableStyles.bottomTh}
              content={chemical.name}
              sideways={true}
            />
          ))
        })}
      </tr>
      <tr className={tableStyles.tableHeaderRow}>
        {blanks.map((index) => (
          <TableHeader style={tableStyles.bottomTh} key={`${index}-blank-contaminantGroup`} content={''} />
        ))}
        <TableHeader content={'CONTAMINANT GROUP'} style={tableStyles.bottomTh} />
        {filteredContaminantGroups.map((group, index) => {
          const groupName = Object.keys(group)[0]
          const groupLength = group[groupName].length
          return (
            <TableHeader
              key={`${index}-contaminantGroup`}
              style={tableStyles.bottomTh}
              content={groupName}
              colSpan={groupLength}
            />
          )
        })}
      </tr>
      <tr className={tableStyles.tableHeaderRow}>
        {blanks.map((index) => (
          <TableHeader key={`${index}-blank-unit`} content={''} />
        ))}
        <TableHeader content={'UNIT'} style={tableStyles.bottomTh} />
        {availableUnits.map((unit, index) => (
          <TableHeader key={`${index}-unit`} content={unit.unit} />
        ))}
      </tr>
      {activeGuidelines.map((guideline) => (
        <GuidelineRow key={guideline.id} guideline={guideline} />
      ))}
      <tr className={tableStyles.tableHeaderRow}>
        {testResultsHeaders.map((testResultHeader, index) => (
          <TableHeader style={tableStyles.bottomTh} key={`${index}-headers`} content={testResultHeader.title} />
        ))}
        {resultBlanks.map((index) => (
          <TableHeader key={`${index}-blank-results`} content={''} />
        ))}
      </tr>
    </thead>
  )
}

export default TableHeaderRows
