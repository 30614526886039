import React from 'react'

const TimesheetTemplate = ({timesheetTemplate}) => {
  const urlHost = window.location.origin

  return (
    <table className='mx-4 my-8 text-left w-11/12'>
    <thead>
      <tr className='bg-regular-orange w-full text-white text-2xl'><th className='px-2 py-3' colSpan='3'>{timesheetTemplate.name} Timesheet</th></tr>
    </thead>
    <tbody>
      <tr><td className='py-6'></td></tr>
      <tr className='border-b border-gray-400'>
        <td className='font-semibold px-2 py-3 w-1/5'>JSON URL:</td>
        <td className='px-2 py-3' colSpan='2'>{`${urlHost}/api/timesheets/${timesheetTemplate.id}.json`}</td>
      </tr>
      <tr><td className='py-6'></td></tr>
    </tbody>
  </table>  )
}

export default TimesheetTemplate
