import React from 'react'
import * as filter from '../filters'
import { usePhotoAPIContext, usePhotoContext } from '../../contexts/photoContext'

const PhotoFilters = () => {
  const { updateFilteredGalleryMap } = usePhotoAPIContext()
  const { filterOptions } = usePhotoContext()
  
  const onEndDateSelect = (value) => { updateFilteredGalleryMap({ filter: 'end_date', value }) }
  const onStartDateSelect = (value) => { updateFilteredGalleryMap({ filter: 'start_date', value }) }

  return (
    <div className='border border-blue-500 p-4 rounded-lg m-4'>
      <h1 className='text-center'><strong>FILTERS</strong></h1>
      <filter.DateFilter dateRange={filterOptions.date_range} onEndDateSelect={onEndDateSelect} onStartDateSelect={onStartDateSelect} updateRange={false} />
    </div>
  )
}

export default PhotoFilters
