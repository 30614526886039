import React from 'react'
import BackgroundTable from './backgroundTable'

const SummaryTable = () => {
  return (
    <div>
      <h1><strong>Summary Table</strong></h1>
      <BackgroundTable />
    </div>
  )
}

export default SummaryTable
