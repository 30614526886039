import React from 'react'

const ShowExtraDataButton = ({toggleShowExtraData}) => {
  return (
    <button
      onClick={toggleShowExtraData}
      className='border text-white bg-regular-blue hover:bg-hover-blue border-regular-blue rounded-md px-2  transition duration-500 select-none text-xl font-bold w-80 text-center m-4'>
      Display Field Screening Values
    </button>
  )
}

export default ShowExtraDataButton
