import React from "react"
import StandardButton from "../../shared/standardButton"
import { tableStyles } from "../../../utilities/stylesHelpers"
import { useTrackerEntryApi } from "../../../contexts/trackerEntryContext"

const EmailCell = ({ header, styler }) => {
  const { toggleEmailModal } = useTrackerEntryApi()

  return (
    <td className={tableStyles.td} style={{minWidth: "120px", ...styler}}>
      <StandardButton onClickHandler={() => toggleEmailModal(header)} buttonText="Email"/>
    </td>
  )
}

export default EmailCell
