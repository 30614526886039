import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import FormEntry from "./formEntry";

const VerticalFormFieldGroup = ({ formField, formDatum }) => {
  let [style, setStyle] = useState({})
  let [isExpanded, setIsExpanded] = useState(false)

  const hasChildren = () => {
    return formField.children.length > 0
  }

  useEffect(() => {
    if (hasChildren() && !isExpanded) {
      setStyle({ height: '5rem', overflowY: 'hidden' })
    } else {
      setStyle({ height: 'auto' })
    }
  }, [isExpanded])

  const Overlay = () => {
    return <div className='absolute x-0 y-0 w-full h-full bg-gradient-to-t from-gray-200 to-gray' />
  }

  const findFormEntry = (formField) => {
    return formDatum.form_entries.find(item => item.form_field_id === formField.id)
  }

  const FieldRow = ({ formField }) => {
    let formEntry = findFormEntry(formField)
    return <tr>
      <th
        className='w-1/2 py-2 font-semibold text-xs text-gray-600 uppercase text-left pr-2 whitespace-nowrap pr-2'>{formField.label}</th>
      <td className='w-1/2'>
        {formEntry ?
          <FormEntry className='float-right' formField={formField} originalFormEntry={formEntry}
                     routes={formEntry.routes} /> :
          ''}
      </td>
    </tr>
  }

  const ExpandableGroup = () => {
    return <div className='w-full p-2 lg:p-4'>
      <div className='cursor-pointer flex flex-row items-center w-full font-bold'
           onClick={() => setIsExpanded(!isExpanded)}>
        <FontAwesomeIcon icon={isExpanded ? faChevronDown : faChevronRight}
                         className='text-gray-600 hover:text-black mr-2' />
        <div className='truncate'>{formField.label}</div>
      </div>

      <div style={style} className='relative'>
        {
          <>
            {!isExpanded && <Overlay />}
            <table className='w-full border-collapse'>
              <tbody>
              {formField.children.map(childField => <FieldRow
                key={`${childField.id}_cell`} formField={childField} />)}
              </tbody>
            </table>
          </>
        }
      </div>
    </div>;
  }

  if (hasChildren()) {
    return <ExpandableGroup />
  } else {
    return <div className='w-full p-2 lg:p-4'>
      <table className='w-full border-collapse'>
        <tbody>
        <FieldRow formField={formField} />
        </tbody>
      </table>
    </div>
  }
}

export default VerticalFormFieldGroup
