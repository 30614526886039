import React from 'react'

const HeaderInput = ({ title, field, value, update }) => {
  return(
    <div className='w-full flex flex-row items-center'>
      <label className='text-lg font-medium w-1/3'>{title}:</label>
      <input
        className="border border-gray-400 bg-gray-200 focus:bg-white rounded-md outline-none text-black p-2 w-2/3"
        value={value}
        onChange={event => update(field, event.target.value)}
      />
    </div>
  )
}

export default HeaderInput
