import React, { useState } from 'react'
import { putPermit } from "../../../api/permits"

const UploadPdfButton = () => {
  const [uploading, setUploading] = useState(false)
  const currentLocation = window.location.pathname

  const onSelectPdf = (e) => {
    e.preventDefault()
    setUploading(true)
    const formData = new FormData()
    formData.append('permit_pdf', e.target.files[0])
    const csrfToken = document.querySelector('[name=csrf-token]').content

    putPermit(
      { csrfToken: csrfToken, formData: formData, permitPath: `${currentLocation}/upload_pdf.json` }
    )
      .then((_) => window.location.reload())
  }

  return (
      <form>
        <label htmlFor="permit_row_pdf" className="btn btn-primary font-semibold mx-4 mb-4 relative cursor-pointer z-10 w-max">
          {uploading ? "Uploading..." : "Upload PDF"}
          <input type="file" name="permit_row_pdf" id="permit_row_pdf" onChange={onSelectPdf} accept=".pdf" className="opacity-0 absolute inset-0 z-0 cursor-pointer top-full h-px" required />
        </label>
      </form>
  )
}

export default UploadPdfButton
