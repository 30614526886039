import React, { useEffect, useState } from 'react'
import { useAnalyticExportApi, useAnalyticExportContext } from '../../contexts/analyticExportContext'
import StandardButton from '../shared/standardButton'

const CreateAnalystButton = () => {
  const { createAnalyticExport } = useAnalyticExportApi()
  const { analyticExport } = useAnalyticExportContext()
  const [active, setActive] = useState(false)
  useEffect(() => {
    setActive(analyticExport.collection_analytic_exports_attributes?.length > 0)
  }, [analyticExport.collection_analytic_exports_attributes])

  return (
    <StandardButton
      buttonText={'Create New Analyst'}
      btnClass={active ? 'btn-primary' : 'btn-disabled'}
      onClickHandler={active ? () => createAnalyticExport(analyticExport) : () => {}}
      toolTipMessage={ !active && 'Requires Selected Sample Collections' } />
  )
}

export default CreateAnalystButton
