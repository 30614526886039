import React from "react"

const MobileProjectOptions = () => {
  return <div>
    <h1>Mobile Project Options:</h1>
    <p className='w-5/6 mb-2'>When you upload the projects and sites you have to chose whether a Mobile Project is created. If “Yes”, a ProDelta DMS project as well as a Mobile Project will be created. If “No” only a project in ProDelta DMS will be created.</p>
    <p className='italic'>* Must be spelt exactly as listed below</p>
    <ul className="list-disc mx-6">
      <li>Yes</li>
      <li>No</li>
    </ul>
  </div>
}

export default MobileProjectOptions
