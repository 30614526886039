import React, { useState, useEffect } from 'react'
import Select from 'react-select'

const DropdownInput = ({ title, field, options, value, update = () => {}, updateFilters }) => {
  const [selectedValue, setSelectedValue] = useState(value)

  const handleOnChange = event => {
    if (event) {
      setSelectedValue(event);
      updateFilters({ [title.toLowerCase()]: event?.value || '' })
    } else {
      setSelectedValue(null);
      updateFilters({ [title.toLowerCase()]: '' })
    }
  }

  useEffect(() => {
    setSelectedValue(value)
  }, [value])

  useEffect(() => {
    update(field, selectedValue?.value || '')
  }, [selectedValue])

  useEffect(() => {
    if (options && options?.length === 1 && selectedValue?.value !== options[0].value) {
      if ((options[0].value !== 'Other')) {
        setSelectedValue(options[0])
      }
      update(field, options[0].value)
    }
  }, [options])

  return (
    <div className='w-full flex flex-row items-center'>
      <label className='text-lg font-medium w-1/3'>{title}:</label>
      <Select
        className="border border-gray-400 bg-gray-200 focus:bg-white rounded-md outline-none text-black p-2 w-2/3"
        options={options}
        onChange={handleOnChange}
        isClearable={true}
        value={selectedValue}
      />
    </div>
  )
}

export default DropdownInput
