import React from 'react'
import { tableStyles } from "../../utilities/stylesHelpers"
import { classNames } from "../../utilities/utils"
import TrackerCell from "./trackerCell"
import { cellContent } from "../../utilities/trackerHelpers"

const VerticalTable = ({ trackerHeaders, trackerEntries }) => {
  return (
    <table className={ classNames(tableStyles.table, "w-full max-w-[978px]") }>
      <tbody>
      { trackerHeaders?.map((header) => (
        <tr key={ header.id }>
          <th key={ header.id } className={ classNames(tableStyles.th, "text-lg text-left") }>{ header?.title }</th>
          { trackerEntries?.map(entry => (
            <TrackerCell
              key={ entry.id || header.id }
              content={ cellContent(header, entry.tracker_attributes) }
              header={ header }
              entryId={ entry.id }/>
          )) }
        </tr>
      )) }
      </tbody>
    </table>
  )
}
export default VerticalTable
