import React, { useState, useEffect } from "react"
import { getPermit } from "../../../api/permits"

const ViewPermitPdfButton = () => {
  const [permit, setPermit] = useState({})
  const currentLocation = window.location.pathname

  useEffect(() => {
    getPermit({ permitPath: `${currentLocation}.json` })
      .then(data => setPermit(data))
  }, [])

  return (
    <>
      {permit?.pdf_path && (
        <a
          className="btn btn-orange--outline relative mr-2 cursor-pointer z-10"
          id="add-permit-row-btn"
          name="add-permit-row-btn"
          target="_blank"
          href={permit?.pdf_path}
        >
          View PDF
        </a>
      )}
    </>
  )
}

export default ViewPermitPdfButton
