import React from 'react'
import { MapContainer, Marker, TileLayer, Tooltip, LayersControl } from 'react-leaflet'
import L from 'leaflet';
import 'leaflet/dist/leaflet.css'
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import '../admin/projects/map.css'
import { BingLayer } from 'react-leaflet-bing-v2'
import { getMapCenter } from "./helpers";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const Map = ({ boreholes }) => {
  const { BaseLayer } = LayersControl
  const BING_API_KEY = process.env.REACT_APP_BING_API_KEY

  const availableBoreholes = boreholes.filter(borehole => borehole.lat && borehole.lng)

  const mapMarkers = boreholes.map((borehole) => {
    const position = { lat: borehole.lat, lng: borehole.lng }

    return <Marker key={borehole.id} position={position}><Tooltip permanent>{borehole.title}</Tooltip></Marker>
  })

  return(
    <MapContainer center={getMapCenter(availableBoreholes)} zoom={18} scrollWheelZoom={false}>
      <LayersControl>
        <BaseLayer name='OpenStreetMap'>
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        </BaseLayer>
        <BaseLayer checked name='Satellite View'>
          <BingLayer bingkey={BING_API_KEY} type='AerialWithLabels' />
        </BaseLayer>
      </LayersControl>
      {mapMarkers}
    </MapContainer>
  )
}

export default Map
