import React from 'react'
import { usePhotoAPIContext } from '../../contexts/photoContext'

const ExportPhotosButton = () => {
const { exportPhotos } = usePhotoAPIContext()

  return (
    <>
      <button
        className='btn btn-green cursor-pointer text-xl font-bold w-40 text-center mx-4 mt-7'
        onClick={() => exportPhotos()}
      >
        Export!
      </button>
    </>
  )
}

export default ExportPhotosButton
