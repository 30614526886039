import React from 'react'

const SampleInformationRow = ({ sample }) => (
  <>
    <td className='p-4 border border-black'>{sample?.sampleId}</td>
    <td className='p-4 border border-black'>{sample?.apec}</td>
    <td className='p-4 border border-black'>{sample?.screening_date}</td>
    <td className='p-4 border border-black'>{sample?.average_depth}</td>
  </>
)
export default SampleInformationRow
