import React from "react";

const CocHeaders = ({ includeChangeOfControl }) => {
  return <>
    {includeChangeOfControl && <>
      <th className='font-semibold' title='Hydrocarbon Screening Value'>HSV</th>
      <th className='font-semibold' title='Salinity Screen Value'>SSV</th>
    </>}
  </>
}

export default CocHeaders
