import axios from "axios"
import { notifyError } from "../components/shared/notice"

export const exportBorehole = (projectId, params) => {
  try {
    return axios.post(`/projects/${projectId}/borehole_log_exports.json`, params)
  } catch (e) {
    console.error(e)
  }
}

export const exportAllBoreholes = (projectId, params) => {
  try {
    return axios({
      method: 'POST',
      url: `/projects/${projectId}/borehole_log_exports/export_all.json`,
      responseType: 'arraybuffer',
      params: params
    })
  } catch (e) {
    console.error(e)
    notifyError('Error Export FAILED' )
  }
}
