import React, { useEffect, useMemo, useState } from 'react'
import Select from 'react-select'
import { useTestResultsContext } from '../../../../contexts/testResultsContext'
import { inputClass } from "../../../../utilities/stylesHelpers"

const GuidelineSelect = ({ guideline }) => {
  const { guidelines } = useTestResultsContext()

  const [availableGuidelines, setAvailableGuidelines] = useState([...guidelines])
  const [selectedGuideline, setSelectedGuideline] = useState(null)
  const [selectedProvince, setSelectedProvince] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState(null)

  const availableProvinces = useMemo(() => {
    const guidelinesToUse = selectedProvince || selectedCategory ? availableGuidelines : guidelines
    const provinces = guidelinesToUse.reduce((acc, guideline) => {
      const province = guideline?.province
      if (province && !acc.some(item => item.value === province)) {
        acc.push({ value: province?.name, label: province?.abbreviation })
      }
      return acc
    }, [])
    return provinces
  }
    , [availableGuidelines, guidelines, selectedProvince, selectedCategory])

  const availableCategories = useMemo(() => {
    const guidelinesToUse = selectedCategory || selectedProvince ? availableGuidelines : guidelines
    const categories = guidelinesToUse.reduce((acc, guideline) => {
      const category = guideline?.category
      if (category && !acc.some(item => item.value === category)) {
        acc.push({ value: category, label: category })
      }
      return acc
    }, [])
    return categories
  }
    , [availableGuidelines, guidelines, selectedCategory, selectedProvince])

  const onProvinceChange = (selectedProvince) => {
    setSelectedProvince(selectedProvince)
    const filteredGuidelines = selectedProvince ? availableGuidelines.filter(guideline => guideline.province?.name === selectedProvince.value) : guidelines
    setAvailableGuidelines(filteredGuidelines)
  }

  const onCategoryChange = (selectedCategory) => {
    setSelectedCategory(selectedCategory)
    const filteredGuidelines = selectedCategory ? availableGuidelines.filter(guideline => guideline.category === selectedCategory.value) : guidelines
    setAvailableGuidelines(filteredGuidelines)
  }

  const guidelineOptions = useMemo(() => {
    return availableGuidelines.map(guideline => ({ value: guideline.id, label: guideline.name }))
  }, [availableGuidelines])

  useEffect(() => {
    guideline ?
      setSelectedGuideline({ value: guideline.id, label: guideline.name }) : setSelectedGuideline(null)
  }, [guideline])

  return (
    <div className="w-full">
      <label htmlFor="guideline_id" className="w-full">
        <div className="mb-4">
          <Select
            name="province"
            options={availableProvinces}
            className={inputClass}
            onChange={option => onProvinceChange(option ? option : null)}
            value={selectedProvince}
            isClearable='true'
            placeholder={'Choose a Province'}
          />
        </div>
        <div className="mb-4">
          <Select
            name="category"
            options={availableCategories}
            className={inputClass}
            onChange={onCategoryChange}
            value={selectedCategory}
            isClearable='true'
            placeholder={'Choose a Category'}
          />
        </div>
        <div className="mb-4">
          <Select
            name="guideline_id"
            options={guidelineOptions}
            className={inputClass}
            onChange={setSelectedGuideline}
            value={selectedGuideline}
            isClearable='true'
            placeholder={'Choose a Guideline'}
          />
          <span className="text-red-500 ml-2">*Required</span>
        </div>
      </label>
    </div>
  )
}

export default GuidelineSelect
