import React, { useMemo } from 'react'
import { safetyTicketHeaders } from '../../constants/headers'
import { useSafetyTicketContext } from '../../contexts/safetyTicketContext'
import Ticket from './ticket'
import UserTicketModal from './userTicketModal'
import { HeaderCell } from '../tableTemplates/headerCell'

const TicketsTable = ({ user }) => {
  const { safetyTickets, userTicketModal, requirements } = useSafetyTicketContext()

  const hasRequirements = useMemo(() => requirements.some(
    req => req.work_force_position.id === user?.work_force_position?.id && req.required !== 'No'
  ), [requirements, user?.work_force_position?.id])

  return (
    <>{!hasRequirements && <h2 className="text-2xl font-bold m-4">{user?.human_identifier} - {user?.work_force_position?.title} has no requirements</h2>}
      {hasRequirements && <>
        <h2 className="text-2xl font-bold m-4">{user?.human_identifier} - {user?.work_force_position?.title}</h2>
        <div className='grid grid-cols-6 mx-3 my-3'>
          {safetyTicketHeaders.map((header, index) => (
            <HeaderCell key={index} title={header.title} />
          ))}
          <div />
          {safetyTickets.map(ticket => (
            <Ticket
              key={ticket.id}
              ticket={ticket}
              user={user}
              requirements={requirements}
            />
          ))}
          {userTicketModal && <UserTicketModal />}
        </div>
      </>}
    </>
  )
}

export default TicketsTable
