import React from "react";
import BoreholeDropDownSelect from "./BoreholeDropdownSelect";
import BoreholeDatePicker from "./BoreholeDatePicker";
import { exportSelectedBoreholes } from "../boreholeLogHelpers";
import SelectedBoreholes from "./SelectedBoreholes";

const Export = ({ boreholes, onBoreholeSelect, dates, project, boreholeDetails }) => {
  return <>
    <h2>Please use the following options to select boreholes to be exported</h2>
    <button
      className={`border text-white bg-regular-blue hover:bg-hover-blue border-regular-blue rounded-md px-2 py-1 transition duration-500 select-none text-xl font-bold w-64 text-center`}
      onClick={() => onBoreholeSelect(boreholes)}
    >
      Select All
    </button>
    <BoreholeDropDownSelect boreholes={boreholes} onBoreholeSelect={onBoreholeSelect} />
    <BoreholeDatePicker boreholes={boreholes} dates={dates} onBoreholeSelect={onBoreholeSelect} />
    <SelectedBoreholes boreholeDetails={boreholeDetails} />
    <button
      className={`border text-white bg-regular-blue hover:bg-hover-blue border-regular-blue rounded-md px-2 py-1 transition duration-500 select-none text-xl font-bold w-64 text-center`}
      onClick={() => exportSelectedBoreholes(boreholeDetails, project)}
    >
      Export!
    </button>
  </>
}

export default Export
