import React from 'react'
import { useAiDocumentsAPI } from '../../../contexts/aiDocumentsContext'

const PromptCollectionView = ({ promptCollection, numberOfHeaders }) => {
  const emptyCells = numberOfHeaders - promptCollection.prompts.length
  const { runPrompts } = useAiDocumentsAPI()
  return (
    <>
      <div className='border border-gray-300 p-2 col-start-1'><strong>{ promptCollection.name }</strong></div>
      { promptCollection.prompts && promptCollection.prompts.map((prompt, index) => (
        <div key={ index } className={ `border border-gray-300 p-2 col-start-${ index + 2 }` }>
          { prompt.prompt }
        </div>
      )) }
      { Array.from({ length: emptyCells }, (_, i) => (
        <div key={ i }
             className={ `border border-gray-300 p-2 col-start-${ promptCollection.prompts.length + i + 2 }` }></div>
      )) }
      <div
        className={ `border border-gray-300 p-2 col-start-${ promptCollection.prompts.length + emptyCells + 2 } flex items-center justify-center` }>
        <button onClick={ () => runPrompts(promptCollection) } className='btn btn-green cursor-pointer'>Use for CSV
        </button>
      </div>
    </>
  )
}

export default PromptCollectionView
