import React from "react"
import { useTrackerAPI, useTrackerContext } from "../../contexts/trackerContext"
import { notifyAlert } from "../shared/notice"
import StandardButton from "../shared/standardButton"

const DuplicateEntryButton = () => {
  const { selectedEntryIds } = useTrackerContext()
  const { duplicateEntries } = useTrackerAPI()

  const onClickHandler = () => {
    duplicateEntries(selectedEntryIds), notifyAlert("Tracker Entry/Entries have been successfully DUPLICATED")
  }

  return <StandardButton buttonText="Duplicate Selected" btnClass="btn-green" onClickHandler={onClickHandler} />
}

export default DuplicateEntryButton
