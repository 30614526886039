import React, { useEffect, useState, useMemo } from "react";
import SyncedAt from "../../admin/projects/syncedAt"
import { getProjectNotes } from "../../../api/projectApi";

const ProjectInfo = ({ project }) => {
  const [localNotesCount, setLocalNotesCount] = useState(0)
  const [notesCount, setNotesCount] = useState(0)
  const [loading, setLoading] = useState( project?.notebook_guid )

  const notesText = useMemo(() => {
    return loading ? 'Loading...' : `${localNotesCount}/${notesCount} Notes Downloaded`
  }, [loading, localNotesCount, notesCount])

  useEffect(() => {
    if (project?.notebook_guid) {
      getProjectNotes(project.id)
        .then(res => {
          setLocalNotesCount(res.data.local_notes_count)
          setNotesCount(res.data.notes_count)
          setLoading(false)
        })
    }
  }, [])

  return(
    <>
      <p className='uppercase text-xs'>
        {notesText}
      </p>
      <p className='text-xs'>
        <SyncedAt project={project} />
      </p>
    </>
  )
}

export default ProjectInfo
