import React from "react"
import ToolTip from "../../tableTemplates/icons/toolTip"
import { useTrackerContext } from "../../../contexts/trackerContext"

const TrackerToolTip = () => {
  const { toolTip, toolTipMessage } = useTrackerContext()

  return <ToolTip toolTip={toolTip} toolTipMessage={toolTipMessage} />
}

export default TrackerToolTip
