import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckSquare, faTimes } from '@fortawesome/free-solid-svg-icons'
import { toggleSample } from "../../../utilities/cocExportHelpers"


export const SampleSelectionToggle = ({ sampleId, selectedSamples, updateSelectedSamples, selected }) => (
  <td className='p-4 border border-black'>
    <div className='cursor-pointer' onClick={() => toggleSample(sampleId, selectedSamples, updateSelectedSamples)}>
      {selected ?
        <FontAwesomeIcon icon={faCheckSquare} size='lg' className='text-green-500' /> :
        <FontAwesomeIcon icon={faTimes} size='lg' />
      }
    </div>
  </td>
)

export default SampleSelectionToggle
