import React, { useReducer } from "react"
import { faAnglesDown, faAnglesUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { toggleReducer } from "../../utilities/helpers"
import { classNames } from "../../utilities/utils"
import { Link, useParams } from "react-router-dom"
import { pathBuilder } from "../../api/documentLogApi"
import { buttonStyles, downloadButtonStyles } from "../../utilities/documentLogHelpers"
import GenerateFilesButton from "./generateFilesButton"
import { useDocumentLogApiContext } from "../../contexts/documentLogContext"

const Document = ({ document, templateSlug }) => {
  const { updateDocument } = useDocumentLogApiContext()
  const { projectId } = useParams()
  const [expanded, setExpanded] = useReducer(toggleReducer, false)

  return (
    <div className="rounded border border-slate-900 max-w-full">
      <div className="w-full flex justify-between items-center cursor-pointer" onClick={ () => setExpanded() }>
        <h3 className="text-xl font-semibold p-4">{ document.unique_id }</h3>
        <FontAwesomeIcon icon={ expanded ? faAnglesUp : faAnglesDown } className="h-4 p-4"/>
      </div>
      <div
        className={ classNames("w-full overflow-hidden flex flex-wrap justify-between gap-4", !expanded ? "h-0" : "p-4") }
      >
        <Link
          to={ pathBuilder({ projectId, templateSlug, documentId: document.id }) }
          className={ buttonStyles }
        >
          Review Document
        </Link>
        <GenerateFilesButton document={ document } templateSlug={ templateSlug } updateDocument={ updateDocument }/>
        <a href={ document.pdf_file_path } className={ downloadButtonStyles(!document.pdf_file_path) } target="_blank"
           rel="noreferrer noopener">
          Download PDF
        </a>
        <a href={ document.docx_file_path } className={ downloadButtonStyles(!document.docx_file_path) } target="_blank"
           rel="noreferrer noopener">
          Download Word
        </a>
      </div>
    </div>
  )
}

export default Document
