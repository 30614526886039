import React, { useMemo } from "react"
import { usePortfolioContext } from "../../contexts/portfolioContext"
import Breadcrumb from "../../layouts/nav/breadcrumb"
import Breadcrumbs from "../../layouts/nav/breadcrumbs"

const PhotoGalleryPageBreadcrumbs = () => {
  const { portfolio, project } = usePortfolioContext()
  const portfolioUrl = useMemo(() => `/portfolios/${portfolio?.id}`, [portfolio])
  const projectUrl = useMemo(() => `/projects/${project?.id}`, [project])

  return (
    <Breadcrumbs>
      <Breadcrumb linkPath={portfolioUrl} linkName={portfolio?.name} firstLink={true} />
      {project && <Breadcrumb linkPath={projectUrl} linkName={project?.name} />}
      <Breadcrumb linkPath={`${project ? projectUrl : portfolioUrl}/'photo_gallery'`} linkName={'Photo Gallery'} />
    </Breadcrumbs>
  )
}

export default PhotoGalleryPageBreadcrumbs
