import React, { useMemo } from 'react'
import { isEmpty } from "lodash"

const SecondarySites = ({ project }) => {
  const secondaryProjectSites = useMemo(() => project?.sites, [project])

  return (
    <div className={`lg:w-1/2 m-8 pb-4`}>
      {!isEmpty(secondaryProjectSites) && <>
        <h2 className="text-2xl font-bold">Secondary Project Sites</h2>
        <table className='w-full text-left mt-3'>
          {secondaryProjectSites?.map((site, index) => <thead key={index}><tr><th colSpan="2">{site?.name}</th></tr></thead>)}
        </table>
      </>}
    </div>
  )
}

export default SecondarySites
