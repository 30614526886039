import React, { useMemo } from "react"
import { useProjectContext } from "../../../contexts/projectContext"

const ImageContent = ({ content }) => {
  const { portfolio } = useProjectContext()

  const imagePath = useMemo(() => {
    switch (content?.defaultContent) {
      case 'owner_logo':
        return portfolio?.owner_logo_path
      case 'service_logo':
        return portfolio?.service_logo_path
      default:
        return
    }
  }, [content, portfolio])

  return <img className="w-full h-full" src={ imagePath } alt={ content?.defaultContent?.split("_")?.join(" ") }/>
}

export default ImageContent
