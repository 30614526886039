// Creates a websocket with the rails server to receive information when running streaming events

const openaiSocket = ({ id, channel = 'Openai::QueryChannel', onMessage = () => {} }) => {
  const SOCKET_URL = '/cable'
  const socket = new WebSocket(SOCKET_URL)

  // When the socket is opened, we can send data to the server
  socket.onopen = event => {
    const msg = {
      command: 'subscribe',
      identifier: JSON.stringify({ id: id, channel: channel })
    }
    socket.send(JSON.stringify(msg))
  }

  socket.onmessage = event => {
    try {
      const data = JSON.parse(event.data)
      if (data.type === "ping") return
      if (data.message) onMessage(data.message)
    } catch (error) {
      console.error('Error parsing message data', error)
    }
  }

  socket.onclose = event => console.log("closing")
  socket.onerror = error => console.log("Websocket error ", error)
  return socket
}

export default openaiSocket
