import axios from "axios"

export const getSamples = (projectId, sampleIds) => {
  try {
    return axios({
      method: 'GET',
      url: `/projects/${projectId}/samples.json`,
      params: { sample_ids: sampleIds }
    })
  }
  catch (e) {
    console.error(e)
  }
}
