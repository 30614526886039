import React, { useState } from "react"
import axios from "axios";
import { notifyAlert, notifyError } from "../../../shared/notice";

const ImportButtons = () => {
  const [displayName, setDisplayName] = useState( 'Upload File' )
  const [selectedFile, setSelectedFile] = useState()

  const handleFileChange = (event) => {
    event.persist()
    const file = event.target.files[0];
    setDisplayName(file.name)
    setSelectedFile(file)
  }

  const handleSubmission = (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('project_import_csv', selectedFile)

    axios.post(`/admin/projects/import_projects.json`, formData)
      .then(res => notifyAlert(res.data))
      .catch(_ => notifyError('There was an error uploading your data'))
  };

  return <>
    <form onSubmit={handleSubmission} className='mx-4'>
      <label className="btn btn-primary btn-outline font-semibold mr-4 relative cursor-pointer z-10">
        {displayName}
        <input type="file" name="tracker_csv" onChange={handleFileChange} accept=".csv" className="opacity-0 absolute inset-0 z-0 cursor-pointer top-full h-px" required />
      </label>
      <input type="submit" className="btn btn-primary cursor-pointer"/>
    </form>
  </>
}

export default ImportButtons
