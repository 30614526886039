import { simpleGet } from "./apiConfig"

export const getUserLandingPage = () => simpleGet('/users/landing_page.json')

export const getUser = () => simpleGet('/user.json')

export const getUsers = ({ organization_id, exclude_user_id, exclude_positon_id }) => {
  return simpleGet(`/users?organization_id=${ organization_id }&exclude[user]=${ exclude_user_id }&exclude[position]=${ exclude_positon_id }`)
}

export const getPortfolioUsers = portfolioId => simpleGet(`/portfolios/${ portfolioId }/users.json`)
