import React from 'react'
import { useAiDocumentsAPI, useAiDocumentsContext } from '../../../contexts/aiDocumentsContext'
import BaseEditModal from "../shared/editModal"

const EditModal = () => {
  const { modals } = useAiDocumentsContext()
  const { toggleModal, updateJson } = useAiDocumentsAPI()

  return <BaseEditModal modals={ modals } toggleModal={ toggleModal } updateJson={ updateJson }/>
}

export default EditModal
