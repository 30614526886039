import React from 'react'
import { notifyAlert } from "../../shared/notice";
import Select from "react-select";
import { statusOptions } from "../../historicalAssessment/historicalAssessmentHelper";
import { updateProjectStatus } from "../../../api/projectApi";

const StatusSelect = ({ project, projectStatus, setProjectStatus }) => {
  const onStatusSelect = (statusOption) => {
    setProjectStatus(statusOption)
    updateProjectStatus(project.id, statusOption.value).then(res => notifyAlert(res.data))
  }

  const assessmentOptions = statusOptions.filter(option => option.value < 6)
  const constructionOptions = statusOptions.filter(option => option.value >= 6)

  const selectOptions = project.portfolio.type === 'Assessment' ? assessmentOptions : constructionOptions

  return (
    <div className='m-8'>
      <h2>Select Project Status Below:</h2>
      <Select
        className={`capitalize w-80`}
        placeholder={'Stage Select'}
        options={selectOptions}
        value={projectStatus && projectStatus}
        onChange={selectedOption => onStatusSelect(selectedOption)}
      />
    </div>
  )
}

export default StatusSelect
