import React from "react"

const SidebarButton = ({ href, text, styles = "" }) => {
  return (
    <a className={`btn btn-primary truncate w-full text-center ${styles}`} href={href}>
      {text}
    </a>
  )
}

export default SidebarButton
