import React, { useMemo } from "react"
import { isHyperlink } from "../../../utilities/utils"
import HyperlinkCell from "./hyperlinkCell"
import DefaultCell from "./defaultCell"

const hyperlinkType = value => isHyperlink(value) ? 'Hyperlink' : ''

const TrackerCell = ({ content, header = {}, entryId = null }) => {
  const type = useMemo(() => header?.type || hyperlinkType(content?.value), [header, content])
  const CellType = useMemo(() => isHyperlink(content?.value) ? HyperlinkCell : DefaultCell, [type])

  return <CellType content={ content } header={ header } entryId={ entryId } cellColor={ content?.cell_color }/>
}

export default TrackerCell
