import React, { useState, useEffect } from "react"
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { pieChartOptions } from "../../utilities/trackerHelpers";

const Chart = ({ chartData }) => {
  const [chartOptions, setChartOptions] = useState(null)

  useEffect(() => {
    if (chartData) {
      setChartOptions(pieChartOptions(chartData))
    } else {
      setChartOptions(null)
    }
  }, [chartData])

  return <>
    {chartOptions && <div className='w-full sticky left-0'>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
      />
    </div>}
  </>
}

export default Chart
