import React, { useMemo } from 'react'
import { useTestResultsFilterContext } from '../../../contexts/testResultsFilterContext'
import { tableStyles } from '../../../utilities/stylesHelpers'
import TableHeader from './tableHeader'

const GuidelineRow = ({ guideline }) => {
  const guidelineString = guideline.name + '\n' + 'DEPTH:' + guideline.analytic_export_guidelines[0].start_depth +
    ' - ' + guideline.analytic_export_guidelines[0].end_depth
  const blanks = Array.from({ length: 6 }, (_, i) => i)
  const { availableChemicalUnits } = useTestResultsFilterContext()

  const updatedLimts = useMemo(() =>
    availableChemicalUnits.map(chemicalUnit => {
      const matchingLimit = guideline.limits.find(limit => limit.chemical_unit_id === chemicalUnit.id);

      return matchingLimit ? { ...chemicalUnit, string_value: matchingLimit.string_value } : chemicalUnit;
    }), [guideline, availableChemicalUnits])



  return (
    <tr className={tableStyles.tableHeaderRow}>
      {blanks.map((index) => (
        <TableHeader key={`${index}-blank`} content={''} />
      ))}
      <TableHeader style={tableStyles.bottomTh} content={'GUIDELINE'} />
      <TableHeader key={`guideline-name`} content={guidelineString} />
      {updatedLimts.map((limit, index) => (
        <TableHeader key={`limit-${index}}`} content={limit?.string_value || ''} />
      ))}
    </tr>)
}

export default GuidelineRow
