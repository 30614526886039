import React from "react"
import { tableStyles } from "../../../utilities/stylesHelpers"

const HyperlinkWrapper = ({ content, styler, onClickHandler }) => {
  return (
    <td className={tableStyles.td} style={{ minWidth: "120px", ...styler }} onClick={onClickHandler}>
      <a
        className={'font-bold hover:underline'}
        target="_blank"
        rel="noopener noreferrer"
        href={content?.value}
        onClick={(e) => e.stopPropagation()}
      >
        Download
      </a>
    </td>
  )
}

export default HyperlinkWrapper
