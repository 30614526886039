import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const HoverIcon = ({ icon, message='N/A', onClickHandler, toggleFunc, updateFunc }) => {
  const handleMouseEnter = () => toggleFunc(true) || updateFunc(message)
  const handleMouseLeave = () => toggleFunc(false) || updateFunc(null)

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={onClickHandler} className="cursor-pointer">
      <FontAwesomeIcon icon={icon} />
    </div>
  )
}

export default HoverIcon
