import React, { useState } from 'react'
import { useChecklistAPI } from '../../../../contexts/checklistContext';
const FileUploadInput = () => {
  const [displayName, setDisplayName] = useState( 'Upload File' )
  const { updateFile } = useChecklistAPI()

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setDisplayName( _.truncate(file.name) )
    const formData = new FormData()
    formData.append('file', file)
    updateFile(formData)
  }

  return(
    <div className='my-3 w-full'>
      <label className="btn btn-primary btn-outline font-semibold relative cursor-pointer z-10">
        {displayName}
        <input type="file" name="tracker_csv" onChange={handleFileChange} className="opacity-0 absolute inset-0 z-0 cursor-pointer top-full h-px" required />
      </label>
    </div>
  )
}

export default FileUploadInput
