import React, { useReducer } from "react"
import { isEmpty } from "lodash"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faChevronUp  } from '@fortawesome/free-solid-svg-icons'
import { formatDateTimeStr } from "../../utilities/dateHelpers"

const HistoryItem = ({ history }) => {
  const [visible, toggleVisible] = useReducer(state => !state, false)
  return (
    <div className='mb-2 pl-4 text-sm'>
      <div className='flex gap-x-2 font-normal items-center'>
        <FontAwesomeIcon
          className=''
          icon={visible ? faChevronUp : faChevronDown}
          size='md'
          onClick={toggleVisible}
        />
        <span className='font-semibold'>{formatDateTimeStr(history?.created_at)}</span>
      </div>
      {visible && <p className='ml-3 font-normal'>{isEmpty(history.emails) ? 'No emails' : history.emails.join(', ')}</p>}
    </div>
  )
}

export default HistoryItem
