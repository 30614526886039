import React, { useEffect, useState } from 'react'
import axios from "axios"
import FormFieldGroup from "./formFieldGroup"
import LoadingSpinner from '../shared/loadingSpinner'
import { useProjectContext } from "../../contexts/projectContext"
import GisPdfButton from "./gisPdfButtons"

const SampleLabels = ({ formData }) => {
  return <table className='table-fixed border-collapse'>
    <thead>
      <tr>
        <th className='py-2 font-semibold text-xs text-gray-600 uppercase text-left pr-2 whitespace-nowrap'>Sample
          ID
        </th>
        <th
          className='py-2 font-semibold text-xs text-gray-600 uppercase text-left pr-2 whitespace-nowrap text-right'>Depth
        </th>
      </tr>
    </thead>
    <tbody>
      {formData.map(formDatum => <tr key={formDatum.sample_id}>
        <td>
          <div
            className='py-2 border border-transparent w-full h-full w-64 truncate'>
            <div className='whitespace-nowrap truncate h-4 leading-none pr-4'>{formDatum.sample_id}</div>
          </div>
        </td>
        <td>
          <div
            className='py-2 border border-transparent w-full h-full w-64 truncate text-right'>
            <div className='whitespace-nowrap truncate h-4 leading-none text-sm'>{formDatum.depth}</div>
          </div>
        </td>
      </tr>)}
    </tbody>
  </table>;
}

const FormTemplate = ({ formTemplate, formDataRoute }) => {
  const [formData, setFormData] = useState()
  const { project } = useProjectContext()

  useEffect(() => {
    const fetchFormData = async () => {
      try {
        const response = await axios.get(`${formDataRoute}?form_template_id=${formTemplate.id}`)
        setFormData(response.data)
      } catch (error) {
        console.error('Error fetching form data:', error)
      }
    }

    void fetchFormData()
    return () => setFormData([])
  }, [formDataRoute, formTemplate.id])

  return !formData ? <LoadingSpinner /> :
    <div className='flex flex-col mb-8'>
      <h2 className='mb-4'>{formTemplate.name}</h2>
      <div className='w-full flex flex-row'>
        {formTemplate.name.toLowerCase().match(/sample_details/) && <div className='flex flex-row w-max mr-4'>
          <div className='flex items-end'>
            <SampleLabels formData={formData} />
          </div>
        </div>}
        <div className={`flex ${project.name.includes('Foreman') ? 'flex-col' : 'flex-row'} w-max gap-x-4 overflow-x-scroll pb-4`}>
          {formTemplate && formTemplate.form_fields.map(formField => <FormFieldGroup key={formField.id}
            formField={formField}
            formData={formData} />)}
        </div>
      </div>
      <GisPdfButton formData={formData} formTemplate={formTemplate} />
    </div>
}

export default FormTemplate
