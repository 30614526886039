import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import DevBlogIndex from "./devBlogIndex"
import DevBlogShow from "./devBlogShow"

export default function DevBlogMain({ posts, post }) {
  return (
    <Router>
      <Routes>
        <Route
          path='/dev-blog/:devBlogSlug'
          element={ <DevBlogShow post={post} /> }
        />
        <Route
          path='/dev-blog'
          element={ <DevBlogIndex posts={posts} /> }
        />
      </Routes>
    </Router>
  )
}
