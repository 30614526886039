import React, { useEffect } from "react";
import ExportCard from "./exportCard";
import { useSiteInspectionReports, useSiteInspectionReportsApi } from "../../contexts/siteInspectionReports";
import { getExportHistory } from "../../api/siteInspectionReportApi";

const ExportHistory = ({ project }) => {
  const { addToExports } = useSiteInspectionReportsApi()
  const { exports } = useSiteInspectionReports()

  useEffect(() => {
    if (project) {
      getExportHistory(project).then(res => addToExports(res.data))
    }
  }, [project])

  return <>
    <div className='mx-10 my-10 w-1/2'>
      <h1>Export History</h1>
      <div className='w-2/5 my-4 flex flex-col gap-y-4'>
        {exports.map(exportData => <ExportCard key={exportData.id} exportData={exportData} />)}
      </div>
    </div>
  </>
}

export default ExportHistory
