import React, { useRef } from 'react'
import { useSampleCollectionApi, useSampleCollectionContext } from '../../../../contexts/sampleCollectionContext'
import ModalWrapper from '../../../modal/modalWrapper'
import FileSelect from './fileSelect'
import LabSelect from './labSelect'
import EventSelect from './eventSelect'
import DateSelect from './dateSelect'
import LotInput from './lotInput'

const ImportModal = () => {
  const { sampleCollection } = useSampleCollectionContext()
  const { toggleField, updateNestedState, onSend } = useSampleCollectionApi()
  const formRef = useRef()
  const sendForm = (formRef, e) => {
    onSend(formRef, e)
    toggleField('loading')
  }

  return (
    <ModalWrapper
      heading="Result Importer Modal"
      closeModal={() => toggleField('importModal')}
      formName="sample-collection-form"
      submitButtonText='Submit'
      modalContainerClasses="max-h-[90%] my-6 w-7/12"
    >
      <form ref={formRef} className="gap-y-6 flex flex-col" onSubmit={e => sendForm(formRef, e)} id="sample-collection-form">
        <LotInput />
        <EventSelect />
        <LabSelect />
        <DateSelect />
        <FileSelect originalFile={sampleCollection.results_csv} setFile={updateNestedState('sampleCollection', 'results_csv')} htmlFor={'results_csv'} required={true} title={'CSV Result File'} accepts={'text/csv'} />
        <FileSelect originalFile={sampleCollection.results_pdf} setFile={updateNestedState('sampleCollection', 'results_pdf')} htmlFor={'results_pdf'} required={false} title={'PDF Result File'} accepts={'application/pdf'} />
      </form>
    </ModalWrapper>)
}

export default ImportModal
