import React from 'react'
import SubmitChatMessage from "./submitMessage"
import { useAiChatbotContext, useAiChatbotApi } from '../../../../contexts/aiChatbotContext'

const PlainTextEditor = () => {
  const { editorState } = useAiChatbotContext()
  const { updateEditor } = useAiChatbotApi()

  return (
    <div className="relative w-full flex flex-col justify-center items-center h-80 min-h-[200px] max-w-6xl">
      <textarea
        className="border-solid border-4 border-[rgba(0,0,0,0.4)] rounded-3xl h-full w-full relative z-10 p-3"
        placeholder="Enter some text..."
        onChange={ event => updateEditor(event.target.value) }
        value={ editorState }
      />
      <SubmitChatMessage/>
    </div>
  )
}

export default PlainTextEditor
