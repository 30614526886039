import React, { useMemo } from "react"
import { isEmpty } from "lodash"
import { tableStyles } from "../../utilities/stylesHelpers";
import { widgetParams } from "../../utilities/widgetHelpers";
import { usePortfolioAPI, usePortfolioContext } from "../../contexts/portfolioContext"
import StandardButton from "../shared/standardButton"
import {useTrackerEntryContext} from "../../contexts/trackerEntryContext";
import {useTrackerContext} from "../../contexts/trackerContext";
import { openInNewTab } from "../../utilities/helpers"
import CollapseCell from "./trackerCell/collapseCell"


const ZoomButton = ({renderPosition}) => {
  const { portfolio, showWidget } = usePortfolioContext()
  const { updateWidget } = usePortfolioAPI()
  const { coords } = useTrackerEntryContext()
  const { frozenStyles, collapsedHeaders } = useTrackerContext()

  const styler = frozenStyles[renderPosition]?.entryStyles || {}
  const collapsed = collapsedHeaders.includes(renderPosition)


  const baseMap = useMemo(() => {
    if (portfolio?.default_widget) {
      return portfolio.default_widget?.full_url
    }

    return portfolio?.type === 'Construction' ?
      'https://www.prodeltamap.com/lizmap/lizmap/www/index.php/view/map/?repository=prodeltademos&project=Construction' :
      'https://www.maps4free.ca/lizmap/lizmap/www/index.php/view/map/?repository=webmaps&project=Basemap_BingAerial'
  }, [portfolio])

  const handleClick = () => {
    if (showWidget) {
      updateWidget(`${baseMap}${(widgetParams(coords.lon, coords.lat))}`)
      document.getElementById("widget")?.scrollIntoView({ behavior: "smooth" })
    } else {
      updateWidget(`${baseMap}${(widgetParams(coords.lon, coords.lat))}`)
      openInNewTab(`${baseMap}${(widgetParams(coords.lon, coords.lat))}`)
    }
  }

  return (
    <>
    {collapsed ? <CollapseCell styler={styler} /> :
    <td className={tableStyles.td} style={{ minWidth: "120px", ...styler }}>
      {!isEmpty(coords) ?
        <StandardButton buttonText="Zoom" onClickHandler={handleClick} /> :
        <p>Missing Site Coordinates</p>
      }
    </td>
    }
    </>

  )
}

export default ZoomButton
