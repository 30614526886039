import React, { useState } from "react"
import AssessmentBreadCrumbs from "./assessmentBreadCrumbs"
import AssessmentForm from "./assessmentForm"
import ProjectDetails from "../projects/project/ProjectDetails"
import Widget from "../shared/widget"
import { HistoricalAssessmentProvider } from "../../contexts/historicalAssessment"

const HistoricalAssessment = ({ project }) => {
  const [widgetUrl, setWidgetUrl] = useState('')

  return (
    <div className="px-4 w-full h-full">
      <header className="w-full flex flex-row justify-between items-center py-2">
        <AssessmentBreadCrumbs project={project} portfolio={project.portfolio} />
      </header>

      <div className="w-full h-full flex flex-row">
        <div className="pr-4 pl-4 h-full lg:w-1/3">
          <a href={widgetUrl} target="_blank" rel="noreferrer noopener" className="w-full btn btn-primary font-semibold cursor-pointer block text-center lg:rounded-t-md lg:rounded-b-none">
            Open Map
          </a>
          <Widget widgetProp={project?.default_widget?.full_url} project={project} widgetStyles='lg:p-0 w-full h-[calc(100%-37px)]' widgetChangeCallback={setWidgetUrl} />
        </div>
        <div className='w-2/3'>
          <ProjectDetails project={project} />
          <HistoricalAssessmentProvider project={project}>
            <AssessmentForm />
          </HistoricalAssessmentProvider>
        </div>
      </div>
    </div>
  )
}

export default HistoricalAssessment
