import React from 'react'
import { useCocExport, useCocExportApi } from "../../../contexts/cocExport";
import HeaderInput from "./HeaderInput";

const SampleCustody = () => {
  const { headerDetails } = useCocExport()
  const { updateSampleCustody } = useCocExportApi()

  return(
    <div className='flex flex-col gap-y-2 py-4'>
      <h1 className="pb-2">Sample Custody:</h1>
      <HeaderInput title={'Sampled By'} field={'sampled_by'} value={headerDetails.sample_custody.sampled_by} update={updateSampleCustody} />
      <HeaderInput title={'Company'} field={'company'} value={headerDetails.sample_custody.company} update={updateSampleCustody} />
      <HeaderInput title={'Signature'} field={'signature'} value={headerDetails.sample_custody.signature} update={updateSampleCustody} />
      <HeaderInput title={'Date'} field={'date'} value={headerDetails.sample_custody.date} update={updateSampleCustody} />
    </div>
  )
}

export default SampleCustody
