import React, { useState, useEffect } from "react"
import ChecklistTemplate from "./checklistTemplate"
import { getPortfolioChecklistTemplates, getAdminPortfolioChecklistTemplates} from "../../../api/portfoliosApi"

const Checklists = ({ portfolio }) => {
  const [checklistTemplates, setChecklistTemplates] = useState([])
  const checklistApiCall = window.location.pathname.includes("admin") ? getAdminPortfolioChecklistTemplates : getPortfolioChecklistTemplates

  useEffect(() => {
    if (portfolio) {
      checklistApiCall(portfolio.id)
        .then(res => setChecklistTemplates(res))
        .catch(err => console.log(err))
    }
  }, [portfolio])

  return <>
    {checklistTemplates.length > 0 && <>
      <tr className='bg-regular-blue text-white'>
        <td className='font-semibold px-2 py-3 w-1/5'>Checklist Name</td>
        <td className='font-semibold px-2 py-3'>GeoJSON URL</td>
        <td className='font-semibold px-2 py-3'>Data Connection URL</td>
      </tr>
      {checklistTemplates.map(template => <ChecklistTemplate key={template.id} portfolio={portfolio} checklistTemplate={template} />)}
      <tr className='border-t border-gray-400 '><td className='py-6'></td></tr>
    </>}
  </>
}

export default Checklists
