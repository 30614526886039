import React from 'react'
import { usePortfolioContext } from "../../contexts/portfolioContext"
import Widget from "../../components/shared/widget"

const WidgetContainer = ({ WidgetComponentReplacement }) => {
  const { portfolio, project, widget, showWidget } = usePortfolioContext()

  return <>
    {showWidget && (
      <div className='flex flex-col w-full h-full overflow-y-auto'>
        <div className="sticky top-0 z-10">
          {WidgetComponentReplacement || <Widget widgetProp={widget} project={project} portfolio={portfolio} showToggle={false} widgetStyles="h-1/3 sticky left-0" />}
        </div>
      </div>
    )}
  </>
}

export default WidgetContainer
