import React, { useContext } from "react"
import DemoLinkButton from "./demoLinkButton"
import { ExportContext } from "../../projects/exportContext"

import { isDemoProject, demoLinksInfo } from "../../../utilities/demoHelpers"
import WidgetDropdown from "../../projects/shared/WidgetDropdown"

export const DemoLinks = ({ changeWidget, propProject }) => {
  const project = propProject || useContext(ExportContext).project
  const demoLinks = demoLinksInfo(project)
  
  const DemoSpacer = () => {
    return <div className={'mt-2 mb-2'} />
  }

  const isKeyera = (projectName) => {
    return !!projectName.includes("Keyera_Chinchaga")
  }

  return (
    isDemoProject(project.id) && (
      <>
        {isKeyera(project.name) && (
          <div className="mb-5">
            <WidgetDropdown project={project} landingPageWidget={['keyera chinchaga', 'keyera chinchaga powerBI']} handleChange={changeWidget} />
          </div>
        )}
        {demoLinks && demoLinks.map((demo, index) => <DemoLinkButton key={index} demo={demo} />)}

        <DemoSpacer />
      </>
    )
  )
}
