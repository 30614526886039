import React, { forwardRef, useMemo } from "react"
import DropdownInput from "./dropdownInput"
import { userFormatDropdownOptions } from "../../../../utilities/tableHelpers";

const UserDropdown = forwardRef(({ users, optionsParam = null }, inputRef) => {
  const options = useMemo(() => {
    return users?.map(userFormatDropdownOptions) || []
  }, [optionsParam, users])
  
  return <>
    {options && <DropdownInput
      paramOptions={options}
      ref={inputRef}
      displayOtherOption={false}
    />}
  </>
})

export default UserDropdown
