import React from "react"
import { isEmpty } from "lodash"
import { usePortfolioContext } from "../../contexts/portfolioContext"
import { useReportContext } from "../../contexts/reportContext"
import { TrackerProvider } from "../../contexts/trackerContext"
import { TrackerFilterProvider } from "../../contexts/trackerFilterContext"
import TrackerTableTabs from "./trackerTableTabs"
import TrackerTable from "../trackers/trackerTable"
import ProjectView from "../../layouts/tablePage/projectView"

const ProjectReport = ({ routes }) => {
  const { project } = usePortfolioContext()
  const { activeTracker } = useReportContext()

  return (
    <ProjectView routes={routes}>
      <TrackerTableTabs />
      {!isEmpty(activeTracker) && <TrackerProvider providedTrackerSlug={activeTracker?.slug} key={activeTracker?.slug}>
        <TrackerFilterProvider>
          <TrackerTable scopeName={project?.name} />
        </TrackerFilterProvider>
      </TrackerProvider>}
    </ProjectView>
  )
}

export default ProjectReport
