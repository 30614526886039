import API, { simpleGet } from "./apiConfig"

export const getPermit = ({ permitPath }) => simpleGet(permitPath)

export const putPermit = ({ csrfToken, formData, permitPath }) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken

  return API
    .put(permitPath, formData)
    .then(res => res.data)
}

export const uploadPermitsCsv = ({ csrfToken, formData, permitsPath }) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken

  return API
    .post(permitsPath, formData)
    .then(res => res.data)
}

export const uploadConditionsCsv = ({ csrfToken, formData, permitPath }) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken

  return API
    .put(permitPath, formData)
    .then(res => res.data)
}
