import React, { useMemo } from "react"
import { cellColors } from "../../../utilities/trackerHelpers"
import { tableStyles } from "../../../utilities/stylesHelpers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit } from "@fortawesome/free-solid-svg-icons"

const UserCell = ({ content, users, styler, cellColor = 'white', onClickHandler, collapsed = false }) => {
  const user = useMemo(() => users.find(user => user.id === parseInt(content)), [users, content]);
  const name = user ? user.first_name + ' ' + user.last_name : content
  const tdTextStyles = collapsed ? tableStyles.tdTextTruncate : tableStyles.tdText
  const tdStyles = collapsed ? tableStyles.tdCollapsed : tableStyles.td

  return (
    <td className={tdStyles} style={{ backgroundColor: cellColors[cellColor], ...styler }} onClick={onClickHandler}>
      <p className={tdTextStyles} style={{ backgroundColor: cellColors[cellColor] }}>{name}</p>
      <FontAwesomeIcon
        className={tableStyles.hoverIcon}
        icon={faEdit}
      />
    </td>
  )
}

export default UserCell
