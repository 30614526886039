import React from "react"
import { cellColors } from "../../../utilities/trackerHelpers"
import { tableStyles } from "../../../utilities/stylesHelpers"

const CollapseCell = ({ styler, cellColor = 'white' }) => {
  return (
    <td className={tableStyles.td} style={{ backgroundColor: cellColors[cellColor], ...styler }}>
      <p className={tableStyles.tdText} style={{ backgroundColor: cellColors[cellColor], textAlign: 'center' }}>...</p>
    </td>
  )
}

export default CollapseCell
