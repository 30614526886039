import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'

const LoadingSpinner = ({ size = '9xl' }) => {
  return (
    <div className='flex flex-col items-center text-2xl'>
      <FontAwesomeIcon
        icon={ faCircleNotch }
        spin
        className={ `text-regular-orange text-${ size }` }
      />
    </div>
  )
}

export default LoadingSpinner
