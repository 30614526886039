import React from "react"
import { formatDateTimeStr } from "../../../../utilities/dateHelpers"
import ModalWrapper from "../../../modal/modalWrapper"
import HistoryItem from "../../../modal/historyItem"

const HistoryModal = ({ modal, toggleModal, trackerReport }) => {
  return (
    <>
      {modal && (
        <ModalWrapper
          heading={`History`}
          closeModal={() => toggleModal({ modalType: 'history' })}
        >
          <div className='w-full'>
            <label key="export-created-by" className="w-1/5 z-20 font-bold">
              Report Created
              <div className='mb-4 pl-4 text-sm'>
                <p className='font-normal'>
                  <span className='font-semibold'>By:</span> {trackerReport?.created_by.email}
                </p>
                <p className='font-normal'>
                  <span className='font-semibold'>At:</span> {formatDateTimeStr(trackerReport?.created_at)}
                </p>
              </div>
            </label>
            <label key="email-history" className="w-1/5 z-20 font-bold">
              Email History
              {trackerReport?.email_histories.map(history => <HistoryItem key={history.id} history={history} />)}
            </label>
          </div>
        </ModalWrapper>
      )}
    </>
  )
}



export default HistoryModal
