import React, { createContext, useContext, useMemo, useReducer } from "react";

import { UPDATE_VALUE, SET_OPTIONS, SET_NOTE, SET_EXPORTS } from "../actions/siteInspectionReports";
import { initialSiteInspectionReportsApiState, initialSiteInspectionReportsState, siteInspectionReportsReducer} from "../reducers/siteInspectionReports";

const SiteInspectionReportsContext = createContext(initialSiteInspectionReportsState)
const SiteInspectionReportsApiContext = createContext(initialSiteInspectionReportsApiState)

export const SiteInspectionReportsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(siteInspectionReportsReducer, initialSiteInspectionReportsState)

  const api = useMemo(() => {
    const onInputChange = (field, value) => {
      dispatch({ type: UPDATE_VALUE, field, value })
    }
    
    const onNoteSelect = (note) => {
      dispatch({ type: SET_NOTE, note })
    }
    
    const onInitializeOptions = (options) => {
      dispatch({ type: SET_OPTIONS, options })
    }

    const addToExports = (exports) => {
      dispatch({ type: SET_EXPORTS, exports })
    }

    return { onInputChange, onInitializeOptions, onNoteSelect, addToExports }

  }, [])

  return (
    <SiteInspectionReportsApiContext.Provider value={api}>
      <SiteInspectionReportsContext.Provider value={state}>
        {children}
      </SiteInspectionReportsContext.Provider>
    </SiteInspectionReportsApiContext.Provider>
  )
}

export const useSiteInspectionReports = () => useContext(SiteInspectionReportsContext)
export const useSiteInspectionReportsApi = () => useContext(SiteInspectionReportsApiContext)
