import React, { useState, useEffect } from "react";
import axios from "axios";
import ProjectRow from "../../projects/projects/projectRow";
import { getProjectSettings } from "../../../api/projectApi";

const Projects = ({ landingPageWidget }) => {
  const [projects, setProjects] = useState([])
  const [searchTerm, setSearchTerm] = useState('')

  const getProjects = () => {
    getProjectSettings(searchTerm).then((res) => setProjects(res.data))
  }

  useEffect(() => {
    getProjects()
  }, [])

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      getProjects()
    }
  }

  return(
    <div className='w-full mt-8'>
      <div className='header'>
        <h1>Projects</h1>
      </div>
      <div className='flex flex-row items-center w-full p-5 font-semibold'>
        <input
          type="text"
          value={searchTerm}
          onChange={() => setSearchTerm(event.target.value)}
          onKeyDown={handleKeyDown}
          placeholder='Enter Project Name'
          className='border border-gray-400 rounded-md outline-none text-black p-2 bg-gray-100'
        />
        <button
          className="mx-1 focus-outline:none btn btn-primary"
          onClick={() => getProjects()}
        >
          Submit
        </button>
      </div>
      <div className='flex flex-col'>
        {projects && projects.length > 0 &&
          <div className='flex flex-col'>
            {projects.map((project) => (
              <ProjectRow key={project.id} project={project} landingPageWidget={landingPageWidget} />
            ))}
          </div>
        }
      </div>
    </div>
  )
}

export default Projects
