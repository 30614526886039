import React from "react";
import { isEmpty } from "lodash";

const SelectedBoreholes = ({ boreholeDetails }) => {
  const boreholeList = boreholeDetails.selected_boreholes?.map((borehole) => {
    return <li key={borehole.id} className='mx-8'><span className='font-bold'>{borehole.title}</span> - <span className='italic'>Collected on: {borehole.sample_date}</span></li>
  })

  return <>
    {isEmpty(boreholeList) ?
      <h2>No boreholes have been selected</h2>
      :
      <div className='flex flex-col gap-y-2'>
        <h2>The following boreholes have been selected:</h2>
        <ul className="list-disc">
          {boreholeList}
        </ul>
      </div>
    }
  </>
}

export default SelectedBoreholes
