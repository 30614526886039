import axios from "axios";
import { notifyError } from "../components/shared/notice";

export const createExport = (params, project) => {
  try {
    return axios({
      method: 'POST',
      url: `/projects/${project.id}/site_inspection_reports.json`,
      params: params
    })
  }
  catch (e) {
    notifyError('There was an error')
  }
}

export const getExportHistory = (project) => {
  try {
    return axios.get(`/projects/${project.id}/site_inspection_reports/export_history.json`)
  }
  catch (e) {
    notifyError('There was an error')
  }
}

