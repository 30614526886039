import React, { createContext, useContext, useMemo, useReducer } from "react"
import { useChecklistContext } from "./checklistContext"
import { getValueByFilter, getChecklistIds } from "../utilities/checklistHelpers"

/* Actions */
const UPDATE_STATE = 'UPDATE_STATE'
const UPDATE_VALUE_OPTIONS = 'UPDATE_VALUE_OPTIONS'
const UPDATE_CHECKLIST_IDS = 'UPDATE_CHECKLIST_IDS'

/* Initial State */
const initialState = {
  checklistIds: [],
  checklistOptions: [{ label: 'Status', value: 'status' }, { label: 'Date', value: 'date' }, { label: 'Description', value: 'description' }],
  valueOptions: [],
  currentChecklistFilter: {},
  currentValueFilter: "",
}

/* Reducer */
const checklistFilterReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_STATE:
      return { ...state, [action.field]: action.value }
    default:
      return state
    case UPDATE_VALUE_OPTIONS:
      const valueOptions = getValueByFilter(action.projectChecklists, action.checklistOption)
      return { ...state, valueOptions: valueOptions }
    case UPDATE_CHECKLIST_IDS:
      return {...state, checklistIds:getChecklistIds(state.valueOptions,action.valueFilter)}
  }
}

/* Contexts */
const ChecklistFilterContext = createContext(initialState)
const ChecklistFilterAPIContext = createContext({
  updateState: () => {},
  updateChecklistOptionFilter: () => {},
  updateValueFilter: () => {},
})

/* Providers */
export const ChecklistFilterProvider = ({ children }) => {
  const { projectChecklists } = useChecklistContext()

  const [state, dispatch] = useReducer(checklistFilterReducer, initialState, i => i)

  const api = useMemo(() => {
    const updateState = (field, value) => dispatch({ type: UPDATE_STATE, field, value })

    const updateChecklistOptionFilter = (checklistOption) => {
      updateState('currentChecklistFilter', checklistOption)
      dispatch({ type: UPDATE_VALUE_OPTIONS, projectChecklists, checklistOption })
    }

    const updateValueFilter = (valueFilter) => {
      updateState('currentValueFilter', valueFilter)
      dispatch({ type: UPDATE_CHECKLIST_IDS, valueFilter })
    }

    return { updateState, updateChecklistOptionFilter, updateValueFilter }
  }, [projectChecklists])

  return (
    <ChecklistFilterAPIContext.Provider value={api}>
      <ChecklistFilterContext.Provider value={state}>
        {children}
      </ChecklistFilterContext.Provider>
    </ChecklistFilterAPIContext.Provider>
  )
}

/* Custom Context Hooks */
export const useChecklistFilterContext = () => useContext(ChecklistFilterContext)
export const useChecklistFilterAPI = () => useContext(ChecklistFilterAPIContext)
