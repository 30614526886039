import React, { createContext, useContext, useReducer, useMemo, useEffect } from "react"
import {
  getImageIndex, photoArrayToGalleryMap, filterMap,
  groupPhotosByDate, updateSelectedPhoto, exportPhotoReport
} from "../utilities/photoHelpers"
import { useParams } from "react-router-dom"
import { getPhotoExports, getPhotos } from "../api/photosAPI"
import { notifyError } from "../components/shared/notice"
import { updateFormEntry, getFormEntry } from "../api/formEntryApi"

/* ACTIONS */
const UPDATE_STATE = 'UPDATE_STATE'
const TOGGLE_STATE = 'TOGGLE_STATE'
const UPDATE_OPEN_INDEX = 'UPDATE_OPEN_INDEX'
const SET_GALLERY_MAP = 'SET_GALLERY_MAP'
const UPDATE_FILTERED_GALLERY_MAP = 'UPDATE_FILTERED_GALLERY_MAP'
const UPDATE_COMMENT = 'UPDATE_COMMENT'
const UPDATE_SELECTED_PHOTO = 'UPDATE_SELECTED_PHOTO'
const EXPORT_PHOTOS = 'EXPORT_PHOTOS'

// Proposed galleryMap structure:
// imageValue: {
//   slide: { src: '', alt: '', description: comment }
// }

/* INITIAL STATES */
const initialState = {
  galleryMap: new Map(),
  lightBoxState: false,
  openIndex: 0,
  filterOptions: {
    date_range: { start_date: '', end_date: '' },
  },
  filteredGalleryMap: new Map(),
  selectedFilters: {
    date_range: { start_date: null, end_date: null }
  },
  comments: {},
  photoGroups: new Map(),
  loading: true,
  commentModal: { status: false, commentId: null },
  commentEntry: {},
  selectedPhotos: [],
  exportStatus: false,
  photoExports: [],
  displayExport: {},
}

/* REDUCER */
const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_STATE:
      return {
        ...state, [action.field]: action.value
      }
    case TOGGLE_STATE:
      return {
        ...state, [action.field]: !state[action.field]
      }
    case UPDATE_OPEN_INDEX:
      const openIndex = getImageIndex(action.imageValue, state.filteredGalleryMap)
      return {
        ...state, openIndex, lightBoxState: true
      }
    case SET_GALLERY_MAP:
      const mergedGalleryMap = new Map([...state.galleryMap, ...photoArrayToGalleryMap(action.entries)])
      return {
        ...state, galleryMap: mergedGalleryMap, filteredGalleryMap: mergedGalleryMap
      }
    case UPDATE_FILTERED_GALLERY_MAP:
      const [updatedMap, updatedSelectedFilters] = filterMap(action.filter, action.value, state.galleryMap, state.filteredGalleryMap, state.selectedFilters)
      return {
        ...state, filteredGalleryMap: updatedMap, selectedFilters: updatedSelectedFilters
      }
    case UPDATE_COMMENT:
      const index = getImageIndex(state.commentModal.imageGuid, state.filteredGalleryMap)
      const commentId = state.commentModal.commentId
      const updatedComment = { ...state.comments[commentId], description: action.description }
      updateFormEntry(updatedComment.update_path, action.description)
      return {
        ...state, comments: { ...state.comments, [commentId]: updatedComment }, openIndex: index
      }
    case UPDATE_SELECTED_PHOTO:
      const updatedSelectedPhotos = updateSelectedPhoto(action.photo, action.comment, state.selectedPhotos)
      return {
        ...state, selectedPhotos: updatedSelectedPhotos
      }
    case EXPORT_PHOTOS:
      return {
        ...state, selectedPhotos: [], exportStatus: false, photoExports: [action.payload, ...state.photoExports]
      }
    default:
      return state
  }
}

/* CONTEXT */

const PhotoContext = createContext(initialState)

const PhotoAPIContext = createContext({
  toggleLightBox: () => {},
  updateOpenIndex: () => {},
  setGalleryMap: () => {},
  updateFilterOptions: () => {},
  updateFilteredGalleryMap: () => {},
  setComments: () => {},
  setPhotoGroups: () => {},
  setLoading: () => {},
  updateCommentDescription: () => {},
  openCommentModal: () => {},
  closeCommentModal: () => {},
  updateSelectedPhoto: () => {},
  toggleExportStatus: () => {},
  exportPhotos: () => {},
  updateDisplayExport: () => {}
})

/* PROVIDER */

export const PhotoProvider = ({ children }) => {
  const { portfolioId, projectId } = useParams()
  const projectOrPortfolio = projectId ? { projectId: projectId } : { portfolioId: portfolioId }


  const [state, dispatch] = useReducer(reducer, initialState)

  const api = useMemo(() => {
    const updateState = (field, value) => {
      dispatch({ type: UPDATE_STATE, field, value })
    }
    const toggleLightBox = () => {
      dispatch({ type: TOGGLE_STATE, field: 'lightBoxState' })
    }
    const updateOpenIndex = (imageValue) => {
      dispatch({ type: UPDATE_OPEN_INDEX, imageValue })
    }
    const setGalleryMap = (entries) => {
      dispatch({ type: SET_GALLERY_MAP, entries })
    }
    const updateFilterOptions = (filterOptions) => {
      api.updateState('filterOptions', filterOptions)
    }
    const updateFilteredGalleryMap = ({ filter, value }) => {
      dispatch({ type: UPDATE_FILTERED_GALLERY_MAP, filter, value })
    }
    const setComments = (comments) => {
      api.updateState('comments', comments)
    }
    const setPhotoGroups = (filteredGalleryMap) => {
      const photoGroups = groupPhotosByDate(filteredGalleryMap)
      api.updateState('photoGroups', photoGroups)
    }
    const setLoading = (loading) => {
      api.updateState('loading', loading)
    }
    const updateCommentDescription = (description) => {
      dispatch({ type: UPDATE_COMMENT, description })
    }
    const openCommentModal = (commentId, imageGuid) => {
      api.updateState('commentModal', { status: true, commentId, imageGuid })
    }
    const closeCommentModal = () => {
      api.updateState('commentModal', { status: false, commentId: null })
    }
    const toggleExportStatus = () => {
      dispatch({ type: TOGGLE_STATE, field: 'exportStatus' })
    }
    const updateSelectedPhoto = (photo, comment) => {
      dispatch({ type: UPDATE_SELECTED_PHOTO, photo, comment })
    }
    const updateDisplayExport = (displayExport) => {
      api.updateState('displayExport', displayExport)
    }

    return {
      updateState, toggleLightBox, updateOpenIndex, setGalleryMap, updateFilterOptions,
      updateFilteredGalleryMap, setComments, setPhotoGroups, setLoading,
      updateCommentDescription, openCommentModal, closeCommentModal, toggleExportStatus, updateSelectedPhoto,
      updateDisplayExport
    }
  }, [])

  useEffect(() => {
    api.setPhotoGroups(state.filteredGalleryMap)
  }, [state.filteredGalleryMap])

  useEffect(() => {
    if (state.commentModal.status) {
      (async function () {
        try {
          const entry = await getFormEntry(state.comments[state.commentModal.commentId].update_path)
          api.updateState('commentEntry', entry)
        } catch (error) {
          notifyError(`Error: ${error}`)
        }
      }())
    }
  }, [state.commentModal])


  /* Initialize photos */

  useEffect(() => {
    (async function () {
      try {
        const photos = await getPhotos(projectOrPortfolio)
        api.setGalleryMap(photos.images)
        api.updateFilterOptions(photos.filter_options)
        api.setComments(photos.comments)
        api.setLoading(false)
        const photoExports = await getPhotoExports(projectOrPortfolio)
        api.updateState('photoExports', photoExports)
      } catch (error) {
        notifyError(`Error: ${error}`)
      }
    }())
  }, [projectId, portfolioId])

  api.exportPhotos = async () => {
    try {
      const photoExport = await exportPhotoReport({...projectOrPortfolio, selectedPhotos: state.selectedPhotos})
      dispatch({
        type: 'EXPORT_PHOTOS',
        payload: photoExport
      })
    }
    catch (error) {
      notifyError(`Error: ${error}`)
    }
  }

  return (
    <PhotoContext.Provider value={state}>
      <PhotoAPIContext.Provider value={api}>
        {children}
      </PhotoAPIContext.Provider>
    </PhotoContext.Provider>
  )
}

export const usePhotoContext = () => useContext(PhotoContext)
export const usePhotoAPIContext = () => useContext(PhotoAPIContext)
