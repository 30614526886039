import React, { useEffect, useState, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight, faExclamation } from "@fortawesome/free-solid-svg-icons";
import ForemanNote from "./foremanNote";
import { Link} from "react-router-dom";


const ForemanNoteRow = ({ note }) => {
  let [showNote, setShowNote] = useState(false)
  let query = new URLSearchParams(location.search)
  let noteId = query.get('noteId')
  
  const displayNote = () => {
    setShowNote(!showNote)
  }

  let noteTitle = (date, title, activity) => {
    let noteTitle = title

    if (date) {
      noteTitle = date + ' - ' + noteTitle
    }
    if (activity) {
      noteTitle += ' - ' + activity
    }

    return noteTitle
  }

  useEffect(() => {
    setShowNote(noteId && note.id === parseInt(noteId))
    if(noteId) document.getElementById(noteId).scrollIntoView({behaviour: 'smooth'})
  },[noteId])

  return <div className='divide-x divide-gray-200'>
    <div
      className={`bg-gray-50 px-6 py-3 text-left text-sm font-medium uppercase tracking-wider flex flex-col justify-between ${showNote ? 'bg-gray-100' : 'bg-white'}`}>
      <div
        id={note.id}
        className='flex flex-row gap-x-2'>
        <Link to={`?noteId=${note.id}`} onClick={displayNote}>
          <FontAwesomeIcon className='cursor-pointer w-8'
                            icon={showNote ? faChevronDown : faChevronRight} />
          {noteTitle(note.date, note.title, note.activity)}
        </Link>
        {note.newPosition ?
          <div className='flex flex-row text-yellow-500 items-center' title='Coordinates are not saved'>
            <FontAwesomeIcon icon={faExclamation} className='mx-1' />
          </div>
          : <></>
        }
      </div>
      {showNote && <ForemanNote noteId={note.id}/>}
    </div>
  </div>
}

export default ForemanNoteRow
