import React, { useMemo } from "react"
import { tableStyles } from "../../../utilities/stylesHelpers"
import { useTrackerAPI, useTrackerContext } from "../../../contexts/trackerContext"
import CollapseCell from "./collapseCell"

const SelectionCell = ({ entryId, renderPosition }) => {
  const { toggleSelected } = useTrackerAPI()
  const { selectedEntryIds, frozenStyles, collapsedHeaders } = useTrackerContext()

  const styler = frozenStyles[renderPosition]?.entryStyles || {}
  const collapsed = collapsedHeaders.includes(renderPosition)

  const checkedValue = useMemo(() => {
    return selectedEntryIds.includes(entryId)
  }, [selectedEntryIds])

  const handleChange = (e) => {
    const isChecked = e.target.checked;
    toggleSelected(entryId, isChecked)
  }

  return (
    <>
      {collapsed ? <CollapseCell styler={styler} /> :
        <td className={tableStyles.td} style={styler}>
          <input type="checkbox" checked={checkedValue} onChange={handleChange} />
        </td>
      }
    </>
  )
}
export default SelectionCell
