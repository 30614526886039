import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $findMatchingParent, $getNearestNodeOfType, mergeRegister } from '@lexical/utils'
import {
  $getRoot,
  $getSelection,
  $isRangeSelection,
  $isRootOrShadowRoot,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
} from 'lexical'
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND
} from "@lexical/list";
import { $generateNodesFromDOM } from '@lexical/html'
import { $isHeadingNode } from '@lexical/rich-text'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { classNames } from '../../../../utilities/utils'
import { styles } from '../../../../utilities/richTextEditorHelpers'
import {
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faBackwardFast,
  faBold,
  faItalic,
  faListOl,
  faListUl,
  faRotateLeft,
  faRotateRight,
  faStrikethrough,
  faUnderline,
} from '@fortawesome/free-solid-svg-icons'

const LowPriority = 1

const blockTypeToBlockName = {
  bullet: 'Bulleted List',
  check: 'Check List',
  code: 'Code Block',
  h1: 'Heading 1',
  h2: 'Heading 2',
  h3: 'Heading 3',
  h4: 'Heading 4',
  h5: 'Heading 5',
  h6: 'Heading 6',
  number: 'Numbered List',
  paragraph: 'Normal',
  quote: 'Quote',
}

const Divider = () => <div className={ styles.divider }/>

const ToolbarPlugin = ({ defaultContent }) => {
  const [editor] = useLexicalComposerContext()
  const toolbarRef = useRef(null)
  const [canUndo, setCanUndo] = useState(false)
  const [canRedo, setCanRedo] = useState(false)
  const [isBold, setIsBold] = useState(false)
  const [isItalic, setIsItalic] = useState(false)
  const [isUnderline, setIsUnderline] = useState(false)
  const [isStrikethrough, setIsStrikethrough] = useState(false)
  const [isOrderedList, setIsOrderedList] = useState(false)
  const [isUnorderedList, setIsUnorderedList] = useState(false)
  const [blockType, setBlockType] = useState('paragraph')

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      // Update text format
      setIsBold(selection.hasFormat('bold'))
      setIsItalic(selection.hasFormat('italic'))
      setIsUnderline(selection.hasFormat('underline'))
      setIsStrikethrough(selection.hasFormat('strikethrough'))

      const anchorNode = selection.anchor.getNode()
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
            const parent = e.getParent();
            return parent !== null && $isRootOrShadowRoot(parent);
          });

      if (element === null) element = anchorNode.getTopLevelElementOrThrow()

      const elementKey = element.getKey()
      const elementDOM = editor.getElementByKey(elementKey)

      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode)
          const type = parentList ? parentList.getListType() : element.getListType()
          setBlockType(type)
          setIsUnorderedList(type === 'bullet')
          setIsOrderedList(type === 'number')
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType()
          if (type in blockTypeToBlockName) {
            setBlockType(type)
          }
          setIsUnorderedList(false)
          setIsOrderedList(false)
        }
      } else {
        setIsUnorderedList(false)
        setIsOrderedList(false)
      }
    }
  }, [])

  const formatList = useCallback((dispatch, listType) => () => {
    const dispatchToSend = blockTypeToBlockName[blockType] === listType ? REMOVE_LIST_COMMAND : dispatch

    editor.dispatchCommand(dispatchToSend, undefined)
  }, [editor, blockType])

  const revertToDefault = useCallback(() => {
    editor.update(() => {
      $getRoot().getChildren().forEach((n) => n.remove())
      const parser = new DOMParser()
      const dom = parser.parseFromString(defaultContent, 'text/html')
      const nodes = $generateNodesFromDOM(editor, dom)
      nodes.forEach((n) => $getRoot().append(n))
    })
  }, [defaultContent])

  useEffect(() => {
    const updateToolbar = editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        $updateToolbar()
      })
    })

    const selectionChangeCommand = editor.registerCommand(SELECTION_CHANGE_COMMAND, (_payload, _newEditor) => {
      $updateToolbar()
      return false
    }, LowPriority)

    const canUndoCommand = editor.registerCommand(CAN_UNDO_COMMAND, (payload) => {
      setCanUndo(payload)
      return false
    }, LowPriority)

    const canRedoCommand = editor.registerCommand(CAN_REDO_COMMAND, (payload) => {
      setCanRedo(payload)
      return false
    }, LowPriority)

    return mergeRegister(updateToolbar, selectionChangeCommand, canUndoCommand, canRedoCommand)
  }, [editor, $updateToolbar])

  return (
    <div className={ styles.toolbar } ref={ toolbarRef }>
      <button
        disabled={ !canUndo }
        onClick={ () => {
          editor.dispatchCommand(UNDO_COMMAND, undefined)
        } }
        className={ classNames(styles.toolbarItem, styles.spaced) }
        aria-label='Undo'>
        <FontAwesomeIcon icon={ faRotateLeft } className={ styles.formatIcon }/>
      </button>
      <button
        disabled={ !canRedo }
        onClick={ () => {
          editor.dispatchCommand(REDO_COMMAND, undefined)
        } }
        className={ styles.toolbarItem }
        aria-label='Redo'>
        <FontAwesomeIcon icon={ faRotateRight } className={ styles.formatIcon }/>
      </button>
      <Divider/>
      <button
        onClick={ () => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')
        } }
        className={ classNames(styles.toolbarItem, styles.spaced, (isBold ? styles.active : '')) }
        aria-label='Format Bold'>
        <FontAwesomeIcon icon={ faBold } className={ styles.formatIcon }/>
      </button>
      <button
        onClick={ () => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')
        } }
        className={ classNames(styles.toolbarItem, styles.spaced, (isItalic ? styles.active : '')) }
        aria-label='Format Italics'>
        <FontAwesomeIcon icon={ faItalic } className={ styles.formatIcon }/>
      </button>
      <button
        onClick={ () => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')
        } }
        className={ classNames(styles.toolbarItem, styles.spaced, (isUnderline ? styles.active : '')) }
        aria-label='Format Underline'>
        <FontAwesomeIcon icon={ faUnderline } className={ styles.formatIcon }/>
      </button>
      <button
        onClick={ () => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough')
        } }
        className={ classNames(styles.toolbarItem, styles.spaced, (isStrikethrough ? styles.active : ''),) }
        aria-label='Format Strikethrough'>
        <FontAwesomeIcon icon={ faStrikethrough } className={ styles.formatIcon }/>
      </button>
      <Divider/>
      <button
        onClick={ formatList(INSERT_UNORDERED_LIST_COMMAND, "Bulleted List") }
        className={ classNames(styles.toolbarItem, styles.spaced, (isUnorderedList ? styles.active : ''),) }
        aria-label='Format Unordered List'>
        <FontAwesomeIcon icon={ faListUl } className={ styles.formatIcon }/>
      </button>
      <button
        onClick={ formatList(INSERT_ORDERED_LIST_COMMAND, "Numbered List") }
        className={ classNames(styles.toolbarItem, styles.spaced, (isOrderedList ? styles.active : ''),) }
        aria-label='Format Ordered List'>
        <FontAwesomeIcon icon={ faListOl } className={ styles.formatIcon }/>
      </button>
      <Divider/>
      <button
        onClick={ () => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left')
        } }
        className={ classNames(styles.toolbarItem, styles.spaced) }
        aria-label='Left Align'>
        <FontAwesomeIcon icon={ faAlignLeft } className={ styles.formatIcon }/>
      </button>
      <button
        onClick={ () => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center')
        } }
        className={ classNames(styles.toolbarItem, styles.spaced) }
        aria-label='Center Align'>
        <FontAwesomeIcon icon={ faAlignCenter } className={ styles.formatIcon }/>
      </button>
      <button
        onClick={ () => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right')
        } }
        className={ classNames(styles.toolbarItem, styles.spaced) }
        aria-label='Right Align'>
        <FontAwesomeIcon icon={ faAlignRight } className={ styles.formatIcon }/>
      </button>
      <button
        onClick={ () => {
          editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'justify')
        } }
        className={ styles.toolbarItem }
        aria-label='Justify Align'>
        <FontAwesomeIcon icon={ faAlignJustify } className={ styles.formatIcon }/>
      </button>
      <Divider/>
      <button
        onClick={ revertToDefault }
        className={ styles.toolbarItem }
        aria-label='Revert to Template Default'>
        <FontAwesomeIcon icon={ faBackwardFast } className={ styles.formatIcon }/>
      </button>
      { ' ' }
    </div>
  )
}

export default ToolbarPlugin
