import React from 'react'
import HeaderCheckbox from "./HeaderCheckbox";
import { useCocExport, useCocExportApi } from "../../../contexts/cocExport";

const RushPriority = () => {
  const { headerDetails } = useCocExport()
  const { updateRushPriority } = useCocExportApi()

  return <>
    <h1>Rush Priority</h1>
    <HeaderCheckbox title={'Same day (200%)'} field={'same_day'} value={headerDetails.rush_priority.same_day} update={updateRushPriority} />
    <HeaderCheckbox title={'Next Day/Two Day (100%)'} field={'next_day'} value={headerDetails.rush_priority.next_day} update={updateRushPriority} />
    <HeaderCheckbox title={'Three or Four Days (50%)'} field={'three_days'} value={headerDetails.rush_priority.three_days} update={updateRushPriority} />
    <HeaderCheckbox title={'5 to 7 Days (Regular TAT)'} field={'five_days'} value={headerDetails.rush_priority.five_days} update={updateRushPriority} />
  </>
}

export default RushPriority
