import React, { useState } from "react"

const TooltipButton = ({ buttonText, tooltipText, className, href = undefined }) => {
  const [show, setShow] = useState(false);

  return (
    <div
      className="flex cursor-pointer relative"
      onMouseEnter={()=>setShow(true)}
      onMouseLeave={()=>setShow(false)}
    >
      {!href && <div className={`border border-solid bg-white p-2 left w-52 -top-12 absolute ${show ? "" : "hidden"}`}>
        {tooltipText}
      </div>}
      <a href={href} target="_blank" rel="noreferrer noopener" className={className + (!href ? " pointer-events-none opacity-70" : "")}>
        {buttonText}
      </a>
    </div>
  )
}

export default TooltipButton
