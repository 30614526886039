import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { AiDocumentProvider } from "../../contexts/aiDocumentContext"
import AiDocuments from "./documents"
import AiDocument from "./document"

const Main = () => {
  return (
    <Router>
      <Routes>
        <Route path='/ai/documents/:documentId' element={ <AiDocumentProvider><AiDocument/></AiDocumentProvider> }/>
        <Route path='/ai/documents' element={ <AiDocuments/> }/>
      </Routes>
    </Router>
  )
}

export default Main
