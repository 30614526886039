import React, { useState, useMemo, useEffect } from 'react'
import { isEmpty } from "lodash";
import { CocExportProvider } from "../../contexts/cocExport"
import CocExportBody from "./CocExportBody"
import { formatBoreholeSamples } from "../../utilities/cocExportHelpers"
import { simpleGet } from "../../api/apiConfig";
import { notifyError } from "../shared/notice";

const Index = ({ exportPath, project, labs }) => {
  const [boreholes, setBoreholes] = useState([])
  const [filterValues, setFilterValues] = useState({})

  useEffect(() => {
    simpleGet(`${window.location.pathname}.json`)
      .then(({ boreholes, filterValues }) => {
        setBoreholes(boreholes)
        setFilterValues(filterValues)
      })
      .catch(error => notifyError('Error: ' + error?.response?.data?.exception))
  }, [])

  const formattedSamples = useMemo(() => {
    return formatBoreholeSamples(boreholes)
  }, [boreholes])

  return (
    <>
      {!isEmpty(boreholes) && !isEmpty(filterValues) && (
        <CocExportProvider allSamples={formattedSamples} labs={labs}>
          <CocExportBody exportPath={exportPath} project={project} boreholes={boreholes} filterValues={filterValues} />
        </CocExportProvider>
      )}
    </>
  )
}

export default Index
