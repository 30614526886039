import React from "react"
import DatePicker from "react-datepicker"

const DateRangeSelect = ({ selected, onChangeDate, title }) => {

  return (
    <div>
      <p>{title} Date</p>
      <DatePicker
        className='block w-full p-1 text-xs text-gray-700 appearance-none focus:outline-none border border-gray-200 focus:shadow-inner m-0 rounded z-1000'
        selected={selected}
        onChange={(date) => onChangeDate(title, date)}
      />
    </div>
  )
}

export default DateRangeSelect
