import React from 'react'
import { tableStyles } from '../../../utilities/stylesHelpers'

const TableHeader = ({ content, style, sideways= false, colSpan = 1 }) => {
  return (
    <th className={style ? style : tableStyles.th}  colSpan={colSpan}>
      <div style={sideways? { writingMode: 'vertical-lr', transform: 'rotate(180deg)' } : {}} className={sideways? tableStyles.thContentSideways: tableStyles.thContent }>
        {content}
      </div>
    </th>
  )
}

export default TableHeader
