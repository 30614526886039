import React, { useEffect, useState, useMemo } from "react"
import { isEmpty } from "lodash"
import Select from "react-select"
import DatePicker from "react-datepicker";
import { useParams } from "react-router-dom"
import { simpleGet } from "../../../api/apiConfig"
import { optionize, pipe } from "../../../utilities/utils"
import { buildReportPath, generateReport, getDateRange } from "../../../api/reportsApi"
import { formatOptions, parseAllDates } from "../../../utilities/reportHelpers"

const SelectDropdown = ({ setTrackerReports }) => {
  const { portfolioId, projectId } = useParams()
  const reportPath = useMemo(() => buildReportPath({ portfolioId, projectId }), [portfolioId, projectId])
  const [reportsAsOptions, setReportsAsOptions] = useState([])
  const [dateRange, setDateRange] = useState([])
  const [selectedReport, setSelectedReport] = useState({})
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [reportFormat, setReportFormat] = useState('')
  const headerMessage = projectId ? 'Export Project Tracker Report' : 'Export Portfolio Tracker Report'
  const submitButtonDisabled = useMemo(() => {
    return isEmpty(selectedReport) || !startDate || !endDate || isEmpty(reportFormat)
  }, [selectedReport, startDate, endDate, reportFormat])

  useEffect(() => void simpleGet(reportPath).then(res => pipe(optionize('name', 'slug'), setReportsAsOptions)(res)), [reportPath])
  useEffect(() => {
    if (!isEmpty(selectedReport)) {
      void getDateRange({ portfolioId, projectId, reportSlug: selectedReport?.value })
        .then(res => pipe(parseAllDates, setDateRange)(res))
    }
  }, [selectedReport])

  const reportSelectHandler = (e) => (e.value !== selectedReport?.value) && pipe(setSelectedReport, setStartDate, setEndDate)(e)
  
  const onSubmit = async (e) => {
    e.preventDefault()

    const formData = new FormData()
    formData.set('start_date', startDate)
    formData.set('end_date', endDate)
    formData.set('report_format', reportFormat.value)

    const generatedReport = await generateReport({ portfolioId, projectId, reportSlug: selectedReport.value, formData })

    setTrackerReports(state => [...state, generatedReport])
  }

  return (
    <div className="p-7">
      <h1 className="text-4xl">{headerMessage}</h1>
      <form onSubmit={onSubmit}>
        <div className="w-full flex flex-row justify-between py-8">
          <label key="report-select" className="w-1/5 z-20">
            Report Select
            <Select options={reportsAsOptions} onChange={reportSelectHandler} />
          </label>
          <label key="start-date" className="w-1/5 z-20">
            Start Date
            <DatePicker
              placeholderText={isEmpty(selectedReport) ? 'Select Report' : 'Select...'}
              className='block w-full appearance-none focus:outline-none border border-[#cccccc] focus:shadow-inner m-0 rounded z-1000'
              disabled={isEmpty(selectedReport)}
              includeDates={dateRange}
              selected={startDate}
              onChange={setStartDate}
            />
          </label>
          <label key="end-date" className="w-1/5 z-20">
            End Date
            <DatePicker
              placeholderText={isEmpty(selectedReport) ? 'Select Report' : 'Select...'}
              className='block w-full appearance-none focus:outline-none border border-[#cccccc] focus:shadow-inner m-0 rounded z-1000'
              disabled={isEmpty(selectedReport)}
              includeDates={dateRange}
              selected={endDate}
              onChange={setEndDate}
            />
          </label>
          <label key="report-format" className="w-1/5 z-20">
            Report Format
            <Select options={formatOptions} onChange={setReportFormat} />
          </label>
        </div>
        <div>
          <button
            className={`btn btn-primary font-semibold mx-4 my-4 relative sm:ml-0 ${submitButtonDisabled ? 'opacity-50' : 'cursor-pointer'}`}
            id={'generate-report-button'}
            name={'generate-report-btn'}
            disabled={submitButtonDisabled}
          >
            Generate Report
          </button>
        </div>
      </form>
    </div>
  )
}

export default SelectDropdown
