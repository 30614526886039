import API from "./apiConfig"

export const getHistoricalAssessment = (project_id) => {
  return API
    .get(`/projects/${project_id}/historical-assessment.json`)
    .then(res => res.data)
}

export const updateHistoricalAssessment = (project_id, formData) => {
  return API
    .put(`/projects/${project_id}/update_historical_assessment`, formData)
    .then(res => res.data)
}
