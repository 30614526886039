import React, { createContext, useContext, useEffect, useMemo, useReducer } from 'react'
import { useParams } from 'react-router-dom'
import { getAnalyticSummary } from '../api/lab/analyticSummaryApi'

// Actions
const INIT = "INIT"
const TOGGLE = 'TOGGLE'
const UPDATE_STATE = 'UPDATE_STATE'

// Initial States
const initialState = {
  analyticSummary: {},
  loading: false,
}

// Reducer
const analyticSummaryReducer = (state, action) => {
  switch (action.type) {
    case INIT:
      return { ...state, analyticSummary: action.analyticSummary }
    case UPDATE_STATE:
      return { ...state, [action.field]: action.value }
    case TOGGLE:
      return { ...state, [action.field]: !state[action.field] }
    default:
      return state
  }
}

// Context
const AnalyticSummaryContext = createContext(initialState)
const AnalyticSummaryApiContext = createContext({
  updateState: () => {},
  toggleField: () => {},
})

// Provider
export const AnalyticSummaryProvider = ({ children }) => {
  const [state, dispatch] = useReducer(analyticSummaryReducer, initialState)
  const { projectId, analyticExportId } = useParams()

  const api = useMemo(() => {
    const updateState = (field, value) => dispatch({ type: UPDATE_STATE, field, value })

    const toggleField = field => dispatch({ type: TOGGLE, field })

    return { updateState, toggleField }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const response = await getAnalyticSummary(projectId, analyticExportId)
      dispatch({ type: INIT, analyticSummary: response })
    }
    fetchData()
  }, [projectId])

  return (
    <AnalyticSummaryContext.Provider value={state}>
      <AnalyticSummaryApiContext.Provider value={api}>
        {children}
      </AnalyticSummaryApiContext.Provider>
    </AnalyticSummaryContext.Provider>
  )
}

// Custom hooks
export const useAnalyticSummaryContext = () => useContext(AnalyticSummaryContext)
export const useAnalyticSummaryApi = () => useContext(AnalyticSummaryApiContext)
