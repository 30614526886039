import React from 'react'
import ProjectInformation from "./ProjectInformation"
import DeliveryFields from "./DeliveryFields"
import { useCocExportApi } from "../../../contexts/cocExport"
import AdditionalReportTo from "./AdditionalReportTo"
import SampleCustody from "./SampleCustody"
import NotesComments from "./NotesComments"
import RushPriority from "./RushPriority"
import ReportResults from "./ReportResults"
import Requirements from "./Requirements"
import LabInformation from "./LabInformation"
import Coolers from "./Coolers"

const Header = ({ project }) => {
  const { updateInvoiceTo, updateReportTo, updateLabInformation } = useCocExportApi()

  return (
    <>
      <div className="container m-auto grid grid-cols-2 gap-x-6 gap-y-8 sm:grid-cols-6">
        <div className='sm:col-span-3'>
          <ProjectInformation project={project} />
          <DeliveryFields title={'Report To'} field={'report_to'} update={updateReportTo} />
          <AdditionalReportTo />
        </div>
        <div className='sm:col-span-3 mx-6'>
          <DeliveryFields title={'Invoice To'} field={'invoice_to'} update={updateInvoiceTo} />
          <LabInformation title={'Lab Information'} field={'lab_information'} update={updateLabInformation} />
          <SampleCustody />
        </div>
      </div>
      <div className='container m-auto grid grid-cols-12 mt-7 gap-x-6'>
        <div className='col-span-2'>
          <Coolers />
        </div>
        <div className='col-span-2'>
          <RushPriority />
        </div>
        <div className='col-span-2'>
          <ReportResults />
        </div>
        <div className='col-span-2'>
          <Requirements />
        </div>
        <div className='col-span-4'>
          <NotesComments />
        </div>
      </div>
    </>
  )
}

export default Header
