import React, { useEffect, useState } from 'react'
import { useTestResultsFilterApi, useTestResultsFilterContext } from '../../../../contexts/testResultsFilterContext'
import { formatDateYearMonthDay, setToDateRange } from '../../../../utilities/dateHelpers'
import * as filterType from '../../../filters'
import ApecFilter from './apecFilter'
import ContaminantGroupFilter from './contaminantGroupFilter'

const TableFilters = () => {
  const { valueOptions } = useTestResultsFilterContext()
  const { addFilter, filter } = useTestResultsFilterApi()
  const [dateRange, setDateRange] = useState({ start_date: formatDateYearMonthDay(Date.now()), end_date: formatDateYearMonthDay(Date.now()) })
  const onEndDateSelect = (date) => { if (date) filter('dateRange', { end_date: date }) }
  const onStartDateSelect = (date) => { if (date) filter('dateRange', { start_date: date }) }

  useEffect(() => {
    if (valueOptions?.sample_date.size !== 0) {
      const newDateRange = setToDateRange(valueOptions?.sample_date)
      setDateRange(newDateRange)
      addFilter('dateRange', { start_date: newDateRange.start_date })
      addFilter('dateRange', { end_date: newDateRange.end_date })
    }
    if (valueOptions?.apec.size !== 0) {
      addFilter('apecs', Array.from(valueOptions?.apec))
    }
    if (valueOptions?.contaminantGroup.length !== 0) {
      addFilter('contaminantGroups', valueOptions?.contaminantGroup)
    }
  }
    , [valueOptions])

  return (
    <div className='border border-blue-500 p-4 rounded-lg m-4 flex flex-col max-w-fit'>
      <h1 className='text-2xl font-bold underline'><strong>Table Filters</strong></h1>
      <div className="m-4 z-10 flex flex-row gap-x-4">
      <div className="flex flex-col border border-grey-500 p-2">
      <h2 className='font-semibold text-blue-950 underline'><strong>Date Range</strong></h2>
        <filterType.DateFilter dateRange={dateRange} onEndDateSelect={onEndDateSelect} onStartDateSelect={onStartDateSelect} updateRange={false} />
        </div>
        <div className="flex flex-col border border-grey-500 p-2">
          <h2 className='font-semibold text-blue-950 underline'><strong>Remove Categories</strong></h2>
          <ApecFilter options={Array.from(valueOptions?.apec)} />
          <ContaminantGroupFilter options={valueOptions?.contaminantGroup} />
        </div>
      </div>
    </div>
  )
}

export default TableFilters
