export const toolTipFormatter = (obj, infoFlag, showExtraData) => {
  const seriesIndex = obj.series.index
  const dataObj = obj.series.chart.options.series[seriesIndex].data[0]

  let returnString = obj.series.name
  let hydrocarbon = 'N/A'
  let hydrocarbon_units = 'N/A'
  let salinity = 'N/A'
  let salinity_units = 'N/A'

  hydrocarbon = dataObj[5]
  hydrocarbon_units = dataObj[6]
  salinity = dataObj[7]
  salinity_units = dataObj[8]

  if (infoFlag) {
    returnString += '<br/> Average Depth: ' + obj.y + tooltipAllInfo(dataObj)
  }

  if (showExtraData) {
    returnString += '<br/> Hydrocarbon: ' + hydrocarbon + ' ' + hydrocarbon_units + '<br/> Salinity: ' + salinity + ' ' + salinity_units
  }

  return returnString
}

const tooltipAllInfo = (dataObj) => {
  let topDepth = 'N/A'
  let endDepth = 'N/A'
  let screening_date = 'N/A'
  let apec = 'N/A'

  if (dataObj) {
    topDepth = dataObj[2]
    endDepth = dataObj[3]
    screening_date = dataObj[4]
    apec = dataObj[9]
  }

  return '<br/> Top Depth: ' + topDepth + '<br/> End Depth: ' + endDepth + '<br/> Screening Date: '
    + screening_date + '<br/> APEC: ' + apec
}

export const scatterOptions = (data, boreHoleNames, formatter, showExtraData) => {

  return {
    chart: {
      type: 'scatter'
    },
    title: {
      text: 'Borehole Depth',
      align: 'left'
    },
    xAxis: {
      type: 'category',
      categories: boreHoleNames,
      opposite: true,
    },
    yAxis: {
      title: {
        text: 'Borehole Depth (m)'
      },
      max: 1,
    },
    plotOptions: {
      scatter: {
        marker: {
          fillColor: '#fde047',
          radius: 6,
          symbol: 'circle'
        }
        ,
        dataLabels: {
          enabled: true,
          formatter: function () {
            return formatter(this, false, showExtraData)
          },
          style: {
            fontSize: '12px',
            color: 'black',
            textOutline: '1px contrast',
            textAlign: 'left'
          },
          allowOverlap: true,
        }
      }
    },
    tooltip: {
      formatter: function () {
        return formatter(this, true, true)
      }
    },
    legend: {
      endabled: true,
    },
    series: data
  }
}
