import React from "react";

const ExportCard = ({ exportData }) => {
  return <>
    <div className='flex flex-row gap-x-6 text-white justify-between bg-regular-blue hover:bg-hover-blue rounded-xl w-full py-4 px-6 items-center'>
      <div className="flex flex-col">
        <div>{exportData.note_title}</div>
        <div className="text-xs">Created By: {exportData.created_by}</div>
        <div className="text-xs">Run at: {exportData.created_at}</div>
      </div>
      {exportData.file_url && <a href={exportData.file_url} target="_blank" rel="noopener noreferrer">
        <i
          className="fa fa-cloud-download-alt fa-2x pointer"
        />
      </a>}
    </div>
  </>
}

export default ExportCard
