import React, { createContext, useContext, useEffect, useMemo, useReducer } from "react"
import { getTimesheet, getTimesheetEntries } from "../api/timesheetsApi"
import { addTimeTables } from "../utilities/timesheetHelpers"
import { getWeekStart, incrementDay, stringToUTCDate } from "../utilities/dateHelpers"

/* Actions */
const ACTIONS = {
  INIT: "INIT",
  INIT_ENTRY_STATE: "INIT_ENTRY_STATE",
  TOGGLE: "TOGGLE",
  UPDATE_STATE: "UPDATE_STATE",
  UPDATE_WEEK: "UPDATE_WEEK",
  INCREMENT_WEEK: "INCREMENT_WEEK",
  ADD_TIMETABLES: "ADD_TIMETABLES",
}

/* Initial States */
const initialState = {
  template: {},
  tasks: [],
  timeTables: {},
  user: {},
  users: [],
  organization: {},
  projects: {},
  portfolios: {},
  selectedUsers: [],
  weekStart: getWeekStart(stringToUTCDate(new Date())),
}

const initState = ({ user, users, template, tasks, entries, projects, portfolios }) => ({
  users: users, template: template, tasks: tasks, timeTables: { [user.id]: entries }, user: user, organization: user.organization, projects: projects, portfolios: portfolios
})


/* Reducer */
const timesheetReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.INIT:
      return { ...state, ...initState(action.initState) }
    case ACTIONS.INIT_ENTRY_STATE:
      return { ...state, editEntry: initEditEntryState(action.initEntryState) }
    case ACTIONS.TOGGLE:
      return { ...state, [action.state]: !state[action.state] }
    case ACTIONS.UPDATE_STATE:
      return { ...state, [action.field]: action.value }
    case ACTIONS.UPDATE_WEEK:
      return { ...state, weekStart: getWeekStart(action.value) }
    case ACTIONS.INCREMENT_WEEK:
      return { ...state, weekStart: getWeekStart(incrementDay(state.weekStart, action.value)) }
    case ACTIONS.ADD_TIMETABLES:
      return { ...state, timeTables: addTimeTables(state.timeTables, action.timeTables) }
    default:
      return state
  }
}

/* Contexts */
const TimesheetContext = createContext(initialState)
const TimesheetApiContext = createContext({})

/* Providers */
export const TimesheetProvider = ({ children }) => {
  const [state, dispatch] = useReducer(timesheetReducer, initialState)

  const api = useMemo(() => {
    const toggleState = (state = 'editModal') => dispatch({ type: ACTIONS.TOGGLE, state })

    const updateState = (field, value) => dispatch({ type: ACTIONS.UPDATE_STATE, field, value })

    const incrementWeek = value => dispatch({ type: ACTIONS.INCREMENT_WEEK, value })

    const updateWeek = value => dispatch({ type: ACTIONS.UPDATE_WEEK, value })

    const updateSelectedUsers = value => updateState('selectedUsers', value)

    const addTimeTables = timeTables => dispatch({ type: ACTIONS.ADD_TIMETABLES, timeTables })

    return {
      toggleState, updateState, incrementWeek, updateWeek, updateSelectedUsers, addTimeTables
    }
  }, [])

  api.getUserEntries = async () => { getTimesheetEntries(state.selectedUsers).then(res => api.addTimeTables(res)) }


  // Initialize state
  useEffect(() => void getTimesheet().then(res => dispatch({ type: ACTIONS.INIT, initState: res })), [])

  return (
    <TimesheetApiContext.Provider value={api}>
      <TimesheetContext.Provider value={state}>
        {children}
      </TimesheetContext.Provider>
    </TimesheetApiContext.Provider>
  )
}

/* Custom Context Hooks */
export const useTimesheetContext = () => useContext(TimesheetContext)
export const useTimesheetApi = () => useContext(TimesheetApiContext)
