import FormDatumRow from "./formDatumRow";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleLeft, faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { useProjectContext } from "../../contexts/projectContext";

const FormFieldGroup = ({ formField, formData }) => {
  let [style, setStyle] = useState({})
  let { project } = useProjectContext()
  let [isExpanded, setIsExpanded] = useState(project.name.includes('Foreman') || formField.label === 'Sample Details' || formField.label === 'Borehole ID')

  const hasChildren = () => {
    return formField.children.length > 0
  }

  const ExpandIcon = () => {
    return <FontAwesomeIcon icon={isExpanded ? faAngleDoubleLeft : faAngleDoubleRight}
                            className='cursor-pointer text-gray-500 hover:text-black mr-1'
                            onClick={() => setIsExpanded(!isExpanded)} />
  }

  useEffect(() => {
    if (hasChildren()) {
      if (isExpanded) {
        setStyle({ width: 'auto' })
      } else {
        setStyle({ width: '16rem', overflowX: 'hidden' })
      }
    } else {
      setStyle({ width: 'auto' })
    }
  }, [isExpanded])

  const childHeaderRows = () => {
    if (hasChildren()) {
      return <tr>{formField.children.map(child => <th
        key={child.id}
        className={`w-64 py-2 font-semibold text-xs text-gray-600 uppercase text-left pr-2 pr-2`}>
        {child.label}
      </th>)}</tr>
    } else {
      return <tr>
        <th
          className={`w-64 py-2 font-semibold text-xs truncate text-gray-600 uppercase text-left `}>{formField.label}</th>
      </tr>
    }
  }

  const Overlay = () => {
    return <div
      className='absolute x-0 y-0 w-full h-full bg-gradient-to-l from-gray-100 to-transparent flex flex-row items-end'>
      <div className='flex flex-col relative w-full h-full items-end p-1'>
        <ExpandIcon />
      </div>
    </div>
  }

  const ExpandableGroup = () => {
    return <div className='flex flex-col'>
      <div style={style} className='relative'>
        {
          <>
            {!isExpanded && <Overlay />}
            <div className='flex flex-row justify-between items-center w-64 font-bold py-1'>
              <div className='truncate'>{formField.label}</div>
              {isExpanded && <ExpandIcon />}
            </div>
            <table className='table-fixed border-collapse'>
              <thead>
              {childHeaderRows()}
              </thead>
              <tbody>
              {formData.map(formData => <FormDatumRow key={formData.id} formDatum={formData} formField={formField} />)}
              </tbody>
            </table>
          </>
        }
      </div>
    </div>
  }

  return hasChildren() ? <ExpandableGroup /> : <div className='flex flex-col'>
    <div className='flex flex-grow items-end'>
      <table className='table-fixed border-collapse'>
        <thead>
        {childHeaderRows()}
        </thead>
        <tbody>
        {formData.map(formData => <FormDatumRow key={formData.id} formDatum={formData} formField={formField} />)}
        </tbody>
      </table>
    </div>
  </div>
}

export default FormFieldGroup
