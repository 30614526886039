import { isEmpty } from "lodash"
import React, { useMemo } from "react"
import StandardButton from "../shared/standardButton"
import { useTrackerAPI, useTrackerContext } from "../../contexts/trackerContext"
import { usePortfolioAPI } from "../../contexts/portfolioContext"

const ShowMetaDataButton = () => {
  const { showExtraData, autoHeaders } = useTrackerContext()
  const { toggleExtraData } = useTrackerAPI()
  const { isPortfolio } = usePortfolioAPI()

  const buttonText = useMemo(() => {
    return `${ showExtraData ? "Hide" : "Show" } Meta Data`
  }, [showExtraData])

  const shouldRender = useMemo(() => isPortfolio || !isEmpty(autoHeaders), [isPortfolio, autoHeaders])

  return shouldRender ? <StandardButton buttonText={ buttonText } onClickHandler={ toggleExtraData }/> : <></>
}

export default ShowMetaDataButton
