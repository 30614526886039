import React from 'react'
import { tableStyles } from "../../utilities/stylesHelpers"
import { classNames } from "../../utilities/utils"
import TrackerCell from "./trackerCell"
import { cellContent } from "../../utilities/trackerHelpers"

const HorizontalTable = ({ trackerHeaders, trackerEntries }) => {
  return (
    <table className={ classNames(tableStyles.table, "w-full max-w-[978px]") }>
      <thead className={ tableStyles.tableHeaderRow }>
      <tr className={ tableStyles.tableHeaderRow }>
        { trackerHeaders?.map(header => (
          <th key={ header.id } className={ classNames(tableStyles.th, "text-lg") }>{ header?.title }</th>
        )) }
      </tr>
      </thead>
      <tbody>
      { trackerEntries?.map((entry) => (
        <tr key={ entry.id }>
          { trackerHeaders?.map(header => (
            <TrackerCell
              key={ header.id }
              content={ cellContent(header, entry.tracker_attributes) }
              header={ header }
              entryId={ entry.id }/>
          )) }
        </tr>
      )) }
      </tbody>
    </table>
  )
}
export default HorizontalTable
