import React from 'react'
import HeaderCheckbox from "./HeaderCheckbox";
import { useCocExport, useCocExportApi } from "../../../contexts/cocExport";

const ReportResults = () => {
  const { headerDetails } = useCocExport()
  const { updateReportResults } = useCocExportApi()

  return <>
    <h1>Report Results</h1>
    <HeaderCheckbox title={'Email'} field={'email'} value={headerDetails.report_results.email} update={updateReportResults} />
    <HeaderCheckbox title={'Online'} field={'online'} value={headerDetails.report_results.online} update={updateReportResults} />
    <HeaderCheckbox title={'Fax'} field={'fax'} value={headerDetails.report_results.fax} update={updateReportResults} />
    <HeaderCheckbox title={'QA/QC'} field={'qa_qc'} value={headerDetails.report_results.qa_qc} update={updateReportResults} />
    <HeaderCheckbox title={'PDF'} field={'pdf'} value={headerDetails.report_results.pdf} update={updateReportResults} />
    <HeaderCheckbox title={'EXCEL'} field={'excel'} value={headerDetails.report_results.excel} update={updateReportResults} />
  </>
}

export default ReportResults
