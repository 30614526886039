/**
 * Get's the environment from the NODE_ENV environment variable.
 * Staging has 'production' as its NODE_ENV variable so we check the url to determine if the environment is staging or production.
 * @returns {string|string} 'production', 'staging', 'development', or 'test'
 */
const getEnvironment = () => {
  return (process.env.NODE_ENV === 'production' && window.location.hostname !== 'fielddms.app') ? 'staging' : process.env.NODE_ENV
}

const layoutExceptionList = [
  'Petronas_Impact',
  'Wolf_Weld_Mapping',
  'Pipeline Demo',
  'Houston_Demo'
]
Object.freeze(layoutExceptionList)

/**
 * For projects whose name is in the layoutExceptions array, will use the foreman note layout for its notes.
 * @param projectName
 * @returns {boolean}
 */
export const noteLayoutException = (projectName) => {
  return layoutExceptionList.includes(projectName)
}

const demoProjectIdsList = {
  production: [
    339,  // Pipeline Demo
    399,  // Keyera_Chinchaga
    411,  // Keyera_Chinchaga_Beta
    438, // Petronas-B11A-94G7-East_Beg
  ],
  staging: [
    401,  // Pipeline Demo
    340,  // Keyera_Chinchaga
    349,  // Keyera_Chinchaga_Beta
    375, // Petronas-B11A-94G7-East_Beg
  ],
  development: [
    3,   // Pipeline Demo
    21,  // Keyera_Chinchaga
    17, // Keyera_Chinchaga_Beta
    535, // Petronas-B11A-94G7-East_Beg-Daily Report
  ],
  test: []
}
Object.freeze(demoProjectIdsList)
Object.getOwnPropertyNames(demoProjectIdsList).forEach(prop => Object.freeze(demoProjectIdsList[prop]))

/**
 * Checks if the project id matches the list of project id's for the environment.
 * Staging environment has a node environment variable of production so to distinguish between the two,
 * we compare the url to the production url.
 * @param projectId
 * @returns {boolean}
 */
export const isDemoProject = (projectId) => {
  const environment = getEnvironment()

  return demoProjectIdsList[environment].includes(projectId)
}

/**
 * Mappable collection of project links for sidebar divided by environment
 */
const demoLinksList = (() => {

  const pipelineDemoDevelopment = [
    {
      projectName: 'Demo Form',
      projectId: 335,
      page: 'notes'
    },
    {
      projectName: 'Demo Project',
      projectId: 154,
      page: 'notes'
    },
    {
      projectName: 'Permit Tracker',
      projectId: 335,
      page: 'permits'
    },
    {
      projectName: 'Water Crossing Tracker',
      projectId: 338,
      page: 'permits'
    },
    {
      projectName: 'Reports Export',
      projectId: 21,
      page: 'landing_page'
    },
  ]

  const pipelineDemoStaging = [
    {
      projectName: 'Demo Form',
      projectId: 339,
      page: 'notes'
    },
    {
      projectName: 'Demo Project',
      projectId: 158,
      page: 'notes'
    },
    {
      projectName: 'Permit Tracker',
      projectId: 339,
      page: 'permits'
    },
    {
      projectName: 'Water Crossing Tracker',
      projectId: 341,
      page: 'permits'
    },
    {
      projectName: 'Reports Export',
      projectId: 5,
      page: 'landing_page'
    },
  ]

  const pipelineDemoProduction = [
    {
      projectName: 'Demo Form',
      projectId: 401,
      page: 'notes'
    },
    {
      projectName: 'Demo Project',
      projectId: 26,
      page: 'notes'
    },
    {
      projectName: 'Permit Tracker',
      projectId: 401,
      page: 'permits'
    },
    {
      projectName: 'Water Crossing Tracker',
      projectId: 404,
      page: 'permits'
    },
    {
      projectName: 'Reports Export',
      projectId: 326,
      page: 'landing_page'
    },
  ]

  const keyeraProjectDevelopment = [
    {
      projectName: "Keyera-Daily Summary",
      projectId: 356,
      page: 'permits'
    },
    {
      projectName: "Keyera-Document Log",
      projectId: 352,
      page: 'permits'
    },
    {
      projectName: "Keyera-Drawing Log",
      projectId: 351,
      page: 'permits'
    },
    {
      projectName: "Keyera-Excavation Tracker",
      projectId: 355,
      page: 'permits'
    },
    {
      projectName: "Keyera-Issue Log",
      projectId: 350,
      page: 'permits'
    },
    {
      projectName: "Keyera-Ticket Tracker",
      projectId: 353,
      page: 'permits'
    },
    {
      projectName: "Keyera-Waste Tracker",
      projectId: 354,
      page: 'permits'
    },
    {
      projectName: "Keyera-Safety Tracker",
      projectId: 352,
      page: 'permits'
    },
    {
      projectName: "Keyera-Chinchaga Excavation 1",
      projectId: 350,
      page: 'notes'
    },
    {
      projectName: "Keyera-Chinchaga Excavation 2",
      projectId: 365,
      page: 'notes'
    },
    {
      projectName: "Keyera-Chinchaga Excavation 3",
      projectId: 364,
      page: 'notes'
    },
    {
      projectName: "Keyera-Chinchaga Excavation 4",
      projectId: 362,
      page: 'notes'
    },
    {
      projectName: "Keyera-Chinchaga Excavation 5",
      projectId: 363,
      page: 'notes'
    },
  ]

  const keyeraProjectStaging = [
    {
      projectName: "Keyera-Daily Summary",
      projectId: 357,
      page: 'permits'
    },
    {
      projectName: "Keyera-Document Log",
      projectId: 353,
      page: 'permits'
    },
    {
      projectName: "Keyera-Drawing Log",
      projectId: 352,
      page: 'permits'
    },
    {
      projectName: "Keyera-Excavation Tracker",
      projectId: 356,
      page: 'permits'
    },
    {
      projectName: "Keyera-Issue Log",
      projectId: 351,
      page: 'permits'
    },
    {
      projectName: "Keyera-Ticket Tracker",
      projectId: 354,
      page: 'permits'
    },
    {
      projectName: "Keyera-Waste Tracker",
      projectId: 355,
      page: 'permits'
    },
    {
      projectName: "Keyera-Safety Tracker",
      projectId: 360,
      page: 'permits'
    },
    {
      projectName: "Keyera-Chinchaga Excavation 1",
      projectId: 359,
      page: 'notes'
    },
    {
      projectName: "Keyera-Chinchaga Excavation 2",
      projectId: 364,
      page: 'notes'
    },
    {
      projectName: "Keyera-Chinchaga Excavation 3",
      projectId: 363,
      page: 'notes'
    },
    {
      projectName: "Keyera-Chinchaga Excavation 4",
      projectId: 361,
      page: 'notes'
    },
    {
      projectName: "Keyera-Chinchaga Excavation 5",
      projectId: 362,
      page: 'notes'
    },
  ]

  const keyeraProjectProduction = [
    {
      projectName: "Keyera-Daily Summary",
      projectId: 419,
      page: 'permits'
    },
    {
      projectName: "Keyera-Document Log",
      projectId: 415,
      page: 'permits'
    },
    {
      projectName: "Keyera-Drawing Log",
      projectId: 414,
      page: 'permits'
    },
    {
      projectName: "Keyera-Excavation Tracker",
      projectId: 418,
      page: 'permits'
    },
    {
      projectName: "Keyera-Issue Log",
      projectId: 413,
      page: 'permits'
    },
    {
      projectName: "Keyera-Ticket Tracker",
      projectId: 416,
      page: 'permits'
    },
    {
      projectName: "Keyera-Waste Tracker",
      projectId: 417,
      page: 'permits'
    },
    {
      projectName: "Keyera-Safety Tracker",
      projectId: 423,
      page: 'permits'
    },
    {
      projectName: "Keyera-Chinchaga Excavation 1",
      projectId: 422,
      page: 'notes'
    },
    {
      projectName: "Keyera-Chinchaga Excavation 2",
      projectId: 427,
      page: 'notes'
    },
    {
      projectName: "Keyera-Chinchaga Excavation 3",
      projectId: 426,
      page: 'notes'
    },
    {
      projectName: "Keyera-Chinchaga Excavation 4",
      projectId: 425,
      page: 'notes'
    },
    {
      projectName: "Keyera-Chinchaga Excavation 5",
      projectId: 424,
      page: 'notes'
    },
  ]


  const petronasProjectDevelopment = [
    {
      projectName: "Daily Summary",
      projectId: 512,
      page: 'permits'
    },
    {
      projectName: "Document Log",
      projectId: 511,
      page: 'permits'
    },
    {
      projectName: "Drawing Log",
      projectId: 510,
      page: 'permits'
    },
    {
      projectName: "Issue Log",
      projectId: 509,
      page: 'permits'
    },
    {
      projectName: "Safety Tracker",
      projectId: 508,
      page: 'permits'
    },
    {
      projectName: "Ticket Tracker",
      projectId: 513,
      page: 'permits'
    }
  ]

  const petronasProjectStaging = [
    {
      projectName: "Daily Summary",
      projectId: 373,
      page: 'permits'
    },
    {
      projectName: "Document Log",
      projectId: 372,
      page: 'permits'
    },
    {
      projectName: "Drawing Log",
      projectId: 371,
      page: 'permits'
    },
    {
      projectName: "Issue Log",
      projectId: 370,
      page: 'permits'
    },
    {
      projectName: "Safety Tracker",
      projectId: 369,
      page: 'permits'
    },
    {
      projectName: "Ticket Tracker",
      projectId: 374,
      page: 'permits'
    }
  ]

  const petronasProjectProduction = [
    {
      projectName: "Daily Summary",
      projectId: 436,
      page: 'permits'
    },
    {
      projectName: "Document Log",
      projectId: 434,
      page: 'permits'
    },
    {
      projectName: "Drawing Log",
      projectId: 434,
      page: 'permits'
    },
    {
      projectName: "Issue Log",
      projectId: 433,
      page: 'permits'
    },
    {
      projectName: "Safety Tracker",
      projectId: 432,
      page: 'permits'
    },
    {
      projectName: "Ticket Tracker",
      projectId: 437,
      page: 'permits'
    }
  ]

  return {
    production: {
      "Pipeline Demo": pipelineDemoProduction,
      "Keyera_Chinchaga": keyeraProjectProduction,
      "Keyera_Chinchaga_Beta": keyeraProjectProduction,
      "Petronas-B11A-94G7-East_Beg" : petronasProjectProduction
    },
    staging: {
      "Pipeline Demo": pipelineDemoStaging,
      "Keyera_Chinchaga": keyeraProjectStaging,
      "Keyera_Chinchaga_Beta": keyeraProjectStaging,
      "Petronas-B11A-94G7-East_Beg" : petronasProjectStaging
    },
    development: {
      "Pipeline Demo": pipelineDemoDevelopment,
      "Keyera_Chinchaga": keyeraProjectDevelopment,
      "Keyera_Chinchaga_Beta": keyeraProjectDevelopment,
      "Petronas-B11A-94G7-East_Beg" : petronasProjectDevelopment
    },
    test: {}
  }
})()

export const demoLinksInfo = (project) => {
  return demoLinksList?.[getEnvironment()]?.[project.name]
}
