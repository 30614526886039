import { simpleGet, simplePost, simplePut } from "./apiConfig"

export const pathBuilder = ({ projectId, templateSlug, documentId, action }) => [
  `/projects/${ projectId }/document-builders`,
  templateSlug && `${ templateSlug }/document-log`,
  documentId,
  action
].filter(i => i).join('/')

export const getTemplates = projectId => simpleGet(pathBuilder({ projectId }))

export const getTemplate = (projectId, templateSlug, documentId = null) =>
  simpleGet(`/projects/${ projectId }/document-builders/${ templateSlug }.json`, { params: { document_id: documentId } })

export const getDocuments = projectId => simpleGet(`/projects/${ projectId }/document-log.json`)

export const getDocument = (projectId, templateSlug, documentId) => {
  return simpleGet(pathBuilder({ projectId, templateSlug, documentId }) + '.json')
}

export const createDocument = (projectId, templateSlug, body) =>
  simplePost(pathBuilder({ projectId, templateSlug }), body)

export const updateDocument = (projectId, templateSlug, documentId, body) =>
  simplePut(pathBuilder({ projectId, templateSlug, documentId }), body)

export const postGenerateFiles = (projectId, templateSlug, documentId) =>
  simplePost(pathBuilder({ projectId, templateSlug, documentId, action: 'generate' }))

export const getSectionLayout = (projectId, sectionLayoutId) =>
  simpleGet(`/projects/${ projectId }/section-layouts/${ sectionLayoutId }.json`)
