import React, { useState, useEffect, useMemo } from "react"
import Select from "react-select"
import { getPortfolioFormTemplates } from '../../../api/portfoliosApi'
import { isEmpty } from "lodash"
import { optionize } from "../../../utilities/utils"

const MobileDataDropdown = ({ portfolio }) => {
  const [formTemplateNameList, setFormTemplateNameList] = useState([])
  const [options, setOptions] = useState([])
  const [selectedHref, setSelectedHref] = useState('')
  const mousePointer = useMemo(() => selectedHref ? {} : { pointerEvents: 'none' }, [selectedHref])

  useEffect(() => {
    getPortfolioFormTemplates(portfolio.id)
      .then(res => setFormTemplateNameList(res))
  }, [])

  useEffect(() => {
    if (!isEmpty(formTemplateNameList)) {
      setOptions(
        optionize('name', 'url',)(formTemplateNameList)
      )
    }
  }, [formTemplateNameList])

const optionHandler = (event) => {setSelectedHref(event.value)}

  return (
    <div>
      <p className="py-2">Mobile Data</p>
      <Select
        className='capitalize w-full mb-5'
        placeholder={'Select'}
        options={options}
        onChange={optionHandler}
      />
      <div className="w-full flex justify-end">
        <a
          href={selectedHref}
          className='btn btn-primary truncate w-full text-center w-min text-sm py-1.5 px-3'
          style={mousePointer}
        >
          View Mobile Data
        </a>
      </div>
    </div>
  )
}

export default MobileDataDropdown
