import React from "react";
import { ImportModal } from "../../shared/importModal"
import LoadingSpinner from "../../shared/loadingSpinner";

const AuditExportButton = ({ loading, selectedStartDate, selectedEndDate, setLoading, getPdfExport, foremanName }) => {
  const requiredFields = selectedStartDate && selectedEndDate && foremanName

  return (
    <>
      {loading ? <ImportModal title={`Exporting Foreman Audit Report`}>
        <LoadingSpinner />
      </ImportModal> : <button
        className={`border ${requiredFields ? 'text-white bg-regular-blue hover:bg-hover-blue border-regular-blue' : 'bg-gray-200 cursor-default'}  rounded-md px-2 py-1 lg:px-4 lg:py-2 transition duration-500 select-none text-xl font-bold w-64 text-center mx-auto`}
        disabled={!requiredFields}
        onClick={() => {
          setLoading(true)
          getPdfExport()
        }}
      >
        Export!
      </button>}
    </>
  )
}

export default AuditExportButton
