import React from 'react'
import CheckBoxField from "../shared/checkBoxField";
import ChemicalSelect from "../chemicals/chemicalSelect";
import { useCocExportApi, useCocExport } from "../../contexts/cocExport";

const AddChemical = ({}) => {
  const { updateSelectedChemicals, toggleChangeOfControl } = useCocExportApi()
  const { selectedChemicals, includeChangeOfControl } = useCocExport()

  const addChemical = (chemical_name) => {
    const newChemicals = selectedChemicals.filter(item => item !== chemical_name)
    updateSelectedChemicals([...newChemicals, chemical_name], chemical_name)
  }

  return(
    <div className='flex flex-row items-center gap-x-4'>
      <ChemicalSelect selectChemical={addChemical} />
      <CheckBoxField
        className='flex flex-row'
        checked={includeChangeOfControl}
        setChecked={toggleChangeOfControl}
        size={24}
      >
        <p className='font-semibold ml-4'>Include Change of Control Fields</p>
      </CheckBoxField>
    </div>
  )
}

export default AddChemical
