import React, { useState, useRef } from 'react'
import axios from "axios"
import { useTrackerAPI, useTrackerContext } from "../../contexts/trackerContext"
import { notifyAlert, notifyError } from "../shared/notice"
import LoadingSpinner from "../shared/loadingSpinner"

const UploadCsv = () => {
  const [fileDisplayName, setFileDisplayName] = useState('')
  const [selectedFile, setSelectedFile] = useState(null)
  const { addTrackerEntries, setLoading } = useTrackerAPI()
  const { loading } = useTrackerContext()
  const fileInput = useRef()

  const handleFileChange = (event) => {
    event.persist()
    const file = event.target.files[0];
    setFileDisplayName(_.truncate(file?.name))
    setSelectedFile(file)
  }

  const handleSubmission = (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('tracker_csv', selectedFile)
    setLoading(true)
    const currentLocation = window.location.pathname

    axios.post(`${currentLocation}/upload_csv`, formData)
      .then(res => {
        const errorMessage = res.data?.error || res.data?.errors?.map(error => <span className="block" key={error}>{error}</span>)
        setSelectedFile(null)
        setFileDisplayName(state => `Error with ${state}`)

        if (errorMessage) {
          notifyError(errorMessage)
        } else {
          notifyAlert("Tracker Data Successfully Seeded!")
          setFileDisplayName("Success!")
          addTrackerEntries(res.data)
        }
      })
      .catch(err => notifyError(err.message))
      .finally(() => {
        setLoading(false),
          fileInput.current.value = ""
      })
  }

  return (
    <div className='my-6'>
      {loading ? <LoadingSpinner /> :
        <form onSubmit={handleSubmission} className='flex flex-row items-center gap-x-2'>
          <label className="btn btn-primary btn-outline cursor-pointer relative w-full text-center">
            <p>Upload CSV</p>
            <input type="file" name="tracker_csv" onChange={handleFileChange} ref={fileInput} accept=".csv" className="opacity-0 absolute inset-0 z-0 cursor-pointer top-full h-px" required />
          </label>
          <input type="submit" value="Submit CSV" accept=".csv" className="btn btn-primary cursor-pointer relative w-full text-center2" />
        </form>
      }
      <p className="mb-2">{fileDisplayName}</p>

    </div>
  )
}

export default UploadCsv
