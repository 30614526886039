import React from 'react'
import ModalWrapper from '../modal/modalWrapper'
import { formatDateTimeStr } from '../../utilities/dateHelpers'
import { usePhotoContext } from '../../contexts/photoContext'

const HistoryModal = ({ modal, toggleModal }) => {
  const { displayExport } = usePhotoContext()
  return (
    <>
      {modal && (
        <ModalWrapper
          heading={`History`}
          closeModal={() => toggleModal({ modalType: 'history' })}
        >
          <div className='w-full'>
            <label key="export-created-by" className="w-1/5 z-20 font-bold">
              Photo Report Created
              <div className='mb-4 pl-4 text-sm'>
                <p className='font-normal'>
                  <span className='font-semibold'>By:</span> {displayExport?.created_by}
                </p>
                <p className='font-normal'>
                  <span className='font-semibold'>At:</span> {formatDateTimeStr(displayExport?.created_at)}
                </p>
              </div>
            </label>
          </div>
        </ModalWrapper>
      )}
    </>
  )
}

export default HistoryModal
