import React from "react";
import TemplateRow from "../../admin/formTemplates/templateRow";

const FormTemplates = ({ portfolio, formTemplates }) => {
  const urlHost = window.location.origin

  return <>
    <div className='m-10'>
      <h1 className='text-2xl my-4'>{portfolio.name} Form Templates</h1>
      <table className='border-collapse text-left align-middle w-1/2'>
        <thead>
        <tr className='text-xl border-b-2 border-gray-500'>
          <th>Form Template Name</th>
          <th className='w-1/12'>ID</th>
          <th>GeoJSON Url</th>
        </tr>
        </thead>
        <tbody>
        {formTemplates.map(template => <TemplateRow key={template.id} portfolio={portfolio} template={template} urlHost={urlHost} />)}
        </tbody>
      </table>
    </div>
  </>
}

export default FormTemplates
