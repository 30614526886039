import React from "react"
import TableCell from "./tableCell"

const TableRow = ({ children, content, headers, permit }) => {
  return (
    <tr>
      {headers && headers.map(header => (
        <TableCell
          key={`${Math.random()}-${content[header]}`}
          permitId={permit?.id}
          content={content[header]}
          header={header}
        />
      ))}
      {children}
    </tr>
  )
}

export default TableRow
