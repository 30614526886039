import React from "react"
import AllTimesheets from "./allTimesheets"
import TimesheetTemplate from "./timesheetTemplate"

const Timesheets = ({ timesheetTemplates }) => {
  return (
    <>
      <h1 className='text-3xl m-4'>TimeSheet Overview</h1>
      <AllTimesheets />
      {timesheetTemplates.map((timesheetTemplate) => ( <TimesheetTemplate key={timesheetTemplate.id} timesheetTemplate={timesheetTemplate} /> ))}
    </>
  )
}

export default Timesheets
