import React from "react"
import HeaderInputField from "./headerInputField";
import HeaderDateField from "./headerDateField";

const HeaderInformation = () => {
  return<>
    <h1 className='my-4'>Header Information</h1>
    <div className='flex flex-col gap-y-3'>
      <HeaderInputField title={'Site Location'} field={'siteLocation'} />
      <HeaderInputField title={'Assessed By'} field={'assessedBy'} />
      <HeaderInputField title={'Assessment Type'} field={'assessmentType'} />
      <HeaderDateField title={'Date'} />
    </div>
  </>
}

export default HeaderInformation
