import React, { useState, useEffect } from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { parseISO, format } from "date-fns"

const BoreholeDatePicker = ({ boreholes, dates, onBoreholeSelect }) => {
  const [selectedDate, setSelectedDate] = useState(null)

  useEffect(() => {
    if (selectedDate) {
      const selectedBoreholes = boreholes.filter(borehole => borehole.sample_date === format(selectedDate, 'yyyy-MM-dd'))
      onBoreholeSelect(selectedBoreholes)
    }
  }, [selectedDate])

  return <>
    <h3 className='font-semibold'>Select a date below:</h3>
    <DatePicker
      className='block p-1 text-gray-700 appearance-none focus:outline-none border border-gray-200 focus:shadow-inner m-0 rounded z-1000'
      selected={selectedDate}
      includeDates={dates.map(date => parseISO(date))}
      onChange={(date) => setSelectedDate(date)}
    />
  </>
}

export default BoreholeDatePicker
