import React, { useRef } from 'react'
import { useTestResultsApi, useTestResultsContext } from '../../../../contexts/testResultsContext'
import ModalWrapper from '../../../modal/modalWrapper'
import DepthInput from './depthInput'
import GuidelineSelect from './guidelineSelect'

const GuidelineModal = () => {
  const { toggleField, updateAnalyticExport, updateState } = useTestResultsApi()
  const { currentAnalyticExportGuideline } = useTestResultsContext()
  const formRef = useRef()

  const handleCloseModal = () => {
    updateState('currentAnalyticExportGuideline', {} )
    toggleField('modal.guidelineModal')
    formRef.current.reset()
  }

  return (
    <ModalWrapper
      heading="Guideline Select Modal"
      closeModal={handleCloseModal}
      formName="guildeine-form"
      submitButtonText='Submit'
      modalContainerClasses="max-h-[90%] my-6 w-7/12"
    >
      <form ref={formRef} className="gap-y-6 flex flex-col" onSubmit={e => updateAnalyticExport(formRef, e, currentAnalyticExportGuideline)} id="guildeine-form">
        <GuidelineSelect guideline={currentAnalyticExportGuideline?.guideline} />
        <DepthInput depthType="start" depth={currentAnalyticExportGuideline?.start_depth} />
        <DepthInput depthType="end" depth={currentAnalyticExportGuideline?.end_depth} />
      </form>
    </ModalWrapper>
  )
}

export default GuidelineModal
