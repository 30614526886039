import React, { useMemo } from 'react'
import Lightbox from "yet-another-react-lightbox"
import { Captions, Download } from "yet-another-react-lightbox/plugins"
import "yet-another-react-lightbox/styles.css"
import "yet-another-react-lightbox/plugins/captions.css"
import { usePhotoAPIContext, usePhotoContext } from '../../../contexts/photoContext'
import Comment from './comment'
import CommentModal from './commentModal'

const LightBox = () => {
  const { filteredGalleryMap, comments, lightBoxState, openIndex, commentModal } = usePhotoContext()
  const { toggleLightBox, closeCommentModal } = usePhotoAPIContext()

  const slidesArray = useMemo(() => {
    return Array.from(
      filteredGalleryMap,
      item => {
        return {
          ...item[1].slide,
          description: <Comment comment={comments[item[1].details.comment_id]} id={item[1].details.comment_id} imageGuid={item[1].slide.title} centerButton={true} />
        }
      }
    )
  }, [filteredGalleryMap, comments])

  return (
    <>
      {commentModal.status && <CommentModal header={'Comment'} closeModal={closeCommentModal} lightBoxStatus={lightBoxState} />}
      <Lightbox
        styles={{ container: { backgroundColor: "rgba(0, 0, 0, .8)" } }}
        plugins={[Captions, Download]}
        slides={slidesArray}
        open={lightBoxState}
        close={toggleLightBox}
        index={openIndex}
        captions={{ descriptionTextAlign: 'center' }}
      />
    </>
  )
}

export default LightBox
