import React, { useEffect, useState } from 'react'
import axios from "axios"
import TableRow from "./tableRow"
import TableCell from "./tableCell"
import { columnNames, tableStyles } from '../../../utilities/permitHelpers'
import { pipe } from "../../../utilities/utils"
import UploadPdfButton from './uploadPdfButton'
import LoadingSpinner from "../../shared/loadingSpinner"
import { useParams } from "react-router-dom"
import Onboarding from "../../shared/onboarding"
import PermitHeader from "./permitHeader"

const Permit = ({ routes }) => {
  const { permitId } = useParams()
  const [permit, setPermit] = useState({})
  const [columnHeaders, setColumnHeaders] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const permitQuery = `${routes.permitsPath}/${permitId}.json`
    axios.get(permitQuery)
      .then((res) => setPermit(res.data))
      .then(() => setLoading(false))
  }, [routes])

  useEffect(() => {
    pipe(columnNames, setColumnHeaders)(permit.conditions_payload)
  }, [permit])

  return (
    <>
      <PermitHeader />
      {loading && <LoadingSpinner />}
      {!loading && columnHeaders?.length === 0 && (
        <div className='flex flex-col lg:flex-row lg:gap-x-2 flex-grow overflow-hidden'>
          <div className='flex flex-col w-full h-2/3 lg:h-auto lg:w-1/2 flex-grow overflow-scroll'>
            <Onboarding title='No permits found.' />
          </div>
        </div>
      )}
      {!loading && columnHeaders?.length > 0 && (
        <table className={tableStyles.table}>
          <thead className={tableStyles.tableHeaderRow}>
            <tr className={tableStyles.tableHeaderRow}>
              {columnHeaders.map((header, index) => <TableCell key={`${index}-header`} content={header} tag={"th"} />)}
            </tr>
          </thead>
          <tbody>
            {permit.conditions_payload.map((condition, index) => <TableRow key={`${permit.id}-${index}`} headers={columnHeaders} content={condition} />)}
          </tbody>
        </table>
      )}
      <div className="mt-8">
        <UploadPdfButton />
      </div>
    </>
  )
}

export default Permit
