import React from "react"
import ImportButtons from "./importButtons";
import SiteTypes from "./siteTypes";
import ProjectStatuses from "./projectStatuses";
import PortfolioList from "./portfolioList";
import DownloadTemplate from "./downloadTemplate";
import MobileProjectOptions from "./mobileProjectOptions";
import Provinces from "./provinces"

const ProjectImport = ({ provinces }) => {
  return <>
    <div className='m-6'>
      <h1 className='text-xl my-4'>Admin Project Import</h1>
      <div className='flex flex-row'></div>
      <div className='flex flex-row gap-x-2 m-6 w-2/3'>
        <div className='flex flex-col gap-y-4 w-1/2'>
          <ProjectStatuses />
          <SiteTypes />
          <Provinces provinces={provinces} />
          <MobileProjectOptions />
        </div>
        <PortfolioList />
      </div>
      <div className='flex flex-row gap-x-2'>
        <DownloadTemplate />
        <ImportButtons />
      </div>
    </div>
  </>
}

export default ProjectImport
