import React, { useEffect, useState } from 'react'
import LoadingSpinner from "../shared/loadingSpinner";

const RevisionFormEntry = ({ revision }) => {
  const revisionDate = new Date(revision.created_at)
  return <div className='flex flex-col text-xs mb-1'>
    <div className='flex flex-row items-center justify-between'>
      <div className='block text-base text-regular-orange my-1 truncate px-2'>
        {revision.value || "(blank)"}
      </div>
      <div className='block text-xs text-gray-900 truncate px-2'>
        {revision.created_by?.email || 'Original Value'}
      </div>
    </div>
    <div className='text-xs text-gray-900 truncate px-2 text-right'>
      {revisionDate.toDateString() === 'Invalid Date'
        ? ''
        : revisionDate.toDateString()}
    </div>
  </div>
}

const Revisions = ({ formEntry }) => {
  let [revisions, setRevisions] = useState()

  useEffect(() => {
    setRevisions(formEntry.versions || [])
  }, [formEntry])

  return <div className='w-full'>
    <div className='block text-xs font-semibold text-gray-900 uppercase my-1 truncate px-2'>
      Revisions
    </div>
    {revisions === undefined && <LoadingSpinner />}
    {revisions && revisions.length > 0 && <div>
      {revisions.map((revision) => (
        <RevisionFormEntry
          revision={revision}
          key={revision.id}
        />
      ))}
    </div>}
    {revisions && revisions.length === 0 && <div>
      <div className='px-2'>No revisions</div>
    </div>}
  </div>
}

export default Revisions
