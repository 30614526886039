import React, { createContext, useContext, useMemo, useEffect, useReducer, useCallback } from "react"
import { usePortfolioContext } from "./portfolioContext"
import { reduceToCoords } from "../utilities/trackerHelpers"
import { useTrackerContext } from "./trackerContext"
import { emailTrackerEntry } from "../api/emailApi"
import { toast } from "react-toastify"
import { whichProject, whichAttribute, subjectValue } from "../utilities/trackerEntryHelpers"
import { isEmpty } from "lodash"

/* Actions */
const ACTIONS = {
  UPDATE_STATE: "UPDATE_STATE",
  TOGGLE_EMAIL_MODAL: "TOGGLE_EMAIL_MODAL",
}

/* Initial States */
const initialState = {
  project: {},
  coords: {},
  emailModal: false,
  attachments: [],
  emailSubject: '',
}

/* Reducer */
const trackerEntryReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.TOGGLE_EMAIL_MODAL:
      return { ...state, emailModal: !state.emailModal }
    case ACTIONS.UPDATE_STATE:
      return { ...state, [action.field]: action.value }
    default:
      return state
  }
}

/* Contexts */
const TrackerEntryContext = createContext(initialState)
const TrackerEntryApiContext = createContext({
  updateState: (f, v) => {},
  toggleEmailModal: () => {},
  sendEmail: () => {}
})

/* Providers */
export const TrackerEntryProvider = ({ children, trackerEntry }) => {
  const { tracker_attributes: attributes } = trackerEntry
  const { projects } = usePortfolioContext()
  const { autoHeaders, headers } = useTrackerContext()
  const [state, dispatch] = useReducer(trackerEntryReducer, initialState, i=>i)

  const api = useMemo(() => {
    const updateState = (field, value) => dispatch({ type: ACTIONS.UPDATE_STATE, field, value })

    const sendEmail = async ({ selectedEmailOptions, subject, body }) => {
      if (isEmpty(selectedEmailOptions)) return toast.error('No recipients selected')

      const params = {
        recipients: selectedEmailOptions.map(email => email.value),
        subject,
        body,
        tracker_entry_id: trackerEntry.id
      }

      return emailTrackerEntry({ params })
        .then(() => {
          api.toggleEmailModal(false)
          toast.success('Email successfully sent')
        })
        .catch(() => toast.error('Email failed to send'))
    }

    return { updateState, sendEmail }
  }, [])

  api.toggleEmailModal = useCallback((header = null) => {
    if (header) {
      const attributeToActOn = whichAttribute(header.tracker_header_pointers[0]?.tracker_header_reference_id, attributes)
      api.updateState('emailSubject', attributeToActOn?.value || '')
    }
    dispatch({ type: ACTIONS.TOGGLE_EMAIL_MODAL })
  }, [attributes])

  useEffect(() => {
    api.updateState('project', whichProject(trackerEntry, projects))
  }, [trackerEntry, projects])

  useEffect(() => {
    api.updateState('coords', reduceToCoords(autoHeaders, trackerEntry.tracker_attributes, state.project))
  }, [autoHeaders, trackerEntry, state.project])

  useEffect(() => {
    api.updateState('attachments', attributes.reduce((agg, attr) => agg.concat(attr?.file || []), []))
  }, [attributes])

  return (
    <TrackerEntryApiContext.Provider value={ api }>
      <TrackerEntryContext.Provider value={ state }>
        { children }
      </TrackerEntryContext.Provider>
    </TrackerEntryApiContext.Provider>
  )
}

/* Hooks */
export const useTrackerEntryContext = () => useContext(TrackerEntryContext)
export const useTrackerEntryApi = () => useContext(TrackerEntryApiContext)
