import React, { useMemo } from "react"
import { useUserLandingPageApi, useUserLandingPageContext } from "../../../contexts/userLandingPageContext"
import AnimatedCheckbox from "../../shared/animatedCheckbox"
import Select from "react-select"

const inputClass = 'block w-full text-gray-700 appearance-none focus:outline-none border border-gray-300 focus:shadow-inner rounded'

const UserProfile = () => {
  const {
    allProvinces,
    userProfile: {
      id,
      homeAddress,
      postalCode,
      city,
      province,
      cellPhone1,
      cellPhone2,
      dateOfBirth,
      mailT4,
      emergencyContactLastName,
      emergencyContactFirstName,
      emergencyContactRelationship,
      emergencyContactCellPhone,
    }
  } = useUserLandingPageContext()
  const { updateNestedUserState } = useUserLandingPageApi()

  const provinceOptions = useMemo(() => allProvinces.map(prov => ({ value: prov.id, label: prov.name })), [allProvinces])

  const cityOptions = useMemo(() => {
    const cities = province ? allProvinces.find(prov => prov.id === province.value)?.cities :
      allProvinces.flatMap(prov => prov.cities)
    return cities.map(city => ({ value: city.id, label: city.name }))
  }, [allProvinces, province])

  const onChangeEventHandler = (field, nestedField) => event => updateNestedUserState(field, nestedField)(event.target.value)

  return (
    <div className="w-full grid grid-cols-2 gap-x-5">
      <h3 className="w-full py-3 text-2xl col-span-2">Contact Info</h3>
      <input className="hidden" name='user_profile_attributes[id]' readOnly value={ id }/>
      <label htmlFor="user_profile_attributes[home_address]">
        Home Address
        <input
          className={ inputClass }
          name='user_profile_attributes[home_address]'
          type='text'
          value={ homeAddress }
          onChange={ onChangeEventHandler('userProfile', 'homeAddress') }
        />
      </label>
      <label htmlFor="user_profile_attributes[postal_code]">
        Postal Code
        <input
          className={ inputClass }
          name='user_profile_attributes[postal_code]'
          type='text'
          value={ postalCode }
          onChange={ onChangeEventHandler('userProfile', 'postalCode') }/>
      </label>
      <label htmlFor="user_profile_attributes[city_id]">
        City
        <Select
          name="user_profile_attributes[city_id]"
          value={ city }
          options={ cityOptions }
          onChange={ updateNestedUserState('userProfile', 'city') }
        />
      </label>
      <label htmlFor="province">
        Province
        <Select
          name="province"
          value={ province }
          options={ provinceOptions }
          onChange={ updateNestedUserState('userProfile', 'province') }
        />
      </label>
      <label htmlFor="user_profile_attributes[cell_phone_1]">
        Cell Phone 1
        <input
          className={ inputClass }
          name='user_profile_attributes[cell_phone_1]'
          type='text'
          value={ cellPhone1 }
          onChange={ onChangeEventHandler('userProfile', 'cellPhone1') }/>
      </label>
      <label htmlFor="user_profile_attributes[cell_phone_2]">
        Cell Phone 2
        <input
          className={ inputClass }
          name='user_profile_attributes[cell_phone_2]'
          type='text'
          value={ cellPhone2 }
          onChange={ onChangeEventHandler('userProfile', 'cellPhone2') }/>
      </label>
      <label htmlFor="user_profile_attributes[date_of_birth]">
        Date of Birth
        <input
          className={ inputClass }
          name='user_profile_attributes[date_of_birth]'
          type='date'
          value={ dateOfBirth }
          onChange={ onChangeEventHandler('userProfile', 'dateOfBirth') }/>
      </label>
      <label htmlFor="user_profile_attributes[mail_t4]">
        Ability to Mail T4
        <input type="hidden" name="user_profile_attributes[mail_t4]" value={ false }/>
        <div className="h-[37px] flex items-center">
          <AnimatedCheckbox
            id='user_profile_attributes[mail_t4]'
            checked={ mailT4 }
            value={ true }
            onClick={ () => updateNestedUserState('userProfile', 'mailT4')(!mailT4) }
          />
        </div>
      </label>

      <h3 className="w-full py-3 text-2xl col-span-2">Emergency Contact</h3>
      <label htmlFor="user_profile_attributes[emergency_contact_last_name]">
        Last Name
        <input
          className={ inputClass }
          name='user_profile_attributes[emergency_contact_last_name]'
          type='text'
          value={ emergencyContactLastName }
          onChange={ onChangeEventHandler('userProfile', 'emergencyContactLastName') }/>
      </label>
      <label htmlFor="user_profile_attributes[emergency_contact_first_name]">
        First Name
        <input
          className={ inputClass }
          name='user_profile_attributes[emergency_contact_first_name]'
          type='text'
          value={ emergencyContactFirstName }
          onChange={ onChangeEventHandler('userProfile', 'emergencyContactFirstName') }/>
      </label>
      <label htmlFor="user_profile_attributes[emergency_contact_relationship]">
        Relationship
        <input
          className={ inputClass }
          name='user_profile_attributes[emergency_contact_relationship]'
          type='text'
          value={ emergencyContactRelationship }
          onChange={ onChangeEventHandler('userProfile', 'emergencyContactRelationship') }/>
      </label>
      <label htmlFor="user_profile_attributes[emergency_contact_cell_phone]">
        Cell Phone
        <input
          className={ inputClass }
          name='user_profile_attributes[emergency_contact_cell_phone]'
          type='text'
          value={ emergencyContactCellPhone }
          onChange={ onChangeEventHandler('userProfile', 'emergencyContactCellPhone') }/>
      </label>
    </div>
  )
}

export default UserProfile
