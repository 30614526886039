import React from 'react'
import Content from '../content'

const DefaultLayout = ({ sectionLayout, ContentComponent = Content }) => (
  <div className="max-w-[978px]">
    { sectionLayout.contentIds.map(contentId => <ContentComponent key={ contentId } contentId={ contentId }/>) }
  </div>
)

export default DefaultLayout
