import React from 'react'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary'
import { ListPlugin } from "@lexical/react/LexicalListPlugin"

import { ListItemNode, ListNode } from "@lexical/list"

import ToolbarPlugin from "./plugins/toolbarPlugin"
import { classNames } from "../../utilities/utils"
import theme from "./theme"
import LoadInitialContentPlugin from "./plugins/loadInitialContentPlugin"
import StoreAsHtmlPlugin from "./plugins/storeAsHtmlPlugin"
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin"

const placeholder = "Enter some text here..."

const onError = (error) => console.error(error)

const RichTextEditor = ({ className, initialValue, defaultContent, onChange }) => {
  const initialConfig = {
    namespace: 'ProDelta Rich Text Editor',
    nodes: [ListNode, ListItemNode],
    theme,
    onError,
  }

  return (
    <LexicalComposer initialConfig={ initialConfig }>
      <div className={ classNames("font-normal relative", className) }>
        <ToolbarPlugin defaultContent={ defaultContent }/>
        <div className="bg-white relative">
          <RichTextPlugin
            contentEditable={
              <ContentEditable
                className="min-h-[150px] resize-none caret-slate-950	outline-0	py-4 px-2.5 border border-t-0 border-slate-300 rounded-b-md"
                aria-placeholder={ placeholder }
              />
            }
            placeholder={ <div className={ theme.placeholder }>{ placeholder }</div> }
            ErrorBoundary={ LexicalErrorBoundary }
          />
          <HistoryPlugin/>
          <ListPlugin/>
          <LoadInitialContentPlugin initialContent={ initialValue }/>
          <StoreAsHtmlPlugin onChange={ onChange }/>
          <TabIndentationPlugin/>
        </div>
      </div>
    </LexicalComposer>
  )
}

export default RichTextEditor
