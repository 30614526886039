import React, { createContext, useCallback, useContext, useEffect, useMemo, useReducer, useRef } from "react"
import {
  createCsvVersion,
  createDocuments,
  createJsonVersion,
  createProjectDocuments,
  deleteDocuments,
  deleteProjectDocuments,
  getBasicSummary,
  getDocuments,
  getProjectDocuments,
  runPromptsOnProject,
  updateDocuments,
  updateProjectDocuments,
  uploadDocumentToOpenai,
  uploadProjectDocumentToOpenai
} from "../api/aiDocumentsApi"
import { notifyError } from "../components/shared/notice"
import {
  deleteAiDocuments,
  handleBroadcast,
  updateAiDocumentField,
  updateAiDocuments,
  updateAiDocumentsField,
  updateSelectAll,
  updateSelectedDocuments
} from "../utilities/aiDocumentHelpers"
import { useParams } from "react-router-dom"
import { useAiChatbotApi } from "./aiChatbotContext"
import { getThreadMessages } from "../api/aiPluginApi"
import openaiSocket from "../sockets/openaiSocket"
import { useAiDocumentsFilterAPI, useAiDocumentsFilterContext } from "./aiDocumentsFilterContext"
import { pluck } from "../utilities/utils"

/* Actions */
const ACTIONS = {
  UPDATE_STATE: 'UPDATE_STATE',
  TOGGLE_MODAL: 'TOGGLE_MODAL',
  UPDATE_DOCUMENT_FIELD: 'UPDATE_DOCUMENT',
  SELECT_DOCUMENT: 'SELECT_DOCUMENT',
  SELECT_ALL: 'SELECT_ALL',
  SELECT_ONE: 'SELECT_ONE',
  SELECT_MULTIPLE: 'SELECT_MULTIPLE',
  DELETE_ALL: 'DELETE_ALL',
  PROMPT_ALL: 'PROMPT_ALL',
  UPDATE_ACTIVE_TAB: 'UPDATE_ACTIVE_TAB',
}

/* Initial States */
const initialState = {
  selectedDocuments: new Set([]),
  loading: false,
  uploadFiles: new FormData(),
  modals: { update: false, upload: false, status: false, json: false },
  promptCollections: [],
  constantTabs: [
    { name: 'Documents', id: 'doc', current: false },
    { name: 'Prompt Collection', id: 'prompt', current: false },
  ],
  activeTab: { name: 'Documents', id: 'doc', current: true },
  trackerTemplates: [],
  selectedPromptCollection: {},
  statuses: [],
}

/* Reducer */
const aiDocumentsReducer = (state, action) => {
  switch (action?.type) {
    case ACTIONS.UPDATE_STATE:
      return { ...state, ...action }
    case ACTIONS.TOGGLE_MODAL:
      return { ...state, modals: { ...state.modals, [action.modalType]: !state.modals[action.modalType] } }
    case ACTIONS.SELECT_DOCUMENT:
      return { ...state, selectedDocuments: updateSelectedDocuments(state.selectedDocuments, action.documentId) }
    case ACTIONS.SELECT_ALL:
      return { ...state, selectedDocuments: updateSelectAll(action.documents, state.selectedDocuments) }
    case ACTIONS.SELECT_ONE:
      return { ...state, selectedDocuments: new Set([action.documentId]) }
    case ACTIONS.SELECT_MULTIPLE:
      return { ...state, selectedDocuments: new Set(action.documentIds) }
    case ACTIONS.DELETE_ALL:
      deleteDocuments([...state.selectedDocuments])
      return { ...state, selectedDocuments: new Set() }
    case ACTIONS.PROMPT_ALL:
      return { ...state, selectedDocuments: new Set() }
    case ACTIONS.UPDATE_DOCUMENT_FIELD:
      return { ...state, documents: updateAiDocumentField(action.id, action.field, action.value, state.documents) }
    case ACTIONS.UPDATE_ACTIVE_TAB:
      const newActiveTab = state.trackerTemplates.concat(state.constantTabs).find(template => template.id === action.activeTabId)
      return { ...state, activeTab: newActiveTab }
    default:
      return state
  }
}

/* API Methods */
const documentsApiMethods = (projectId) => ({
  fetchDocuments: async () => projectId ? getProjectDocuments(projectId) : getDocuments(),
  deleteDocuments: async (id) => projectId ? deleteProjectDocuments(projectId, id) : deleteDocuments(id),
  createDocuments: async (files) => projectId ? createProjectDocuments(projectId, files) : createDocuments(files),
  updateDocuments: async (ids, body) => projectId ? updateProjectDocuments(projectId, ids, body) : updateDocuments(ids, body),
  getBasicSummary: async (id) => getBasicSummary(id),
  openaiUpload: async (id) => projectId ? uploadProjectDocumentToOpenai(projectId, id) : uploadDocumentToOpenai(id),
});

/* Contexts */
const AiDocumentsContext = createContext(initialState)
const AiDocumentsApiContext = createContext({
  toggleModal: () => {},
  onUpload: () => {},
  onUpdate: () => {},
  onDelete: () => {},
  onGetBasicSummary: () => {},
  updateDocumentField: () => {},
  openaiUpload: () => {},
  selectDocument: () => {},
  selectAll: () => {},
  deleteAll: () => {},
  promptDocument: () => {},
  promptAll: () => {},
})

/* Providers */
export const AiDocumentsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(aiDocumentsReducer, initialState)
  const { projectId } = useParams()
  const { updateSelectedPromptCollection: chatbotPrompts, addMessage } = useAiChatbotApi()
  const socket = useRef(null)
  const { filteredDocuments: documents } = useAiDocumentsFilterContext()
  const { updateDocuments, addDocuments } = useAiDocumentsFilterAPI()

  const api = useMemo(() => documentsApiMethods(projectId), [projectId])

  const documentApi = useMemo(() => {

    const toggleModal = (modalType) => dispatch({ type: ACTIONS.TOGGLE_MODAL, modalType: modalType })

    const updatePromptCollections = (promptCollections) => dispatch({ type: ACTIONS.UPDATE_STATE, promptCollections })

    const updateSelectedPromptCollection = (selectedPromptCollection) =>
      dispatch({ type: ACTIONS.UPDATE_STATE, selectedPromptCollection })

    const updateTrackerTemplates = (trackerTemplates) => dispatch({ type: ACTIONS.UPDATE_STATE, trackerTemplates })

    const updateActiveTabById = (activeTabId) => dispatch({ type: ACTIONS.UPDATE_ACTIVE_TAB, activeTabId })

    const updateLoading = (loadingState) => dispatch({ type: ACTIONS.UPDATE_STATE, loading: loadingState })

    const runOcrOnDocument = documentId => createJsonVersion(documentId, projectId)
      .then(res => documentApi.updateDocumentField(documentId, "json_file_path", res.json_file_path))

    const selectDocument = documentId => dispatch({ type: ACTIONS.SELECT_DOCUMENT, documentId })

    const selectAll = () => dispatch({ type: ACTIONS.SELECT_ALL, documents: documents })

    const selectMultiple = (documentIds) => dispatch({ type: ACTIONS.SELECT_MULTIPLE, documentIds })

    const selectOne = documentId => dispatch({ type: ACTIONS.SELECT_ONE, documentId })

    const updateUploadFiles = (files) => { dispatch({ type: ACTIONS.UPDATE_STATE, uploadFiles: files }) }

    return {
      toggleModal, updateUploadFiles, updatePromptCollections, updateSelectedPromptCollection,
      updateTrackerTemplates, updateActiveTabById, updateLoading, runOcrOnDocument,
      selectDocument, selectAll, selectMultiple, selectOne
    }
  }, [documents])

  documentApi.promptDocument = useCallback(documentId => {
    createCsvVersion([documentId], projectId)
    updateDocuments(updateAiDocumentField(documentId, 'status', 'prompting', documents))
  }, [projectId, documents])

  documentApi.promptAll = () => {
    createCsvVersion([...state.selectedDocuments], projectId)
    updateDocuments(updateAiDocumentsField('status', 'prompting', documents, [...state.selectedDocuments]))
    dispatch({ type: ACTIONS.PROMPT_ALL })
  }

  documentApi.updateDocumentField = (id, field, value) => { updateDocuments(updateAiDocumentField(id, field, value, documents)) }

  documentApi.onUpload = (initJson) => {
    const body = state.uploadFiles
    body.append('init_json', initJson)

    api.createDocuments(body)
      .then((res) => {
        documentApi.toggleModal('upload')
        res.map(doc => addDocuments(doc))
      }).catch(() => {
      notifyError('Failed to update document')
    })
  }

  documentApi.deleteAll = () => {
    alert("Are you sure you would like to delete these documents?")
    updateDocuments(deleteAiDocuments([...state.selectedDocuments], documents))
    dispatch({ type: ACTIONS.DELETE_ALL })
  }

  documentApi.onDelete = (id) => {
    api.deleteDocuments([id]).then(() => {
      updateDocuments(deleteAiDocuments([id], documents))
    }).catch(() => {
      notifyError('Failed to delete document')
    })
  }

  documentApi.onGetBasicSummary = (aiDocumentId) => {
    updateDocuments(updateAiDocumentField(aiDocumentId, 'loading', true, documents))
    getBasicSummary(aiDocumentId).then((res) => {
      let updatedDocs = updateAiDocumentField(aiDocumentId, 'summary', res.summary, documents)
      updatedDocs = updateAiDocumentField(aiDocumentId, 'loading', false, updatedDocs)
      updateDocuments(updatedDocs)
    }).catch((res) => {
      notifyError(res.response.data.errors)
      updateDocuments(updateAiDocumentField(aiDocumentId, 'loading', false, documents))
    })
  }

  documentApi.openaiUpload = (id) => {
    updateDocuments(updateAiDocumentField(id, 'loading', true, documents))
    api.openaiUpload(id).then((res) => {
      const updatedDocs = updateAiDocumentField(id, 'openai_id', res.openai_id, documents)
      updateDocuments(updatedDocs)
    }).catch((res) => {
      notifyError(res.response.data.errors)
    })
    updateDocuments(updateAiDocumentField(id, 'loading', true, documents))
  }

  documentApi.updateStatus = (status) => {
    api.updateDocuments([...state.selectedDocuments], { status: status }).then(() => {
      updateDocuments(updateAiDocumentsField('status', status, documents, [...state.selectedDocuments]))
      dispatch({ type: ACTIONS.UPDATE_STATE, selectedDocuments: new Set() })
    }).catch(() => {
      notifyError('Failed to update documents')
    })
  }

  documentApi.updateJson = (body) => {
    api.updateDocuments([...state.selectedDocuments], body).then(documentRes => {
      updateDocuments(updateAiDocuments(documents, documentRes))
      dispatch({ type: ACTIONS.UPDATE_STATE, selectedDocuments: new Set() })
    }).catch(() => {
      notifyError('Failed to update documents')
    })
  }

  documentApi.runPrompts = (promptCollection) => {
    documentApi.updateLoading(true)
    runPromptsOnProject(projectId, promptCollection.id).then((res) => {
      documentApi.updateSelectedPromptCollection(promptCollection)
      chatbotPrompts(promptCollection)

      return {
        promptsResponse: res,
        threadMessages: getThreadMessages(projectId)
      }
    })
      .then(({ promptsResponse, threadMessages }) => {
        return threadMessages.then((messages) => {
          return {
            promptsResponse: promptsResponse,
            messages: messages
          }
        })
      })
      .then(({ promptsResponse, messages }) => {
        messages.forEach((message) => {
          addMessage(message.content, message.isResponse)
        })
        addMessage(promptsResponse, true)
      })
      .catch(() => {
        notifyError('Failed to run prompts')
      })
      .finally(() => {
        documentApi.updateLoading(false)
      })
  }

  // Initialize the context
  useEffect(() => {
    api.fetchDocuments().then((res) => {
      // TODO: GraphQl, replace this query
      documentApi.updatePromptCollections(res.promptCollections)
      documentApi.updateTrackerTemplates(res.trackerTemplates)
      dispatch({ type: ACTIONS.UPDATE_STATE, statuses: res.statuses })
    }).catch(() => {
      notifyError('Failed to fetch documents')
    })
    if (projectId) socket.current = openaiSocket({
      onMessage: handleBroadcast,
      id: projectId,
      channel: "Openai::GenerateCsvChannel"
    })
  }, [])

  useEffect(() => {
    const ids = pluck(documents, "id")
    const selectedDocumentIds = [...state.selectedDocuments]
    const elementsThatExistInBoth = selectedDocumentIds.filter(documentId => ids.includes(documentId))
    if (elementsThatExistInBoth.length !== selectedDocumentIds.length) {
      documentApi.selectMultiple(elementsThatExistInBoth)
    }
  }, [state.selectedDocuments, documents])

  return (
    <AiDocumentsContext.Provider value={ state }>
      <AiDocumentsApiContext.Provider value={ documentApi }>
        { children }
      </AiDocumentsApiContext.Provider>
    </AiDocumentsContext.Provider>
  )
}

/* Hooks */
export const useAiDocumentsContext = () => useContext(AiDocumentsContext)
export const useAiDocumentsAPI = () => useContext(AiDocumentsApiContext)
