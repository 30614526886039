import React, { useMemo } from "react"
import { useTimesheetContext } from "../../contexts/timesheetContext"
import { formatDateString, getWeekEnd } from "../../utilities/dateHelpers"
import { pipe } from "../../utilities/utils"

const TimesheetBreadcrumbs = () => {
  const { organization, weekStart } = useTimesheetContext()
  const weekEnd = useMemo(() => pipe(getWeekEnd, formatDateString)(weekStart), [weekStart])

  return <div className="flex gap-8 items-center">
    <h1 className="text-3xl font-bold">{organization?.name} Timesheet</h1>
    <p className="text-3xl">{formatDateString(weekStart)} - {weekEnd}</p>
  </div>
}

export default TimesheetBreadcrumbs
