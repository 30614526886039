import React, { useState } from 'react'
import DropDown from '../../shared/dropdown'

const DataCollectionDropdown = ({ dataCollectionArray }) => {
  const [selectedHref, setSelectedHref] = useState('')
  const mousePointer = selectedHref ? {} : { pointerEvents: 'none' }

  return (
    <>
      <div>
        <p className="py-2">Data Collection</p>
        <DropDown options={ dataCollectionArray } styles='mb-5' handleChange={ setSelectedHref }/>
        <div className="w-full flex justify-end ">
          <a
            href={ selectedHref }
            className='btn btn-primary truncate w-full text-center w-min text-sm py-1.5 px-3 '
            style={ mousePointer }
            rel="noopener noreferrer"
            target="_blank"
          >
            View Data
          </a>
        </div>
      </div>
    </>
  )
}

export default DataCollectionDropdown
