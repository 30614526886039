export const TOGGLE_CHANGE_OF_CONTROL = 'TOGGLE_CHANGE_OF_CONTROL'
export const UPDATE_SELECTED_SAMPLES = 'UPDATE_SELECTED_SAMPLES'
export const UPDATE_SELECTED_CHEMICALS = 'UPDATE_SELECTED_CHEMICALS'
export const UPDATE_PROJECT_INFORMATION = 'UPDATE_PROJECT_INFORMATION'
export const UPDATE_INVOICE_TO = 'UPDATE_INVOICE_TO'
export const UPDATE_REPORT_TO = 'UPDATE_REPORT_TO'
export const UPDATE_LAB_INFORMATION = 'UPDATE_LAB_INFORMATION'
export const UPDATE_ADDITIONAL_REPORT_TO = 'UPDATE_ADDITIONAL_REPORT_TO'
export const UPDATE_SAMPLE_CUSTODY = 'UPDATE_SAMPLE_CUSTODY'
export const UPDATE_NOTES = 'UPDATE_NOTES'
export const UPDATE_RUSH_PRIORITY = 'UPDATE_RUSH_PRIORITY'
export const UPDATE_REPORT_RESULTS = 'UPDATE_REPORT_RESULTS'
export const UPDATE_REQUIREMENTS = 'UPDATE_REQUIREMENTS'
export const SET_ALL_SAMPLES = 'SET_ALL_SAMPLES'
export const UPDATE_EXPORT_HISTORY = 'UPDATE_EXPORT_HISTORY'
export const SET_SELECTED_SAMPLES_COUNT = 'SET_SELECTED_SAMPLES_COUNT'
export const FILTER_SAMPLES = 'FILTER_SAMPLES'
export const UPDATE_LAB_FILTERS = 'UPDATE_LAB_FILTERS'
export const SET_NUMBER_OF_COOLERS = 'SET_NUMBER_OF_COOLERS'
export const UPDATE_EMAIL_INFO = 'UPDATE_EMAIL_INFO'
export const SET_LABS = 'SET_LABS'
export const UPDATE_HEADER_DETAILS = 'UPDATE_HEADER_DETAILS'
