import React, { useCallback, useState } from "react"
import { buttonClass } from "../../utilities/profileHelpers"
import { readAsDataURL } from "../../utilities/utils"

const ImageSelect = ({ originalImage, setImage, htmlFor, required = false, title = '' }) => {
  const [imagePreview, setImagePreview] = useState(originalImage)

  const onChangeHandler = useCallback((event) => {
    setImage(event.target.files[0])
    readAsDataURL(event.target.files[0])
      .catch(() => null)
      .then(preview => preview && setImagePreview(preview))
  }, [setImage])

  return (
    <label htmlFor={htmlFor} className="flex flex-row items-center justify-start w-full">
      { title && <span className="mr-2"><strong>{title}: </strong></span> }
      <input
        onChange={onChangeHandler}
        className="opacity-0 w-0 h-0 peer"
        name={htmlFor}
        accept="image/*, application/pdf"
        type="file"
        id={htmlFor}
        required={required && !originalImage}
      />
      <div className="h-28 min-w-28 w-28 rounded-full overflow-hidden bg-[#DDD] relative">
        {!!imagePreview && (
          <img
            src={imagePreview}
            alt={htmlFor}
            className="object-cover w-full h-full"
          />
        )}
      </div>
      <button type="button" className={buttonClass}>
        <label htmlFor={htmlFor}>Choose File</label>
      </button>
      {required && !originalImage && <span className="text-red-500 ml-2">*Required</span>}
    </label>
  )
}

export default ImageSelect
