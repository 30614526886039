import React from "react"

const ProfileNav = ({ user }) => {
  return <>
    <a className='flex items-center gap-x-2 cursor-pointer group' href="/users/landing_page">
      <div className="object-cover h-[30px] w-[30px] rounded-full overflow-hidden bg-gray-200 group-hover:bg-gray-300 relative">
        {user?.headshot_path && (
          <img height={30} width={30} src={user?.headshot_path} className="object-cover w-full h-full" />
        )}
      </div>
      <span className='hidden lg:block'>{user?.human_identifier}</span>
    </a>
  </>
}

export default ProfileNav
