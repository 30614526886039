import React from "react";
import Select from "react-select";

const FormEntrySelect = ({ formField, setValue, value }) => {
  const options = formField.options.map(option => {
    return { label: option, value: option }
  })

  return <>
    <Select value={{ label: value, value: value }}
            options={options}
            onChange={(selectedOption) => setValue(selectedOption.value)}
            className='block w-full p-1 text-xs text-gray-700 appearance-none h-16 m-0'
    />
  </>
}
export default FormEntrySelect
