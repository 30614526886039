import React, { useMemo } from "react"
import StaticContent from "./content/staticContent"
import { useDocumentBuilderContext } from "../../contexts/documentBuilderContext"
import ImageContent from "./content/image"

const componentSwitch = currentLayoutType => {
  switch (currentLayoutType) {
    case 'Image':
      return ImageContent
    default:
      return StaticContent
  }
}

const Content = ({ contentId }) => {
  const { contents } = useDocumentBuilderContext()
  const content = useMemo(() => contents[contentId], [contentId, contents])
  const ContentComponent = useMemo(() => componentSwitch(content.type), [content])

  return <ContentComponent contentId={ contentId } content={ content }/>
}

export default Content
