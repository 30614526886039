import React from 'react'
import TicketsTable from './ticketsTable'
import { useSafetyTicketContext } from '../../contexts/safetyTicketContext'
import UserSelect from './userSelect'

const UserTable = () => {
  const { selectedUsers, user } = useSafetyTicketContext()
  return (
    <div className="mb-32">
      <TicketsTable user={user} />
      <UserSelect />
      {selectedUsers.map(user => (
        <TicketsTable key={user.id} user={user} />
      ))}
    </div>
  )
}

export default UserTable
