import API, { simpleGet } from "./apiConfig"

export const getPortfolios = () => simpleGet('/portfolios.json')

export const getPortfolio = (portfolioID) => simpleGet(`/portfolios/${portfolioID}.json`)

export const getPortfolioProjects = (portfolioID) => simpleGet(`/portfolios/${portfolioID}/projects.json`)

export const getPortfolioWidgets = (portfolioID) => simpleGet('/widgets', { params: { portfolio_id: portfolioID } })

export const adminGetPortfolios = () => simpleGet('/admin/portfolios.json')

export const getPortfolioFormTemplates = (portfolioID) => simpleGet(`/portfolios/${portfolioID}/form-templates.json`)

export const assignTemplates = (portfolioID, templateIds) => {
  const csrfToken = document.querySelector('[name=csrf-token]').content
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken

  return API
    .put(`/admin/portfolios/${portfolioID}.json`, { form_template_ids: templateIds })
    .then(res => res.data)
}

export const getAdminFormTemplates = () => simpleGet('/admin/form_templates.json')

export const getAdminPortfolioFormTemplates = (portfolioId) => {
  return simpleGet('/admin/portfolio/form-templates.json', { params: { portfolio_id: portfolioId } })
}

export const getPortfolioTrackerTemplates = (portfolioId) => simpleGet(`/portfolios/${portfolioId}/tracker_templates.json`)

export const getAdminPortfolioTrackerTemplates = (portfolioId) => {
  return simpleGet('/admin/portfolio/tracker_templates.json', { params: { portfolio_id: portfolioId } })
}

export const getPortfolioProjectFormTemplates = (portfolioID) => {
  return simpleGet(`/portfolios/${portfolioID}/project_form_templates.json`)
}

export const getPortfolioChecklistTemplates = (portfolioId) => simpleGet(`/portfolios/${portfolioId}/checklist_templates.json`)

export const getAdminPortfolioChecklistTemplates = (portfolioId) => {
  return simpleGet('/admin/portfolio/checklist_templates.json', { params: { portfolio_id: portfolioId } })
}
