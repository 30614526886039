import React, { forwardRef } from "react"
import { useTrackerAPI, useTrackerContext } from "../../../contexts/trackerContext"
import { notifyError } from "../../shared/notice"

const WholeNumberInput = forwardRef(({ placeholder = '0', step = '1' }, inputRef) => {
  const { editCellContent } = useTrackerContext()
  const { onContentChange } = useTrackerAPI()

  const handleKeyDown = (e) => {

    if (
      !/^\d$/.test(e.key) &&
      e.key !== "Backspace" &&
      e.key !== "Delete" &&
      e.key !== "Enter" 
    ) {
      e.preventDefault()
      notifyError("This cell only accepts whole numbers!")
    }
  }

  const handleChange = (e) => {
    const inputValue = e.target.value
    onContentChange(inputValue)
  }

  return (
    <input
      className='block w-full p-1 text-xs text-gray-700 appearance-none focus:outline-none border border-gray-200 focus:shadow-inner m-0 rounded'
      type='number'
      step={step}
      min="0"
      ref={inputRef}
      placeholder={placeholder}
      value={editCellContent}
      onKeyDown={handleKeyDown}
      onChange={handleChange}
    />
  )
})

export default WholeNumberInput
