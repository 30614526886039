import React, { useEffect, useState } from "react"
import Select from "react-select"
import { useProjectContext } from "../../../contexts/projectContext"
import { useDocumentBuilderApiContext } from "../../../contexts/documentBuilderContext"
import { getPortfolioUsers } from "../../../api/userApi"
import { userIdentifier } from "../../../utilities/userHelpers"
import { returnInitialUserOption } from "../../../utilities/documentBuilderHelpers"

const UserSelect = ({ contentId, content }) => {
  const { updateContent } = useDocumentBuilderApiContext()
  const { portfolio } = useProjectContext()
  const [options, setOptions] = useState([])
  const [currentOption, setCurrentOption] = useState({})

  useEffect(() => {
    currentOption && updateContent(contentId, 'documentContent', currentOption.label)
  }, [contentId, currentOption])
  
  useEffect(() => {
    getPortfolioUsers(portfolio.id)
      .then(response => response.map(user => ({ value: user.id, label: userIdentifier(user) })))
      .then(async userOptions => {
        setOptions(userOptions)
        setCurrentOption(await returnInitialUserOption(userOptions, content) || null)
      })
  }, [])

  return (
    <div className="w-full flex flex-col gap-2">
      <Select value={ currentOption } options={ options } onChange={ setCurrentOption } placeholder="Choose User"/>
    </div>
  )
}

export default UserSelect
