import React, { useState, useEffect, useRef, useMemo } from "react"
import { singleOptionize, sitesList, tableStyles } from "./historicalAssessmentHelper"
import Select from "react-select"

const SiteDropdown = ({ rowNameStub, initialValue, otherValue }) => {
  const [textFieldActive, setTextFieldActive] = useState(false)
  const [siteChosen, setSiteChosen] = useState( singleOptionize(initialValue) )
  const defaultOption = useMemo(() => singleOptionize(initialValue), [initialValue] )
  const textInputRef = useRef(null)

  useEffect(() => {
    if (siteChosen?.label === 'Other') setTextFieldActive(true)
    if (textFieldActive && siteChosen?.label !== 'Other') setTextFieldActive(false)
  }, [siteChosen])

  useEffect(() => {
    if (textFieldActive && !otherValue) textInputRef.current.focus()
  }, [textFieldActive])

  return (
    <>
      <div className={tableStyles.tdContent}>
        <Select
          menuPortalTarget={document.body}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
          className="capitalize w-64 border-gray-900"
          placeholder={'Select'}
          options={sitesList}
          name={`${rowNameStub}[sites]`}
          onChange={setSiteChosen}
          defaultValue={ defaultOption }
        />
      </div>
      {textFieldActive && (
        <div className={`mt-6 border-gray-300 ${tableStyles.tdContent}`}>
          <input
            type="text"
            ref={textInputRef}
            name={`${rowNameStub}[sites-other]`}
            className="w-full p-2 border border-gray-300 border-solid rounded-sm"
            defaultValue={otherValue || ''}
          />
        </div>
      )}
    </>
  )
}

export default SiteDropdown
