import React from 'react'
import LightBox from './photo/lightBox'
import { usePhotoContext } from '../../contexts/photoContext'
import DateGroup from './dateGroup'
import LoadingSpinner from '../shared/loadingSpinner'
import PhotoFilters from './photoFilters'
import PhotoExportToggleSwitch from './photoExportToggleSwitch'
import ExportPhotosButton from './exportPhotosButton'
import ExportHistory from '../photoGalleryHistory'
import PhotoGalleryPageBreadcrumbs from './photoGalleryBreadcrumbs'

const PhotoGallery = () => {
  const { photoGroups, loading } = usePhotoContext()
  return (
    <>
    {(!photoGroups.length && !loading) && <div className='text-center text-9xl w-full'>
      No photos found
      </div>}
    {(photoGroups.length || loading) && <>
      <PhotoGalleryPageBreadcrumbs />
      <div className='header'>
        <h1>Photo Gallery</h1>
      </div>
      {loading && <div className='text-center text-9xl w-full'>
        Loading...<LoadingSpinner />
      </div>}
      {!loading && (
        <div className="w-full flex flex-row items-start justify-between h-full">
          <div className='grid gap-x-1 pr-2 overflow-y-scroll h-full w-9/12'>
            {photoGroups.map((dateGroup, i) => <DateGroup photoDateGroup={dateGroup} key={i} />)}
          </div>
          <div className='w-1/3 overflow-y-scroll h-full'>
            <div className='border border-blue-500 p-4 rounded-lg m-4 flex flex-col items-center'>
              <PhotoExportToggleSwitch />
              <ExportPhotosButton />
            </div>
            <PhotoFilters />
            <ExportHistory />
          </div>
          <LightBox />
        </div>
      )}
    </>}
    </>
  )
}

export default PhotoGallery
