import React from "react"

const ChecklistTemplate = ({ portfolio, checklistTemplate }) => {
  const urlHost = window.location.origin

  return <>
    <tr className='border-t border-gray-400 even:bg-gray-200'>
      <td className='px-2 py-3 w-1/6'>{checklistTemplate.name}</td>
      <td className='px-2 py-3'>{`${urlHost}/api/portfolios/${portfolio.id}/checklists/${checklistTemplate.slug}/geo_json.geojson`}</td>
      <td className='px-2 py-3'>{`${urlHost}/api/portfolios/${portfolio.id}/checklists/${checklistTemplate.slug}.json`}</td>
    </tr>
  </>
}

export default ChecklistTemplate
