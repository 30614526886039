import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ChecklistProvider } from '../../contexts/checklistContext'
import { PortfolioProvider } from "../../contexts/portfolioContext"
import { TrackerProvider } from "../../contexts/trackerContext"
import { ReportProvider } from "../../contexts/reportContext"
import { PhotoProvider } from '../../contexts/photoContext'
import Portfolios from "./portfolios"
import Portfolio from "./portfolio"
import Tracker from "../trackers"
import TrackerChangeLog from "../trackers/changeLog"
import Checklist from '../checklists'
import Report from "../reports"
import ReportsExport from "../reports/reports-export"
import PhotoGallery from '../photoGallery'

export default function MainPortfolio() {
  return (
    <Router>
      <Routes>
        <Route path='/portfolios/:portfolioId/trackers/:trackerSlug/change-log'
          element={
            <PortfolioProvider>
              <TrackerProvider>
                <TrackerChangeLog />
              </TrackerProvider>
            </PortfolioProvider>
          }
        />
        <Route path='/portfolios/:portfolioId/trackers/:trackerSlug'
          element={
            <PortfolioProvider>
              <TrackerProvider>
                <Tracker />
              </TrackerProvider>
            </PortfolioProvider>
          }
        />
        <Route path='/portfolios/:portfolioId/checklists/:checklistSlug'
          element={
            <PortfolioProvider>
              <ChecklistProvider>
                <Checklist />
              </ChecklistProvider>
            </PortfolioProvider>
          }
        />
        <Route path='/portfolios/:portfolioId/reports/:reportSlug'
          element={
            <PortfolioProvider>
              <ReportProvider>
                <Report />
              </ReportProvider>
            </PortfolioProvider>
          }
        />
        <Route path='/portfolios/:portfolioId/photo_gallery'
          element={
            <PortfolioProvider>
              <PhotoProvider>
                <PhotoGallery />
              </PhotoProvider>
            </PortfolioProvider>
          }
        />
        <Route path='/portfolios/:portfolioId' element={<PortfolioProvider><Portfolio /></PortfolioProvider>} />
        <Route path='/portfolios/:portfolioId/reports-export' element={<PortfolioProvider><ReportsExport /></PortfolioProvider>} />
        <Route path={'/portfolios?'} element={<Portfolios />} />
      </Routes>
    </Router>
  )
}
