import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { useSiteInspectionReports, useSiteInspectionReportsApi } from "../../contexts/siteInspectionReports";

const HeaderDateField = ({ title }) => {
  const [selectedDate, setSelectedDate] = useState(null)
  const { onInputChange } = useSiteInspectionReportsApi()
  const { date } = useSiteInspectionReports()

  useEffect(() => {
    if (date) setSelectedDate(new Date(date.split('-')))
  }, [date])

  const handleChange = (date) => {
    setSelectedDate(date)
    onInputChange('date', format(date, 'MM-dd-yyyy'))
  }

  return(
    <div className='flex flex-row items-center justify-between'>
      <label className='text-lg font-medium w-1/2'>{title}:</label>
      <DatePicker
        className='border border-gray-400 bg-gray-200 focus:bg-white rounded-md outline-none text-black p-2 w-full z-1000'
        dateFormat="MM-dd-yyyy"
        selected={selectedDate}
        onChange={date => handleChange(date)}
      />
    </div>
  )
}

export default HeaderDateField
