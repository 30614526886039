import React, { useState, useEffect } from 'react'
import { SafetyTicketProvider } from '../../contexts/safetyTicketContext'
import { useUserContext } from '../../contexts/userContext'
import UserTable from './userTable'
import UnavailableFeature from '../shared/unavailableFeature'
const SafetyTicket = () => {
  const { user } = useUserContext()
  const [enabled, setEnabled] = useState(true)
  const [isLoaded, setIsLoaded] = useState(false)
  
  useEffect(() => {
    if (Object.keys(user).length !== 0) {
      setEnabled(user?.organization?.safety_ticket_enabled)
      setIsLoaded(true)
    }
  }, [user])

  return (<>
    {!isLoaded && <div>Loading...</div>}
    {isLoaded && <>
      {!!!enabled && <UnavailableFeature featureName='Safety Tickets' />}
      {enabled &&
        <SafetyTicketProvider user={user}>
          <h1 className="text-3xl font-bold m-4">Safety Tickets</h1>
          <UserTable />
        </SafetyTicketProvider>
      }
    </>
    }
  </>
  )
}

export default SafetyTicket
