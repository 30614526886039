import React, { forwardRef } from "react"
import { useTrackerAPI, useTrackerContext } from "../../../contexts/trackerContext"

const TextInput = forwardRef(({}, inputRef) => {
  const { editCellContent } = useTrackerContext()
  const { onContentChange } = useTrackerAPI()

  return (
    <textarea
      className='block w-full p-1 text-xs text-gray-700 appearance-none focus:outline-none border border-gray-200 focus:shadow-inner m-0 rounded'
      type='text'
      ref={inputRef}
      value={editCellContent}
      onChange={e => onContentChange(e.target.value)}
    />
  )
})

export default TextInput
