import React, { useState } from 'react'
import { useAiDocumentsAPI } from '../../../../contexts/aiDocumentsContext'

const FileUploadInput = () => {
  const [chosenFileNames, setChosenFileNames] = useState([])
  const { updateUploadFiles } = useAiDocumentsAPI()

  const handleFileChange = (event) => {
    const files = event.target.files
    setChosenFileNames(Array.from(files).map(file => file.name))
    const formData = new FormData()
    for (const file of files) {
      formData.append('files[]', file, file.name)
    }
    updateUploadFiles(formData)
  }

  return (
    <div className='my-3 w-full flex flex-row justify-between items-center'>
      <label className="h-min btn btn-primary btn-outline font-semibold relative cursor-pointer z-10">
        Upload File
        <input type="file" onChange={ handleFileChange } accept=".pdf"
               className="opacity-0 absolute inset-0 z-0 cursor-pointer top-full h-px" required multiple/>
      </label>
      <ul>
        { chosenFileNames.map((fileName, i) => <li key={ i }>{ fileName }</li>) }
      </ul>
    </div>
  )
}

export default FileUploadInput
