import React from 'react'
import { useCocExport } from "../../../contexts/cocExport"
import HeaderInput from "./HeaderInput"

const DeliveryFields = ({ title, field, update }) => {
  const { headerDetails } = useCocExport()

  return(
    <div className='flex flex-col gap-y-2 py-4'>
      <h1 className="pb-2">{title}:</h1>
      <HeaderInput title={'Company'} field={'company'} value={headerDetails[field].company} update={update} />
      <HeaderInput title={'Address'} field={'address'} value={headerDetails[field].address} update={update} />
      <HeaderInput title={'Phone'} field={'phone'} value={headerDetails[field].phone} update={update} />
      <HeaderInput title={'Fax'} field={'fax'} value={headerDetails[field].fax} update={update} />
      <HeaderInput title={'Email'} field={'email'} value={headerDetails[field].email} update={update} />
    </div>
  )
}

export default DeliveryFields
