import React, { useState } from 'react'
import SideBar from "./sidebar"
import Widget from "./widget";

const LandingPage = ({ project }) => {
  const [widget, setWidget] = useState(project?.default_widget?.full_url)

  const changeWidget = (newWidget) => {
    setWidget(newWidget)
  }

  return (
    <div className='flex flex-col lg:flex-row'>
      <SideBar changeWidget={changeWidget} />
      <Widget widgetProp={widget} project={project} />
    </div>
  )
}

export default LandingPage
