import React, { useReducer } from 'react'
import { tableStyles } from '../../utilities/stylesHelpers'
import { usePhotoContext } from '../../contexts/photoContext'
import ExportHistoryRow from './exportHistoryRow'
import HistoryModal from './historyModal'

const ExportHistory = () => {
  const { photoExports } = usePhotoContext()
  const onModalToggle = ({ modalType }) => {
    if (modalType === 'history') toggleHistoryModal()
  }
  const [historyModal, toggleHistoryModal] = useReducer(state => !state, false)
  return (
    <>
      <h1 className="text-2xl font-bold text-center mt-4">Export History</h1>
      <div className="m-4">
        <table className={ tableStyles.table }>
          <thead className={ tableStyles.tableHeaderRow + " bg-gray-700 text-white" }>
          <tr className={ tableStyles.tableHeaderRow }>
            <th className={ "border-gray-200 border border-b-2 align-top sticky top-0 p-4" }>Name</th>
            <th className={ "border-gray-200 border border-b-2 align-top sticky top-0 p-4" }>Date Generated</th>
            <th className={ "border-gray-200 border border-b-2 align-top sticky top-0 p-4" }>Actions</th>
          </tr>
          </thead>
          <tbody>
          { photoExports?.slice().reverse().map(exportObject =>
            <ExportHistoryRow exportObject={ exportObject } toggleModal={ onModalToggle } key={ exportObject.id }/>
          ) }
          </tbody>
        </table>
      </div>
      { historyModal && (
        <HistoryModal
          modal={ historyModal }
          toggleModal={ onModalToggle }
        />
      ) }
    </>
  )
}

export default ExportHistory
