import React, { useCallback } from "react"
import { Link, useParams } from "react-router-dom"
import { pathBuilder } from "../../api/documentLogApi"
import { useDocumentBuilderApiContext, useDocumentBuilderContext } from "../../contexts/documentBuilderContext"
import GenerateFilesButton from "../documentLog/generateFilesButton"
import { downloadButtonStyles } from "../../utilities/documentLogHelpers"
import { newDocument } from "../../utilities/documentBuilderHelpers"

const buttonStyles = "btn btn-primary my-4 font-semibold cursor-pointer inline-block"

const ActionsNav = () => {
  const { projectId, templateSlug, documentId } = useParams()
  const { document, dataSources } = useDocumentBuilderContext()
  const { updateState, toggleModal } = useDocumentBuilderApiContext()

  const updateDocument = useCallback(document => updateState('document', newDocument(document)), [updateState])

  return (
    <div className="flex justify-end gap-4 w-full sticky top-0 bg-white z-10 shadow-lg px-4">
      <GenerateFilesButton
        document={ document }
        templateSlug={ templateSlug }
        updateDocument={ updateDocument }
        classes="my-4"/>
      <a href={ document.pdfFilePath }
         className={ downloadButtonStyles(!document.pdfFilePath, buttonStyles) }
         target="_blank" rel="noreferrer noopener"
      >
        Download PDF
      </a>
      <a href={ document.docxFilePath }
         className={ downloadButtonStyles(!document.docxFilePath, buttonStyles) }
         target="_blank" rel="noreferrer noopener"
      >
        Download Word
      </a>
      <Link
        to={ pathBuilder({ projectId, templateSlug, documentId, action: 'edit' }) }
        className={ buttonStyles }
      >
        Edit Document
      </Link>
    </div>
  )
}

export default ActionsNav
