import React from "react";

const FormEntryRadioGroup = ({ formField, formEntry, submitField }) => {
  return <div className='flex flex-col'>
    {
      formField.options.map(option => {
        return <div key={option} className='flex flex-row items-center gap-x-2'>
          <input type='radio' value={option} checked={formEntry.value === option} id={`option_${option}`}
                 onChange={(e) => submitField(e.target.value)} /><label htmlFor={`option_${option}`}>{option}</label>
        </div>
      })
    }
  </div>
}

export default FormEntryRadioGroup
                                                           
