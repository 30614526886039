import React, { createContext, useContext, useEffect, useMemo, useReducer } from "react"
import { isEmpty } from "lodash"
import { getHistoricalAssessment } from "../api/historicalAssessmentApi"
import { cycleResponse } from "../components/historicalAssessment/historicalAssessmentHelper"

/* In File Helpers */
let accId = 0
const inqId = () => ++accId

/* Actions */
const UPDATE_STATE = "UPDATE_STATE"
const ADD_ROW = "ADD_ROW"

/* Initial State */
export const initialApecRow = () => {
  return {
    id: inqId(),
    apec: null,
    betx: null,
    hydrocarbons: null,
    polyAromaticHydrocarbons: null,
    salinity: null,
    metals: null,
    other: null,
    boreholes: null,
    minDepth: null,
    maxDepth: null
  }
}
const initialState = (initialProject) => ({
  project: initialProject,
  assessmentRows: [],
})

/* Reducer */
const historicalAssessmentReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_STATE:
      return { ...state, [action.field]: action.value }
    case ADD_ROW:
      return {
        ...state,
        assessmentRows: [
          ...state.assessmentRows,
          {
            id: inqId(),
            ...action.payload,
          }
        ]
      }
    default:
      return state
  }
}

/* Contexts */
const HistoricalAssessmentContext = createContext(initialState)
const HistoricalAssessmentAPIContext = createContext({
  updateState:()=>{},
  addRow:()=>{},
  addBlankRow:()=>{},
  setRows:()=>{},
})

/* Providers */
export const HistoricalAssessmentProvider = ({ children, project }) => {
  const [state, dispatch] = useReducer(historicalAssessmentReducer, initialState(project), i=>i)

  const api = useMemo(() => {
    const updateState = (field, value) => dispatch({ type: UPDATE_STATE, field, value })

    const addRow = (newRow) => dispatch({ type: ADD_ROW, payload: newRow })

    const addBlankRow = () => addRow( initialApecRow() )

    const setRows = (rows) => api.updateState('assessmentRows', rows)

    const updateRows = (inputCollection) => cycleResponse(inputCollection, api.addRow)

    return { updateState, addRow, addBlankRow, setRows, updateRows }
  }, [])

  useEffect(() => {
    getHistoricalAssessment(project.id)
      .then(res => {
        if (!isEmpty(res)) {
          api.updateRows(res)
        } else {
          api.addBlankRow()
        }
      })
  }, [])

  return (
    <HistoricalAssessmentAPIContext.Provider value={api}>
      <HistoricalAssessmentContext.Provider value={state}>
        {children}
      </HistoricalAssessmentContext.Provider>
    </HistoricalAssessmentAPIContext.Provider>
  )
}

/* Custom Context Hooks */
export const useHistoricalAssessmentContext = () => useContext(HistoricalAssessmentContext)
export const useHistoricalAssessmentAPI = () => useContext(HistoricalAssessmentAPIContext)
