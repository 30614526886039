import React from 'react'
import Select from 'react-select'
import { useSampleCollectionApi } from '../../../../contexts/sampleCollectionContext'
import { inputClass } from "../../../../utilities/stylesHelpers"

const EventSelect = () => {
  const { updateNestedState } = useSampleCollectionApi()

  const onColumnSelect = (selectedOption) => {
    updateNestedState('sampleCollection', 'event_type')(selectedOption?.value)
  }

const valueOptions = [{value: 'Site Assessment', label: 'Site Assessment'},
{value: 'Confirmatory Sampling', label: 'Confirmatory Sampling'},
{value: 'Spill Response', label: 'Spill Response'},
{value: 'Waste Classification', label: 'Waste Classification'},
{value: 'other', label: 'other'}]

  return (
    <>
      <label htmlFor="event_type" className="flex flex-row items-center justify-start w-full">
        <Select
          name="event_type"
          options={valueOptions}
          onChange={onColumnSelect}
          className={inputClass}
          isClearable='true'
          placeholder={'Choose a event type'}
        />
      </label>
    </>
  )
}

export default EventSelect
