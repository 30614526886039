import React from "react"
import { cellColors } from "../../../utilities/trackerHelpers"
import { tableStyles } from "../../../utilities/stylesHelpers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit } from "@fortawesome/free-solid-svg-icons"

const DefaultCell = ({ content, styler, cellColor = 'white', onClickHandler, collapsed = false  }) => {
  const tdTextStyles = collapsed ? tableStyles.tdTextTruncate : tableStyles.tdText
  const tdStyles = collapsed ? tableStyles.tdCollapsed : tableStyles.td
  return (
    <td className={tdStyles} style={{ backgroundColor: cellColors[cellColor], ...styler }} onClick={onClickHandler}>
      <p className={tdTextStyles} style={{ backgroundColor: cellColors[cellColor] }}>{content}</p>
      <FontAwesomeIcon
        className={tableStyles.hoverIcon}
        icon={faEdit}
      />
    </td>
  )
}

export default DefaultCell
