import {
  INIT,
  LOADING_TRUE,
  SET_PERMITS,
  SET_NOTES,
  SET_SYNCED_AT,
  SET_SYNC_ENABLED,
  SET_SHOW_MAP,
  SET_AWAITING_JOBS,
  SET_PORTFOLIO,
  SET_GIS_PDF_TEMPLATES,
  SET_ROUTES
} from "../actions/projectActions"

/* Reducer */
const projectReducer = (state, action) => {
  switch(action?.type) {
    case INIT:
      return {
        ...state,
        routes: action.payload.routes,
        selectedNoteId: action.payload.selectedNoteId,
        project: action.payload.project,
        syncEnabled: action.payload.syncEnabled,
      }
    case LOADING_TRUE:
      return { ...state, loading: action.payload }
    case SET_PERMITS:
      return { ...state, permits: action.payload }
    case SET_NOTES:
      return { ...state, notes: action.payload }
    case SET_SYNCED_AT:
      return { ...state, syncedAt: action.payload }
    case SET_SYNC_ENABLED:
      return { ...state, syncEnabled: action.payload }
    case SET_SHOW_MAP:
      return { ...state, showMap: action.payload }
    case SET_AWAITING_JOBS:
      return { ...state, awaitingJobs: action.payload }
    case SET_PORTFOLIO:
      return { ...state, portfolio: action.payload.portfolio, portfolioType: action.payload.portfolioType }
    case SET_GIS_PDF_TEMPLATES:
      return { ...state, gisPdfTemplates: action.payload }
    case SET_ROUTES:
      return { ...state, routes: action.payload }
    default:
      return state
  }
}

export default projectReducer
