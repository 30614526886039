import React, { useEffect, useState } from "react";

const FormTemplateRow = ({ formTemplate, attachedTemplates, setAttachedTemplates }) => {
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    setChecked(attachedTemplates.includes(formTemplate.id))
  }, [])

  const handleChange = () => {
    if (checked) {
      setAttachedTemplates(prevState => prevState.filter(item => item !== formTemplate.id))
    } else {
      setAttachedTemplates(prevState => [...prevState, formTemplate.id])
    }

    setChecked(!checked)
  }

  return <>
    <tr className='border-b border-gray-300'>
      <td className='w-1/4 text-right'>
        <input
          style={{transform: 'scale(1.5)'}}
          type="checkbox"
          className="border border-gray-400 bg-gray-200 rounded-md outline-none mr-4"
          checked={checked}
          onChange={handleChange}
        />
      </td>
      <td className='w-3/4 py-2'>{formTemplate.name}</td>
    </tr>
  </>
}

export default FormTemplateRow
