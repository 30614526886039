import React from 'react'
import { useCocExport, useCocExportApi } from "../../../contexts/cocExport";

const NotesComments = () => {
  const { headerDetails } = useCocExport()
  const { updateNotes } = useCocExportApi()

  return(
    <div className='flex flex-col my-2'>
      <label className='text-lg font-medium w-1/2'>Notes/Comments:</label>
      <textarea
        className="border border-gray-400 bg-gray-200 focus:bg-white rounded-md outline-none text-black p-2"
        rows="3"
        value={headerDetails.notes}
        onChange={event => updateNotes(event.target.value)}
      />
    </div>
  )
}

export default NotesComments
