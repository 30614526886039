import React, { useRef, useState, useEffect, useMemo } from 'react'
import Modal from '../../shared/modal'
import { usePhotoContext, usePhotoAPIContext } from '../../../contexts/photoContext'
import Revisions from '../../formData/revisions'
import RenderModal from '../../modal/renderModal'

const CommentModal = ({ lightBoxStatus = false }) => {
  const { comments, commentModal, commentEntry } = usePhotoContext()
  const { closeCommentModal, updateCommentDescription } = usePhotoAPIContext()
  const inputRef = useRef()
  const comment = comments[commentModal.commentId]
  const [commentDescription, setCommentDescription] = useState(comment.description)
  const selectorStatus = useMemo(() => lightBoxStatus ? '.yarl__root' : '#modal-root', [lightBoxStatus])

  const onSave = () => {
    updateCommentDescription(commentDescription)
    closeCommentModal()
  }

  useEffect(() => {
    if (commentModal.status && inputRef.current) inputRef.current.focus()
  }, [commentModal.status, inputRef.current])
  return (
    <>
      {commentModal.status &&
        <RenderModal selector={selectorStatus}>
          <Modal heading={'Comment'} closeModal={closeCommentModal} onSave={onSave} >
            <div className='flex flex-col lg:flex-row lg:justify-between gap-y-4 lg:gap-y-0 lg:gap-x-4 w-full h-full'>
              <textarea
                className='block w-full p-1 text-xs text-gray-700 appearance-none focus:outline-none border border-gray-200 focus:shadow-inner m-0 rounded'
                ref={inputRef}
                value={commentDescription}
                onChange={e => setCommentDescription(e.target.value)}
              />
            </div>
            <Revisions formEntry={commentEntry} />
          </Modal>
        </RenderModal>}
    </>
  )
}

export default CommentModal
