import React, { useEffect, useState } from 'react'
import axios from 'axios'
import LoadingSpinner from '../../shared/loadingSpinner'
import { ImportModal } from '../../shared/importModal'
import Onboarding from '../../shared/onboarding'
import ProjectRow from "./projectRow";
import useInterval from "../../../hooks/use_interval";
import ProjectInfo from "./projectInfo";

const Projects = ({ originalSearchTerm, currentPage, landingPageWidget }) => {
  let [projects, setProjects] = useState()
  const [loading, setLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState(originalSearchTerm);

  const handleChange = (event) => {
    setSearchTerm(event.target.value)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    loadProjects();
  }

  const syncProjects = () => {
    axios
      .post(`/sync_projects.json`)
      .then((res) => setLoading(true))
  }

  function loadProjects() {
    axios
      .get(`/projects.json`, { params: { page: currentPage, searchTerm: searchTerm } })
      .then((res) => setProjects(res.data))
  }

  useEffect(() => {
    getActiveJob().then((res) => setLoading(res.data !== null))
  }, [])

  useEffect(() => {
    if (!loading) {
      loadProjects()
    }
  }, [loading])

  useInterval(async () => {
    if (loading) {
      const response = await getActiveJob()
      setLoading(response.data !== null)
    }
  },
    3000
  )

  const getActiveJob = async () => {
    return axios.get(`/synchronized_jobs/find_active.json?job_name=SyncProjectsJob`)
  }

  const SyncProjectsButton = () => {
    if (loading) {
      return (
        <ImportModal title='Pulling Projects'>
          <LoadingSpinner />
        </ImportModal>
      )
    } else {
      return (
        <button className='focus-outline:none btn btn-primary' onClick={() => syncProjects()}>
          Pull projects
        </button>
      )
    }
  }

  return (
    <>
      <div className='header'>
        <h1>Projects</h1>
        <div className='actions'>
          <SyncProjectsButton />
        </div>
      </div>
      <div className='flex flex-row items-center w-full w-full p-5 font-semibold'>
        <form onSubmit={handleSubmit}>
          <label>
            <input type="text" value={searchTerm} onChange={handleChange} placeholder='Enter Project Name'
              className='border border-gray-400 rounded-md outline-none text-black p-2 bg-gray-100' />
          </label>
          <input type="submit" value="Submit" className='mx-1 focus-outline:none btn btn-primary' />
        </form>
      </div>
      {!projects && <LoadingSpinner />}

      <div className='flex flex-col overflow-y-scroll'>
        {projects && projects.length > 0 &&
          <div className='flex flex-col'>
            {projects.map((project) => (
              <ProjectRow key={project.id} project={project} landingPageWidget={landingPageWidget}>
                <ProjectInfo project={project} />
              </ProjectRow>
            ))}
          </div>
        }
        {projects && projects.length === 0 && <Onboarding title='No projects found.' />}
      </div>
    </>
  )
}

export default Projects
