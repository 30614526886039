import React, { createContext, useContext, useEffect, useMemo, useReducer } from 'react'
import axios from 'axios'
import { isEmpty } from "lodash"
import { useParams } from 'react-router-dom'
import useInterval from '../hooks/use_interval'
import projectReducer from "../reducers/projectReducer"
import { notifyError } from "../components/shared/notice"
import * as Action from '../actions/projectActions'
import { getProject, getProjectRoutes } from "../api/projectApi";

/* Initial State */
export const initialState = {
  notes: [],
  selectedNoteId: null,
  project: {},
  portfolio: null,
  portfolioType: '',
  routes: {},
  loading: true,
  syncedAt: '',
  syncEnabled: null,
  permits: [],
  permitsPage: window.location.pathname.includes('permits'),
  showMap: true,
  awaitingJobs: true,
  gisPdfTemplates: [],
}

/* Contexts */
const ProjectContext = createContext(initialState)

const ProjectApiContext = createContext({
  setNotes: () => {},
  syncNotes: () => {},
  setSyncedAt: () => {},
  setSyncEnabled: () => {},
  getPermits: () => {},
  setPermits: () => {},
  createPermit: () => {},
  setShowMap: () => {},
  setAwaitingJobs: () => {},
  setPortfolio: () => {},
  getGisPdfTemplates: () => {},
})

/* Provider */
const Project = ({ children, project, routes }) => {
  const { projectId, noteId } = useParams()
  const [state, dispatch] = useReducer(projectReducer, initialState)

  const api = useMemo(() => {
    const setLoading = (boolean) => dispatch({ type: Action.LOADING_TRUE, payload: boolean })

    const getNotes = (notesPath, projectId) => axios.get(notesPath, { params: { projectId: projectId } })

    const setNotes = (notes) => dispatch({ type: Action.SET_NOTES, payload: notes })

    const syncNotes = (syncNotesPath) => axios.post(syncNotesPath).then(() => setAwaitingJobs(true))

    const setSyncedAt = (syncedAt) => dispatch({ type: Action.SET_SYNCED_AT, payload: syncedAt })

    const setSyncEnabled = (boolean) => dispatch({ type: Action.SET_SYNC_ENABLED, payload: boolean })

    const setPermits = (permits) => dispatch({ type: Action.SET_PERMITS, payload: permits })

    const getPermits = (permitsPath) => axios.get(permitsPath + '.json')

    const setShowMap = (boolean) => dispatch({ type: Action.SET_SHOW_MAP, payload: boolean })

    const setAwaitingJobs = (boolean) => dispatch({ type: Action.SET_AWAITING_JOBS, payload: boolean })

    const setPortfolio = (portfolio, portfolioType) =>
      dispatch({ type: Action.SET_PORTFOLIO, payload: { portfolio: portfolio, portfolioType: portfolioType } })

    const getGisPdfTemplates = (gisPdfTemplatesPath) => axios.get(gisPdfTemplatesPath)

    const setGisPdfTemplates = (GisPdfTemplates) =>
      dispatch({ type: Action.SET_GIS_PDF_TEMPLATES, payload: GisPdfTemplates })

    return {
      setLoading, getNotes, setNotes, syncNotes, setSyncedAt, setSyncEnabled, getPermits, setPermits, setShowMap,
      setAwaitingJobs, setPortfolio, getGisPdfTemplates, setGisPdfTemplates
    }
  }, [])

  api.createPermit = (permitsPath, params) => {
    api.setLoading(true)

    return axios.post(permitsPath + '.json', params)
      .then(res => {
        api.setLoading(false)
        api.setPermits([...state.permits, ...res.data])
        return res
      })
      .catch(err => {
        api.setLoading(false)
        console.error(err)
        notifyError('Unable to create Permit')
      })
  }

  const internalApi = useMemo(() => {
    const getActiveJob = (getActiveJobPath) => axios.get(getActiveJobPath)

    const fetchData = async () => {
      const activeJobRes = await getActiveJob(routes.getActiveJobPath)
      if (activeJobRes.data !== null) {
        api.setAwaitingJobs(true)
      } else {
        api.setAwaitingJobs(false)
      }
    }

    const loadNotes = (isPermitsPage, routes) => {
      if (isPermitsPage) {
        api.getPermits(routes.permitsPath)
          .then(response => api.setPermits(response.data))
          .then(_ => api.setLoading(false))
      } else {
        api.getNotes(routes.notesPath)
          .then((response) => api.setNotes(response.data))
          .then(() => api.setLoading(false))
      }
    }

    const loadGisPdfTemplates = (routes) => {
      api.getGisPdfTemplates(routes.gisPdfsPath)
        .then((response) => { api.setGisPdfTemplates(response.data) })
    }

    const updateProjectRoutes = () => {
      getProjectRoutes(projectId)
        .then((response) => dispatch({ type: Action.SET_ROUTES, payload: response.data }))
    }

    const updateSyncedAt = () => {
      void getProject(projectId).then(({ data: { synced_at: syncedAt } }) => {
        syncedAt && api.setSyncedAt(syncedAt)
      })
    }

    return { getActiveJob, fetchData, loadNotes, loadGisPdfTemplates, updateProjectRoutes, updateSyncedAt }
  }, [])

  useInterval(async () => {
    if (state.awaitingJobs) {
      const response = await internalApi.getActiveJob(routes.getActiveJobPath)
      api.setAwaitingJobs(response.data !== null)
    }
  }, 3000)

  useEffect(() => {
    if (project?.portfolio) {
      api.setPortfolio(project?.portfolio, project?.portfolio?.type)
    }

    void internalApi.fetchData()
    internalApi.loadGisPdfTemplates(routes)
    dispatch({
      type: Action.INIT,
      payload: {
        routes: routes,
        selectedNoteId: noteId,
        project: project,
        syncEnabled: project?.sync_enabled,
      }
    })
  }, [])

  useEffect(() => {
    if (!state.awaitingJobs) {
      void internalApi.loadNotes(state.permitsPage, routes)
      void internalApi.updateProjectRoutes()
      void internalApi.updateSyncedAt()
    }
  }, [state.awaitingJobs])

  useEffect(() => {
    const isLeaflet = state.portfolio && !state.portfolio?.site_assessment_map === 'leaflet'
    const noSite = state.project && !state.project?.site
    const noNoteData = !isEmpty(state.notes?.filter(note => note.feature))
    if (isLeaflet && noSite && noNoteData) {
      api.setShowMap(false)
    }
  }, [state.project, state.notes, state.portfolio])

  return (
    <ProjectApiContext.Provider value={ api }>
      <ProjectContext.Provider value={ state }>
        { children }
      </ProjectContext.Provider>
    </ProjectApiContext.Provider>
  )
}

export default Project

/* Custom Context Hooks */
export const useProjectContext = () => useContext(ProjectContext)
export const useProjectApiContext = () => useContext(ProjectApiContext)
