import React, { useCallback, useReducer } from "react"
import { postGenerateFiles } from "../../api/documentLogApi"
import { useParams } from "react-router-dom"
import { toggleReducer } from "../../utilities/helpers"
import LoadingSpinner from "../shared/loadingSpinner"
import { classNames } from "../../utilities/utils"

export const buttonStyles = "btn btn-primary font-semibold"

const GenerateFilesButton = ({ document, templateSlug, updateDocument, classes }) => {
  const { projectId } = useParams()
  const [loading, setLoading] = useReducer(toggleReducer, false)

  const generateFiles = useCallback(() => {
    setLoading(true)
    return postGenerateFiles(projectId, templateSlug, document.id)
      .then(res => {
        updateDocument(res)
        setLoading(false)
      })
  }, [setLoading, postGenerateFiles, updateDocument, projectId, templateSlug, document])

  return (<>
    { loading && (
      <button className={ classNames(buttonStyles, classes) } onClick={ setLoading }>
        <LoadingSpinner size={ '2xl' }/>
      </button>
    ) }
      { !loading && (
        <button onClick={ generateFiles } className={ classNames(buttonStyles, classes) }>
          Generate Files
        </button>
      ) }
  </>)
}

export default GenerateFilesButton
