import React, { useState, useEffect } from "react"
import Select from "react-select"

const DropDown = ({ options, handleChange = () => {}, styles, defaultWidget }) => {
  const [selectedWidget, setSelectedWidget] = useState()
  const handleWidgetSelect = (newWidget) => {
    handleChange(newWidget.value)
    setSelectedWidget(newWidget)
  }

  useEffect(() => {
    if (defaultWidget) setSelectedWidget(defaultWidget)
  }, [defaultWidget])

  return <>
    {options &&
      <Select
        className={`capitalize w-full ${styles}`}
        placeholder={'Select'}
        options={options}
        value={selectedWidget}
        onChange={(valueObject) => handleWidgetSelect(valueObject)}
      />}
  </>
}

export default DropDown
