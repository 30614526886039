import React, { createContext, useContext, useReducer, useMemo, useEffect } from "react"
import { useProjectContext } from "./projectContext"
import { INIT_FILTER_LISTS, SELECT_FILTER, initialFilterState, filterReducer } from "../reducers/noteFilterReducer"

/* Contexts */
const NoteFilterContext = createContext(initialFilterState)

const NoteFilterApiContext = createContext({
  initializeFilterLists: () => {},
  setFilter: () => {},
})

/* Providers */
export const NoteFilterProvider = ({ children }) => {
  const [state, dispatch] = useReducer(filterReducer, initialFilterState)
  const { notes } = useProjectContext()

  const api = useMemo(() => {
    const initializeFilterLists = (notes) => dispatch({ type: INIT_FILTER_LISTS, payload: notes })

    const setFilter = (filter) => (selection) => dispatch({ type: SELECT_FILTER, payload: { filter: filter, selection: selection } })

    return { initializeFilterLists, setFilter }
  }, [])

  useEffect(() => {
    api.initializeFilterLists(notes)
  }, [notes])

  return(
    <NoteFilterApiContext.Provider value={api}>
      <NoteFilterContext.Provider value={state}>
        {children}
      </NoteFilterContext.Provider>
    </NoteFilterApiContext.Provider>
  )
}

/* Custom Context Hooks */
export const useNoteFilterContext = () => useContext(NoteFilterContext)
export const useNoteFilterApi = () => useContext(NoteFilterApiContext)
