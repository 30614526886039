import React from "react"

const ProjectDetails = ({ project, containerStyles = '' }) => {

  return (
    <div className={`w-full px-2 py-4 bg-gray-200 ${containerStyles}`}>
      <div className="flex flex-col space-y-4">
        <h2 className="text-2xl font-bold">{project?.name}</h2>
        <p className="text-lg">Project Number: {project?.job_number}</p>
        {project?.historical_project_number && (
          <p className="text-lg">Historical Project Number: { project?.historical_project_number }</p>) }
        <p className="text-lg">Portfolio Name: {project?.portfolio?.name}</p>
        <p className="text-lg">Province: {project?.site?.province?.name}</p>
        <p className="text-lg">Site Type: {project?.site?.type}</p>
        <p className="text-lg">Site Location: {project?.site?.name}</p>
      </div>
    </div>
  )
}

export default ProjectDetails
