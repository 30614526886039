import API, { simpleGet } from "./apiConfig"
import { projectOrPortfolioPath } from "../utilities/projectOrPortfolioHelpers"

const csrfToken = document.querySelector('[name=csrf-token]').content

export const buildTrackerPath = ({ portfolioId, projectId, trackerSlug }) => {
  const baseUrl = projectOrPortfolioPath({ portfolioId, projectId })
  const trackerPath = trackerSlug ? `/trackers/${trackerSlug}.json` : '/trackers.json'
  return `${baseUrl}${trackerPath}`
}

export const getTracker = (trackerPath) => simpleGet(trackerPath)

export const createTrackerEntry = (trackerPath) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API
    .post(trackerPath)
    .then(res => res.data)
}

export const duplicateTrackerEntry = (params) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API
    .post(`/tracker_entries/duplicate`, { entries: params })
    .then(res => res.data)
}

export const updateTrackerEntry = (trackerPath, formData) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API
    .put(trackerPath, formData)
    .then(res => res.data)
}

export const deleteTrackerEntries = (entriesId) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API
    .delete(`/tracker_entries/destroy`, { params: { entries: entriesId } })
    .then(res => res.data)
}

export const updateTrackerEntryProject = (entryId, projectId) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API.put(`/tracker_entries/${entryId}/update_project`, { project_id: projectId })
    .then(res => res.data)
}

export const uploadTrackerAttributeFile = (file, params) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API.post(`/tracker_attributes/file_upload`, file, { params: params })
    .then(res => res.data)
}

export const deleteTrackerAttribute = (attributeId) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API.delete(`/tracker_attributes/${attributeId}`)
    .then(res => res.data)
}
