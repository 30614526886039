import React from "react";
import axios from "axios";
import { notifyAlert, notifyError } from "../shared/notice";

const Export = ({ e, getExports, exportPath, admin, url }) => {
  const deleteReport = () => {
    axios({
      method: 'delete',
      url: `${url}/${e.id}.json`,
      data: {
        id: e.id
      }
    })
      .then(res => {
        notifyAlert(res.data)
        getExports(exportPath)
      })
      .catch(err => {
        notifyError('There was an error')
        console.error(err)
      })
  }

  return(
    <div className='flex flex-row gap-x-6 justify-between bg-regular-blue hover:bg-hover-blue rounded-xl w-full py-4 px-6 items-center'>
      <div className="flex flex-col">
        <div>Report Date: {e.date}</div>
        <div className="text-xs">Created By: {e.created_by}</div>
        <div className="text-xs">Run at: {e.created_at}</div>
      </div>
      {e.pdf_path && <a href={e.pdf_path} target="_blank" rel="noopener noreferrer">
        <i
          className="fa fa-cloud-download-alt fa-2x pointer"
        />
      </a>}
      {admin && <i
        className="fa fa-trash-alt fa-2x cursor-pointer"
        onClick={() => deleteReport()}
      />}
    </div>
  )
}

export default Export
