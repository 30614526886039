import { getPortfolio } from "../api/portfoliosApi"
import { getProject } from "../api/projectApi"

export const initPortfolioAndProject = ({ portfolioId, projectId, callback }) => {
  if (portfolioId) {
    getPortfolio(portfolioId)
      .then(portfolioRes => {
        const { portfolio, projects, default_widget: defaultWidget } = portfolioRes
        callback({ portfolio, projects, defaultWidget })
      })
  } else if (projectId) {
    getProject(projectId)
      .then(projectRes => {
        const project = projectRes.data
        getPortfolio(project.portfolio_id)
          .then(portfolioRes => {
            const { portfolio, default_widget: defaultWidget } = portfolioRes
            callback({ portfolio, projects: [project], project, defaultWidget })
          })
      })
  }
}
