import React, { useMemo } from 'react'
import { useTestResultsContext } from '../../../contexts/testResultsContext'
import { useTestResultsFilterContext } from '../../../contexts/testResultsFilterContext'
import { getGuideline } from '../../../utilities/labAnalyticHelpers'
import { tableStyles } from '../../../utilities/stylesHelpers'
import TestResultCell from './testResultCell'

const TestResultRow = ({ row, rowIndex }) => {
  const { activeGuidelines } = useTestResultsContext()
  const { availableChemicalUnits } = useTestResultsFilterContext()

  const guideline = useMemo(
    () => getGuideline(row.form_datum_info, activeGuidelines),
    [row.form_datum_info, activeGuidelines]
  )
  const orderedTestResults = useMemo(() => availableChemicalUnits?.map(chemicalUnit => row.test_results.find(testResult => testResult.chemical_unit_id === chemicalUnit.id) || ''),
    [availableChemicalUnits, row.test_results]
  )

  return (
    <tr>
      <td className={tableStyles.td}>{row.form_datum_info.apec}</td>
      <td className={tableStyles.td}>{row.form_datum_info.borehole_id}</td>
      <td className={tableStyles.td}>{row.form_datum_info.sample_id}</td>
      <td className={tableStyles.td}>{row.form_datum_id}</td>
      <td className={tableStyles.td}>{row.form_datum_info.start_depth} - {row.form_datum_info.end_depth}</td>
      <td className={tableStyles.td}>{row.form_datum_info.sample_date}</td>
      <td className={tableStyles.td}>{row.form_datum_info.event_type}</td>
      <td className={tableStyles.td}>{guideline?.name}</td>
      {orderedTestResults.map((testResult, index) => (
        <TestResultCell key={index} testResult={testResult} rowIndex={rowIndex} guideline={guideline} />
      ))}
    </tr>
  )
}

export default TestResultRow
