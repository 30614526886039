export const INIT = 'INIT'
export const LOADING_TRUE = 'LOADING_TRUE'
export const SET_PERMITS = 'SET_PERMITS'
export const SET_NOTES = 'SET_NOTES'
export const SET_SYNCED_AT = 'SET_SYNCED_AT'
export const SET_SYNC_ENABLED = 'SET_SYNC_ENABLED'
export const SET_SHOW_MAP = 'SET_SHOW_MAP'
export const SET_AWAITING_JOBS = 'SET_AWAITING_JOBS'
export const SET_PORTFOLIO = 'SET_PORTFOLIO'
export const SET_GIS_PDF_TEMPLATES = 'SET_GIS_PDF_TEMPLATES'
export const SET_ROUTES = 'SET_ROUTES'
