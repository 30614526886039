import React, { useCallback } from "react"
import { useDocumentBuilderApiContext } from "../../../contexts/documentBuilderContext"
import RichTextEditor from "../../richTextEditor"

const UserInput = ({ contentId, content }) => {
  const { updateContent } = useDocumentBuilderApiContext()

  const handleChange = useCallback(event => updateContent(contentId, 'documentContent', event), [updateContent, contentId])

  return <RichTextEditor
    className="min-w-[80%] min-h-[100px] min-h-fit py-4 rounded-md"
    initialValue={ content?.documentContent || content?.defaultContent }
    defaultContent={ content?.defaultContent }
    onChange={ handleChange }
  />
}

export default UserInput
