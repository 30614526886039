import React from "react"

const AttributeRevision = ({ revision }) => {
  const revisionDate = new Date(revision.created_at).toDateString()
  const revisionDateString = revisionDate === 'Invalid Date' ? '' : revisionDate

  return <div className='flex flex-col text-xs mb-1'>
    <div className='flex flex-row items-center justify-between'>
      <div className='block text-base text-regular-orange my-1 truncate px-2'>
        {revision?.value || "(blank)"}
      </div>
      <div className='block text-xs text-gray-900 truncate px-2'>
        {revision?.created_by?.email || 'Original Value'}
      </div>
    </div>
    <div className='text-xs text-gray-900 truncate px-2 text-right'>
      {revisionDateString}
    </div>
  </div>
}

export default AttributeRevision
