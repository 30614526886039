import React from "react"

const StatusList = ({ statuses }) => {
  const statusList = statuses.map(status => {
    return <tr>
      <td className='w-1/4'>{status.value}</td>
      <td>{status.label}</td>
    </tr>
  })

  return <>
    <table className="list-disc w-full text-left">
      <tr>
        <th className='w-1/4'>Value</th>
        <th>Description</th>
      </tr>
      {statusList}
    </table>
  </>
}

export default StatusList
