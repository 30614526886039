import React, { useEffect, useState } from "react"
import { parseISO } from "date-fns"
import { syncAtFormatter} from "../../../utilities/dateHelpers"

const SyncedAt = ({ project }) => {
  const [syncedAt, setSyncedAt] = useState('')

  useEffect(() => {
    const formattedDate = project.synced_at ? syncAtFormatter(parseISO(project.synced_at)) : 'N/A'
    setSyncedAt(formattedDate)
  }, [project.synced_at])

  return (
    <span>
      {`Last synced at: ${syncedAt}`}
    </span>
  )
}

export default SyncedAt
