import React, { useReducer, useContext, createContext, useEffect, useMemo, useCallback } from "react"
import { getSafetyTickets, getUserTickets, getRequirements, createUserTicket } from "../api/safetyTicketApi"
import { simplePut } from "../api/apiConfig"
import { updateUserTickets } from "../utilities/safetyTicketHelpers"
import { getUsers } from "../api/userApi"

// Actions
const INIT = "INIT"
const TOGGLE = 'TOGGLE'
const UPDATE_STATE = 'UPDATE_STATE'
const UPDATE_NESTED_STATE = 'UPDATE_NESTED_STATE'
const UPDATE_USER_TICKET = 'UPDATE_USER_TICKET'
const UPDATE_SELECTED_USERS = 'UPDATE_SELECTED_USERS'

// Initial States

const initialState = {
  safetyTickets: [],
  userTickets: [],
  requirements: [],
  users: [],
  user: {},
  selectedUsers: [],
  userTicketModal: false,
  userTicket: {
    id: "",
    user_id: "",
    safety_ticket_id: "",
    front_ticket_image_path: "",
    back_ticket_image_path: "",
    expiry: "",
  }
}

// Reducer

const safetyTicketReducer = (state, action) => {
  switch (action.type) {
    case INIT:
      return {
        ...state, safetyTickets: action.safetyTickets, userTickets: action.userTickets,
        requirements: action.requirements, users: action.users
      }
    case TOGGLE:
      return { ...state, [action.field]: !state[action.field] }
    case UPDATE_STATE:
      return { ...state, [action.field]: action.value }
    case UPDATE_NESTED_STATE:
      return { ...state, [action.field]: { ...state[action.field], [action.nestedField]: action.value } }
    case UPDATE_USER_TICKET:
      const updatedUserTickets = updateUserTickets(state.userTickets, action.userTicket)
      return { ...state, userTicket: action.userTicket, userTickets: updatedUserTickets }
    case UPDATE_SELECTED_USERS:
      return { ...state, selectedUsers: action.selectedUsers }
    default:
      return state
  }
}

// context
const SafetyTicketContext = createContext(initialState)
const SafetyTicketApiContext = createContext({})

// provider

export const SafetyTicketProvider = ({ children, user }) => {
  const [state, dispatch] = useReducer(safetyTicketReducer, { ...initialState, user })

  const api = useMemo(() => {
    const updateState = (field, value) => dispatch({ type: UPDATE_STATE, field, value })

    const toggleField = (field) => dispatch({ type: TOGGLE, field })

    const updateNestedState = (field, nestedField) => value => dispatch({ type: UPDATE_NESTED_STATE, field, nestedField, value })

    const updateUserTicket = (userTicket) => dispatch({ type: UPDATE_USER_TICKET, userTicket })

    const updateSelectedUsers = (selectedUsers) => dispatch({ type: UPDATE_SELECTED_USERS, selectedUsers })

    return { updateState, toggleField, updateNestedState, updateUserTicket, updateSelectedUsers }
  }, [])

  api.onSend = (formRef, event) => {
    event.preventDefault()
    const formDataFromRef = new FormData(formRef.current)

    if (state.userTicket.id == null || state.userTicket.id == undefined) {
      createUserTicket(formDataFromRef).then(response => {
        api.updateUserTicket(response)
        api.toggleField('userTicketModal')
      }
      )
    } else {
      simplePut(`/user-tickets/${state.userTicket.id}`, formDataFromRef).then(response => {
        api.updateUserTicket(response)
        api.toggleField('userTicketModal')
      })
    }
  }

  const initializeFunc = useCallback(async () => {
    const safetyTickets = await getSafetyTickets()
    const userTickets = await getUserTickets()
    const requirements = await getRequirements()
    await Promise.all([userTickets, requirements])
    const users = (user?.role === "admin" || user?.role === "project_manager") ?
      await getUsers({ organization_id: user?.organization.id, exclude_user_id: user.id, exclude_positon_id: null }) : []

    dispatch({
      type: INIT,
      safetyTickets: safetyTickets,
      userTickets: userTickets,
      requirements: requirements,
      users: users
    })
  }, [])

  // Initialize state
  useEffect(() => void initializeFunc(), [])

  return (
    <SafetyTicketApiContext.Provider value={api}>
      <SafetyTicketContext.Provider value={state}>
        {children}
      </SafetyTicketContext.Provider>
    </SafetyTicketApiContext.Provider>
  )
}

// Custom Hooks

export const useSafetyTicketContext = () => useContext(SafetyTicketContext)
export const useSafetyTicketApi = () => useContext(SafetyTicketApiContext)
