import React, { forwardRef, useEffect, useState, useMemo } from "react"
import Select from "react-select";
import { isEmpty } from "lodash"
import { useChecklistContext, useChecklistAPI } from "../../../../contexts/checklistContext";


const DropdownInput = forwardRef(({ paramOptions, displayOtherOption = true, otherOptionLabel = 'Other' }, inputRef) => {
  const { editCellContent } = useChecklistContext()
  const { onContentChange } = useChecklistAPI()
  const [selectedOption, setSelectedOption] = useState(undefined)

  const otherOption = useMemo(() => ({ value: 'Other', label: otherOptionLabel }), [])

  const options = useMemo(() => {
    return displayOtherOption ? [...paramOptions, otherOption] : paramOptions
  }, [paramOptions, otherOption])

  useEffect(() => {
    if (!isEmpty(editCellContent)) {
      if (isEmpty(options.filter(option => option.value.toString() === editCellContent))) {
        setSelectedOption(otherOption)
      } else {
        setSelectedOption(options.filter(option => option.value.toString() === editCellContent)[0])
      }
    }
  }, [options])

  const onDropdownSelect = (event) => {
    setSelectedOption(event)
    if (event.value != 'Other') {
      const selectedOption = options.find(option => option.value === event.value);
      const color = selectedOption ? selectedOption.cell_color : undefined;
      onContentChange(event.value, color)
    }
  }

  return <>
    {options && <>
      <Select
        options={options}
        onChange={event => onDropdownSelect(event)}
        className='block w-full p-1 text-xs text-gray-700 appearance-none focus:outline-none border border-gray-200 focus:shadow-inner rounded'
        value={selectedOption}
      />
      {selectedOption?.value === 'Other' &&
        <input
          className='block w-full my-3 p-3 text-xs text-gray-700 appearance-none focus:outline-none border border-gray-200 focus:shadow-inner rounded'
          type='text'
          ref={inputRef}
          placeholder={`Enter ${otherOptionLabel} option here...`}
          onChange={e => onContentChange(e.target.value, 'white')}
        />}
    </>}
  </>
})

export default DropdownInput
