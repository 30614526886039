
export const updateTestResultRows = (testResult, rowIndex, testResultRows) => {
  const copyTestResultRows = [...testResultRows]
  copyTestResultRows[rowIndex].test_results = copyTestResultRows[rowIndex].test_results.map((result) => {
    if (result.id === testResult.id) {
      return testResult
    } else {
      return result
    }
  })
  return copyTestResultRows
}

export const getGuideline = (formDatumInfo, activeGuidelines) => {
  const startDepth = parseFloat(formDatumInfo.start_depth)
  const endDepth = parseFloat(formDatumInfo.end_depth)
  const depthRange = { start: startDepth, end: endDepth }

  return activeGuidelines.find(guideline => {
    const guidelineEndDepth = parseFloat(guideline.analytic_export_guidelines[0].end_depth)
    const guidelineStartDepth = parseFloat(guideline.analytic_export_guidelines[0].start_depth)
    return depthRange.start >= guidelineStartDepth && depthRange.end <= guidelineEndDepth
  })
}

export const getLimt = (guideline, testResult) => {
  return guideline.limits.find(limit => {
    return limit.chemical_unit_id === testResult.chemical_unit_id
  })
}

export const testResultStyles = (testResult, limit) => {
  const testResultValue = parseFloat(testResult?.value)
  if (testResult.background_status) {
    return 'bg-yellow-200'
  }
  else if (testResult?.value?.includes('<') || testResult?.value?.includes('>')) {
    return ''
  }
  else if (isNaN(testResultValue)) {
    return 'bg-purple-200'
  }
  else if (testResultValue > parseFloat(limit?.string_value)) {
    return 'bg-red-200'
  }
  else {
    return ''
  }
}

export const orderGuidelines = (guidelines, chemical_units) => {
  if (!guidelines.length) return []

  return guidelines.map(guideline => ({
    ...guideline,
    limits: chemical_units.map(chemicalUnit =>
      guideline.limits.find(limit => limit.chemical_unit_id === chemicalUnit.id) || ''
    )
  }))
}

export const alphaNumericOrderRows = (rows) => {
  return rows.sort((a, b) => {
    const aSampleId = a.form_datum_info.sample_id
    const bSampleId = b.form_datum_info.sample_id
    return aSampleId.localeCompare(bSampleId, 'en', { numeric: true })
  })
}

export const getFilterValues = (testResultRows, contaminantGroups) => {
  return testResultRows.reduce((acc, row) => {
    acc.filterValues.apec.add(row.form_datum_info.apec)
    acc.filterValues.sample_date.add(row.form_datum_info.sample_date)
    return acc
  }, {
    filterValues: {
      apec: new Set(),
      sample_date: new Set(),
      contaminantGroup: contaminantGroups
    }
  })
}

export const filterRows = (rows, filters, contaminantGroups, chemicalUnits) => {
  const resultsCopy = [...rows]
  const startDateParts = filters.dateRange.start_date.split('-')
  const startDate = new Date(startDateParts[0], startDateParts[1] - 1, startDateParts[2])
  const endDateParts = filters.dateRange.end_date.split('-')
  const endDate = new Date(endDateParts[0], endDateParts[1] - 1, endDateParts[2])
  const newContaminantGroups = filterContaminantGroup(filters, contaminantGroups)

  const newResultRows = resultsCopy.map(row => {

    const sampleDateParts = row.form_datum_info.sample_date.split('-')
    const sampleDate = new Date(sampleDateParts[0], sampleDateParts[1] - 1, sampleDateParts[2])
    if (sampleDate >= startDate && sampleDate <= endDate && filters.apecs.includes(row.form_datum_info.apec)) {
      return row
    }
    return null
  }).filter(row => row)
  const availableChemicalUnits = getAvailableChemicalUnits(chemicalUnits, newContaminantGroups)

  return [newResultRows, newContaminantGroups, availableChemicalUnits]
}

const filterContaminantGroup = (filters, contaminantGroups) => {
  return contaminantGroups.filter(group =>
    filters.contaminantGroups.includes(Object.keys(group)[0]))
}

const getAvailableChemicalUnits = (chemicalUnits, filteredContaminantGroups) => {
  const chemicalIds = filteredContaminantGroups.flatMap(group => Object.values(group).flat()).map(chemical => chemical.id)

  return chemicalUnits.filter(chemicalUnit => chemicalIds.includes(chemicalUnit.chemical_id))
}

export const organizeContaminantGroups = (chemicals) => {
  const chemicalIdOrder = []

  const groupMap = chemicals.reduce((acc, chemical) => {
    const groupName = chemical.contaminant_group_name

    if (!acc[groupName]) {
      acc[groupName] = []
    }

    acc[groupName].push(chemical)

    return acc
  }, {})

  const contaminantGroups = Object.entries(groupMap).map(([groupName, chemicals]) => {
    const sortedChemicals = chemicals.sort((a, b) => a.chemical_position - b.chemical_position)
    sortedChemicals.forEach(chemical => chemicalIdOrder.push(chemical.id))

    return { [groupName]: sortedChemicals }
  })

  return { contaminantGroups, chemicalIdOrder }
}

export const orderChemicalUnits = (chemicalIds, chemicalUnits) => {
  return chemicalIds.map(id => chemicalUnits.find(chemicalUnit => chemicalUnit.chemical_id === id))
}

export const backgroundResultMapping = (analyticSummary) => {
  const backgroundHeader = []
  const values = []

  analyticSummary?.background_results?.map((backgroundResult, index) => {
    backgroundHeader.push(backgroundResult.form_datum.sample_id)
    values.push(backgroundResult.value)
  })
  
  return { backgroundHeader, values }
}
