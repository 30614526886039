import React, { useCallback, useState } from "react"
import AnimatedCheckbox from "../../shared/animatedCheckbox"
import { useAiDocumentAPI } from "../../../contexts/aiDocumentContext"

const TextractTable = ({ table }) => {
  const [previewExpanded, setPreviewExpanded] = useState(false)
  const { updateTable } = useAiDocumentAPI()

  const onApprovalClick = useCallback(() => updateTable(table.id, { approved: !table.approved }))

  return (
    <div className="w-full grid grid-cols-12 gap-4 border border-solid border-gray-300 p-4">
      <div className="col-span-1 w-full flex items-start justify-center">
        <AnimatedCheckbox checked={ table.approved } onClick={ onApprovalClick }/>
      </div>
      <div className="col-span-1">
        <p>{ table.page_number }</p>
      </div>
      <div className="col-span-3">
        <h3 className="text-xl font-bold">{ table.name }</h3>
        <h3 className="text-md">{ table.footer }</h3>
      </div>
      <div className="col-span-3">
        { table.generated_csv_path && (
          <a className="btn btn-primary font-semibold relative cursor-pointer text-center"
             href={ table.generated_csv_path } target="_blank" rel="noopener noreferrer"
          >
            Download Generated CSV
          </a>
        ) }
        { table.edited_csv_path && (
          <a className="btn btn-primary font-semibold relative cursor-pointer text-center"
             href={ table.edited_csv_path } target="_blank" rel="noopener noreferrer"
          >
            Download Edited CSV
          </a>
        ) }
      </div>
      <div className="col-span-4">
        <button
          className="btn btn-primary font-semibold relative cursor-pointer text-center"
          onClick={ () => setPreviewExpanded(prevState => !prevState) }
        >
          { previewExpanded ? 'Hide' : 'Preview' } Page
        </button>
        { previewExpanded && (
          <a target="_blank" href={ table.preview_path } rel="noopener noreferrer">
            <img src={ table.preview_path } alt={ `${ table.name } page preview` }/>
          </a>
        ) }
      </div>
    </div>
  )
}

export default TextractTable
