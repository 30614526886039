import React from "react"
import { tableStyles } from "../../../utilities/simpleTrackerTableHelpers"

const HyperlinkCell = ({ content }) => {
  return (
    <td className={ tableStyles.td } style={ { minWidth: "120px" } }>
      <p className={ tableStyles.tdText }>
        <a className={ 'font-bold hover:underline' } target="_blank" rel="noopener noreferrer" href={ content?.value }>
          { content?.value }
        </a>
      </p>
    </td>
  )
}

export default HyperlinkCell
