import React from "react"
import { useAiPluginApi } from "../../../contexts/aiPluginContext"

const SubmitChatMessage = () => {
  const { submitMessage } = useAiPluginApi()

  return (
    <button
      className="absolute right-4 bottom-4 pointer bg-blue-600 text-white text-center mt-4 py-4 px-12 rounded-3xl z-20"
      onClick={() => submitMessage()}
    >
      Submit
    </button>
  )
}

export default SubmitChatMessage
