import React, { useMemo } from 'react'
import { useDocumentBuilderContext } from "../../../contexts/documentBuilderContext"
import { classNames } from "../../../utilities/utils"
import Content from "../content"

const TableLayout = ({ sectionLayout, ContentComponent = Content }) => {
  const { contents } = useDocumentBuilderContext()

  const numberOfColumns = useMemo(() => sectionLayout?.columns || 1, [sectionLayout])
  const hasBorders = useMemo(() => sectionLayout?.borders, [sectionLayout])

  const sectionLayoutRows = useMemo(() => {
    const sectionLayoutContents = sectionLayout.contentIds.map(id => ({ ...contents[id], id: id }))

    let currentColspan = 0
    let currentRow = 0

    return sectionLayoutContents.reduce((acc, content) => {
      const contentColspan = (content?.colspan && content?.colspan > 0) ? content.colspan : 1

      if ((currentColspan + contentColspan) > numberOfColumns) {
        currentColspan = contentColspan
        currentRow += 1
        acc.push([content])
      } else {
        currentColspan += contentColspan
        acc[currentRow].push(content)
      }

      return acc
    }, [[]])
  }, [sectionLayout, contents, numberOfColumns])

  return (
    <table className={ classNames("max-w-[978px]", hasBorders && 'border-solid border-2 border-slate-500') }>
      <colgroup>
        { Array(numberOfColumns).fill(null).map((_, i) => (
          <col key={ i }
               className={ classNames(hasBorders && 'border-solid border-2 border-slate-500') }
               style={ { width: `${ 100 / numberOfColumns }%`, maxWidth: `${ 100 / numberOfColumns }%` } }
          />
        )) }
      </colgroup>
      <tbody>
      { sectionLayoutRows.map((row, index) => (
        <tr key={ index } className={ classNames(hasBorders && 'border-solid border-2 border-slate-500') }>
          { row.map(content => (
            <td key={ content.id } colSpan={ content.colspan || 1 } className="p-2">
              <ContentComponent contentId={ content.id }/>
            </td>
          )) }
        </tr>
      )) }
      </tbody>
    </table>
  )
}

export default TableLayout
