import React from 'react'
import Select from "react-select"
import { isEmpty } from "lodash"
import { useAiDocumentsFilterAPI, useAiDocumentsFilterContext } from '../../../contexts/aiDocumentsFilterContext'

const DocumentFilters = () => {
  const { documentOptions, valueOptions, currentDocumentFilter } = useAiDocumentsFilterContext()
  const { updateDocumentOptionFilter, updateValueFilter } = useAiDocumentsFilterAPI()

  const onFilterSelect = (e) => { updateDocumentOptionFilter(e?.value) }
  const onColumnSelect = (e) => { updateValueFilter(e?.value) }

  return (
    <div className="m-4 z-50 flex flex-row gap-x-4 sticky left-4">
      <Select
        options={ documentOptions }
        onChange={ onFilterSelect }
        className='w-1/2, z-50'
        isClearable='true'
        placeholder={ 'Choose a Filter Option' }
      />
      <Select
        options={ valueOptions }
        onChange={ onColumnSelect }
        className='w-1/2 z-50'
        isClearable
        isDisabled={ isEmpty(currentDocumentFilter) }
        placeholder={ 'Choose Value to Filter for' }
      />
    </div>
  )
}

export default DocumentFilters
