import React from 'react'
import { useSampleCollectionApi } from '../../../../contexts/sampleCollectionContext'

const LotInput = () => {
  const { updateNestedState } = useSampleCollectionApi()

  return (
    <label htmlFor="lot_number" className="flex flex-row items-center justify-start w-full">
      <input
        type="text"
        name="lot_number"
        onChange={e => updateNestedState('sampleCollection', 'lot_number')(e.target.value)}
        className="input"
        placeholder={'Lot Number'}
        required={true}
      />
      <span className="text-red-500 ml-2">*Required</span>
    </label>
  )
}

export default LotInput
