import React from 'react'
import { useTestResultsApi, useTestResultsContext } from '../../../contexts/testResultsContext'
const GuidelineDisplay = () => {
  const { analyticExportGuidelines } = useTestResultsContext()
  const { removeGuideline, updateState, toggleField } = useTestResultsApi()
  const onEditHandler = (analyticExportGuideline) => {
    updateState("currentAnalyticExportGuideline", analyticExportGuideline)
    toggleField('modal.guidelineModal')
  }

  return (

    <div className='grid grid-cols-4 mx-3 my-3 text-center content-center border border-gray-300'>
      <h2 className='border border-gray-300'> ACTIVE GUIDELINES </h2>
      <h2 className='border border-gray-300'> Start Depth (m)</h2>
      <h2 className='border border-gray-300'> End Depth (m)</h2>
      <h2 className='border border-gray-300'> Delete / Edit </h2>
      {analyticExportGuidelines && analyticExportGuidelines?.map((analyticExportGuideline, index) => (
        <React.Fragment key={index}>
          <div className='text-left p-2 border border-gray-300' > {analyticExportGuideline?.guideline?.name} </div>
          <div className='text-left p-2 border border-gray-300' > {analyticExportGuideline?.start_depth} </div>
          <div className='text-left p-2 border border-gray-300' > {analyticExportGuideline?.end_depth} </div>
          <div className='text-center p-2 border border-gray-300' >
            <button className='btn btn-danger mx-2' onClick={() => removeGuideline(analyticExportGuideline.id)}> Delete </button>
            <button className='btn btn-orange mx-2' onClick={() => onEditHandler(analyticExportGuideline)}> Edit </button>
          </div>
        </React.Fragment>
      ))}
    </div>)
}


export default GuidelineDisplay
