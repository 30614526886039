import React from 'react'
import HeaderCheckbox from "./HeaderCheckbox";
import { useCocExport, useCocExportApi } from "../../../contexts/cocExport";

const Requirements = () => {
  const { headerDetails } = useCocExport()
  const { updateRequirements } = useCocExportApi()

  return <>
    <h1>Requirements</h1>
    <HeaderCheckbox title={'HCDWORG'} field={'hcdworg'} value={headerDetails.requirements.hcdworg} update={updateRequirements} />
    <HeaderCheckbox title={'AB Tier 1'} field={'ab_tier_1'} value={headerDetails.requirements.ab_tier_1} update={updateRequirements} />
    <HeaderCheckbox title={'SPIGEC'} field={'spigec'} value={headerDetails.requirements.spigec} update={updateRequirements} />
    <HeaderCheckbox title={'BCCSR'} field={'bccsr'} value={headerDetails.requirements.bccsr} update={updateRequirements} />
  </>
}

export default Requirements
