import React from 'react'
import { initializeState } from '../utilities/noteFilterHelpers'

/* Actions */
export const INIT_FILTER_LISTS = 'INIT_FILTER_LISTS'
export const SELECT_FILTER = 'SELECT_FILTER'

/* Initial Filter States */
export const initialFilterState = {
  formTemplateNameList: [],
  foremanNameList: [],
  activitiesList: [],
  dateList: {},
  selectedFormTemplateName: null,
  selectedForemanName: null,
  selectedActivity: null,
  selectedDate: null,
}

/* Reducer */
export const filterReducer = (state, action) => {
  switch(action.type) {
    case INIT_FILTER_LISTS:
      const initializedState = initializeState(action.payload)
      return {
        ...state,
        ...initializedState
      }
    case SELECT_FILTER:
      return {
        ...state,
        [action.payload.filter]: action.payload.selection
      }
    default:
      return state
  }
}
