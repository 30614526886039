import React from 'react'
import AiDocumentsPageBreadcrumbs from './aiDocumentsBreadcrumbs'
import ProjectView from '../../../layouts/tablePage/projectView'
import AiDisplays from '../documents/aiDisplays'
import ChatBotView from './chatbot/chatBotView'

const ProjectDocumentsView = (routes) => {
  return (
    <div className="h-full">
      <AiDocumentsPageBreadcrumbs />
      <h1 className="mx-4-2">AI Project Document Repository</h1>
      <div className='flex flex-col lg:flex-row w-full h-[calc(100%-74px)]'>
        <ProjectView WidgetComponentReplacement={<ChatBotView />} showWidget={true} routes={routes} buttonName='Chat'>
          <AiDisplays />
        </ProjectView>
      </div>
    </div>
  )
}

export default ProjectDocumentsView
