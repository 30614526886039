import React from 'react'
import { analysisHeaders } from '../../../constants/headers'
import { useAnalyticExportContext } from '../../../contexts/analyticExportContext'
import { HeaderCell } from '../../tableTemplates/headerCell'
import AnalyticExportRow from './analyticExportRow'

const AnalyticExportTable = () => {
  const { analyticExports} = useAnalyticExportContext()
  return (
    <>
      <div className={`w-full px-2 py-4 bg-gray-200 flex justify-center`}>
        <h2 className="text-2xl font-bold">Compare Lab Data to Standards / Guidelines</h2>
      </div>
      <div className="flex flex-col lg:flex-row w-full">
        <div className='grid grid-cols-5 mx-3 my-3'>
          {analysisHeaders.map((header, index) => (
            <HeaderCell key={index} title={header.title} />
          ))}
          {analyticExports.map((analyticExport, index) => (
            <AnalyticExportRow key={index} analyticExport={analyticExport} />
          ))}
        </div>

      </div>
    </>
  )
}

export default AnalyticExportTable
