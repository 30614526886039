import API from "./apiConfig"

const csrfToken = document.querySelector('[name=csrf-token]').content

export const queryAi = query => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API
    .post('/ai-plugin/query', { query })
    .then(res => res.data)
}

export const streamAiQuery = query => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API
    .post('/ai-plugin/stream-query', { query })
    .then(res => res.data)
}

export const csvClient = (query, project_id) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API
    .post(`/projects/${project_id}/ai-plugin/csv_client`, { query })
    .then(res => res.data)
}

export const getThreadMessages = (project_id) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API
    .get(`/projects/${project_id}/ai-plugin/get_thread_messages`)
    .then(res => res.data)
}
