import React, { useEffect, useRef } from 'react'
import { isEmpty } from "lodash"
import Modal from "../../shared/modal"
import { useChecklistContext, useChecklistAPI } from '../../../contexts/checklistContext'
import TextInput from './inputs/textInput'
import DefaultDropdown from './inputs/defaultDropdown'
import DateInput from './inputs/dateInput'
import FileUploadInput from './inputs/fileUploadInput'
import NumberInput from './inputs/numberInput'
import AttributeRevision from '../../modal/attributeRevision'
import UserDropdown from './inputs/userDropdown'

const ChecklistModal = () => {
  const { modals, editCellColumn, editCellRevisions, users } = useChecklistContext()
  const { onSave, toggleModal, updateAttribute, updateEntry } = useChecklistAPI()
  const inputRef = useRef()
  const type = editCellColumn?.type

  const modal = modals.checklistModal
  const onSaveHandler = () => {
    onSave()
      .then(res => {
        if (editCellColumn?.type === 'UserColumn') updateEntry(res)
        else updateAttribute(res)
        toggleModal('checklistModal')
      })
  }
  useEffect(() => {
    if (modal && inputRef.current) inputRef.current.focus()
  }, [modal, inputRef.current])


  return (
    <>
      {modal && (
        <Modal
          heading={editCellColumn.title}
          closeModal={() => toggleModal('checklistModal')}
          onSave={onSaveHandler}
        >
          <div className='flex flex-col lg:justify-between gap-y-4 lg:gap-y-0 lg:gap-x-4'>
            {!isEmpty(editCellRevisions) && (
              <div className='w-full py-4'>
                <h3>Revision History</h3>
                <div className="max-h-50vh overflow-y-scroll">
                  {editCellRevisions.map((revision, index) => <AttributeRevision key={index} revision={revision} />)}
                </div>
              </div>
            )}
            <div className='w-full'>
              {type === 'DropdownColumn' && <DefaultDropdown checklistColumn={editCellColumn} ref={inputRef} />}
              {type === 'DateColumn' && <DateInput />}
              {type === 'StatusColumn' && <DefaultDropdown checklistColumn={editCellColumn} ref={inputRef} otherOptionLabel="Write Reason for N/A" />}
              {type === 'UserColumn' && <UserDropdown users={users} ref={inputRef} />}
              {!type && <TextInput ref={inputRef} />}
              {type === 'CurrencyColumn' && <NumberInput placeholder={'$0.00'} />}
              {type == 'FileUploadColumn' && <FileUploadInput />}
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

export default ChecklistModal
