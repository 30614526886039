import Select from "react-select"
import React, { useState } from "react"

const WidgetDropdown = ({ project, landingPageWidget, handleChange= () => {} }) => {
  const [selectedWidget, setSelectedWidget] = useState({label: project?.default_widget?.name, value: project?.default_widget?.full_url})

  const widgetOptions = landingPageWidget
    .map((widget) => {
      return { label: widget, value: widget }
    })

  const handleWidgetSelect = (newWidget) => {
    handleChange(newWidget.value)
    setSelectedWidget(newWidget)
  }
  
  return (
    <Select
      className='capitalize w-80'
      placeholder={'Select Widget for Landing Page'}
      options={widgetOptions}
      value={selectedWidget}
      onChange={(valueObject) => handleWidgetSelect(valueObject)}
    />
  )
}

export default WidgetDropdown
