import React from "react"

const Breadcrumbs = ({ children }) => (
  <nav className='breadcrumbs' aria-label='Breadcrumb'>
    <ol className='list-none p-0 inline-flex'>
      {children}
    </ol>
  </nav>
)

export default Breadcrumbs
