import React from "react"
import SidebarButton from "../sidebarButton"

const DemoLinkButton = ({demo = {}}) => {
  const builtUrl = `/projects/${demo.projectId}/${demo.page}`

  return <SidebarButton text={demo.projectName} href={builtUrl} />
}

export default DemoLinkButton
