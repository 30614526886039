import React, { useMemo } from 'react'
import { useSafetyTicketContext } from '../../contexts/safetyTicketContext'
import AddUpdateTicketButton from './addUpdateTicketButton'
import ExpiryComponent from '../shared/expiryComponent'
import Image from './image'

const Ticket = ({ ticket, user, requirements }) => {
  const { userTickets } = useSafetyTicketContext()

  const userTicket = useMemo(() => {
    return userTickets.find(userTicket => userTicket.safety_ticket_id === ticket.id && userTicket.user_id === user.id)
  }, [userTickets, ticket.id])

  const currentRequirement = requirements.find(requirement => requirement.safety_ticket_id === ticket.id && requirement.work_force_position.id === user?.work_force_position?.id)

  return (
    <>
      {((currentRequirement?.required !== 'No') && currentRequirement) &&
        <>
          <div className='p-2 text-center content-center border border-gray-300'>
            {ticket.name}
          </div>
          <div className='p-2 text-center content-center border border-gray-300'>
            { currentRequirement?.required}
          </div>
          <div className='p-2 text-center content-center border border-gray-300'>
            {userTicket?.front_ticket_image_path && (
              <>
                <Image userTicket={userTicket} user={user} ticket={ticket} />
              </>
            )}
          </div>
          <div className='p-2 text-center content-center border border-gray-300'>
            {userTicket?.expiry ? <ExpiryComponent dateString={userTicket?.expiry} /> : ''}
          </div>
          <div className='p-2 text-center content-center border border-gray-300'>
            {userTicket?.created_by?.human_identifier}
          </div>
          <div className='p-2 text-center content-center border border-gray-300'>
            <AddUpdateTicketButton ticket={ticket} user={user} userTicket={userTicket} />
          </div>
        </>
      }
    </>
  )
}

export default Ticket
