import React, { useEffect, useRef, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit } from "@fortawesome/free-solid-svg-icons"

const TextInput = ({ fieldName, initialValue }) => {
  const [active, setActive] = useState(!!initialValue)
  const [input, setInput] = useState(initialValue)
  const textInputRef = useRef(null)

  useEffect(() => {
    if (active && !initialValue) textInputRef.current.focus()
  }, [active])

  return (
    <div className="relative h-full w-full flex flex-row justify-center cursor-pointer" onClick={()=>setActive(true)}>
      <input
        type="text"
        name={fieldName}
        ref={textInputRef}
        value={input || ''}
        onChange={ ( e ) => setInput(e.target.value) }
        className={`transition duration-500 w-full p-2 border border-gray-600 border-solid rounded-sm ${ active ? "opacity-100" : "opacity-0" }`}
      />
      {!active && <FontAwesomeIcon
        className={"text-xl text-gray-600 absolute top-1 z-10"}
        icon={faEdit}
      />}
    </div>
  )
}

export default TextInput
