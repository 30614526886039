import React from 'react'

const SiteRow = ({ attributePairs, TableCellTag = 'td' }) => {
  return (
    <tr>
      <TableCellTag className="w-1/2">{attributePairs[0]}</TableCellTag>
      <TableCellTag className="w-1/2">{attributePairs[1]}</TableCellTag>
    </tr>
  )
}

export default SiteRow
