import React, { useMemo } from 'react'
import { useAnalyticExportApi, useAnalyticExportContext } from '../../contexts/analyticExportContext'

const SampleCheckBox = ({ collection }) => {
  const { analyticExport } = useAnalyticExportContext()
  const { updateNestedState } = useAnalyticExportApi()

  const sampleCollectionIds = useMemo(() => {
    return analyticExport?.collection_analytic_exports_attributes.map(collection => collection?.sample_collection_id) || []
  }, [analyticExport])
  const isChecked = useMemo(() => sampleCollectionIds.includes(collection.id), [sampleCollectionIds, collection])
  const handleSelect = () => {
    const updatedSampleCollectionIds = isChecked
      ? sampleCollectionIds.filter(id => id !== collection.id)
      : [...sampleCollectionIds, collection.id]

    const mappedSampleCollectionIds = updatedSampleCollectionIds.map(id => ({ sample_collection_id: id }))
    updateNestedState('analyticExport', 'collection_analytic_exports_attributes')(mappedSampleCollectionIds)
  }

  return (
    <>
      <input className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        type="checkbox"
        checked={isChecked}
        onChange={handleSelect}
      />
    </>
  )
}

export default SampleCheckBox
