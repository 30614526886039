import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import CreatableSelect from "react-select/creatable"
import Select from "react-select"
import { isEmpty } from 'lodash'
import { pipe } from '../../utilities/utils'
import { optionizeEmailEntries, optionizeEmails } from '../../utilities/reportHelpers'
import { getEmailGroups } from '../../api/emailApi'
import ModalWrapper from './modalWrapper'

const EmailModal = ({ modal, title, fileName, onSend, onClose, project, emailSubject = '', emailBody = '' }) => {
  const { portfolioId, projectId } = useParams()
  const [emailGroupOptions, setEmailGroupOptions] = useState([])
  const [selectedEmailGroupOptions, setSelectedEmailGroupOptions] = useState([])
  const [emailOptions, setEmailOptions] = useState([])
  const [selectedEmailOptions, setSelectedEmailOptions] = useState([])
  const [subject, setSubject] = useState(emailSubject)
  const [body, setBody] = useState(emailBody)
  const getEmailGroupProjectId = project ? project.id : projectId

  useEffect(() => {
    getEmailGroups({ portfolioId, projectId: getEmailGroupProjectId })
      .then(res => {
        pipe(optionizeEmails('name', 'name'), setEmailGroupOptions)(res)
      })
  }, [getEmailGroupProjectId])

  useEffect(() => {
    if (!isEmpty(selectedEmailGroupOptions)) {
      const emails = selectedEmailGroupOptions.map(option => option.emails)
      const output = emails?.reduce((prev, curr) => [...prev, ...curr])
      pipe(optionizeEmailEntries('email', 'email'), setEmailOptions)(output)
    } else {
      setEmailOptions([])
    }
  }, [selectedEmailGroupOptions])

  useEffect(() => {
    setSelectedEmailOptions(emailOptions)
  }, [emailOptions])

  return (
    <>
      { modal && (
        <ModalWrapper
          heading={ `Email ${ title }` }
          closeModal={ onClose }
          onSend={ () => onSend({ selectedEmailOptions, subject, body }) }
          submitButtonText='Send'
        >
          <div className='w-full'>
            <label key="email-group-select" className="w-1/5 z-20">
              Add User Group
              <Select
                className='mb-4'
                isMulti
                options={ emailGroupOptions }
                onChange={ setSelectedEmailGroupOptions }
                value={ selectedEmailGroupOptions }
              />
            </label>
            <label key="recipient-select" className="w-1/5 z-20">
              Recipients
              <CreatableSelect
                className='mb-4'
                isMulti
                options={ emailOptions }
                onChange={ setSelectedEmailOptions }
                value={ selectedEmailOptions }
              />
            </label>
            <label key="email-subject" className="w-1/5 z-20">
              Subject
              <input
                className='block w-full p-1 text-xs text-gray-700 appearance-none focus:outline-none border border-gray-300 focus:shadow-inner rounded mb-4'
                type='text'
                value={ subject }
                onChange={ e => setSubject(e.target.value) }
              />
            </label>
            <label key="email-body" className="w-1/5 z-20">
              Email body
              <textarea
                className='block w-full p-1 text-xs text-gray-700 appearance-none focus:outline-none border border-gray-200 focus:shadow-inner rounded mb-4'
                rows='8'
                value={ body }
                onChange={ e => setBody(e.target.value) }
              />
            </label>
            { fileName && <label key="email-attachment" className="w-1/5 z-20">
              Attached
              <p
                className='block w-full p-1 text-xs text-gray-700 appearance-none focus:outline-none border border-gray-200 focus:shadow-inner rounded mb-4'>
                { [].concat(fileName).join(', ') || 'None' }
              </p>
            </label> }
          </div>
        </ModalWrapper>
      ) }
    </>
  )
}

export default EmailModal
