import React from 'react'
import ExportButton from './ExportButton'
import FormatSelect from './formatSelect'

const ExportCoc = ({onFormatSelect, exportPath, exportFormat, projectId}) => {
  return (
    <div className="flex w-1/2">
      <div className="flex-1">
        <FormatSelect onFormatSelect={onFormatSelect} />
      </div>
      <div className="flex-1">
        <ExportButton exportPath={exportPath} exportFormat={exportFormat} projectId={projectId} />
      </div>
    </div>
  )
}

export default ExportCoc
