import React from "react"
import { useDocumentBuilderContext } from "../../contexts/documentBuilderContext"
import ProjectLayout from "../../layouts/projectLayout"
import ActionsNav from "./actionsNav"
import Section from "./section"
import Breadcrumbs from "./breadcrumbs"

const DocumentBuilder = () => {
  const { template, sections, modal } = useDocumentBuilderContext()

  return (
    <ProjectLayout
      nestedBreadcrumbs={ <Breadcrumbs/> }
      pageTitle={
        <div className='pl-8 pb-5'>
          <h1 className='text-3xl font-bold'>{ template.name } Builder</h1>
        </div>
      }
    >
      <div className="flex flex-col gap-4 w-full z-0">
        <ActionsNav/>
        <div className="flex flex-col w-full p-8 gap-4 z-0">
          { sections.map(section => <Section key={ section.id } section={ section }/>) }
        </div>
      </div>
    </ProjectLayout>
  )
}

export default DocumentBuilder
