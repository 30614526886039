import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Select from "react-select"
import { useNoteFilterContext, useNoteFilterApi } from "../../../contexts/noteFilterContext"
import { getFormTemplates } from "../../../api/projectApi";
import { optionize } from "../../../utilities/utils";

const NotesDropdown = () => {
  const { projectId } = useParams()
  const { selectedFormTemplateName } = useNoteFilterContext()
  const { setFilter } = useNoteFilterApi()
  const [options, setOptions] = useState([])
  const [selectedHref, setSelectedHref] = useState()
  const mousePointer = selectedHref ? {} : { pointerEvents: 'none' }

  useEffect(() => {
    getFormTemplates(projectId)
      .then(res => {
        setOptions(optionize('name', 'url')(res.data))
      })
  }, [])
  
  const handleChange = (event) => {
    setSelectedHref(event.value)
    setFilter('selectedFormTemplateName')(event)
  }

  return (
    <div>
      <p className="py-2">Mobile Data</p>
      <Select
        className='capitalize w-full mb-5'
        placeholder={'Select'}
        options={options}
        value={selectedFormTemplateName}
        onChange={handleChange}
      />
      <div className="w-full flex justify-end">
        <a
          href={selectedHref}
          className='btn btn-primary truncate w-full text-center w-min text-sm py-1.5 px-3'
          style={mousePointer}
        >
          View Mobile Data
        </a>
      </div>
    </div>
  )
}

export default NotesDropdown
