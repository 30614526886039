import React from "react"
import { tableStyles } from "../../../utilities/stylesHelpers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { useTrackerAPI, useTrackerContext } from "../../../contexts/trackerContext"
import { notifyAlert } from "../../shared/notice"
import CollapseCell from "./collapseCell"

const DeleteCell = ({ entryId, renderPosition }) => {
  const { deleteEntry } = useTrackerAPI()
  const { frozenStyles, collapsedHeaders } = useTrackerContext()
  const styler = frozenStyles[renderPosition]?.entryStyles || {}
  const collapsed = collapsedHeaders.includes(renderPosition)

  const onClickHandler = () => {
    deleteEntry(entryId)
      .then(() => notifyAlert("Tracker Entry has been successfully deleted"))
  }

  return (<>
    {collapsed ? <CollapseCell styler={styler} /> :
      <td className={tableStyles.td} onClick={onClickHandler} style={styler}>
        <FontAwesomeIcon
          className={tableStyles.deleteIcon}
          icon={faTrash}
        />
      </td>
    }
  </>
  )
}

export default DeleteCell
