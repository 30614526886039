import React from "react"
import TimesheetFilters from "./filters"
import { TimesheetProvider } from "../../contexts/timesheetContext"
import TimesheetBreadcrumbs from "./breadcrumbs"
import UserSelect from "./userSelect"
import UserTable from "./userTable"

const Timesheet = () => {
  return (
    <TimesheetProvider>
      <div className="p-8 flex flex-col gap-8 h-full w-full overflow-auto">
        <TimesheetBreadcrumbs />
        <TimesheetFilters />
        <UserTable />
        <UserSelect />
      </div>
    </TimesheetProvider>
  )
}

export default Timesheet;
