import React, {useState, useEffect} from "react";
import LoadingSpinner from "../../shared/loadingSpinner";
import { getAdminFormTemplates } from "../../../api/portfoliosApi";
import FormTemplateTable from "./formTemplateTable";

const Portfolio = ({ portfolio }) => {
  const [formTemplates, setFormTemplates] = useState(null)
  const [loading, setLoading] = useState(true)
  const [attachedTemplates, setAttachedTemplates] = useState([])

  useEffect(() => {
    if (portfolio) {
      getAdminFormTemplates().then(res => setFormTemplates(res))
      setAttachedTemplates(portfolio.form_template_ids)
    }
  }, [portfolio])

  useEffect(() => {
    if (formTemplates) setLoading(false)
  }, [formTemplates])


  useEffect(() => {
    if (!loading) {
      setFormTemplates(prevState => {
        const attached = prevState.filter(template => attachedTemplates.includes(template.id))
        const notAttached = prevState.filter(template => !attachedTemplates.includes(template.id))
        
        return [...attached, ...notAttached]
      })
    }
  }, [loading, attachedTemplates])

  return <>
    <div className='mx-20 my-10 w-1/2'>
      <h1 className='my-2 text-xl'>{portfolio.name}</h1>
      {!formTemplates ? <><h1>Loading Form Templates...</h1><LoadingSpinner /></> : <FormTemplateTable portfolio={portfolio} formTemplates={formTemplates} attachedTemplates={attachedTemplates}  setAttachedTemplates={setAttachedTemplates} />}
    </div>
  </>
}

export default Portfolio
