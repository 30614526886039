import React, { useState } from "react"
import { useTrackerAPI, useTrackerContext } from "../../contexts/trackerContext"
import { notifyAlert } from "../shared/notice"
import StandardButton from "../shared/standardButton"
import ConfirmationModal from "../shared/confirmation_modal"

const DeleteMultipleEntryButton = () => {
  const { selectedEntryIds } = useTrackerContext()
  const { deleteMultipleEntries } = useTrackerAPI()
  const [showModal, setShowModal] = useState(false)
  const modalMessage = "Are you sure you want to delete the " + selectedEntryIds.length + " selected entries?"

  const confirmationHandler = () => {
    deleteMultipleEntries(selectedEntryIds), notifyAlert("Tracker Entry/Entries have been successfully DELETED")
    setShowModal(!showModal)
  }


  const onClickHandler = () => {
    setShowModal(!showModal)
  }

  return (
    <>
      {showModal && <ConfirmationModal title="Deletion Confirmation" children={modalMessage} onConfirm={confirmationHandler} onCancel={() => setShowModal(!showModal)} confirm_text='Approve' />}
      <StandardButton buttonText="Delete Selected" btnClass="btn-danger" onClickHandler={onClickHandler} />
    </>
  )
}

export default DeleteMultipleEntryButton
