import React, { useEffect, useMemo, useState } from 'react'
import ToggleWidgetButtons from '../../layouts/tablePage/toggleWidgetButtons'
import { isHyperlink } from "../../utilities/utils"
import { isSafeDomain, projectWidgetParams, setParams, widgetLinks } from "../../utilities/widgetHelpers"

export const whichWidget = (widget, project) => {
  if (widgetLinks[widget]) return widgetLinks[widget]

  if (isHyperlink(widget)) {
    if (isSafeDomain(widget) && project && !widget.includes('&crs=')) {
      return `${widget}${projectWidgetParams(project)}`
    }
    return widget
  }

  if (widget?.full_url) {
    if (widget.full_url.includes('lizmap')) {
      return `${widget.full_url}${projectWidgetParams(project)}`
    } else return widget.full_url
  }

  return `https://www.maps4free.ca/lizmap/lizmap/www/index.php/view/map/?repository=webmaps&project=Basemap_BingAerial${projectWidgetParams(project)}`
}

const Widget = ({ widgetProp, project, widgetStyles = '', portfolio = null, widgetChangeCallback = _ => {}, showToggle = true, buttonName = null, vertical = false }) => {
  const [widgetUrl, setWidgetUrl] = useState("")
  const [showWidget, setShowWidget] = useState(true)
  const containerClasses = useMemo(() => showWidget ? 'h-full w-full' : '', [showWidget])

  useEffect(() => {
    const newWidget = whichWidget(widgetProp, project)
    setParams(newWidget, setWidgetUrl, portfolio?.name)
  }, [widgetProp])

  useEffect(() => widgetChangeCallback(widgetUrl), [widgetUrl])

  return (
    <div className={`flex flex-col ${containerClasses}`}>
      <div className={`${vertical ? 'grid grid-cols-[auto,1fr]' : ''} mx-3 my-3`}>
        {showWidget && (
          <div className={`w-full p-4 pt-0 ${widgetStyles}`} id="widget">
            <iframe
              className='w-full h-full min-h-400'
              src={widgetUrl}
              sandbox='allow-same-origin allow-scripts allow-popups allow-popups-to-escape-sandbox allow-downloads allow-forms allow-modals'>
            </iframe>
          </div>
        )}
        {showToggle && <ToggleWidgetButtons buttonName={buttonName} toggleWidget={() => setShowWidget(!showWidget)} widget={widgetUrl} vertical={vertical} />}
      </div>
    </div>
  )
}

export default Widget
