import React from "react"
import { useParams } from "react-router-dom";
import { useReportContext } from "../../contexts/reportContext"
import ProjectReport from "./projectReport"
import PortfolioReport from "./portfolioReport"
import TablePageBreadcrumbs from "../../layouts/tablePage/tablePageBreadcrumbs"

const Report = ({ routes }) => {
  const { portfolioId, projectId } = useParams()
  const { report } = useReportContext()

  return (
    <div className="overflow-x-scroll h-full">
        <TablePageBreadcrumbs tableName={report?.name} tableSlug={report?.slug} scopeType={"reports"} />
        <div className='flex flex-col lg:flex-row w-full' style={{ height: "calc(100% - 49px)" }}>
          {projectId && <ProjectReport routes={routes} />}
          {portfolioId && <PortfolioReport />}
        </div>
    </div>
  )
}

export default Report
