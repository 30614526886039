import React from 'react'
import Select from 'react-select'

const FormatSelect = ({ onFormatSelect }) => {
  const formatOptions = [{ value: 'pdf', label: 'pdf' }, { value: 'csv', label: 'csv' }, { value:'labPackage', label: 'labPackage (pdf & csv for email)' }]
  return (
    <div>
    <h1>Select Export Format Style</h1>
      <Select
        options={formatOptions}
        onChange={onFormatSelect}
        defaultValue={formatOptions[0]}
      />
    </div>
  )
}

export default FormatSelect
