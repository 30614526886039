import { classNames } from "../../utilities/utils"

const theme = {
  heading: {
    h1: 'text-2xl text-neutral-950 m-0 mb-3 p-0',
    h2: 'text-xl text-neutral-600 font-semibold m-0 mt-2.5 p-0 uppercase',
    h3: 'editor-heading-h3',
    h4: 'editor-heading-h4',
    h5: 'editor-heading-h5',
  },
  image: 'editor-image',
  link: 'text-blue-600 no-underline',
  list: {
    ul: 'p-0 m-0 ml-4',
    ol: 'p-0 m-0 ml-4',
    listitem: 'my-2 ml-8',
    nested: {
      listitem: 'list-none',
    },
    ulDepth: [
      'list-disc',
      'list-circle',
      'list-square',
    ],
    olDepth: [
      'list-decimal',
    ],
  },
  paragraph: 'm-0 mb-2 relative last:mb-0',
  placeholder: 'text-slate-400 overflow-hidden absolute text-ellipsis top-4 left-2.5 inline-block pointer-events-none',
  text: {
    bold: 'font-bold',
    code: 'bg-slate-100 py-px px-1 font-mono',
    italic: 'italic',
    strikethrough: 'line-through',
    underline: 'underline',
    underlineStrikethrough: 'underline line-through',
  },
  quote: 'm-0 ml-5 text-neutral-500 border-l-4 border-neutral-300 pl-4',
  code: classNames(
    'bg-neutral-100 font-mono block p-2 pl-12 text-sm mx-0 my-2 overflow-x-auto relative',
    'before:content-[attr(data-gutter)] before:absolute before:bg-slate-100 before:left-0 before:top-0 before:border-r',
    'before:border-neutral-400 before:p-2 before:text-zinc-500 before:whitespace-pre-wrap before:text-right before:min-w-6',
    'after:content=[attr(data-highlight-language)] after:top-0 after:right-1 after:p-1 after:text-sm after:uppercase',
    'after:absolute after:opacity-50'
  ),
  rtl: 'rtl',
  ltr: 'ltr',
}

export default theme
