import React, { useCallback, useEffect, useState } from "react"
import { toolTipStyles } from "../../../utilities/stylesHelpers"

const setPositionToMouseListenerFunc = (setPosition) => (event) => {
  const mouseX = event.clientX
  const mouseY = event.clientY
  setPosition({ bottom: `${ window.innerHeight - mouseY }px`, left: `${ mouseX }px` })
}

const ToolTip = ({ toolTip, toolTipMessage }) => {
  const [position, setPosition] = useState({ bottom: 0, left: 0 })

  const setPositionToMouseListener = useCallback(setPositionToMouseListenerFunc(setPosition), [setPosition])

  useEffect(() => {
    if (toolTip) {
      document.addEventListener('mousemove', setPositionToMouseListener)
    } else {
      document.removeEventListener('mousemove', setPositionToMouseListener)
    }
    return () => document.removeEventListener('mousemove', setPositionToMouseListener)
  }, [toolTip])

  return toolTip && (
    <div className={ toolTipStyles.tooltiptext }
         style={ { bottom: position.bottom, left: position.left, zIndex: 11 } }>
      <strong>{ toolTipMessage }</strong>
    </div>
  )
}

export default ToolTip
