import React, { useMemo } from 'react'
import { tableStyles } from '../../utilities/stylesHelpers'
import StandardButton from '../shared/standardButton'
import { openInNewTab } from '../../utilities/helpers'
import { formatDateTimeStr } from '../../utilities/dateHelpers'
import { usePhotoAPIContext } from '../../contexts/photoContext'

const ExportHistoryRow = ({ exportObject, toggleModal }) => {
  const { updateDisplayExport } = usePhotoAPIContext()

  const dateValue = useMemo(() => formatDateTimeStr(exportObject.created_at), [exportObject])
  const onLoadReport = () => { window.open(exportObject.pdf_path, '_blank', 'noopener,noreferrer') }
  const onPreview = () => openInNewTab(`${exportObject.pdf_path}`)
  const onClickHistory = () => { toggleModal({ modalType: 'history' }), updateDisplayExport(exportObject) }
  
  return (
    <tr>
      <td className={tableStyles.td}>{exportObject.photo_export_filename}</td>
      <td className={tableStyles.td}>{dateValue}</td>
      <td className={tableStyles.td}>
        <StandardButton onClickHandler={onPreview} buttonText="Preview" />
        <StandardButton onClickHandler={onClickHistory} buttonText="View History" btnClass='btn-orange' />
        <StandardButton onClickHandler={onLoadReport} buttonText="Load Photo Report" btnClass='btn-green' />
      </td>
    </tr>)
}

export default ExportHistoryRow
