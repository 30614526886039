import React, { useMemo } from "react"
import { cellColors } from "../../../utilities/trackerHelpers"
import { simpleContentValue, tableStyles } from "../../../utilities/simpleTrackerTableHelpers"

const DefaultCell = ({ content, header, cellColor = 'white' }) => {
  const contentValue = useMemo(() => simpleContentValue(content, header), [content, header])

  return (
    <td className={ tableStyles.td } style={ { backgroundColor: cellColors[cellColor] } }>
      <p className={ tableStyles.tdText } style={ { backgroundColor: cellColors[cellColor] } }>{ contentValue }</p>
    </td>
  )
}

export default DefaultCell
