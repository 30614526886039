import React, { createContext, useContext, useEffect, useMemo, useReducer } from "react"
import { filterRows } from "../utilities/labAnalyticHelpers"
import { useTestResultsContext } from "./testResultsContext"
// /* Actions */
const UPDATE_STATE = "UPDATE_STATE"
const ADD_FILTER = "ADD_FILTER"
const FILTER = "FILTER"

// /* Initial State */
const initialState = {
  testResultRows: [],
  filteredTestResultRows: [],
  filteredContaminantGroups: [],
  contaminantGroups: [],
  chemicalUnits: [],
  availableChemicalUnits: [],
  valueOptions: {
    "apec": new Set(),
    "sample_date": new Set(),
    "contaminantGroup": [],
  },
  filters: { dateRange: { start_date: "", end_date: "" }, apecs: [], contaminantGroups: [] },
}

/* Reducer */
const testResultsFilterReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_STATE:
      return { ...state, [action.field]: action.value }
    case ADD_FILTER:
      return {
        ...state, filters: {
          ...state.filters,
          [action.field]: Array.isArray(action.value)
            ? [...action.value]
            : { ...state.filters[action.field], ...action.value }
        }
      }
    case FILTER:
      const newFilters = {
        ...state.filters,
        [action.field]: Array.isArray(action.value)
          ? [...action.value]
          : { ...state.filters[action.field], ...action.value }
      }
      const [filteredTestResultRows, filteredContaminantGroups, availableChemicalUnits] = filterRows(state.testResultRows, newFilters, state.contaminantGroups, state.chemicalUnits)
      return { ...state, filteredTestResultRows, filteredContaminantGroups, filters: newFilters, availableChemicalUnits }
    default:
      return state
  }
}

/* Contexts */
const TestResultsFilterContext = createContext(initialState)
const TestResultsFilterAPIContext = createContext({
  updateState: () => {},
})

/* Provider */
export const TestResultsFilterProvider = ({ children }) => {
  const [state, dispatch] = useReducer(testResultsFilterReducer, initialState)
  const { filterValues, testResultRows, chemicalUnits } = useTestResultsContext()

  const api = useMemo(() => {

    const updateState = (field, value) => dispatch({ type: UPDATE_STATE, field, value })

    const addFilter = (field, value) => dispatch({ type: ADD_FILTER, field, value })

    const filter = (field, value) => dispatch({ type: FILTER, field, value })

    return {
      updateState, addFilter, filter
    }
  }, [])

  useEffect(() => {
    const { filterValues: values } = filterValues

    if (values) {
      const updatedValueOptions = {
        ...values,
        contaminantGroup: values.contaminantGroup.flatMap(Object.keys),
      }

      api.updateState('valueOptions', updatedValueOptions)
      api.updateState('contaminantGroups', values.contaminantGroup)
      api.updateState('filteredContaminantGroups', values.contaminantGroup)
    }
  }, [filterValues])

  useEffect(() => {
    if (testResultRows) {
      api.updateState('testResultRows', testResultRows)
      api.updateState('filteredTestResultRows', testResultRows)
    }
  }
    , [testResultRows])

  useEffect(() => {
    if (chemicalUnits) {
      api.updateState('chemicalUnits', chemicalUnits)
      api.updateState('availableChemicalUnits', chemicalUnits)
    }
  }, [chemicalUnits])
    

  return (
    <TestResultsFilterAPIContext.Provider value={api}>
      <TestResultsFilterContext.Provider value={state}>
        {children}
      </TestResultsFilterContext.Provider>
    </TestResultsFilterAPIContext.Provider>
  )
}

/* Custom Hooks */
export const useTestResultsFilterContext = () => useContext(TestResultsFilterContext)
export const useTestResultsFilterApi = () => useContext(TestResultsFilterAPIContext)
