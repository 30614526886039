import { isString } from "lodash"
import { notifyError, notifyInfo } from "../components/shared/notice"
import { pluck } from "./utils"

export const deleteAiDocuments = (ids, documents) => {
  return documents.filter(document => !ids.includes(document.id))
}

export const updateAiDocumentField = (id, field, value, documents) => {
  return documents.map(doc => doc.id === id ? { ...doc, [field]: value } : doc)
}

export const updateAiDocumentsField = (field, value, documents, selectedDocuments) => {
  return documents.map(doc => selectedDocuments.includes(doc.id) ? ({ ...doc, [field]: value }) : doc)
}

export const updateAiDocuments = (stateDocuments, updatedDocuments) => {
  const updatedDocIds = pluck(updatedDocuments, "id")
  return stateDocuments.reduce((acc, currentDoc) => (
    [...acc, updatedDocIds.includes(currentDoc.id) ? updatedDocuments.find(doc => doc.id === currentDoc.id) : currentDoc]
  ), [])
}

export const updateSelectAll = (documents, selectedDocuments) => {
  const ids = documents.length === selectedDocuments.size ? [] : documents.map(doc => doc.id)
  return new Set(ids)
}

export const updateSelectedDocuments = (selectedDocuments, documentId) => {
  const inPlaceSet = new Set(selectedDocuments)
  return inPlaceSet.delete(documentId) ? inPlaceSet : inPlaceSet.add(documentId)
}

export const handleBroadcast = input => {
  if (isString(input)) {
    notifyInfo(input)
  } else if (input.in_progress) {
    notifyInfo(`Waiting for ${ input.in_progress.length } documents...`)
  } else if (input.failed) {
    notifyError([
      "The following documents encountered errors:",
      ...input.failed.flatMap(failed_file =>
        [failed_file.name, failed_file.last_error.code, failed_file.last_error.message].join(" "))
    ].join(" "))
  }
}

export const getValueByFilter = (documents, filter) => {
  const values = documents.map(doc => doc[filter])
  const valueSet = new Set(values)

  return [...valueSet].map(value => value && ({ label: value.charAt(0).toUpperCase() + value.slice(1), value: value }))
}

export const getFilteredDocuments = (documents, filter, value) => {
  if (!value) return documents

  return documents.filter(doc => doc[filter] === value)
}

export const fileNameParsed = fileName => fileName?.split(/[-_]/)?.join(' ')
