import React, { useMemo } from 'react'
import { isEmpty } from "lodash"
import { useProjectContext } from "../../../contexts/projectContext";
import NoteRow from "../noteRow";
import LoadingSpinner from "../../shared/loadingSpinner";
import ForemanNoteRow from "../foremanReport/foremanNoteRow";
import { compareDate } from "../../../utilities/dateHelpers"
import useNoteQuery from "../../../hooks/useNoteQuery";
import { noteLayoutException } from "../../../utilities/demoHelpers"
import NotesHeader from "./notesHeader"
import Onboarding from "../../shared/onboarding"
import { PhotoProvider } from '../../../contexts/photoContext';
import AssessmentLayout from "./assessmentLayout"
import ConstructionLayout from "./constructionLayout"
import LightBox from "../../photoGallery/photo/lightBox"

const showAsForemanReport = (routes, project) => (routes?.exportForemanReportPath || noteLayoutException(project.name))

const RenderNotes = ({ notes, routes, project }) => {
  const isForemanReport = showAsForemanReport(routes, project)

  return (!isEmpty(notes) &&
    <div className={`flex overflow-x-scroll ${isForemanReport ? 'flex-col' : 'flex-row min-h-10'}`}>
      {isForemanReport && notes.sort(compareDate).map((note, index) => <ForemanNoteRow key={`${note.id}-${index}`} note={note} />)}
      {!isForemanReport && notes.map(note => <NoteRow key={note.id} note={note} />)}
    </div>
  )
}

const Notes = ({ children }) => {
  const noteQuery = useNoteQuery()
  const { notesToView } = noteQuery
  const { routes, notes, project, portfolioType, loading, showMap, portfolio } = useProjectContext()
  const isConstruction = portfolioType !== 'Assessment'
  const LayoutComponent = useMemo(() => isConstruction ? ConstructionLayout : AssessmentLayout, [isConstruction])

  const showFormNameFilter = () => {
    return project.name !== 'Wolf_Weld_Mapping'
  }

  return (
    <PhotoProvider>
      <div className='flex flex-col flex-1 h-full'>
        <NotesHeader />
        {loading && <LoadingSpinner />}
        {!loading && !isEmpty(notes) && (
          <LayoutComponent project={project} portfolio={portfolio} routes={routes} showMap={showMap} showFormNameFilter={showFormNameFilter} >
            <RenderNotes notes={notesToView} routes={routes} project={project} />
            {children}
          </LayoutComponent>
        )}
        {!loading && isEmpty(notes) && (
          <div className='flex flex-col lg:flex-row lg:gap-x-2 flex-grow overflow-hidden'>
            <div className='flex flex-col w-full h-2/3 lg:h-auto lg:w-1/2 flex-grow overflow-scroll'>
              <Onboarding title='No notes found.' />
            </div>
          </div>
        )}
      </div>
      <LightBox />
    </PhotoProvider>
  )
}

export default Notes
