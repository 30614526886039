import React, { useRef } from "react"
import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { usePermitTableAPI } from "../../../contexts/permitTableContext"
import { tableStyles } from "../../../utilities/permitHelpers"
import { isHyperlink } from "../../../utilities/utils"

const TableHeaderCell = ({ content }) => <div className={tableStyles.thContent} >{content}</div>

const HyperlinkWrapper = ({ content }) => (
  <a
    className={'font-bold hover:underline'}
    target="_blank"
    rel="noopener noreferrer"
    href={content}
    onClick={(e) => e.stopPropagation()}
  >
    Download
  </a>
)

const DefaultCell = ({ content }) => (
  <>
    <p className={tableStyles.tdText}>{content}</p>
    <FontAwesomeIcon
      className={tableStyles.hoverIcon}
      icon={faEdit}
    />
  </>
)

const InnerContent = ( content, tag = 'td' ) => (
  <>
    {
      (tag === 'th') ?
        <TableHeaderCell content={content} /> :
        isHyperlink(content) ?
          <HyperlinkWrapper content={content} /> :
          <DefaultCell content={content} />
    }
  </>
)

const TableCell = ({ content, header, permitId, tag = 'td' }) => {
  const { onContentInit, toggleModal } = usePermitTableAPI()
  const cellRef = useRef()
  const cellStyle = tableStyles[tag]

  const onClickHandler = () => {
    if (cellRef.current.nodeName === 'TD' && permitId) {
      toggleModal()
      onContentInit(content, header, permitId)
    }
  }

  return React.createElement(tag, { className: cellStyle, onClick: onClickHandler, ref: cellRef, style: {minWidth: "120px"} }, InnerContent(content, tag))
}

export default TableCell
