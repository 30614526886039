import { format } from "date-fns"

export const formatDate = entry => {
  const createdDate = format(new Date(entry.created_at), "iii, MMM do, y 'at' ppp")

  return {
    ...entry,
    created_at: createdDate
  }
}

export const mapEntries = (func) => (entries) => entries.map(entry => func(entry))

export const sortEntries = entries => entries.sort((a, b) => {
  const aDate = new Date(a.created_at)
  const bDate = new Date(b.created_at)

  switch(+aDate < +bDate) {
    case true:
      return 1
    case false:
      return -1
    default:
      return 0
  }
})
