import React from "react"
import { useParams } from "react-router-dom"
import { useDocumentLogContext } from "../../contexts/documentLogContext"
import ProjectLayout from "../../layouts/projectLayout"
import Breadcrumb from "../../layouts/nav/breadcrumb"
import Template from "./template"

const DocumentLog = () => {
  const { projectId } = useParams()
  const { templates } = useDocumentLogContext()

  return (
    <ProjectLayout nestedBreadcrumbs={
      <Breadcrumb linkPath={ `/projects/${ projectId }/document-log` } linkName="Document Log" routerLink={ true }/>
    }>
      <div className='flex flex-col w-full md:w-3/4 p-8 pt-0'>
        <div className="grid grid-cols-2 gap-4 items-start">
          { templates.map((template) => (
            <Template key={ template.slug } template={ template }/>
          )) }
        </div>
      </div>
    </ProjectLayout>
  )
}

export default DocumentLog
