import { UPDATE_VALUE, SET_NOTE, SET_OPTIONS, SET_EXPORTS } from "../actions/siteInspectionReports";

export const initialSiteInspectionReportsState = {
  siteLocation: '',
  assessedBy: '',
  assessmentType: 'M and M Inspection',
  date: null,
  options: null,
  note: null,
  exporting: false,
  exports: [],
}

export const initialSiteInspectionReportsApiState = {
  onInputChange: () => {},
  onNoteSelect: () => {},
  onInitializeOptions: () => {},
  addToExports: () => {}
}

export const siteInspectionReportsReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_VALUE:
      return { ...state, [action.field]: action.value }
    case SET_NOTE:
      return { ...state, note: action.note }
    case SET_OPTIONS:
      return { ...state, options: action.options }
    case SET_EXPORTS:
      return {...state, exports: [ ...action.exports, ...state.exports] }
    default:
      return state
  }
}
