import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import ExportBreadCrumbs from "./exportBreadCrumbs"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";

export const ExportContext = React.createContext({
  notes: null,
  setNotes: null,
  selectedNoteId: null,
  project: null,
  routes: null,
  loading: null,
  notesCount: null,
  localNotesCount: null,
  hasSampleData: null
})

export const ExportContextProvider = ({ children, project, routes }) => {
  let { projectId, noteId } = useParams()
  let [notes, setNotes] = useState(null)
  const [loading, setLoading] = useState(true)
  const [notesCount, setNotesCount] = useState(null)
  const [localNotesCount, setLocalNotesCount] = useState(null)

  useEffect(() => {
    axios
      .get(routes.notesPath, { params: { projectId: projectId } })
      .then((response) => {
        setNotes(response.data)
        setLoading(false)
      })

  }, [loading])

  const hasSampleData = () => {
    return notes && notes.filter((note) => note.has_sample_data).length > 0
  }

  useEffect(() => {
    axios.get(routes.notesCountPath).then(res => {
      setLocalNotesCount(res.data.local_notes_count)
      setNotesCount(res.data.notes_count)
    })
  }, [])

  return (
    <ExportContext.Provider
      value={{
        project: project,
        notes: notes,
        setNotes: setNotes,
        selectedNoteId: noteId,
        routes: routes,
        loading: loading,
        notesCount: notesCount,
        localNotesCount: localNotesCount,
        hasSampleData: hasSampleData()

      }}>
      {children}
    </ExportContext.Provider>
  )
}

const Export = ({ children, project, routes }) => {

  return (
    <ExportContextProvider project={project} routes={routes} >
      <div className='flex flex-row justify-between'>
        <ExportBreadCrumbs />
      </div>
      {children}
    </ExportContextProvider>
  )
}

export const ExportButton = ({ link, children }) => {
  const  title = children === 'CoC' ? 'Export CoC' : ` Export ${children} Reports`
  return (
    <a className="btn btn-primary truncate w-full text-center" href={link}>
      <FontAwesomeIcon icon={faFilePdf} className="mr-2" />
      {title}
    </a>
  )
}

export default Export
