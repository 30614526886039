import React from 'react'
import { useSampleCollectionApi } from '../../../contexts/sampleCollectionContext'
import SampleSelectButton from '../sampleCheckBox'

const SampleCollectionRow = ({ collection }) => {
  const { onDelete } = useSampleCollectionApi()
  return (
    <>
      <div className='p-2 text-center border border-gray-300'>
        <SampleSelectButton collection={collection} />
      </div>
      <div className='p-2 text-center border border-gray-300'>
        {collection?.lot_number || 'N/A'}
      </div>
      <div className='p-2 text-center border border-gray-300'>
        {collection?.start_date || 'N/A'}
      </div>
      <div className='p-2 text-center border border-gray-300'>
        {collection?.end_date || 'N/A'}
      </div>
      <div className='p-2 text-center border border-gray-300'>
        {collection?.event_type || 'N/A'}
      </div>
      <div className='p-2 text-center border border-gray-300'>
        {collection?.lab?.company || 'N/A'}
      </div>
      <div className='p-2 text-center border border-gray-300'>
        {collection?.result_date || 'N/A'}
      </div>
      <div className='p-2 text-center border border-gray-300'>
        <a
          className='btn btn-primary truncate cursor-pointer w-fit h-fit font-semibold'
          href={collection.results_csv}
          target="_blank"
          rel="noreferrer noopener"
        >
          Download
        </a>
      </div>
      <div className='p-2 text-center border border-gray-300'>
        {collection?.results_pdf ?
          <a
            className='btn btn-primary truncate cursor-pointer w-fit h-fit font-semibold'
            href={collection.results_pdf}
            target="_blank"
            rel="noreferrer noopener"
          >
            Download
          </a> : 'N/A'}
      </div>
      <div className='p-2 text-center border border-gray-300'>
        N/A
      </div>
      <div className='p-2 text-center border border-gray-300'>
        <button
          className='btn btn-danger cursor-pointer text-center w-fit h-fit font-semibold'
          onClick={() => { onDelete(collection.id) }}
        >
          Delete
        </button>
      </div>
    </>)
}

export default SampleCollectionRow
