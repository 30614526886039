import React, { useState } from 'react'
import { AnalyticExportProvider } from '../../contexts/analyticExportContext'
import { SampleCollectionProvider } from '../../contexts/sampleCollectionContext'
import Widget from '../shared/widget'
import AnalyticExportTable from './analyticExports/analyticExportTable'
import LabBreadCrumbs from './labBreadCrumbs'
import SampleCollectionTable from './sampleCollections/sampleCollectionTable'

const LabAnalytics = ({ project, routes }) => {
  const [widgetUrl, setWidgetUrl] = useState('')

  return (
    <div className="px-4 w-full h-full">
      <LabBreadCrumbs project={project} routes={routes} extension={'sample-collections'} />
      <div className='w-full h-full flex flex-row'>

        <div className="flex flex-col pr-4 pl-4 h-full flex-shrink flex-grow">
          <div className='grid grid-cols-[auto,1fr] mx-3 my-3'>
            <a href={widgetUrl} target="_blank" rel="noreferrer noopener"
              className="btn btn-primary font-semibold cursor-pointer text-center lg:rounded-t-md lg:rounded-b-none"
              style={{ writingMode: 'vertical-rl', transform: 'rotate(180deg)' }}>
              Open Map
            </a>
            <Widget widgetProp={project?.default_widget?.full_url} project={project}  widgetChangeCallback={setWidgetUrl} vertical={true} />
          </div>
        </div>

        <div className='flex-grow px-2 py-4'>
          <div className={`w-full px-2 py-4 `}>
            <h2 className="text-2xl font-bold">PROJECT: {project?.name} </h2>
          </div>
          <SampleCollectionProvider>
            <AnalyticExportProvider>
              <SampleCollectionTable />
              <AnalyticExportTable />
            </AnalyticExportProvider>
          </SampleCollectionProvider>
        </div>
      </div>
    </div>
  )
}

export default LabAnalytics
