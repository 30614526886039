import React from "react"
import { useTrackerAPI } from "../../../contexts/trackerContext"
import BaseHoverIcon from "../../tableTemplates/icons/hoverIcon"

const HoverIcon = ({ icon, message = 'N/A', onClickHandler }) => {
  const { toggleToolTip, updateToolTipMessage } = useTrackerAPI()

  return <BaseHoverIcon icon={ icon } message={ message } onClickHandler={ onClickHandler } toggleFunc={ toggleToolTip }
                        updateFunc={ updateToolTipMessage }/>
}

export default HoverIcon
