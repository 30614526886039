/**
 * Returns true if input string is a valid url.
 * Returns false other wise
 * @param input
 * @returns {boolean}
 */
export const isHyperlink = (input) => {
  try {
    return !!new URL(input, input)
  } catch (_) {
    return false
  }
}

/**
 * Pipe function taking a number of functions and piping a subject through them.
 *
 * ie.<br />
 * func1 = (x) => x + 10<br />
 * func2 = (x) => x * 2<br />
 * func3 = (x) => x - 4<br />
 *<br/>
 * pipe(func1, func2, func3)(2)<br />
 * would call func1 with the value of 2. Then call func2 with the value returned from func1.
 * Then call func3 with the value returned from func2. Then return the result.
 *<br/>
 * pipe(...functions)(subject)
 *
 * @param pipeline A comma separated list of functions<br/>
 * @param subject The value to be operated on by each function in turn
 * @returns {function(*): *} The result of subject being piped to each function
 */
export const pipe = (...pipeline) => (subject) => pipeline.reduce((aggregate, func) => func(aggregate), subject)

export const parameterizeString = (string) => {
  return string.trim().toLowerCase().replace(/[^a-zA-Z0-9 -]/, "").replace(/\s/g, "-")
}

export const directedFlatten = (direction) => (inputArray) => inputArray.flatMap(element => element[direction])

export const optionize = (labelAttr, valueAttr = labelAttr) => (options) => {
  return options?.map(option => ({ label: option?.[labelAttr], value: option?.[valueAttr] }))
}

export const classNames = (...classes) => classes.filter(Boolean).join(' ')

export const readAsDataURL = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = (error) => reject(error)
    reader.onload = () => resolve(reader.result)
    reader.readAsDataURL(file)
  })
}

/**
 * Maps an array of objects to an object
 *
 * @param removeKey Should the key property be included in the mapped object
 * @param key The property of the objects that should be used as the key in the returned object
 * @param propertyMappings An object with shallow keys to replace
 * @returns {Function} A function that takes an array of objects and returns a mapped object
 */
export const mapArrayToObject = ({ removeKey = false, key = 'id', propertyMappings = {} }) => (inputArray) => {
  return inputArray.reduce((acc, item) => {

    // Apply property mappings and remove key
    const remappedItem = Object.entries(item).reduce((obj, [propName, value]) => {
      const newPropName = propertyMappings[propName] || propName

      if (!(removeKey && propName === key)) obj[newPropName] = value

      return obj
    }, {})

    return { ...acc, [item[key]]: remappedItem }
  }, {})
}

export const pluck = (arr, key) => arr.map(i => i[key])
