import React, { useCallback, useState } from 'react'
import { parameterizeString } from "../../utilities/utils"
import ToolTip from '../tableTemplates/icons/toolTip'

const StandardButton = ({ onClickHandler, buttonText, buttonStyles, btnClass = 'btn-primary', toolTipMessage, vertical = false }) => {
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, [])

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false)
  }, [])

  return (
    <>
      <button
        className={`btn ${btnClass} font-semibold m-4 relative cursor-pointer z-10 ${buttonStyles}`}
        id={parameterizeString(buttonText) + '-button'}
        name={parameterizeString(buttonText) + '-btn'}
        onClick={onClickHandler}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
                style={vertical ? { writingMode: 'vertical-rl', transform: 'rotate(180deg)' } : {}}
      >
        {buttonText}
      </button>
      {toolTipMessage && <ToolTip toolTip={isHovered} toolTipMessage={toolTipMessage} />}
    </>
  )
}

export default StandardButton
