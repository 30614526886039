import API, { simpleGet } from "./apiConfig"
import { projectOrPortfolioPath } from "../utilities/projectOrPortfolioHelpers"

const csrfToken = document.querySelector('[name=csrf-token]').content

export const buildReportPath = ({ portfolioId, projectId, reportSlug, postFix = '.json' }) => {
  const baseUrl = projectOrPortfolioPath({ portfolioId, projectId })
  const reportPath = reportSlug ? `reports/${reportSlug}` : 'reports'
  return `${baseUrl}/${reportPath}${postFix}`
}

export const getDateRange = ({ portfolioId, projectId, reportSlug }) => {
  const reportPath = buildReportPath({ portfolioId, projectId, reportSlug, postFix: '' })
  return API.get(`${reportPath}/date-range`).then(res => res.data)
}

export const generateReport = ({ portfolioId, projectId, reportSlug, formData }) => {
  const reportPath = buildReportPath({ portfolioId, projectId, reportSlug, postFix: '' })
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API.post(`${reportPath}/generate-export`, formData).then(res => res.data)
}

export const deleteReport = ({ portfolioId, projectId, params }) => {
  const reportPath = projectOrPortfolioPath({ portfolioId, projectId })
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API.delete(`${reportPath}/tracker-report`, { params }).then(res => res.data)
}

export const getTrackerReports = ({ portfolioId, projectId }) => {
  const basePath = projectOrPortfolioPath({ portfolioId, projectId })
  return simpleGet(`${basePath}/tracker-reports`)
}

export const getReportTemplates = ({ portfolioId, projectId }) => {
  const basePath = projectOrPortfolioPath({ portfolioId, projectId })
  return simpleGet(`${basePath}/reports`)
}
