import React, { useEffect, useState, useMemo } from 'react'
import { updateSample } from "../../utilities/cocExportHelpers"
import SampleInformationRow from './sampleSelectComponents/sampleInformationRow'
import ChemicalList from './sampleSelectComponents/chemicalList'
import CocCheckBoxes from "./CocCheckBoxes"
import { useCocExport, useCocExportApi } from "../../contexts/cocExport"
import SampleSelectionToggle from './sampleSelectComponents/sampleSelectionToggle'

const ExportSampleSelect = ({ sample, chemicals, hasBlackBorderBottom, title, position }) => {
  const [hsv, setHsv] = useState(true)
  const [ssv, setSsv] = useState(true)
  const [selectedChemicals, setSelectedChemicals] = useState([])
  const { updateSelectedSamples } = useCocExportApi()
  const { selectedSamples } = useCocExport()

  const currentSample = selectedSamples.find(sampleObject => Object.keys(sampleObject)[0] == sample.sampleId)
  const selected = useMemo(() => currentSample?.selected, [currentSample?.selected])
  const middlePositon = useMemo(() => position.location === Math.ceil(position.total / 2), [position])

  useEffect(() => setSelectedChemicals(currentSample?.chemicalNames || []), [chemicals])
  useEffect(() => updateSample(sample.sampleId, hsv, ssv, selectedChemicals, selectedSamples, updateSelectedSamples)
    , [hsv, ssv, selectedChemicals])

  return (
    <tr className={`p-4 ${hasBlackBorderBottom ? 'border-b-4' : ''} border-black justify-between shadow-lg rounded-lg bg-white w-full items-center hover:bg-gray-100 ${selected ? 'opacity-100' : 'opacity-25'}`}>
      <td className=' border-l border-black'>
        <strong>{middlePositon ? title : ''}</strong>
      </td>
      <SampleSelectionToggle sampleId={sample.sampleId} selectedSamples={selectedSamples} updateSelectedSamples={updateSelectedSamples} selected={selected} />
      <SampleInformationRow sample={sample} />
      <CocCheckBoxes hsv={hsv} setHsv={setHsv} ssv={ssv} setSsv={setSsv} />
      <ChemicalList chemicals={chemicals} selectedChemicals={selectedChemicals} setSelectedChemicals={setSelectedChemicals} />
    </tr>
  )
}

export default ExportSampleSelect
