import API, { simpleGet, simplePut } from "./apiConfig"

const csrfToken = document.querySelector('[name=csrf-token]').content

export const getDocuments = () => {
  return API
    .get('/ai/documents.json')
    .then(res => res.data)
}

export const getDocument = (documentId) => {
  return simpleGet(`/ai/documents/${ documentId }.json`)
}

export const createDocuments = (files) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API
    .post('/ai/documents', files)
    .then(res => res.data)
}

export const deleteDocuments = (ids) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API
    .delete(`/ai/documents?id=${ ids.join(",") }`)
    .then(res => res.data)
}

export const updateDocument = (id, body) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API
    .patch(`/ai/documents/${ id }`, body)
    .then(res => res.data)
}

export const updateDocuments = (ids, body) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API
    .patch(`/ai/documents?id=${ ids.join(",") }`, body)
    .then(res => res.data)
}

export const getBasicSummary = (id) => {
  return API
    .get(`/ai/ask_your_pdf/${ id }/basic_summary`)
    .then(res => res.data)
}

export const getProjectDocuments = (projectId) => {
  return API
    .get(`/projects/${ projectId }/ai/documents.json`)
    .then(res => res.data)
}

export const createProjectDocuments = (projectId, files) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API
    .post(`/projects/${ projectId }/ai/documents`, files)
    .then(res => res.data)
}

export const updateProjectDocuments = (projectId, ids, body) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API
    .patch(`/projects/${ projectId }/ai/documents?id=${ ids.join(",") }`, body)
    .then(res => res.data)
}

export const deleteProjectDocuments = (projectId, ids) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API
    .delete(`/projects/${ projectId }/ai/documents?id=${ ids.join(",") }`)
    .then(res => res.data)
}

export const uploadProjectDocumentToOpenai = (projectId, id) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API
    .post(`/projects/${ projectId }/ai/${ id }/upload_to_openai`)
    .then(res => res.data)
}

export const uploadDocumentToOpenai = (id) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API
    .post(`/ai/${ id }/upload_to_openai`)
    .then(res => res.data)
}

export const runPromptsOnProject = (projectId, prompt_collection_id) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API
    .post(`/projects/${ projectId }/ai/run_prompts`, { prompt_collection_id })
    .then(res => res.data)
}

export const createJsonVersion = (id, projectId) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  const projectPath = projectId ? `/projects/${ projectId }` : ""
  return API
    .post(`${ projectPath }/ai/${ id }/create_json_version`)
    .then(res => res.data)
}

export const createCsvVersion = (documentIds, projectId) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  const projectPath = projectId ? `/projects/${ projectId }` : ""
  return API
    .post(`${ projectPath }/ai/documents/create_csv_version`, { id: documentIds.join(",") })
    .then(res => res.data)
}

export const getStatuses = () => simpleGet('/ai/statuses')

export const updateTextractTable = (tableId, body) => simplePut(`/ai/tables/${ tableId }`, body)
