import { classNames } from "./utils"

export const buttonStyles = "btn btn-primary leading-6 font-semibold relative cursor-pointer inline-block md:whitespace-nowrap"

export const downloadButtonStyles = (isDisabled, otherStyles = buttonStyles) => {
  return classNames(otherStyles, isDisabled && "pointer-events-none opacity-50")
}

export const updateDocument = (documents, updatedDocument) => {
  return {
    ...documents,
    [updatedDocument.template_id]: documents[updatedDocument.template_id].map(document => {
      return (document.id !== updatedDocument.id) ? document : { ...document, ...updatedDocument }
    })
  }
}
