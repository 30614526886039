import React, { forwardRef } from "react"
import { useTrackerAPI, useTrackerContext } from "../../../contexts/trackerContext"

const NumberInput = forwardRef(({ placeholder = '0.00', step = '0.01' }, inputRef) => {
  const { editCellContent } = useTrackerContext()
  const { onContentChange } = useTrackerAPI()

  return (
    <input
      className='block w-full p-1 text-xs text-gray-700 appearance-none focus:outline-none border border-gray-200 focus:shadow-inner m-0 rounded'
      type='number'
      step={step}
      min="0"
      ref={inputRef}
      placeholder={placeholder}
      value={editCellContent}
      onChange={e => onContentChange(e.target.value)}
    />
  )
})

export default NumberInput
