import React from 'react'
import AnimatedCheckbox from "../../shared/animatedCheckbox"

const HeaderCheckBox = ({ title, field, value, update }) => {
  return (
    <div className='w-full flex flex-row items-center gap-x-2'>
      <AnimatedCheckbox checked={ value } onClick={ () => update(field, !value) }/>
      <label className='text-lg font-medium py-1'>{ title }</label>
    </div>
  )
}

export default HeaderCheckBox
