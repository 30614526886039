import React, { useEffect, useState } from "react"
import { tableStyles } from "../../../utilities/stylesHelpers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit } from "@fortawesome/free-solid-svg-icons"

const CurrencyCell = ({ content, styler, onClickHandler, collapsed = false  }) => {
  const [cellContent, setCellContent] = useState('')
  const tdTextStyles = collapsed ? tableStyles.tdTextTruncate : tableStyles.tdText
  const tdStyles = collapsed ? tableStyles.tdCollapsed : tableStyles.td

  useEffect(() => {
    if (content?.value) {
      const formatter = new Intl.NumberFormat('en-CA', {
        style: 'currency',
        currency: 'CAD',
      });
      setCellContent(formatter.format(content.value))
    }
  }, [content])

  return (
    <td className={tdStyles}
      style={{...styler}}
      onClick={onClickHandler}>
      <p className={tdTextStyles}>{cellContent}</p>
      <FontAwesomeIcon
        className={tableStyles.hoverIcon}
        icon={faEdit}
      />
    </td>
  )
}

export default CurrencyCell
