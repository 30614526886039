import React, { useEffect, useState } from "react"
import axios from "axios"
import "react-datepicker/dist/react-datepicker.css"
import AuditExportButton from "./AuditExportButton"
import { notifyAlert, notifyError } from "../../shared/notice"
import DateRangeSelect from "./DateRangeSelect";
import Select from "react-select";

const AuditExport = ({ exportPath, foremanNames }) => {
  const [loading, setLoading] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(null)
  const [selectedEndDate, setSelectedEndDate] = useState(null)
  const [foremanName, setForemanName] = useState('')
  const [options, setOptions] = useState([])

  const getPdfExport = () => {
    axios.post(exportPath, {
      start_date: selectedStartDate,
      end_date: selectedEndDate,
      foreman_name: foremanName
    })
      .then(res => {
        notifyAlert('Please wait while your report is being generated')
        window.open(res.data.pdf_path , '_blank');
        setLoading(false)
    })
      .catch(err => {
        console.error(err)
        notifyError(err.response.data)
        setLoading(false)
      })
  }

  const onChangeDate = (title, date) => {
    title === "Start" ? setSelectedStartDate(date) : setSelectedEndDate(date)
  }

  useEffect(() => {
    if (foremanNames) {
      setOptions(foremanNames.map(name => {
        return { value: name, label: name }
      }))
    }
  }, [])

  return (
    <div className='flex flex-row w-full justify-between p-8'>
      <div className='flex flex-col gap-y-4'>
        <h1>Run Foreman Audit Report</h1>
        <div>
          <p>Report Filled By:</p>
          <Select
            options={options}
            onChange={(selectedOption) => setForemanName(selectedOption.value)}
          />
        </div>
        <DateRangeSelect
          selected={selectedStartDate}
          onChangeDate={onChangeDate}
          title={"Start"}
        />
        <DateRangeSelect
          selected={selectedEndDate}
          onChangeDate={onChangeDate}
          title={"End"}
        />
        <AuditExportButton
          loading={loading}
          setLoading={setLoading}
          selectedStartDate={selectedStartDate}
          selectedEndDate={selectedEndDate}
          foremanName={foremanName}
          getPdfExport={getPdfExport}
        />
      </div>
    </div>
  )
}

export default AuditExport
