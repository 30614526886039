import axios from "axios"
import React, { useCallback, useMemo, useReducer, useState } from "react"
import { isEmpty } from "lodash"
import { useProjectContext } from "../../contexts/projectContext"
import { getGisPdfTemplates } from "../../utilities/formTemplateHelpers"
import EmailModal from "../modal/emailModal"
import RenderModal from "../modal/renderModal"
import { toggleReducer } from "../../utilities/helpers"
import { toast } from "react-toastify"
import { emailQgisReport } from "../../api/emailApi"
import { notifyError } from "../shared/notice"

const GisPdfButton = ({ formData, formTemplate }) => {
  const [generatingReport, setGeneratingReport] = useState(false)
  const [emailModal, toggleEmailModal] = useReducer(toggleReducer, false)
  const { gisPdfTemplates } = useProjectContext()

  const gisPdfTemplate = useMemo(() => {
    return getGisPdfTemplates(gisPdfTemplates, formTemplate.name)
  }, [gisPdfTemplates, formTemplate.name])

  const emailSubject = useMemo(() => gisPdfTemplate?.name, [gisPdfTemplate])

  const openPdf = useCallback(async () => {
    if (!isEmpty(gisPdfTemplate) && !isEmpty(formData)) {
      setGeneratingReport(true)
      await axios.get(`/form_datum/${ formData[0].id }/gis-pdfs/${ gisPdfTemplate.id }.json`)
        .then(res => window.open(res.data, 'noopener,noreferrer'))
        .catch(error => {
          console.error("ERROR::::: ", error)
          notifyError("There was an error generating the PDF. Please try again.")
        })
      setGeneratingReport(false)
    }
  }, [gisPdfTemplate, formData])

  const sendEmail = useCallback(async ({ selectedEmailOptions, subject, body }) => {
    if (isEmpty(selectedEmailOptions)) return toast.error('No recipients selected')
    const params = { recipients: selectedEmailOptions.map(email => email.value), subject, body }

    return emailQgisReport({
      gis_pdf_template_id: gisPdfTemplate.id,
      form_datum_id: formData[0].id,
      params
    })
      .then(() => {
        toggleEmailModal(false)
        toast.success('Email successfully sent')
      })
      .catch(() => toast.error('Email failed to send'))
  }, [formData, gisPdfTemplate])

  return (
    <>
      { !isEmpty(gisPdfTemplate) && !isEmpty(formData) && (
        <div className="flex flex-row justify-between gap-4">
          <button
            className="btn btn-primary font-semibold mx-4 my-4 relative cursor-pointer z-10 w-full"
            onClick={ openPdf }
          >
            { generatingReport ? `Generating ${ gisPdfTemplate.name }` : `View ${ gisPdfTemplate.name }` }
          </button>
          <button
            className="btn btn-primary font-semibold mx-4 my-4 relative cursor-pointer z-10 w-full"
            onClick={ toggleEmailModal }
          >
            Email { gisPdfTemplate.name }
          </button>
          <RenderModal>
            { emailModal && (
              <EmailModal modal={ emailModal } fileName="" title="Send QGIS Report" emailSubject={ emailSubject }
                          emailBody="" onClose={ toggleEmailModal } onSend={ sendEmail }/>
            ) }
          </RenderModal>
        </div>
      ) }
    </>
  )
}

export default GisPdfButton
