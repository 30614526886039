import React from 'react'
import { UserLandingPageProvider } from "../../contexts/userLandingPageContext"
import Display from './display'

const UserLandingPage = () => {
  return (
    <UserLandingPageProvider>
      <Display />
    </UserLandingPageProvider>
  )
}

export default UserLandingPage
