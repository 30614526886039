import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faSnowflake, faSort, faSortUp, faSortDown, faCompressArrowsAlt, faMaximize, faInfo
} from "@fortawesome/free-solid-svg-icons"
import React, { useRef, useMemo, useReducer } from "react"
import { useTrackerFilterAPI, useTrackerFilterContext } from "../../../contexts/trackerFilterContext"
import { useTrackerContext, useTrackerAPI } from "../../../contexts/trackerContext"
import HoverIcon from "../trackerIcon/hoverIcon"
import { toggleReducer } from "../../../utilities/helpers"
import { specialHeaders } from "../../../utilities/trackerHelpers"
import { columnSizes } from "../../../utilities/tableHelpers"
import { tableStyles } from "../../../utilities/stylesHelpers"

const TableHeader = ({ content, headerId = content, headerCellWidth, renderPosition, description }) => {
  const headerRef = useRef(null)
  const { updateFrozenStyles, updateCollapsedHeaders } = useTrackerAPI()
  const { orderedBy } = useTrackerFilterContext()
  const { orderTrackerEntries } = useTrackerFilterAPI()
  const { frozenStyles, collapsedHeaders } = useTrackerContext()

  const [isAscending, setIsAscending] = useReducer(toggleReducer, true)
  const [frozen, toggleFrozen] = useReducer(toggleReducer, false)
  const [collapsed, toggleCollapsed] = useReducer(toggleReducer, false)

  const sortHandler = () => setIsAscending(!isAscending) || orderTrackerEntries(headerId, isAscending)
  const handleFreeze = (e) => updateFrozenStyles(headerRef.current.clientWidth, renderPosition, e.target.checked) || toggleFrozen()
  const handleCollapse = (e) => updateCollapsedHeaders(renderPosition, e.target.checked) || toggleCollapsed()

  const styler = useMemo(() => frozenStyles[renderPosition]?.headerStyles || {}, [frozenStyles, renderPosition])
  const cellSize = useMemo(() => {
    return collapsedHeaders.includes(renderPosition) ? 0 : columnSizes[headerCellWidth]
  }, [collapsedHeaders, renderPosition, headerCellWidth])

  const sortIcon = useMemo(() => {
    return (orderedBy !== headerId) ? faSort : isAscending ? faSortUp : faSortDown
  }, [isAscending, orderedBy])

  const frozenIconStyles = useMemo(() => {
    return ["p-1 border", frozen ? "border-[#155e75] bg-[#cff2fc]" : "border-transparent"].join(" ")
  }, [frozen])

  return (
    <th className={ tableStyles.th } ref={ headerRef } style={ { minWidth: cellSize, ...styler } }>
      <div className={ tableStyles.thContent }>
        { content }
        { content !== specialHeaders &&
          <FontAwesomeIcon className="ml-2 cursor-pointer" icon={ sortIcon } onClick={ sortHandler }/> }
        <br/>
        <div className="flex flex-row gap-2 w-full items-center justify-center">
          <label htmlFor={ `${ headerId }-frozen` } className="cursor-pointer flex items-center">
            <FontAwesomeIcon icon={ faSnowflake } style={ { color: "#155e75", } } className={ frozenIconStyles }/>
            <input id={ `${ headerId }-frozen` } type="checkbox" onChange={ handleFreeze } className="hidden"/>
          </label>
          <label htmlFor={ `${ headerId }-collapsed` } className="cursor-pointer">
            <FontAwesomeIcon icon={ collapsed ? faMaximize : faCompressArrowsAlt }/>
            <input id={ `${ headerId }-collapsed` } type="checkbox" onChange={ handleCollapse } className="hidden"/>
          </label>
          { description && <HoverIcon icon={ faInfo } message={ description }/> }
        </div>
      </div>
    </th>
  )
}

export default TableHeader
