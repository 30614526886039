import React from "react"
import { useParams } from "react-router-dom"
import { useTrackerAPI, useTrackerContext } from "../../contexts/trackerContext"
import { buildTrackerPath } from "../../api/trackers"
import { notifyAlert } from "../shared/notice"
import StandardButton from "../shared/standardButton"

const NewTrackerEntryButton = () => {
  const { trackerTemplate } = useTrackerContext()
  const { addNewEntry } = useTrackerAPI()
  const { portfolioId, projectId } = useParams()
  const createTrackerPath = buildTrackerPath({ portfolioId, projectId })

  const onClickHandler = () => {
    addNewEntry(`${createTrackerPath}?tracker_slug=${trackerTemplate?.slug}`, projectId), notifyAlert("A new tracker entry has been created!")
  }

  return <StandardButton buttonText="Add New" onClickHandler={onClickHandler} />
}

export default NewTrackerEntryButton
