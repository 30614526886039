import React, { useRef, useState } from "react"


const NumricalInput = ({ fieldName, initialValue }) => {
  const [input, setInput] = useState(initialValue)
  const inputRef = useRef(null)

  return (
    <div className="relative h-full w-full flex flex-row justify-center cursor-pointer">
      <input
        type="number"
        name={fieldName}
        ref={inputRef}
        value={input || ''}
        onChange={ ( e ) => setInput(e.target.value) }
        className={`transition duration-500 w-full p-2 border border-gray-600 border-solid rounded-sm`}
      />
    </div>
  )
}

export default NumricalInput
