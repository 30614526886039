import React, { useEffect, useRef } from "react"
import { parseISO } from "date-fns"
import { useProjectContext, useProjectApiContext } from "../../contexts/projectContext"
import { syncAtFormatter } from "../../utilities/dateHelpers"

const SyncedAt = () => {
  const { loading, project, syncedAt } = useProjectContext()
  const { setSyncedAt } = useProjectApiContext()
  const renderRef = useRef(-1)

  useEffect(() => {
    if (!loading) {
      renderRef.current += 1
    }

    if (!loading && renderRef.current === 0) {
      const formattedDate = project.synced_at ? syncAtFormatter(parseISO(project.synced_at)) : 'N/A'
      setSyncedAt(formattedDate)
    }

    if (!loading && renderRef.current > 0) {
      setSyncedAt(syncAtFormatter())
    }
  }, [loading])

  return (
    <span>
      {'Last synced at: '}
      {loading && 'Updating...'}
      {!loading && syncedAt}
    </span>
  )
}

export default SyncedAt
