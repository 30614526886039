import React, { useCallback, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useDocumentLogContext } from "../../contexts/documentLogContext"
import Document from "./document"
import { createDocument, pathBuilder } from "../../api/documentLogApi"
import LoadingSpinner from "../shared/loadingSpinner"

const Template = ({ template }) => {
  const { projectId } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const { documents } = useDocumentLogContext()

  const createNewDocument = useCallback(() => {
    setLoading(true)
    createDocument(projectId, template.slug, {})
      .then(res => {
        setLoading(false)
        navigate(pathBuilder({ projectId, templateSlug: template.slug, documentId: res.id, action: 'edit' }))
      })
  }, [navigate, projectId, template])

  return (
    <div className="grid gap-4">

      <h2 className="text-2xl font-bold">{ template.name }</h2>
      <div className="w-full flex flex-row justify-start items-center gap-4">
        <button
          onClick={ createNewDocument }
          className="btn btn-primary leading-6 font-semibold relative cursor-pointer inline-block w-fit"
        >
          Build New Document
        </button>
        { loading && <LoadingSpinner size={ '3xl' }/> }
      </div>
      { documents[template.id]?.map(document => (
        <Document key={ document.id } document={ document } templateSlug={ template.slug }/>)
      ) }
    </div>
  )
}

export default Template
