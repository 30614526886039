import React from 'react'
import Select from 'react-select'
import { useSampleCollectionContext, useSampleCollectionApi } from '../../../../contexts/sampleCollectionContext'
import { inputClass } from "../../../../utilities/stylesHelpers"

const LabSelect = () => {
  const { updateNestedState } = useSampleCollectionApi()
  const { labs } = useSampleCollectionContext()

  const valueOptions = labs.map(lab => ({ value: lab.id, label: lab.company + ' - ' + lab.city }))
  const onColumnSelect = (selectedOption) => {
    updateNestedState('sampleCollection', 'lab_id')(selectedOption?.value)
  }

  return (
    <label htmlFor="lab_id" className="flex flex-row items-center justify-start w-full">
      <Select
        name="lab_id"
        options={valueOptions}
        onChange={onColumnSelect}
        className={inputClass}
        isClearable='true'
        placeholder={'Choose a Lab'}
      />
      <span className="text-red-500 ml-2">*Required</span>
    </label>
  )
}

export default LabSelect
