import React from "react"
import { useChecklistContext } from "../../contexts/checklistContext"
import ToolTip from "../tableTemplates/icons/toolTip"

const ChecklistToolTip = () => {
  const { toolTip, toolTipMessage } = useChecklistContext()

  return <ToolTip toolTip={toolTip} toolTipMessage={toolTipMessage} />
}

export default ChecklistToolTip
