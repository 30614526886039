import React, { useMemo } from 'react'
import TextractTable from "./textractTable"

const TextractTables = ({ document }) => {
  const tables = useMemo(() => document?.tables || [], [document])

  return (
    <div className="w-full flex flex-col gap-4 py-4">
      <h2 className="text-2xl font-bold">Document Tables</h2>
      <div className="w-full grid grid-cols-12 gap-4 border border-solid border-gray-300 p-4">
        <h3 className="text-2xl font-bold col-span-1">Approved</h3>
        <h3 className="text-2xl font-bold col-span-1">Page</h3>
        <h3 className="text-3xl font-bold col-span-3">Table Name & Footer</h3>
        <h3 className="text-3xl font-bold col-span-3">CSV File download</h3>
        <h3 className="text-3xl font-bold col-span-4">Preview</h3>
      </div>
      { tables.map(table => <TextractTable key={ table.id } table={ table }/>) }
    </div>
  )
}

export default TextractTables
