import { updateLandingPageWidget, updateProjectLandingPage } from "../api/projectApi";

export const handleLandingPage = (project, hasLandingPage, setHasLandingPage) => {
  updateProjectLandingPage(project.id, hasLandingPage).then((_) => setHasLandingPage(!hasLandingPage))
}

export const changeLandingPageWidget = (projectId) => (newWidget) => {
  updateLandingPageWidget(projectId, newWidget)
}

export const userProfileCreator = (user, provinces) => {
  const flatCities = provinces.flatMap(prov => prov.cities)
  const userCity = flatCities.find(city => city.id === user?.user_profile?.city_id)
  const userProvince = provinces.find(prov => prov.id === userCity?.province_id)
  const userProfile = {
    id: user?.user_profile?.id,
    homeAddress: user?.user_profile?.home_address || "",
    employeeStatus: user?.user_profile?.employee_status,
    city: userCity ? { value: userCity.id, label: userCity.name } : null,
    province: userProvince ? { value: userProvince.id, label: userProvince.name } : null,
    postalCode: user?.user_profile?.postal_code || "",
    dateOfBirth: user?.user_profile?.date_of_birth || "",
    mailT4: !!user?.user_profile?.mail_t4 || false,
    cellPhone1: user?.user_profile?.cell_phone_1 || "",
    cellPhone2: user?.user_profile?.cell_phone_2 || "",
    division: user?.user_profile?.work_force_division_id || "",
    position: user?.user_profile?.work_force_position_id || "",
    office: user?.user_profile?.work_force_office_id || "",
    emergencyContactFirstName: user?.user_profile?.emergency_contact_first_name || "",
    emergencyContactLastName: user?.user_profile?.emergency_contact_last_name || "",
    emergencyContactRelationship: user?.user_profile?.emergency_contact_relationship || "",
    emergencyContactCellPhone: user?.user_profile?.emergency_contact_cell_phone || "",
  }

  return userProfile
}
