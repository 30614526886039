const preliminaryTicketHeadersUpper = [
  "Historial Assessment",
  "Historical Assessment",
  "Itemized Scope",
  "Service Provider",
]

const preliminaryTicketHeadersLower = [
  "historial_assessment",
  "historical_assessment",
  "itemized_scope",
  "service_provider",
]

const firstHeaders = [
  "ID",
  "Project Id",
  "Project Name",
  "Project Number",
  "Date",
  ...preliminaryTicketHeadersUpper,
  "Entry Date",
  "Transmittal Date",

  "id",
  "project_id",
  "project_name",
  "project_number",
  "date",
  ...preliminaryTicketHeadersLower,
  "entry_date",
  "transmittal_date",
]

const lastHeaders = [
  "Comments",
  "Report Link",
  "X",
  "Y",

  "comments",
  "report_link",
  "x",
  "y",
]

const dailySummary = [
  "Daily Summary",
  "Next Day Forecast",
  "3 Day Look Ahead",
  "Project Impact Concerns",
  "Staff On-Site",
  "Owner Rep On-Site",
  "Daily Truck Count",
  "Daily Temp",

  "daily_summary",
  "next_day_forecast",
  "3_day_look_ahead",
  "project_impact_concerns",
  "staff_onsite",
  "owner_rep_onsite",
  "daily_truck_count",
  "daily_temp",
]

const excavationTracker = [
  "Excavation Name",
  "Area Potential Environmental Concern",
  "Potential Contaiminates of Concern",
  "Potential Contaminates of Concern",
  "RFP Volume",
  "Modeled Volume",
  "Excavation Volume",
  "Stockpiled Volume",
  "Confirmatory Samples Clean",
  "Confirmatory Sample Tables",

  "excavation_name",
  "area_potential_environmental_concern",
  "potential_contaiminates_of_concern",
  "potential_contaminates_of_concern",
  "rfp_volume",
  "modeled_volume",
  "excavation_volume",
  "stockpiled_volume",
  "confirmatory_samples_clean",
  "confirmatory_sample_tables",
]

const wasteTracker = [
  "Disposal Date",
  "Disposal Location",
  "Transport Company",
  "Quantity (Tonnes)",

  "disposal_date",
  "disposal_location",
  "transport_company",
  "quantity_tonnes",
]

const documentAndDrawingLog = [
  "Document Date",
  "Document Type",
  "Document Originator",
  "Document Title",
  "Drawing Date",
  "Drawing Type",
  "Drawing Originator",
  "Drawing Title",

  "document_date",
  "document_type",
  "document_originator",
  "document_title",
  "drawing_date",
  "drawing_type",
  "drawing_originator",
  "drawing_title",

  "Revision No", // Duplicated
  "Revision No.",

  "revision_no",

  "Document Comments",
  "Document Link",
  "Drawing Comments",
  "Drawing Link",

  "document_comments",
  "document_link",
  "drawing_comments",
  "drawing_link",
]

const issueLog = [
  "Date Identified",
  "Status",
  "Priority",
  "Issue Description",
  "Owner",
  "Estimated Resolution Date",
  "Escalation Needed (Y/N)?",
  "Impact",
  "Actions",
  "Logged By",
  "Actual Resolution/Completion Date",
  "Final Resolution & Follow-on actions",

  "date_identified",
  "status",
  "priority",
  "issue_description",
  "owner",
  "estimated_resolution_date",
  "escalation_needed_yn",
  "impact",
  "actions",
  "logged_by",
  "actual_resolutioncompletion_date",
  "final_resolution_followon_actions",
]

const keyera_safety_tracker = [
  "ProDelta Man Hours",
  "Sub Contractor Man Hours",
  "Owner Man Hours",
  "Trucking Man Hours",
  "Hazard ID",
  "Near Miss",
  "Incident",

  "prodelta_man_hours",
  "sub_contractor_man_hours",
  "owner_man_hours",
  "trucking_man_hours",
  "hazard_id",
  "near_miss",
  "incident",
]

const ground_disturbance_tracker = [
  "Site Location",
  "Scheduled Start Date",
  "Scheduled End Date",
  "Purpose of Ground Disturbance",
  "Supporting Document Type",
  "Supporting Document",

  "site_location",
  "scheduled_start_date",
  "scheduled_end_date",
  "purpose_of_ground_disturbance",
  "supporting_document_type",
  "supporting_document",
]

const ticketTracker = [
  "Ticket Date",
  "Company Name",
  "Ticket No",
  "Ticket No.",
  "Type of Work",
  "Ticket Number",
  "Ticket Value",
  "Ticket Copy",

  "ticket_date",
  "company_name",
  "ticket_no",
  "type_of_work",
  "ticket_number",
  "ticket_value",
  "ticket_copy",

  "Invoice Received",
  "Corresponding Invoice No.",
  "Corresponding Invoice No",
  "Ticket Link",

  "invoice_received",
  "corresponding_invoice_no",
  "ticket_link",
]

const budget_tracker = [
  "Project General Scope",
  "project_general_scope",

  ...ticketTracker,

  "Estimated Quantity",
  "Rate",
  "Total",

  "estimated_quantity",
  "rate",
  "total",

  ...issueLog,

  "Date Completed",
  "date_completed",
]

const invoice_tracker = [
  "Invoice Date",
  "Invoice Number",
  "Invoice Value",
  "Sent to Account Payable",
  "Invoice Link",

  "invoice_date",
  "invoice_number",
  "invoice_value",
  "sent_to_account_payable",
  "invoice_link",
]

const master_service_provider = [
  "Service Provider Name",
  "Primary Contact",
  "Primary Contact Email",
  "Primary Contact Phone",
  "Company Address",
  "Services Provided",
  "First Nations Affiliation",
  "First Nations Affilliation",

  "service_provider_name",
  "primary_contact",
  "primary_contact_email",
  "primary_contact_phone",
  "company_address",
  "services_provided",
  "first_nations_affiliation",
  "first_nations_affilliation ",
]

const bodyHeaders = [
  ...dailySummary,
  ...excavationTracker,
  ...wasteTracker,
  ...documentAndDrawingLog,
  ...keyera_safety_tracker,
  ...ground_disturbance_tracker,
  ...budget_tracker,
  ...invoice_tracker,
  ...master_service_provider,
]

/**
 * Preferentially ordered headers
 *
 * @type {*[]}
 */
const preferentialHeaders = [
  ...firstHeaders,

  ...bodyHeaders,

  ...lastHeaders
]

/**
 * Returns an array of unique keys.
 * Keys are string representative's of object attribute names.
 * Uses the preferentialHeaders constant as a starting point to determine column name order.
 * While looping through the attributes of rows, it adds unique keys to the presentHeaders array.
 * Otherwise it checks if the values exists on the NotPresentPreferentialHeaders array and if it does, it filters out that value.
 * Then any values left in the NotPresentPreferentialHeaders array are filtered out before the column names are returned.
 *
 * @param rows [] - An array of objects.
 * @returns {*[]} An array containing all keys of rows without duplicates
 */
export const columnNames = (rows) => {
  // Return if there are no rows to prevent crashing
  if (!rows) return [];
  // To keep track of which headers are present
  let notPresentPreferentialHeaders = [...preferentialHeaders]
  // Headers that will be returned
  const presentHeaders = [...preferentialHeaders]
  // console.log("Original Not Present Headers: ", notPresentPreferentialHeaders)
  // For each row
  rows.forEach((row) => {
    // For each attribute of the row
    for (const rowKey in row) {
      const key = rowKey.trim()
      // If the attribute is not present in presentHeaders
      if (!presentHeaders.includes(key)) {
        // Add the header
        presentHeaders.push(key)
        // Otherwise, if the attribute is present in NotPresentPreferentialHeaders
      } else if (notPresentPreferentialHeaders.includes(key)) {
        // Remove the attribute as it is in fact present
        notPresentPreferentialHeaders = notPresentPreferentialHeaders.filter(value => value !== key)
      }
    }
  })

  // Filter out the headers that have not been removed from the NotPresentPreferentialHeaders array and return the remainder
  return presentHeaders.filter(value => !notPresentPreferentialHeaders.includes(value))
}

/**
 * utility style class allowing universal styling of permit table elements
 *
 * @type {{cell: string, table: string}}
 */
export const tableStyles = {
  table: "border-separate table-fixed border-gray-200 border m-4 border-spacing-0",
  tableHeaderRow: "sticky top-0 z-10",
  th: "border-gray-200 w-32 border border-b-2 align-top sticky top-0 bg-white",
  thContent: "w-full h-full text-center p-2 sticky top-0",
  td: "border-gray-200 border text-left relative text-center p-4 pb-6 align-middle cursor-pointer group hover:bg-blue-100",
  tdText: "text-left w-full",
  tdNoHover: "border-gray-200 border-2 text-center p-4 align-middle",
  hoverIcon: "text-xs opacity-0 group-hover:opacity-100 text-gray-600",
}
