import React from 'react'
import Modal from "../../shared/modal"
import { useChecklistAPI, useChecklistContext } from '../../../contexts/checklistContext'
import LastUpdateInfoInput from './inputs/lastUpdateInfoInput'

const InfoModal = () => {
  const { modals, taskDescription, modalTitle } = useChecklistContext()
  const { toggleModal } = useChecklistAPI()

  const message = (modalTitle ==='Update') ? <LastUpdateInfoInput /> : (modalTitle? taskDescription : 'N/A')

  return (
    <>
      {modals.infoModal && (
        <Modal heading={`${modalTitle} Information`} closeModal={() => toggleModal('infoModal')}>
          <div className='block w-full p-1 text-xs text-gray-700 appearance-none focus:outline-none border border-gray-200 focus:shadow-inner m-0 rounded'>
            {message}
          </div>
        </Modal>
      )}
    </>
  )
}

export default InfoModal
