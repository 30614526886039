import React, { useEffect, useRef } from "react"
import { usePermitTable, usePermitTableAPI } from "../../../contexts/permitTableContext"
import Modal from "../../shared/modal"
import { useProjectApiContext, useProjectContext } from "../../../contexts/projectContext"
import { notifyError } from "../../shared/notice"

const isIdentifier = (header) => !!['permit_id', 'id', 'fid'].find(e => e === header)

const hasContent = (content) => !!content?.toLowerCase().trim()

const PermitModal = () => {
  const { permits } = useProjectContext()
  const { setPermits } = useProjectApiContext()
  const { editCellContent, editCellHeader, editCellHeaderFormatted, editCellId, modal } = usePermitTable()
  const { onContentChange, onSave, toggleModal } = usePermitTableAPI()
  const inputRef = useRef()

  const updatePermitAfterSave = (newPermit) => {
    const permitIndex = permits.findIndex(p => p.id === editCellId)
    const updatedPermits = [...permits]

    updatedPermits[permitIndex] = newPermit
    setPermits(updatedPermits)
  }

  const onSaveHandler = () => {
    if (isIdentifier(editCellHeader) && !hasContent(editCellContent)) {
      return notifyError('Permits require an identifier')
    }

    onSave(editCellId, editCellHeader, editCellContent)
      .then(res => {
        if (res) {
          toggleModal()
          updatePermitAfterSave(res.data)
        }
      })
  }

  const onModalClose = () => {
    if (isIdentifier(editCellHeader) && !hasContent(editCellContent)) {
      return notifyError('Permits require an identifier')
    }

    toggleModal()
  }

  useEffect(() => {
    if (modal && inputRef.current) inputRef.current.focus()
  }, [modal, inputRef.current])

  return (
    <>
      {modal && (
        <Modal
          heading={editCellHeaderFormatted}
          closeModal={() => onModalClose()}
          onSave={() => onSaveHandler()}
        >
          <div className='flex flex-col lg:flex-row lg:justify-between gap-y-4 lg:gap-y-0 lg:gap-x-4'>
            <div className='w-full'>
              <input
                className='block w-full p-1 text-xs text-gray-700 appearance-none focus:outline-none border border-gray-200 focus:shadow-inner m-0 rounded'
                type='text'
                ref={inputRef}
                value={editCellContent}
                onChange={e => onContentChange(e.target.value)}
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

export default PermitModal
