import React from "react"
import { tableStyles } from "./historicalAssessmentHelper"

const RadioButton = ({ label, name, value, checked, onChange }) => {
  const isChecked = checked === value

  return (
    <label className={tableStyles.radioButtonLabel}>
      <input
        type="radio"
        name={name}
        value={value}
        className={`${tableStyles.radioButton} ${isChecked && tableStyles.radioButtonChecked}`}
        checked={isChecked}
        onChange={(e) => onChange(e.target.value)}
      />
      <p>
        {label}
      </p>
    </label>
  )
}

export default RadioButton
