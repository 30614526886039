import { format, parse } from 'date-fns'
import React, { useEffect, useRef, useState } from "react"
import DatePicker from "react-datepicker"
import { useDocumentBuilderApiContext } from "../../../contexts/documentBuilderContext"

const DateContent = ({ contentId, content }) => {
  const { updateContent } = useDocumentBuilderApiContext()

  const [date, setDate] = useState(new Date())
  const isDefaultSetRef = useRef(false)

  useEffect(() => {
    if (isDefaultSetRef.current) return
    if (content?.documentContent) setDate(parse(content.documentContent, 'd MMMM yyyy', new Date()))
    isDefaultSetRef.current = true
  }, [content])

  useEffect(() => updateContent(contentId, 'documentContent', format(date, 'd MMMM yyyy')), [updateContent, contentId, date])

  return (
    <DatePicker
      className='block p-1 text-gray-700 appearance-none focus:outline-none border border-gray-200 focus:shadow-inner m-0 rounded z-1000'
      selected={date}
      onChange={(date) => setDate(date)}
      dateFormat="d MMMM yyyy"
    />
  )
}

export default DateContent
