import { exportProjectPhotos, exportPhotos } from "../api/photosAPI"

export const getImageIndex = (imageValue, galleryMap) => {
  const keys = [...galleryMap.keys()]
  return keys.findIndex((key) => key === imageValue)
}

export const galleryMapToSlidesArray = (galleryMap, comments) => {
  return Array.from(
    galleryMap,
    item => {
      return {
        ...item[1].slide,
        description: comments[item[1].details.comment_id].description
      }
    }
  )
}

export const photoArrayToGalleryMap = (photos) => {
  const galleryMap = new Map()
  photos.forEach(photo => {
    galleryMap.set(photo.slide.title, { slide: photo.slide, details: photo.details })
  })
  return galleryMap
}

export const groupPhotosByDate = (photoMap) => {
  const groupedPhotos = new Map()
  photoMap.forEach((photo, key) => {
    const date = photo.details.date
    if (groupedPhotos.has(date)) {
      groupedPhotos.get(date).push(photo)
    } else {
      groupedPhotos.set(date, [photo])
    }
  })
  return Array.from(groupedPhotos.values())
}

export const filterMap = (filter, value, galleryMap, oldFilteredGalleryMap, selectedFilters) => {

  const refilter = isRefilter(filter, value, selectedFilters)
  const updatedSelectedFilters = updateSelectedFilters(filter, value, selectedFilters)
  const updatedMap = refilter ? allFilters(updatedSelectedFilters, galleryMap) : oneFilter(filter, value, oldFilteredGalleryMap)

  return [updatedMap, updatedSelectedFilters]
}

const allFilters = (updatedSelectedFilters, map) => {
  const updatedMap = new Map()
  map?.forEach((photo, key) => {
    Object.entries(updatedSelectedFilters).forEach(([filter, value]) => {
      if (filter === 'date_range') {
        if (new Date(photo.details.date) >= new Date(value.start_date) && new Date(photo.details.date) <= new Date(value.end_date)) {
          updatedMap.set(key, photo)
        }
      }
    })
  })
  return updatedMap
}

const oneFilter = (filter, value, map) => {
  const updatedMap = new Map()

  map?.forEach((photo, key) => {
    if (filter === 'start_date') {
      if (new Date(photo.details.date) >= new Date(value)) {
        updatedMap.set(key, photo)
      }
    }
    if (filter === 'end_date') {
      if (new Date(photo.details.date) <= new Date(value)) {
        updatedMap.set(key, photo)
      }
    }
  })
  return updatedMap
}

const isRefilter = (filter, value, selectedFilters) => {
  if (filter === 'start_date') {
    if (!selectedFilters.date_range.start_date) return true
    return new Date(selectedFilters.date_range.start_date) >= new Date(value)
  }
  if (filter === 'end_date') {
    if (!selectedFilters.date_range.end_date) return true
    return new Date(selectedFilters.date_range.end_date) <= new Date(value)
  }
  return true
}

const updateSelectedFilters = (filter, value, selectedFilters) => {
  if (filter === 'start_date' && value) {
    return { ...selectedFilters, date_range: { start_date: value, end_date: selectedFilters.date_range.end_date } }
  }
  if (filter === 'end_date' && value) {
    return { ...selectedFilters, date_range: { start_date: selectedFilters.date_range.start_date, end_date: value } }
  }
  else {
    return selectedFilters
  }
}

export const updateSelectedPhoto = (photo, comment, selectedPhotos) => {
  const updatedSelectedPhotos = JSON.parse(JSON.stringify(selectedPhotos))

  const existingPhotoIndex = updatedSelectedPhotos.findIndex(p => p.photo_guid === photo);

  if (existingPhotoIndex !== -1) {
    updatedSelectedPhotos.splice(existingPhotoIndex, 1);
  } else {
    updatedSelectedPhotos.push({ photo_guid: photo, comment: comment });
  }

  return updatedSelectedPhotos
}

export const exportPhotoReport =({selectedPhotos, projectId, portfolioId}) => {
  return exportPhotos({projectId, portfolioId, photoExports: { date: new Date(), photo_objs: selectedPhotos }}).then(res => {
    window.open(res.pdf_path, '_blank', 'noopener,noreferrer')
    return res
  })
}

