import React, { useRef } from 'react'
import { useTestResultsApi, useTestResultsContext } from '../../../contexts/testResultsContext'
import ModalWrapper from '../../modal/modalWrapper'


const TestResultModal = () => {
  const { toggleField, updateTestResult, updateState } = useTestResultsApi()
  const { currentTestResult } = useTestResultsContext()
  const formRef = useRef()

  const handleCloseModal = () => {
    updateState('currentTestResult', {})
    toggleField('modal.testResultModal')
    formRef.current.reset()
  }
  const isChecked = currentTestResult?.background_status === true
  const handleSelect = () => {
    updateState('currentTestResult', { ...currentTestResult, background_status: !isChecked })
  }

  return (
    <ModalWrapper
      heading="Test Result Toggle Modal"
      closeModal={handleCloseModal}
      formName="test-result-form"
      submitButtonText='Submit'
      modalContainerClasses="max-h-[90%] my-6 w-7/12"
    >
      <form ref={formRef} className="gap-y-6 flex flex-col" onSubmit={e => updateTestResult(formRef, e, currentTestResult)} id="test-result-form">
        <label htmlFor={'background_status'} className="flex flex-row items-center justify-start w-full">
          <span className="text-sm mx-2">Background Status</span>
          <input className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            type="checkbox"
            checked={isChecked}
            onChange={handleSelect}
          />
        </label>
      </form>
    </ModalWrapper>
  )
}

export default TestResultModal
