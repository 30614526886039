export const optionize = (value, label = value) => ({ value, label })

export const reducePortfolios = portfolios => {
  return Object.keys(portfolios).map(portfolio_id => optionize(portfolio_id, portfolios[portfolio_id]))
}

export const reduceProjects = (projects, portfolioId) => {
  return Object.keys(projects).reduce((acc, projectId) => {
    if (portfolioId && parseInt(portfolioId) !== projects[projectId]?.portfolio_id) return acc
    acc[0].push(optionize(projectId, projects[projectId].job_number))
    acc[1].push(optionize(projectId, projects[projectId].name))
    if (projects[projectId].historical_project_number) {
      acc[2].push(optionize(projectId, projects[projectId].historical_project_number))
    }
    return acc
  }, [[], [], []])
}

export const projectOptionsValues = (projects, projectId) => {
  if (projectId) {
    return [
      optionize(projectId, projects[projectId].job_number),
      optionize(projectId, projects[projectId].name),
      optionize(projectId, projects[projectId].historical_project_number),
    ]
  } else {
    return [null, null, null]
  }
}

export const apiVerifier = formRef => {
  const formData = new FormData(formRef.current)
  const [projectId, taskId, weekStart] =
    [formData.get('project_id'), formData.get('timesheet_task_id'), formData.get('week_start')]
  return (projectId && taskId && weekStart) ? formData : null
}

export const optionizeTasks = (tasks) => tasks.map(task => optionize(task.id, task.name))

const totalsFunc = (
  { sundayTotal = 0, mondayTotal = 0, tuesdayTotal = 0, wednesdayTotal = 0, thursdayTotal = 0, fridayTotal = 0, saturdayTotal = 0, total = 0 },
  { sunday, monday, tuesday, wednesday, thursday, friday, saturday }
) => {

  return {
    sundayTotal: sunday + sundayTotal,
    mondayTotal: monday + mondayTotal,
    tuesdayTotal: tuesday + tuesdayTotal,
    wednesdayTotal: wednesday + wednesdayTotal,
    thursdayTotal: thursday + thursdayTotal,
    fridayTotal: friday + fridayTotal,
    saturdayTotal: saturday + saturdayTotal,
    total: total + sunday + monday + tuesday + wednesday + thursday + friday + saturday
  }
}

export const weeklyTotals = entries => entries.reduce(totalsFunc, {})

export const addTimeTables = (timeTables, newTimeTables) => {
  return { ...timeTables, ...newTimeTables }
}

export const getUsername = (users, userId) => {
  const user = users.find(user => user[0] === parseInt(userId))
  return user ? user[1] : "Unknown User"
}
