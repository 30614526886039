import React, { useState, useEffect } from "react"
import axios from "axios";
import Portfolio from "./portfolio";

const Portfolios = () => {
  const [portfolios, setPortfolios] = useState([])

  useEffect(() => {
    axios.get('/admin/portfolios.json')
      .then(res => setPortfolios(res.data))
      .catch(err => console.log(err))
  }, [])

  return <>
    {portfolios.map(portfolio => <Portfolio key={portfolio.id} portfolio={portfolio} />)}
  </>
}

export default Portfolios
