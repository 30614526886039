import React from 'react'

import SelectChemical from '../../shared/selectChemical'

const ChemicalList = ({ chemicals, selectedChemicals, setSelectedChemicals }) => (
  chemicals.map(chemical => (
    <td className='border-r border-regular-orange px-2' key={chemical}>
      <SelectChemical
        selected={selectedChemicals.includes(chemical)}
        chemical={chemical}
        selectedChemicals={selectedChemicals}
        setSelectedChemicals={setSelectedChemicals}
      />
    </td>
  ))
);

export default ChemicalList
