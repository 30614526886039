import React, { useMemo } from "react"
import { isEmpty } from "lodash"
import { tableStyles } from "../../utilities/stylesHelpers"
import TableHeaderRow from "./tableHeaderRow"
import TrackerFilters from "./trackerFilters"
import NewTrackerEntryButton from "./newTrackerEntryButton"
import DuplicateEntryButton from "./duplicateEntryButton"
import DeleteMultipleEntryButton from "./deleteMultipleButton"
import TrackerModal from "./trackerModal"
import { useTrackerContext } from "../../contexts/trackerContext"
import { useTrackerFilterAPI, useTrackerFilterContext } from "../../contexts/trackerFilterContext"
import LoadingSpinner from "../shared/loadingSpinner"
import ShowMetaDataButton from "./showMetaDataButton"
import ToolTip from "./trackerIcon/toolTip"
import { TrackerEntryProvider } from "../../contexts/trackerEntryContext"
import TrackerRow from "./trackerRow"
import StandardButton from "../shared/standardButton"

const TrackerTable = ({ scopeName }) => {
  const { trackerTemplate, headers, loading } = useTrackerContext()
  const { trackerEntries, loadedAll } = useTrackerFilterContext()
  const { incrementPage } = useTrackerFilterAPI()

  const tableTitle = useMemo(() => {
    return [scopeName, trackerTemplate?.name].filter(i => i).join(" - ")
  }, [trackerTemplate, scopeName])

  const entryLoading = useMemo(() => isEmpty(trackerTemplate) && isEmpty(trackerEntries), [trackerTemplate, trackerEntries])

  return <>
    {loading ? <LoadingSpinner /> :
      <div className='flex flex-col w-full h-full overflow-scroll'>
        <ToolTip />
        <div className="sticky left-0">
          <h1 className="text-3xl font-bold m-4">{ tableTitle }</h1>
          <TrackerFilters/>
          <div className="flex justify-between">
            <NewTrackerEntryButton/>
            <ShowMetaDataButton/>
            <div>
              { trackerTemplate?.enable_entry_duplication && <DuplicateEntryButton/> }
              { trackerTemplate?.enable_entry_deletion && <DeleteMultipleEntryButton/> }
            </div>
          </div>
        </div>
        { entryLoading && <LoadingSpinner/> }
        <div className="m-4 w-full">
          <table className={ tableStyles.table }>
            <TableHeaderRow/>
            <tbody>
            { trackerEntries?.map((entry) => (
              <TrackerEntryProvider trackerEntry={ entry } key={ entry.id }>
                <TrackerRow entry={ entry } headers={ headers }/>
              </TrackerEntryProvider>
            )) }
            </tbody>
          </table>
        </div>
        <div className="sticky left-0 flex justify-between">
          <NewTrackerEntryButton />
          { loadedAll ? <span></span> : <StandardButton buttonText="Load More" onClickHandler={ incrementPage }/> }
          { loadedAll ? <span></span> : <StandardButton buttonText="Load All" onClickHandler={() => incrementPage({ loadAll: true })} /> }
          {trackerTemplate?.enable_entry_duplication && <DuplicateEntryButton />}
        </div>
      </div>
    }
    <TrackerModal />
  </>
}

export default TrackerTable
