import React, { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react'
import { useParams } from 'react-router-dom'
import { getLabs } from '../api/lab/labApi'
import { deleteSampleCollection, getSampleCollections, uploadSampleCollection } from '../api/lab/sampleCollectionApi'
// Actions
const INIT = "INIT"
const ADD_COLLECTION = "ADD_COLLECTION"
const TOGGLE = 'TOGGLE'
const UPDATE_STATE = 'UPDATE_STATE'
const UPDATE_NESTED_STATE = 'UPDATE_NESTED_STATE'

// Initial States
const initialState = {
  collections: [],
  importModal: false,
  sampleCollection: {
    id: "",
    result_date: "",
    results_csv: "",
    results_pdf: "",
    lab_id: 0,
    event_type: "",
    lot_number: "",
  },
  labs: [],
  loading: false,
}

// Reducer
const sampleCollectionReducer = (state, action) => {
  switch (action.type) {
    case INIT:
      return { ...state, collections: action.collections, labs: action.labs }
    case UPDATE_STATE:
      return { ...state, [action.field]: action.value }
    case UPDATE_NESTED_STATE:
      return { ...state, [action.field]: { ...state[action.field], [action.nestedField]: action.value } }
    case ADD_COLLECTION:
      return { ...state, collections: [...state.collections, action.collection] }
    case TOGGLE:
      return { ...state, [action.field]: !state[action.field] }
    default:
      return state
  }
}

// context
const SampleCollectionContext = createContext(initialState)
const SampleCollectionApiContext = createContext({})

// provider
export const SampleCollectionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(sampleCollectionReducer, initialState)
  const { projectId } = useParams()

  const api = useMemo(() => {
    const toggleField = field => dispatch({ type: TOGGLE, field })

    const updateState = (field, value) => dispatch({ type: UPDATE_STATE, field, value })

    const updateNestedState = (field, nestedField) => value => dispatch({ type: UPDATE_NESTED_STATE, field, nestedField, value })

    return { toggleField, updateNestedState, updateState }
  }, [])

  api.onSend = (formRef, event) => {
    event.preventDefault()
    const formDataFromRef = new FormData(formRef.current)
    uploadSampleCollection(formDataFromRef, projectId).then(response => {
      dispatch({ type: ADD_COLLECTION, collection: response })
      api.toggleField('importModal')
      api.toggleField('loading')
    })
      .catch((error) => {
        api.toggleField('loading')
      })
  }

  api.onDelete = (id) => {
    api.toggleField('loading')
    const updatedCollections = state.collections.filter(collection => collection.id !== id)

    deleteSampleCollection(projectId, id).then(() => {
      dispatch({ type: UPDATE_STATE, field: 'collections', value: updatedCollections })
      dispatch({ type: UPDATE_STATE, field: 'sampleCollection', value: {} })
      api.toggleField('loading')
    })
      .catch((error) => {
        console.error("Error deleting sample collection:", error)
        api.toggleField('loading')
      })
  }

  const initializeFunc = useCallback(async () => {
    try {
      const samples = await getSampleCollections(projectId)
      const labs = await getLabs()
      dispatch({ type: INIT, collections: samples, labs: labs.data })
    } catch (error) {
      console.error("Error initializing data:", error);
    }
  }, [projectId, dispatch])

  useEffect(() => {
    void initializeFunc()
  }, [])

  return (
    <SampleCollectionContext.Provider value={state}>
      <SampleCollectionApiContext.Provider value={api}>
        {children}
      </SampleCollectionApiContext.Provider>
    </SampleCollectionContext.Provider>
  )
}

// custom hooks
export const useSampleCollectionContext = () => useContext(SampleCollectionContext)
export const useSampleCollectionApi = () => useContext(SampleCollectionApiContext)
