import React, { useEffect, useState } from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { stringToUTCDate, formatDateYearMonthDay, formatDateString } from "../../utilities/dateHelpers"
import LoadingSpinner from "../shared/loadingSpinner"


const DateFilter = ({ dateRange, onEndDateSelect, onStartDateSelect, updateRange = true }) => {
  const [selectedStartDate, setSelectedStartDate] = useState(null)
  const [selectedEndDate, setSelectedEndDate] = useState(null)
  const startUTCDate = stringToUTCDate(dateRange.start_date)
  const endUTCDate = stringToUTCDate(dateRange.end_date)
  const loading = !startUTCDate || !endUTCDate

  useEffect(() => {
    setSelectedStartDate(dateRange.start_date ? startUTCDate : null)
    setSelectedEndDate(dateRange.end_date ? endUTCDate : null)
    onStartDateSelect(dateRange.start_date ? dateRange.start_date : null)
    onEndDateSelect(dateRange.end_date ? dateRange.end_date : null)
  }, [dateRange])

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date)
    onStartDateSelect(formatDateYearMonthDay(date))
  }

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date)
    onEndDateSelect(formatDateYearMonthDay(date))
  }

  const minDate = updateRange ? selectedStartDate : startUTCDate
  const maxDate = updateRange ? selectedEndDate : endUTCDate

  return (
    <div>
      {loading && <><h1 className="flex flex-col items-center">Loading Date Filter</h1><LoadingSpinner /></>}
      {!loading && (<>
        <h2 className='font-semibold'>Dates are between <strong>{formatDateString(startUTCDate)}</strong> and <strong>{formatDateString(endUTCDate)}</strong></h2>
        <h3 className='font-semibold'>Select a start date below:</h3>
        <DatePicker
          className='block p-1 text-gray-700 appearance-none focus:outline-none border border-gray-200 focus:shadow-inner m-0 rounded z-1000'
          minDate={minDate}
          maxDate={maxDate}
          selected={selectedStartDate}
          onChange={handleStartDateChange}
        />
        <h3 className='font-semibold'>Select an end date below:</h3>
        <DatePicker
          className='block p-1 text-gray-700 appearance-none focus:outline-none border border-gray-200 focus:shadow-inner m-0 rounded z-1000'
          minDate={minDate}
          maxDate={maxDate}
          selected={selectedEndDate}
          onChange={handleEndDateChange}
        />
      </>)}
    </div>
  )
}

export default DateFilter;
