import React from "react"
import DatePicker from "react-datepicker"
import { inputClass } from "../../utilities/stylesHelpers"
import { useTimesheetContext, useTimesheetApi } from "../../contexts/timesheetContext"
import StandardButton from "../shared/standardButton"

const TimesheetFilters = () => {
  const { weekStart } = useTimesheetContext()
  const { incrementWeek, updateWeek } = useTimesheetApi()

  return (
    <div className="z-20 flex flex-row gap-x-4 items-cener">
      <StandardButton buttonText="Prev Week" buttonStyles="whitespace-nowrap" onClickHandler={ () => incrementWeek(-7) }/>
      <label htmlFor="week_start" className="w-full flex justify-center items-center gap-8">
        <h3 className="font-bold text-xl">Week Of</h3>
        <DatePicker
          className={ inputClass }
          showWeekNumbers
          showWeekPicker
          selected={ weekStart }
          value={ weekStart }
          onChange={ updateWeek }
        />
      </label>
      <StandardButton buttonText="Next Week" buttonStyles="whitespace-nowrap" onClickHandler={ () => incrementWeek(7) }/>
    </div>
  )
}

export default TimesheetFilters
