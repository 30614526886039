import React from "react";
import { useSiteInspectionReports, useSiteInspectionReportsApi } from "../../contexts/siteInspectionReports";

const HeaderInputField = ({ title, field }) => {
  const { onInputChange } = useSiteInspectionReportsApi()
  const state  = useSiteInspectionReports()

  return(
    <div className='flex flex-row items-center justify-between'>
      <label className='text-lg font-medium w-1/2'>{title}:</label>
      <input
        className="border border-gray-400 bg-gray-200 focus:bg-white rounded-md outline-none text-black p-2 w-full"
        value={state[field]}
        onChange={event => onInputChange(field, event.target.value)}
      />
    </div>
  )
}

export default HeaderInputField
