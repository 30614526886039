import API, { simpleGet, simplePost } from "../apiConfig"
const csrfToken = document.querySelector('[name=csrf-token]').content

export const getSampleCollections = (projectId) => simpleGet(`/projects/${projectId}/sample-collections.json`)

export const uploadSampleCollection = (formData, projectId) => simplePost(`/projects/${projectId}/sample-collections`, formData)

export const deleteSampleCollection = (projectId, sampleCollectionId) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API
    .delete(`/projects/${projectId}/sample-collections/${sampleCollectionId}`)
    .then(res => res.data)
}
