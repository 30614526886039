import React, { useState } from 'react'
import { MultiSelect as MultiSelectLibrary } from "react-multi-select-component";
import { format } from "date-fns";

const MultiSelect = ({ items, fieldName, initialSelected }) => {
  const [selected, setSelected] = useState(initialSelected || [])

  const options = items.map((item) => {
    let date_created = format(new Date(item.created_at), 'yyyy-MM-dd')
    return { label: `${item.name} (Created ${date_created})`, value: item.id }
  })

  const handleChange = (value) => {
    setSelected(value)
  }
  return (
    <div>
      <MultiSelectLibrary
        options={options}
        value={selected}
        onChange={(value) => handleChange(value)}
        labelledBy='Select'
      />

      {selected.map((item) => (
        <input key={item.value} type='hidden' name={fieldName} value={item.value} />
      ))}
    </div>
  )
}

export default MultiSelect
