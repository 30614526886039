import React, { createContext, useContext, useEffect, useReducer } from "react"
import { getUser } from "../api/userApi"

/* Actions */
const INIT = "INIT"

/* Initial States */
const initialState = {
  user: {},
}

/* Reducer */

const userReducer = (state, action) => {
  switch (action.type) {
    case INIT:
      return { ...state, user: action.user }
    default:
      return state
  }
}

/* context */
const UserContext = createContext(initialState)

/* Providers */
export const UserProvider = ({ children }) => {

  const [state, dispatch] = useReducer(userReducer, initialState)

  useEffect(() => {
    void getUser()
      .then(user => {
        dispatch({ type: INIT, user })
      })
  }, [])

  return (
    <UserContext.Provider value={state}>
      {children}
    </UserContext.Provider>
  )
}

/* Hooks */
export const useUserContext = () => useContext(UserContext)
