import React from 'react'
import axios from "axios";
import { notifyAlert } from "../shared/notice";

const PinMovementRequests = (formField, value, formEntry, routes) => {
  if (formField.label === 'Northing / Latitude (Manual Override / Correction)') {
    axios.put(routes.notePath, { lat: value, lng: formEntry.note_lat_lng.lng }).then(res => {
      notifyAlert('Refresh page to see change in map')
    })

  } else if (formField.label === 'Easting / Longitude  (Manual Override / Correction)') {
    axios.put(routes.notePath, { lat: formEntry.note_lat_lng.lat, lng: value }).then(res => {
      notifyAlert('Refresh page to see change in map')
    })
  }
}

export default PinMovementRequests
