import React from 'react'
import { useAiDocumentsAPI, useAiDocumentsContext } from '../../../contexts/aiDocumentsContext'
import BaseStatusModal from "../shared/statusModal"

const StatusModal = () => {
  const { statuses, modals } = useAiDocumentsContext()
  const { toggleModal, updateStatus } = useAiDocumentsAPI()

  return (
    <BaseStatusModal statuses={ statuses } modals={ modals } toggleModal={ toggleModal } updateStatus={ updateStatus }/>
  )
}

export default StatusModal
