import React, { useReducer, useState } from 'react'
import { getChartData } from '../../../../api/boreholeChartApi'
import { useParams } from 'react-router-dom'
import { notifyError } from '../../../shared/notice'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSync } from "@fortawesome/free-solid-svg-icons/faSync"
import * as filter from '../../../filters'

const initialFilterOptions = {
  borehole_ids: [],
  apecs: [],
  date_range: { start_date: null, end_date: null }
}

const reducer = (filterOptions, action) => {
  if (action.type === 'updateSelectedBoreholes') {
    return { ...filterOptions, borehole_ids: action.value }
  }
  if (action.type === 'updateSelectedApecs') {
    return { ...filterOptions, apecs: action.value }
  }
  if (action.type === 'updateSelectedStartDate') {
    return { ...filterOptions, date_range: { ...filterOptions.date_range, start_date: action.value } }
  }
  if (action.type === 'updateSelectedEndDate') {
    return { ...filterOptions, date_range: { ...filterOptions.date_range, end_date: action.value } }
  }
}

const ChartFilters = ({ onUpdateData, filters }) => {
  const { projectId } = useParams()
  const [filterOptions, dispatch] = useReducer(reducer, initialFilterOptions)
  const [loadingStatus, setLoadingStatus] = useState(false)
  const onBoreholeSelect = (value) => { return dispatch({ type: 'updateSelectedBoreholes', value }) }
  const onApecSelect = (value) => { return dispatch({ type: 'updateSelectedApecs', value }) }
  const onEndDateSelect = (value) => { return dispatch({ type: 'updateSelectedEndDate', value }) }
  const onStartDateSelect = (value) => { return dispatch({ type: 'updateSelectedStartDate', value }) }

  const onClick = () => {
    setLoadingStatus(true)
    getChartData(projectId, filterOptions)
      .then(response => {
        setLoadingStatus(false)
        onUpdateData(response.data, response.borehole_ids)
      })
      .catch((error) => {
        notifyError('Error: ' + error.response.data.exception)
      })
  }

  const loading = (loadingStatus) => {
    return (
      <>
        {loadingStatus &&
          <>
            <FontAwesomeIcon icon={faSync} spin className='text-indigo-500' />
            <p className='ml-3 -mr-2'>Updating Chart</p>
          </>}
        {!loadingStatus && <>Update Filters</>}
      </>
    )
  }

  return (
    <div className='border border-blue-500 p-4 rounded-lg m-4'>
    <h1 className='text-center'><strong>FILTERS</strong></h1>
      <filter.DateFilter dateRange={filters.date_range} onEndDateSelect={onEndDateSelect} onStartDateSelect={onStartDateSelect} />
      <filter.OptionFilter options={filters.boreholeIds.length === 0 ? ["N/A"] : filters.boreholeIds} onSelect={onBoreholeSelect} name={"boreholes"} />
      <filter.OptionFilter options={filters.apecs.length === 0 ? ["N/A"] : filters.apecs} onSelect={onApecSelect} name={"apecs"} />
      <button
        className={`border text-white bg-regular-blue hover:bg-hover-blue border-regular-blue rounded-md px-2 py-1 transition duration-500 select-none text-xl font-bold w-64 text-center m-2`}
        onClick={onClick}
      >
        {loading(loadingStatus)}
      </button>
    </div>
  )
}

export default ChartFilters
