import API, { simpleGet, simplePost } from "./apiConfig"
import { projectOrPortfolioPath } from "../utilities/projectOrPortfolioHelpers"

const csrfToken = document.querySelector('[name=csrf-token]').content

export const getEmailGroups = ({ portfolioId, projectId }) => {
  const basePath = projectOrPortfolioPath({ portfolioId, projectId })
  return simpleGet(`${basePath}/email-groups`)
}

export const emailReport = ({ portfolioId, projectId, params }) => {
  const reportPath = projectOrPortfolioPath({ portfolioId, projectId })
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API.post(`${reportPath}/email-report`, params).then(res => res.data)
}

export const emailCocExport = ({ params }) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return API.post(`email-coc`, params).then(res => res.data)
}

export const emailTrackerEntry = ({ params }) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return simplePost(`/tracker_entries/${params.tracker_entry_id}/email-tracker-entry`, params)
}

export const emailQgisReport = ({ gis_pdf_template_id, form_datum_id, params }) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return simplePost(`/form_datum/${form_datum_id}/gis-pdfs/${gis_pdf_template_id}/send-email`, params)
}

export const emailChecklistEntry = ({ params }) => {
  API.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
  return simplePost(`/checklist_entries/${params.checklist_entry_id}/email`, params)
}
