
/**
 * utility style class allowing universal styling of table elements
 */

export const tableStyles = {
  table: "border-separate table-fixed border-gray-200 border border-spacing-0 relative z-0",
  tableHeaderRow: "sticky top-0 z-10",
  th: "border-gray-200 border border-b-2 align-top sticky top-0 bg-white p-2 content-left",
  bottomTh: "border-gray-200 border border-b-2 align-bottom sticky top-0 bg-white p-2 content-left",
  thBase: "border-gray-200 border border-b-2 align-top sticky top-0 p-2",
  thTextTruncate: "truncate border-gray-200 border border-b-2 align-top sticky top-0 bg-white",
  thSm: "min-w-16",
  thLg: "min-w-64",
  thXl: "min-w-96",
  thContent: "w-full h-full text-center sticky top-0",
  thContentSideways: "w-full text-center sticky flex items-end justify-start",
  tdBase: "border-gray-200 border text-center relative p-4 align-middle cursor-pointer group hover:bg-blue-100 z-neg10",
  td: "border-gray-200 border text-center relative p-4 pb-6 align-middle cursor-pointer group hover:bg-blue-100 z-neg10 bg-white",
  tdCollapsed: "border-gray-200 border text-center relative p-1 align-middle cursor-pointer group hover:bg-blue-100 z-neg10 bg-white",
  tdText: "text-left w-full",
  tdTextTruncate: "text-left truncate max-w-96",
  tdNoHover: "border-gray-200 border-2 text-center p-4 align-middle",
  hoverIcon: "text-xs opacity-0 group-hover:opacity-100 text-gray-600 absolute p-1",
  deleteIcon: "text-xl text-gray-400 group-hover:text-gray-600",
}

export const toolTipStyles = {
  tooltiptext: "w-30 bg-black text-white text-center p-5 border-radius-6 absolute bottom-full transform -translate-x-1/2  ",
}

export const inputClass = 'block w-full text-gray-700 appearance-none focus:outline-none border border-gray-300 focus:shadow-inner rounded'

export const docTableStyles = {
  table: "border-separate table-fixed border-spacing-0 relative",
  tableHeaderRow: "sticky top-0",
  th: "border-gray-300 border border-b-2 align-top sticky top-0 bg-white p-4",
  thContent: "flex gap-4",
  tr: "cursor-pointer group",
  td: "border-gray-300 border text-left relative p-4 align-middle cursor-pointer group-hover:bg-blue-100 bg-white",
}
