import React, { useMemo } from "react"
import { usePortfolioContext } from "../../contexts/portfolioContext"
import Breadcrumb from "../nav/breadcrumb"
import Breadcrumbs from "../nav/breadcrumbs"

const TablePageBreadcrumbs = ({ children, tableName, tableSlug, scopeType }) => {
  const { portfolio, project } = usePortfolioContext()
  const portfolioUrl = useMemo(() => `/portfolios/${portfolio?.id}`, [portfolio])
  const projectUrl = useMemo(() => `/projects/${project?.id}`, [project])
  return (
    <Breadcrumbs>
      <Breadcrumb linkPath={portfolioUrl} linkName={portfolio?.name} firstLink={true} />
      <Breadcrumb linkPath={`${portfolioUrl}/${scopeType}/${tableSlug}`} linkName={tableName} />
      {project && <Breadcrumb linkPath={projectUrl} linkName={project?.name} />}
      { children }
    </Breadcrumbs>
  )
}

export default TablePageBreadcrumbs
