import React, { useMemo } from 'react'
import { usePhotoAPIContext } from '../../../contexts/photoContext'

const Comment = ({ comment, id, imageGuid, centerButton = false }) => {
  const buttonPosition = centerButton ? 'mx-auto' : 'max-w-max '
  const { openCommentModal } = usePhotoAPIContext()
  const commentDisplay = useMemo(() => {
    if (comment) {
      return comment?.description.length <= 50 ? comment?.description : `${comment?.description.substring(0, 50)}...`
    }
  }, [comment])

  const formDisplay = useMemo(() => {
    return comment?.form_name ? comment?.form_name.split('_').join(' ') : null
  }, [comment])

  return (
    <div className="mt-auto flex justify-center ">
      {(commentDisplay || formDisplay) && (
        <div className="relative grid grid-cols-1 gap-4 p-4 m-4 border rounded-lg bg-white shadow-lg w-full">
          {formDisplay && <p className="text-gray-400 text-sm">{formDisplay}</p>}
          {commentDisplay && <p className="-mt-4 text-gray-500">{commentDisplay}</p>}
          <button
            className={`btn btn-primary font-semibold m-1 relative cursor-pointer z-0 max-w-max ${buttonPosition}`}
            onClick={() => openCommentModal(id, imageGuid)}
          >
            Edit
          </button>
        </div>
      )}
    </div>
  )
}

export default Comment
