import React, { useMemo } from "react"
import { Link } from "react-router-dom"
import CaratIcon from "./caratIcon"

const RouterLink = ({ linkPath, linkStyle, linkName }) => <Link to={linkPath} className={linkStyle}>{linkName}</Link>
const AnchorLink = ({ linkPath, linkStyle, linkName }) => <a href={linkPath} className={linkStyle}>{linkName}</a>

function Breadcrumb({ linkPath, linkName, linkStyle = 'text-regular-blue', firstLink = false, routerLink = false }) {
  const LinkComponent = useMemo(() => routerLink ? RouterLink : AnchorLink, [routerLink])

  return (
    <li className='flex items-center'>
      {!firstLink && <CaratIcon />}
      <LinkComponent linkPath={linkPath} linkStyle={linkStyle} linkName={linkName} />
    </li>
  )
}

export default Breadcrumb
