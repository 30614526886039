import React from "react"
import { useUserLandingPageContext, useUserLandingPageApi } from "../../contexts/userLandingPageContext"
import RenderModal from "../modal/renderModal"
import ProfileModal from "./profileModal"
import StandardButton from "../shared/standardButton"

const Details = ({ user, containerStyles = '' }) => {
  const { modal } = useUserLandingPageContext()
  const { toggleField } = useUserLandingPageApi()
  return (
    <div className={`w-full grid grid-cols-2 px-2 py-4 bg-gray-200 ${containerStyles}`}>
      <div>
        <div className="flex flex-col space-y-4">
          <h2 className="text-2xl font-bold">User Details</h2>
          <div className="object-cover h-[100px] w-[100px] overflow-hidden bg-gray-200 group-hover:bg-gray-300 relative">
            {user?.headshot_path && (
              <img height={100} width={100} src={user?.headshot_path} className="object-cover w-full h-full" />
            )}
          </div>
          <p className="text-lg">{user?.human_identifier}</p>
          <p className="text-lg"><strong>Organization:</strong> {user?.organization?.name}</p>
          <p className="text-lg"><strong>Role: </strong>{user?.role}</p>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="mt-auto">
          <StandardButton buttonText='Edit User Details' onClickHandler={ toggleField('modal')}/>
        </div>
      </div>
          { modal && <RenderModal><ProfileModal/></RenderModal> }
    </div>
  )
}

export default Details
