import React from 'react'
import { AiDocumentsProvider } from '../../../contexts/aiDocumentsContext'
import { AiChatbotProvider } from '../../../contexts/aiChatbotContext'

import ProjectDocumentsView from './projectDocumentsView'
import { AiDocumentsFilterProvider } from "../../../contexts/aiDocumentsFilterContext"

const AiProjectDocument = (routes) => {

  return (
    <AiChatbotProvider>
      <AiDocumentsFilterProvider>
        <AiDocumentsProvider>
          <ProjectDocumentsView routes={ routes }/>
        </AiDocumentsProvider>
      </AiDocumentsFilterProvider>
    </AiChatbotProvider>
  )
}

export default AiProjectDocument
