import React, { createContext, useContext, useEffect, useMemo, useReducer } from "react"
import { notifyError } from "../components/shared/notice"
import { useParams } from "react-router-dom"
import { getDocuments, getProjectDocuments } from "../api/aiDocumentsApi"
import { getFilteredDocuments, getValueByFilter } from "../utilities/aiDocumentHelpers"

// Actions
const UPDATE_STATE = 'UPDATE_STATE'
const UPDATE_VALUE_OPTIONS = 'UPDATE_VALUE_OPTIONS'
const UPDATE_FILTERED_DOCUMENTS = 'UPDATE_FILTERED_DOCUMENTS'
const ADD_DOCUMENTS = 'ADD_DOCUMENT'
const UPDATE_DOCUMENTS = 'UPDATE_DOCUMENTS'

// Initial State
const initialState = {
  documents: [],
  filteredDocuments: [],
  documentOptions: [{ label: 'Status', value: 'status' }],
  valueOptions: [],
  currentDocumentFilter: {},
  currentDocumentValueFilter: "",
}

// Reducer

const aiDocumentsFilterReducer = (state, action) => {
  switch (action?.type) {
    case UPDATE_STATE:
      return { ...state, [action.field]: action.value }
    case UPDATE_DOCUMENTS:
      return { ...state, documents: action.documents, filteredDocuments: action.documents }
    case ADD_DOCUMENTS:
      const addedDocuments = action.documents ? action.documents : [action.document]
      return {
        ...state,
        documents: [...state.documents, ...addedDocuments],
        filteredDocuments: [...state.documents, ...addedDocuments]
      }
    case UPDATE_VALUE_OPTIONS:
      const valueOptions = getValueByFilter(state.documents, action.documentOption)
      return { ...state, valueOptions: valueOptions }
    case UPDATE_FILTERED_DOCUMENTS:
      return {
        ...state,
        filteredDocuments: getFilteredDocuments(state.documents, state.currentDocumentFilter, action.valueFilter)
      }
    default:
      return state
  }
}
/* API Methods */
const documentsApiMethods = (projectId) => ({
  fetchDocuments: async () => projectId ? getProjectDocuments(projectId) : getDocuments(),
})

// Contexts

const AiDocumentsFilterContext = createContext(initialState)
const AiDocumentsFilterAPIContext = createContext({
  updateState: () => {},
  addDocuments: () => {},
  updateDocumentOptionFilter: () => {},
  updateValueFilter: () => {},
  updateUploadFiles: () => {},
  onUpload: () => {},
})

// Providers

export const AiDocumentsFilterProvider = ({ children }) => {
  const [state, dispatch] = useReducer(aiDocumentsFilterReducer, initialState)
  const { projectId } = useParams()

  const api = useMemo(() => documentsApiMethods(projectId), [projectId])

  const documentApi = useMemo(() => {

    const updateState = (field, value) => dispatch({ type: UPDATE_STATE, field, value })

    const updateDocuments = (documents) => dispatch({ type: UPDATE_DOCUMENTS, documents })

    const addDocuments = (document) => dispatch({ type: ADD_DOCUMENTS, document })

    const updateDocumentOptionFilter = (documentOption) => {
      updateState('currentDocumentFilter', documentOption)
      dispatch({ type: UPDATE_VALUE_OPTIONS, documentOption })
    }

    const updateValueFilter = (valueFilter) => {
      updateState('currentDocumentValueFilter', valueFilter)
      dispatch({ type: UPDATE_FILTERED_DOCUMENTS, valueFilter })
    }

    return {
      updateState,
      updateDocuments,
      updateDocumentOptionFilter,
      updateValueFilter,
      addDocuments,
    }
  }, [])

  // Initialize the context
  useEffect(() => {
    api.fetchDocuments().then((res) => {
      documentApi.updateDocuments(res.documents)
    }).catch(() => { notifyError('Failed to fetch documents') })
  }, [])

  return (
    <AiDocumentsFilterContext.Provider value={ state }>
      <AiDocumentsFilterAPIContext.Provider value={ documentApi }>
        { children }
      </AiDocumentsFilterAPIContext.Provider>
    </AiDocumentsFilterContext.Provider>
  )
}

export const useAiDocumentsFilterContext = () => useContext(AiDocumentsFilterContext)
export const useAiDocumentsFilterAPI = () => useContext(AiDocumentsFilterAPIContext)
