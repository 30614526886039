import React from 'react'
import { tableStyles } from "../../utilities/stylesHelpers"
import ProjectChecklistColumn from './projectChecklistColumn'
import ProjectChecklistRow from './projectChecklistRow'
import { useChecklistContext } from "../../contexts/checklistContext"
import UpdateChecklistButton from './checklistButtons/updateChecklistButton'
import HideChecklistButton from './checklistButtons/hideChecklistButton'

function ProjectChecklist({ projectChecklist }) {
  const { columns, rows, entriesByProjectChecklistId, collapsedChecklists } = useChecklistContext()
  const entries = entriesByProjectChecklistId[parseInt(projectChecklist.id)]
  const status = projectChecklist?.status ? projectChecklist.status.toUpperCase() : 'N/A'
  const date = projectChecklist?.date ? new Date(projectChecklist.date).toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  }) : 'N/A'

  const rowsContent = rows?.map((row) => {
    const filteredEntries = entries?.filter((entry) => entry.checklist_row_id === row.id)
    const filteredEntry = filteredEntries ? filteredEntries[0] : null
    return (<ProjectChecklistRow key={row.id} task={row.task} description={row?.description} entry={filteredEntry} checklistId={projectChecklist.id} />)
  })

  return (
    <>
      <div className="m-4 my-2 sticky left-4">
        <h3 className="text-xl">
          <strong>Project Name: </strong> {projectChecklist.project_name}
        </h3>
      </div>
      <div className="flex justify-between items-center mx-4 sticky left-4">
        <div>
          <strong> Date: </strong>{date},
          <strong> Status: </strong>{status},
          <strong> Description: </strong>{projectChecklist?.description ? projectChecklist.description : 'N/A'}
          <UpdateChecklistButton projectChecklist={projectChecklist} />
        </div>
        <div>
          <HideChecklistButton projectChecklistId={projectChecklist.id} />
        </div>
      </div>
      <div className="p-4">
        <table className={tableStyles.table}>
          <ProjectChecklistColumn columns={columns} />
          {!collapsedChecklists.includes(projectChecklist.id) && <tbody>{rowsContent}</tbody>}
        </table>
      </div>
    </>
  )
}

export default ProjectChecklist
