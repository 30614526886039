import React, { useMemo } from "react"
import { useParams } from "react-router-dom"
import { usePortfolioContext } from "../../contexts/portfolioContext"
import PortfolioChecklistView from "./portfolioChecklistView"
import ProjectChecklistView from "./projectChecklistView"
import { useChecklistContext, useChecklistAPI } from "../../contexts/checklistContext"
import TablePageBreadcrumbs from "../../layouts/tablePage/tablePageBreadcrumbs"
import { ChecklistFilterProvider } from "../../contexts/checklistFilterContext"
import EmailModal from "../modal/emailModal"
import { toast } from "react-toastify"
import { emailChecklistEntry } from "../../api/emailApi"

const Checklist = ({ routes }) => {
  const { portfolioId, projectId } = useParams()
  const { template, modals, selectedEntry } = useChecklistContext()
  const { toggleModal, setSelectedEntry } = useChecklistAPI()
  const { portfolio, project } = usePortfolioContext()
  const portfolioOrProjectName = project ? project?.name : portfolio?.name

  const onClose = () => {
    toggleModal('emailModal')
    setSelectedEntry(null)
  }

  const onSendEmail = async ({ selectedEmailOptions, subject, body }) => {
    const params = {
      recipients: selectedEmailOptions.map(email => email.value),
      subject,
      body,
      checklist_entry_id: selectedEntry.id
    }
    try {
      await emailChecklistEntry({ params })
      toast.success(`Email sent to ${selectedEmailOptions.map(email => email.value)}`)
      onClose()
    } catch (e) {
      toast.error('Email failed to send')
    }
  }

  const emailBody = useMemo(() => selectedEntry ? `${selectedEntry.task} status is: ${selectedEntry.checklist_attributes[0].value} the link is ${window.location.href}` : '', [selectedEntry])

  return (
    <>
      <TablePageBreadcrumbs tableName={template?.name} tableSlug={template?.slug} scopeType={"checklists"} />
      <ChecklistFilterProvider>
        <div className="flex flex-col lg:flex-row w-full" style={{ height: "calc(100% - 49px)" }}>

          { projectId && <ProjectChecklistView routes={routes} /> }
          { portfolioId && <PortfolioChecklistView /> }

        </div>
        {modals.emailModal && <EmailModal
          modal={modals.emailModal}
          onClose={onClose}
          onSend={onSendEmail}
          emailBody={emailBody}
          emailSubject={selectedEntry ? `Job#${selectedEntry.job_number} - ${portfolioOrProjectName} ${template?.name} - Checklist Update` : ''}
        />}
      </ChecklistFilterProvider>
    </>
  )
}

export default Checklist
