import React from "react";
import BoreholeHeaderField from "./BoreholeHeaderField";
import CoordinatesField from "./CoordinatesField";

const HeaderFields = ({ boreholeDetails, onInputChange, onUseLatLonToggle }) => {
  return (<>
    <h2>Header Information</h2>
    <div className='flex flex-col gap-y-3'>
      <BoreholeHeaderField key={1} title={'Project'} field={'project'} boreholeDetails={boreholeDetails} onInputChange={onInputChange} />
      <BoreholeHeaderField key={2} title={'Client'} field={'client'} boreholeDetails={boreholeDetails} onInputChange={onInputChange} />
      <BoreholeHeaderField key={3}  title={'Legal'} field={'legal'} boreholeDetails={boreholeDetails} onInputChange={onInputChange} />
      <BoreholeHeaderField key={4}  title={'Checked By'} field={'checked_by'} boreholeDetails={boreholeDetails} onInputChange={onInputChange} />
      <CoordinatesField boreholeDetails={boreholeDetails} onUseLatLonToggle={onUseLatLonToggle} onInputChange={onInputChange} />
    </div>
  </>)
}

export default HeaderFields
