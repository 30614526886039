import React, { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { pathBuilder } from "../api/documentLogApi"
import {
  initializeState,
  saveDocument,
  updateContentsState,
  updateContentState,
  updateSectionState
} from "../utilities/documentBuilderHelpers"

/* ACTIONS */
const ACTIONS = {
  UPDATE_STATE: 'UPDATE_STATE',
  UPDATE_CONTENT: 'UPDATE_CONTENT',
  UPDATE_CONTENTS: 'UPDATE_CONTENTS',
  UPDATE_SECTION: 'UPDATE_SECTION',
}

/* INITIAL STATES */
const initialState = {
  document: {},
  template: {},
  sections: [],
  contents: {},
  modal: { definitions: false },
  dataSources: [],
  documentDefinitions: {},
}

/* REDUCER */
const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_STATE:
      return { ...state, [action.field]: action.value }
    case ACTIONS.UPDATE_CONTENT:
      return { ...state, contents: updateContentState(state.contents, action.field, action.value, action.contentId) }
    case ACTIONS.UPDATE_CONTENTS:
      return { ...state, contents: updateContentsState(state.contents, action.contents) }
    case ACTIONS.UPDATE_SECTION:
      return { ...state, sections: updateSectionState(state.sections, action.field, action.value, action.sectionId) }
    case ACTIONS.TOGGLE_MODAL:
      return { ...state, modal: { ...state.modal, [action.modal]: action.state } }
    default:
      return state
  }
}

/* CONTEXTS */
const DocumentBuilderContext = createContext(initialState)
const DocumentBuilderApiContext = createContext({
  updateState: () => {},
  updateContent: () => {},
  updateContents: () => {},
  updateSectionState: () => {},
  toggleModal: () => {},
  saveDocument: () => {},
})

/* PROVIDER */
export const DocumentBuilderProvider = ({ children }) => {
  const { projectId, templateSlug, documentId } = useParams()
  const navigate = useNavigate()
  const [state, dispatch] = useReducer(reducer, initialState)

  const api = useMemo(() => {
    const updateState = (field, value) => dispatch({ type: ACTIONS.UPDATE_STATE, field, value })
    const updateContent = (contentId, field, value) => dispatch({
      type: ACTIONS.UPDATE_CONTENT, contentId, field, value
    })

    const updateContents = contents => dispatch({ type: ACTIONS.UPDATE_CONTENTS, contents })

    const updateSectionState = (sectionId, field, value) => dispatch({
      type: ACTIONS.UPDATE_SECTION, sectionId, field, value
    })

    const toggleModal = (modal, state) => dispatch({ type: ACTIONS.TOGGLE_MODAL, modal, state })

    return { updateState, updateContent, updateContents, updateSectionState, toggleModal }
  }, [])

  api.saveDocument = useCallback(async () => {
    const responseDocument = await saveDocument({
      contents: state.contents,
      sections: state.sections,
      projectId,
      templateSlug,
      documentId
    })

    api.updateContents(responseDocument.contents)
    navigate(pathBuilder({ projectId, templateSlug, documentId }))
  }, [projectId, templateSlug, documentId, navigate, state])

  // Initialize document builder when url changes
  useEffect(() => {
    void initializeState({ projectId, templateSlug, documentId, updateState: api.updateState })
  }, [projectId, templateSlug, documentId, api])

  return (
    <DocumentBuilderApiContext.Provider value={ api }>
      <DocumentBuilderContext.Provider value={ state }>
        { children }
      </DocumentBuilderContext.Provider>
    </DocumentBuilderApiContext.Provider>
  )
}

/* CUSTOM HOOKS */
export const useDocumentBuilderContext = () => useContext(DocumentBuilderContext)
export const useDocumentBuilderApiContext = () => useContext(DocumentBuilderApiContext)
