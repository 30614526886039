import React from "react"
import { tableStyles } from "../../../utilities/stylesHelpers"
import TimesheetTableHeader from "./header"
import { useTimeTableContext } from "../../../contexts/timeTableContext"
import TimesheetTableRow from "./row"
import TimesheetTableTotalRow from "./totalRow"
import TimesheetNewEntryButton from "../newEntryButton"

const TimesheetTable = () => {
  const { currentWeeksEntries } = useTimeTableContext()
  return (
    <>
      <table className={tableStyles.table + " min-w-full"}>
        <TimesheetTableHeader />
        <tbody>
          {currentWeeksEntries.map(entry => <TimesheetTableRow key={entry.id} entry={entry} />)}
          <TimesheetTableTotalRow />
        </tbody>
      </table>
      <div className="flex justify-between">
        <TimesheetNewEntryButton />
      </div>
    </>
  )
}

export default TimesheetTable
