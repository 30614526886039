import React, { useState, useEffect } from "react"
import FormTemplate from "./formTemplate";
import { getAdminPortfolioFormTemplates, getPortfolioProjectFormTemplates } from "../../../api/portfoliosApi";

const FormTemplates = ({ portfolio }) => {
  const [formTemplates, setFormTemplates] = useState([])
  const formApiCall = window.location.pathname.includes("admin") ? getAdminPortfolioFormTemplates : getPortfolioProjectFormTemplates

  useEffect(() => {
    if (portfolio) {
      formApiCall(portfolio.id)
        .then(res => setFormTemplates(res))
        .catch(err => console.log(err))
    }
  }, [portfolio])

  return <>
    {formTemplates.length > 0 && <>
      <tr className='bg-regular-blue text-white'>
        <td className='font-semibold px-2 py-3 w-1/5'>Form Template Name</td>
        <td className='font-semibold px-2 py-3' colSpan='2'>GeoJSON URL</td>
      </tr>
      {formTemplates.map((template, index) => <FormTemplate key={index} portfolio={portfolio} formTemplate={template} />)}
      <tr className='border-t border-gray-400 '><td className='py-6'></td></tr>
    </>}
  </>
}

export default FormTemplates
