import React, { useCallback, useMemo } from 'react'
import { useTestResultsApi } from '../../../contexts/testResultsContext'
import { getLimt, testResultStyles } from '../../../utilities/labAnalyticHelpers'
import { tableStyles } from '../../../utilities/stylesHelpers'

const TestResultCell = ({ testResult, guideline, rowIndex }) => {
  const { toggleField, updateState } = useTestResultsApi()

  const onClickHandler = useCallback(() => {
    updateState("currentTestResult", { ...testResult, rowIndex: rowIndex })
    toggleField('modal.testResultModal')
  }, [testResult, rowIndex])

  const limit = useMemo(() => {
    if (!guideline) return null
    return getLimt(guideline, testResult)
  }, [guideline, testResult])
  
  const backgroundClass = useMemo(() => (limit || testResult.background_status) ? testResultStyles(testResult, limit) : '', [testResult, limit])
  return (
    <td className={backgroundClass ? `${backgroundClass} ${tableStyles.tdBase}`:`${tableStyles.td}`} onClick={onClickHandler}>
      {testResult?.value}
    </td>
  )
}

export default TestResultCell
