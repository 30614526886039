import React from "react"

const DeletePermitsButton = ({ onPermitsDelete }) => {
  return (
    <button
      className="btn btn-danger btn-outline font-semibold mr-2 relative cursor-pointer z-10"
      id="destroy-permits-btn"
      name="destroy-permits-btn"
      onClick={onPermitsDelete}
    >
      Delete Permits
    </button>
  )
}

export default DeletePermitsButton
