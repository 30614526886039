import React from 'react'
import { isEmpty } from "lodash"
import { useCocExport } from '../../contexts/cocExport'

const TableTotals = () => {
  const { selectedSamplesCount, selectedChemicalsCount, selectedApecsCount, numberOfCoolers } = useCocExport()

  const listCount = (selectedCount, type) => {
    return isEmpty(selectedCount)
      ? `No ${type} selected`
      : Object.entries(selectedCount).map(([selected, count]) => {
        return <div key={selected} style={{ fontWeight: 'normal' }}>{selected}: {count}</div>
      })
  }
  const coolerCount = numberOfCoolers === 0 ? 'Add Cooler Count in Billing Info' : numberOfCoolers

  const apecCount = listCount(selectedApecsCount, 'samples')
  const chemicalCount = listCount(selectedChemicalsCount, 'chemicals')

  return (
    <>
      <h1 className='text-1/4'><strong>SELECTION STATS</strong></h1>
      <h2>Total Samples Collected: {selectedSamplesCount}</h2>
      <h2>Analysis Count: &nbsp;
        {chemicalCount}
      </h2>
      <h2>{isEmpty(selectedApecsCount) ? "Apec:" : "Apecs: No.Samples Collected"} &nbsp;
        {apecCount}
      </h2>
      <br />
      <h2>Number of Coolers: {coolerCount}</h2>
    </>
  )
}

export default TableTotals
