import React from "react"
import ChatBox from "./chatBox"

const ChatBot = () => {
  return (
    <div className="h-full w-full p-10 flex flex-col justify-end items-center gap-8">
        <ChatBox/>
      <img src="/powered-by-openai-badge-outlined-on-light.svg" alt="powered by openai" className="w-60"/>
    </div>
  )
}

export default ChatBot
