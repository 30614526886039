import { parseISO } from "date-fns"

export const formatOptions = [{ value: 'pdf', label: 'PDF' }, { value: 'csv', label: 'CSV' }]
export const parseAllDates = inputArray => inputArray?.map(dateString => parseISO(dateString)) || []

export const optionizeEmails = (labelAttr, valueAttr) => (options) => options?.map(option =>
  ({ label: option?.[labelAttr], value: option?.[valueAttr], id: option?.id, emails: option?.email_group_entries })
)
export const optionizeEmailEntries = (labelAttr, valueAttr) => (options) => options?.map(option =>
  ({ label: option?.[labelAttr], value: option?.[valueAttr], groupId: option?.email_group_id })
)
