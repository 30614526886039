import React, { useCallback, useMemo, useState } from 'react'
import { useAiDocumentsAPI, useAiDocumentsContext } from '../../../contexts/aiDocumentsContext'
import { byteToMB } from '../../../utilities/helpers'
import LoadingSpinner from '../../shared/loadingSpinner'
import AnimatedCheckbox from "../../shared/animatedCheckbox"
import { classNames } from "../../../utilities/utils"
import { docTableStyles } from "../../../utilities/stylesHelpers"
import { formatDateTimeStr } from "../../../utilities/dateHelpers"
import { fileNameParsed } from "../../../utilities/aiDocumentHelpers"
import { Link } from "react-router-dom"
import ActionButtons from "../shared/actionButtons"

const DocumentView = ({ document }) => {
  const { selectedDocuments } = useAiDocumentsContext()
  const {
    selectDocument,
    onDelete,
    onGetBasicSummary,
    openaiUpload,
    promptDocument,
    toggleModal,
    selectOne
  } = useAiDocumentsAPI()
  const [isExpanded, setIsExpanded] = useState(false)

  const { loading, status, id: documentId } = useMemo(() => document, [document])
  const isSelected = useMemo(() => selectedDocuments.has(documentId), [selectedDocuments, document])

  const onSelectDocument = useCallback(() => selectDocument(documentId), [document])
  const onToggleModal = useCallback((modalType) => {
    selectOne(documentId)
    toggleModal(modalType)
  }, [selectOne, toggleModal])

  const statusMessage = useMemo(() => {
    switch (status) {
      case 'uploaded':
        return 'Document is awaiting further action'
      case 'processing':
        return "Generating Json"
      case 'prompting':
        return "Generating CSV"
      default:
        return
    }
  }, [status])

  return (
    <>
      <tr className={ docTableStyles.tr }>
        <td className={ classNames(docTableStyles.td) } onClick={ () => setIsExpanded(!isExpanded) }>
          { fileNameParsed(document.file_name) }
        </td>
        <td className={ classNames(docTableStyles.td) } onClick={ () => setIsExpanded(!isExpanded) }>
          <p>{ document.status[0].toUpperCase() + document.status.slice(1) }</p>
          { document?.json_file_path && document.status === 'processing' && <p>Json has not finished generating</p> }
          { loading && <LoadingSpinner size="2xl"/> }
        </td>
        <td className={ classNames(docTableStyles.td) } onClick={ () => setIsExpanded(!isExpanded) }>
          { formatDateTimeStr(document.updated_at, 'yyyy-MM-dd hh:mmaaa') }
        </td>
        <td className={ classNames(docTableStyles.td) } onClick={ () => setIsExpanded(!isExpanded) }>
          <AnimatedCheckbox checked={ !!document?.openai_id }/>
        </td>
        <td className={ classNames(docTableStyles.td) }>
          <div className={ classNames('flex w-full justify-center items-center') }>
            <AnimatedCheckbox onClick={ onSelectDocument } checked={ isSelected }/>
          </div>
        </td>
      </tr>
      { isExpanded && (
        <tr>
          <td className="border-gray-100 border-l-2 bg-white p-4 align-top">
            <p>
              <strong>Size: </strong>{ byteToMB(document.file_size) } MB
              { statusMessage && <><br/><span>{ statusMessage }</span></> }
            </p>
          </td>
          <td colSpan="4" className="border-gray-100 border-l-0 bg-white">
            <div className="w-full p-4 py-8 grid grid-cols-3 items-start">
              <div className="flex flex-col gap-4">
                <div>
                  <p>Summary:</p>
                  <p>{ document.summary || "Summary has not been generated yet." }</p>
                </div>
                { document.errors_status && (
                  <div>
                    <p>Errors:</p>
                    { document.errors_status.map((error, index) => (
                      <p key={ [documentId, index, 'error'].join("-") }>{ error }</p>
                    )) }
                  </div>
                ) }
              </div>
              <ActionButtons
                generateTables={ () => promptDocument(documentId) } onDelete={ () => onDelete(documentId) }
                toggleModal={ onToggleModal } uploadToOpenAi={ () => openaiUpload(documentId) }
                onGetBasicSummary={ onGetBasicSummary } document={ document }
              >
                <Link
                  className='btn btn-primary truncate cursor-pointer w-fit h-fit font-semibold'
                  to={ `/ai/documents/${ documentId }` }
                >
                  Review Document
                </Link>
              </ActionButtons>
            </div>
          </td>
        </tr>
      ) }
    </>
  )
}

export default DocumentView
