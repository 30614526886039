import React from "react"
import { isEmpty } from "lodash"
import { usePortfolioContext } from "../../contexts/portfolioContext"
import TrackerTableTabs from "./trackerTableTabs"
import TrackerTable from "../trackers/trackerTable"
import { TrackerProvider } from "../../contexts/trackerContext"
import { useReportContext } from "../../contexts/reportContext"
import { TrackerFilterProvider } from "../../contexts/trackerFilterContext"
import PortfolioView from "../../layouts/tablePage/portfolioView"

const PortfolioReport = () => {
  const { portfolio } = usePortfolioContext()
  const { activeTracker } = useReportContext()

  return (
    <PortfolioView>
      <TrackerTableTabs />
      {!isEmpty(activeTracker) && <TrackerProvider providedTrackerSlug={activeTracker?.slug} key={activeTracker?.slug}>
        <TrackerFilterProvider>
          <TrackerTable scopeName={portfolio?.name} />
        </TrackerFilterProvider>
      </TrackerProvider>}
    </PortfolioView>
  )
}

export default PortfolioReport
