import React, { useState, useEffect } from "react"
import { tableStyles } from "../../../utilities/stylesHelpers"

const FileUploadCell = ({ content, styler, onClickHandler, onDeleteFile }) => {
  const [filePath, setFilePath] = useState(null)
  const [fileName, setFileName] = useState('')

  useEffect(() => {
    if (content?.file) {
      setFilePath(content.file.filepath)
      setFileName(content.file.filename)
    }
  }, [content])

  const handleDelete = () => {
    onDeleteFile()
    setFilePath(null)
    setFileName('')
  }

  return <>
    {filePath ?
      <td className={tableStyles.td}
        style={{ ...styler }} >
        <div className="flex justify-between items-center">
          <a
            className='btn btn-primary truncate w-full text-center'
            href={filePath}
            download={fileName}
            rel="noreferrer noopener"
          >
            Download
          </a>
          <button
            className="btn btn-danger ml-2"
            onClick={handleDelete}
          >
            Delete
          </button>
        </div>
      </td>
      :
      <td className={tableStyles.td} style={{ minWidth: "120px", ...styler }} onClick={onClickHandler}>
        <button className='btn btn-primary truncate w-full text-center' >Upload</button>
      </td>
    }
  </>
}

export default FileUploadCell
