import React, { useEffect, useMemo, useReducer, useState } from "react"

const ResponseLine = ({ text, delay, callback }) => {
  const [currentText, setCurrentText] = useState('')
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    if (currentIndex < text.length) {
      const timeout = setTimeout(() => {
        setCurrentText(prevText => prevText + text[currentIndex])
        setCurrentIndex(prevIndex => prevIndex + 1)
      }, delay)

      return () => clearTimeout(timeout)
    } else {
      callback()
    }
  }, [currentIndex, delay, text])

  return <p>{ currentText }&nbsp;</p>
}

const ChatBubble = ({ message: { content, isResponse } }) => {
  const [currentIndex, incrementIndex] = useReducer((state, action = 1 + state) => action, 0)
  const [displayMessage, setDisplayMessage] = useState([])

  const messageArray = useMemo(() => content.split(/\r?\n/), [content])
  const placingStyle = useMemo(() => isResponse ? 'items-start' : 'items-end', [isResponse])
  const backgroundColor = useMemo(() => isResponse ? 'bg-[#BBB]' : 'bg-[#DDD]', [isResponse])

  useEffect(() => {
    if (isResponse) {
      // Populate chat bubble with response lines
      if (messageArray[currentIndex] || currentIndex < messageArray.length) {
        setDisplayMessage(state => {
          return [
            ...state,
            <ResponseLine key={ currentIndex } text={ messageArray[currentIndex] } delay={ 10 } callback={ incrementIndex }/>
          ]
        })
      }
    } else {
      // Display all text with no delay
      setDisplayMessage(messageArray.map((line, i) => {
        return <ResponseLine key={`line-${i}`} text={ line } delay={ 3 } callback={ () => {} }/>
      }))
    }
  }, [currentIndex])

  return (
    <div className={ `w-full flex flex-col items-start overflow-anchor-none ${ placingStyle }` }>
      <div className={ `${ backgroundColor } w-fit max-w-[55%] whitespace-normal p-6 rounded h-full` }>
        { displayMessage }
      </div>
    </div>
  )
}

export default ChatBubble
