import React from "react"

const TemplateRow = ({ template, urlHost }) => {
  return <>
    <tr key={template.id} className='border-b border-gray-400 leading-8'>
      <td>{template.name}</td>
      <td className='w-1/12'>{template.id}</td>
      <td>{`${urlHost}/api/form_templates/${template.id}/geo_json.geojson`}</td>
    </tr>
  </>
}

export default TemplateRow
