import React from "react"
import RadioButtonsGroup from "./radioButtonsGroup"
import SiteDropdown from "./siteDropdown"
import TextInput from "./textInput"
import NumericalInput from "./numericalInput";

import { tableStyles, yesNoOptions } from "./historicalAssessmentHelper"

const ApecPcocRow = ({ rowIndex, rowInfo }) => {
  const rowName = `historical_assessment_data[${rowIndex}]`

  return (
    <tr>
      <td className={tableStyles.td + ' sticky bg-white w-72 left-0 z-50'}>
        <SiteDropdown rowNameStub={rowName} initialValue={rowInfo?.sites} otherValue={rowInfo?.['sites-other']} />
      </td>
      <td className={tableStyles.td}>
        <RadioButtonsGroup fieldName={`${rowName}[betx]`} options={yesNoOptions} initialValue={rowInfo?.betx} />
      </td>
      <td className={tableStyles.td}>
        <RadioButtonsGroup fieldName={`${rowName}[hydrocarbons-f1-f4]`} options={yesNoOptions} initialValue={rowInfo?.['hydrocarbons-f1-f4']} />
      </td>
      <td className={tableStyles.td}>
        <RadioButtonsGroup fieldName={`${rowName}[poly-aromatic-hydrocarbons]`} options={yesNoOptions} initialValue={rowInfo?.['poly-aromatic-hydrocarbons']} />
      </td>
      <td className={tableStyles.td}>
        <RadioButtonsGroup fieldName={`${rowName}[salinity]`} options={yesNoOptions} initialValue={rowInfo?.salinity} />
      </td>
      <td className={tableStyles.td}>
        <RadioButtonsGroup fieldName={`${rowName}[metals]`} options={yesNoOptions} initialValue={rowInfo?.metals} />
      </td>
      <td className={tableStyles.td} >
        <NumericalInput fieldName={`${rowName}[boreholes]`} initialValue={rowInfo?.boreholes} />
      </td>
      <td className={tableStyles.td} >
        <NumericalInput fieldName={`${rowName}[minDepth]`} initialValue={rowInfo?.minDepth} />
      </td>
      <td className={tableStyles.td} >
        <NumericalInput fieldName={`${rowName}[maxDepth]`} initialValue={rowInfo?.maxDepth} />
      </td>
      <td className={tableStyles.td} >
        <TextInput fieldName={`${rowName}[other]`} initialValue={rowInfo?.other} />
      </td>
    </tr>
  )
}

export default ApecPcocRow
