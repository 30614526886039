import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import ConfirmationModal from "../shared/confirmation_modal";

const Approve = (routes) => {
  const [approveModal, setApproveModal] = useState(false)
  const onConfirm = () => {window.location.href = routes.approvePath}

  return (<>
    <a
      onClick={() => setApproveModal(!approveModal)}
      className='btn btn-primary'>
      <FontAwesomeIcon icon={faCheck} className='mr-2' />
      Approve
    </a>
    {approveModal && (
      <ConfirmationModal
        onConfirm={onConfirm}
        onCancel={() => setApproveModal(!approveModal)}
        title='I have reviewed all data and agree that it is accurate and ready for exports. '
        confirm_text='Approve'>
        <p>
          If any changes to the data are made, additional fees may be
          incurred{' '}
        </p>
      </ConfirmationModal>
    )}
  </>

  )
}
export default  Approve
