import React, { useState } from "react"
import DropDown from "./dropdown"

const SidebarDropdown = ({ options, selectionType }) => {
  const [selectedHref, setSelectedHref] = useState('')
  const mousePointer = selectedHref ? {} : {pointerEvents: 'none'}

  return (
    <div>
      <p className="py-2">{selectionType} Select</p>
      <DropDown options={options} styles='mb-5' handleChange={setSelectedHref} />
      <div className="w-full flex justify-end">
        <a
          href={selectedHref}
          className='btn btn-primary truncate w-full text-center w-min text-sm py-1.5 px-3'
          style={ mousePointer }
        >
          View {selectionType}
        </a>
      </div>
    </div>
  )
}

export default SidebarDropdown
