import React, { useEffect, useState } from 'react'
import axios from "axios";
import { useDebounce } from "use-debounce";

const ChemicalSelect = ({ selectChemical }) => {
  let [chemicals, setChemicals] = useState()
  let [chemicalName, setChemicalName] = useState('')
  let [showDropdown, setShowDropdown] = useState(false)

  let [debouncedChemicalName] = useDebounce(chemicalName, 500)

  useEffect(() => {
    if (debouncedChemicalName.length > 2) {
      axios.get(`/chemicals.json?query=${debouncedChemicalName}`).then(res => setChemicals(res.data))
    }
  }, [debouncedChemicalName])

  const ChemicalOption = ({ chemical }) => {
    return <div onMouseDown={() => {
      selectChemical(chemical.name)
      setChemicalName('')
    }}
                className='p-2 hover:bg-blueGray-100 cursor-pointer'>
      <div className='flex flex-row justify-between'>
        <label>{chemical.name}</label>
      </div>
      <div className='text-xs text-blueGray-500'>
        {chemical.cas}
      </div>
    </div>
  }

  const ChemicalsList = () => {
    if (showDropdown && chemicals && chemicals.length > 0) {
      return <div
        className='absolute top-16 bg-white z-10 border border-blueGray-200 w-full shadow-lg h-64 overflow-y-scroll'>
        {chemicals.map(chemical => <ChemicalOption key={chemical.id} chemical={chemical} />)}
      </div>
    } else {
      return null
    }
  }

  function handleKeyDown(e) {
    switch (e.key) {
      case 'Escape':
        setShowDropdown(false);
        break
      case 'Enter':
        selectChemical(e.target.value)
        setChemicalName('')
        setShowDropdown(false);
        break
    }
    return null;
  }

  return <div className='w-1/2'>
    <div className='form-group relative'>
      <label>Add Chemical</label>
      <input type='text'
             value={chemicalName}
             onKeyDown={e => handleKeyDown(e)}
             onChange={e => setChemicalName(e.target.value)}
             onBlur={() => setShowDropdown(false)}
             onFocus={() => setShowDropdown(true)} />
      <ChemicalsList />
    </div>
  </div>
}

export default ChemicalSelect
