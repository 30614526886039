import React from 'react'
import AnimatedCheckbox from "./animatedCheckbox"

const CheckBoxField = ({ checked, setChecked, className = '', size = 16, children }) => {
  return <div className={ className }>
    <AnimatedCheckbox checked={ checked } onClick={ () => setChecked(!checked) }/>
    <div className='cursor-pointer' onClick={ () => setChecked(!checked) }>{ children }</div>
  </div>
}

export default CheckBoxField
