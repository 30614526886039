import React, { useState, useEffect } from "react"
import { adminGetPortfolios } from "../../../../api/portfoliosApi";

const PortfolioList = () => {
  const [portfolios, setPortfolios] = useState(null)

  useEffect(() => {
    adminGetPortfolios().then(res => setPortfolios(res))
  }, [])

  const portfolioList = portfolios?.map(portfolio => {
    return <tr>
      <td className='w-1/6'>{portfolio.id}</td>
      <td className='w-1/2'>{portfolio.name}</td>
      <td className='w-1/3'>{portfolio.type}</td>
    </tr>
  })

  return <div className='mx-4'>
    <h1>Portfolio List:</h1>
    <p className='italic'>* Please use the ID of the portfolio, not the name</p>
    <table className='w-full text-left mt-3'>
      <tr>
        <th className='w-1/6'>ID:</th>
        <th className='w-1/2'>Name:</th>
        <th className='w-1/3'>Type:</th>
      </tr>
      {portfolioList}
    </table>
  </div>
}

export default PortfolioList
