export const updateUserTickets = (userTickets, userTicket) => {
  const userTicketsCopy = [ ...userTickets ]
  const userTicketIndex = userTicketsCopy.findIndex(ticket => ticket.id === userTicket.id)

  if (userTicketIndex === -1) {
    return [...userTicketsCopy, userTicket]
  }
  else{
    userTicketsCopy[userTicketIndex] = userTicket
  }
  return userTicketsCopy
}
