import React from "react"
import StandardButton from "../shared/standardButton"
import { blankEditEntry, useTimeTableApi } from "../../contexts/timeTableContext"

const TimesheetNewEntryButton = () => {
  const { toggleState, updateState } = useTimeTableApi()
  const onClickHandler = () => updateState('editEntry', blankEditEntry) || toggleState()

  return <StandardButton buttonText="Add New Entry" onClickHandler={onClickHandler} />
}

export default TimesheetNewEntryButton
