import React from 'react'
import { useChecklistContext } from '../../../../contexts/checklistContext'
import { stringifyRevision } from "../../../../utilities/checklistHelpers"

const LastUpdateInfoInput = () => {
  const { entry } = useChecklistContext()
  const { created_by_name, updatedValue, date, defined } = entry ? stringifyRevision(entry?.last_attribute_version) : { created_by_name: 'N/A', updatedValue: 'N/A', date: 'N/A', defined: false }

    if (defined)
      return (<><strong>{entry?.column_updated}</strong> was changed by <strong>{created_by_name}</strong> on <strong>{date}</strong> from <strong>{updatedValue}</strong></>)
    else
      return (<><strong>{entry?.column_updated}</strong> was updated or created on <strong>{date}</strong> by an <strong>undefined user</strong></>)

}

export default LastUpdateInfoInput
