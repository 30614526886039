import React from "react"

const RevisionEntry = ({ revision, revisionNumber }) => {
  const createdBy = `${revision?.created_by?.email || ''}`
  return (
    <div className="mb-2">
      <p>Revision #{revisionNumber}</p>
      <p>{createdBy}</p>
      <p>Previous value: {revision?.value}</p>
      <p>{revision?.created_at}</p>
      <hr className='border-black mb-7' />
    </div>
  )
}

export default RevisionEntry
