import React from "react"

const FormTemplate = ({ portfolio, formTemplate }) => {
  const urlHost = window.location.origin

  return <>
    <tr className='border-t border-gray-400 even:bg-gray-200'>
      <td className='px-2 py-3 w-1/5'>{formTemplate.name}</td>
      <td className='px-2 py-3' colSpan='2'>{`${urlHost}/api/portfolios/${portfolio.id}/form_templates/${formTemplate.id}/geo_json.geojson`}</td>
    </tr>
  </>
}

export default FormTemplate
