import React, { useEffect, useMemo, useRef, useState } from 'react'
import { MapContainer, Marker, Popup, TileLayer, Tooltip, LayersControl } from 'react-leaflet'
import L from 'leaflet';
import { isEmpty } from 'lodash'
import 'leaflet/dist/leaflet.css'
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import './map.css'
import axios from "axios";
import { BingLayer } from 'react-leaflet-bing-v2'
import PermitMarker from "./permitMarker"
import { useLocation } from "react-router-dom"
import { useProjectApiContext } from "../../../contexts/projectContext"

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const DraggableMarker = ({ note, notes }) => {
  const [position, setPosition] = useState({ lat: note?.lat, lng: note?.lng })
  const [newPosition, setNewPosition] = useState()
  const { setNotes } = useProjectApiContext()

  const markerRef = useRef(null)
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current
        if (marker != null) {
          setNewPosition(marker.getLatLng())
        }
      },
    }),
    [],
  )

  const resetPointer = () => {
    setPosition({ lat: note.lat, lng: note.lng })
    setNewPosition(null)
  }

  const saveNewPosition = () => {
    axios.put(note.path, { lat: newPosition.lat, lng: newPosition.lng }).then(res => {
      setPosition({ lat: res.data.lat, lng: res.data.lng })
      setNewPosition(undefined)
    })
  }

  const SaveButton = ({ saveNewPosition }) => {
    return <div
      className='text-white text-xs rounded-md px-2 py-1 border-indigo-500 bg-indigo-500 hover:bg-indigo-600 cursor-pointer'
      onClick={() => saveNewPosition()}>Save</div>
  }
  const ResetButton = () => {
    return <div
      className='text-white text-xs rounded-md px-2 py-1 border-yellow-500 bg-yellow-500 hover:bg-regular-orange cursor-pointer'
      onClick={() => resetPointer()}>Reset</div>
  }

  useEffect(() => {
    if (notes && position) {
      const index = notes.findIndex((item) => item.id === note.id);
      const newNote = {
        ...note,
        position: position,
        newPosition: newPosition
      };

      const otherNotes = [...notes]
      otherNotes.splice(index, 1)
      const newNotes = [newNote, ...otherNotes];

      setNotes(newNotes);
    }
  }, [position, newPosition])

  return <Marker draggable={true} key={note.id} position={position} ref={markerRef} eventHandlers={eventHandlers}>
    <Popup>
      <div className='flex flex-col gap-y-1'>
        <div className='font-bold text-lg'>{note.title}</div>
        {newPosition ? <>
          <div>Original: ({Number(position?.lat).toFixed(2)}, {Number(position?.lng).toFixed(2)})</div>
          <div className='mb-2'>Unsaved: ({newPosition.lat.toFixed(2)}, {newPosition.lng.toFixed(2)})</div>
          <div className='flex flex-row gap-x-2'><SaveButton saveNewPosition={saveNewPosition} /><ResetButton /></div>
        </> : <div>({position.lat.toFixed(2)}, {position.lng.toFixed(2)})</div>
        }

      </div>
    </Popup>
    <Tooltip permanent>
      {note.title}
    </Tooltip>
  </Marker>
}

const Map = ({ notes, permits, project }) => {
  const { BaseLayer } = LayersControl
  const BING_API_KEY = process.env.REACT_APP_BING_API_KEY

  const currentMap = useLocation().pathname.includes('permits') ? 'permits' : 'notes'

  const calculateCenter = () => {
    let coords = { lat: null, lng: null }

    if (currentMap === 'notes') {
      if (!isEmpty(notes?.filter( note => note.feature))) {
        coords = parseCoordinates(notes?.filter(note => note.feature)[0])
      } else if (project?.site) {
        coords = {
          lat: project?.site?.lat,
          lng: project?.site?.lon
        }
      }
    }

    if (currentMap === 'permits') {
      coords = parseCoordinates(
        permits?.filter(permit => permit.payload.lat && permit.payload.lon)[0]
        ?? permits?.filter(permit => permit.payload.x && permit.payload.y)[0]
        ?? permits?.filter(permit => permit.payload?.["X"] && permit.payload?.["Y"])[0]
      )
    }

    return [coords.lat, coords.lng]
  }

  const hasCenter = () => {
    const center = calculateCenter()
    return !!(center[0] && center[1])
  }

  return (
    <>
      {hasCenter() && (
        <MapContainer
          center={calculateCenter()}
          zoom={18}
          scrollWheelZoom={false}
        >
          <LayersControl>
            <BaseLayer name='OpenStreetMap'>
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </BaseLayer>
            <BaseLayer checked name='Satellite View'>
              <BingLayer bingkey={BING_API_KEY} type='AerialWithLabels' />
            </BaseLayer>
          </LayersControl>
          {currentMap === 'notes' && notes?.filter(note => note?.lat && note?.lng).map((note) => (
            <DraggableMarker key={note.id} note={note} notes={notes} />
          ))}
          {currentMap === 'permits' && permits?.map((permit) => (
            <PermitMarker key={permit.id} permit={permit} parseCoordinates={parseCoordinates} />
          ))}
        </MapContainer>
      )}
    </>
  )
}

const stringHasDot = (value) => value?.toString().includes('.')

const isValidCoord = (coords) => !!(stringHasDot(coords?.lat) && stringHasDot(coords?.lng))

const parseCoordinates = (entry = null) => {
  if (isValidCoord({ lat: entry?.lat, lng: entry?.lng})) {
    return { lat: entry.lat, lng: entry.lng }
  }
  if (isValidCoord({ lat: entry?.payload.lat, lng: entry?.payload.lon})) {
    return { lat: entry.payload.lat, lng: entry.payload.lon }
  }
  if (isValidCoord({ lat: entry?.payload.y, lng: entry?.payload.x })) {
    return { lat: entry.payload.y, lng: entry.payload.x }
  }
  if (isValidCoord({ lat: entry?.payload?.["Y"], lng: entry?.payload?.["X"] })) {
    return { lat: entry.payload["Y"], lng: entry.payload["X"] }
  }
  return { lat: null, lng: null }
}

export default Map
