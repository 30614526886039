import React from 'react'
import ModalWrapper from '../../modal/modalWrapper'
import { formatDateTimeStr } from '../../../utilities/dateHelpers'
import HistoryItem from '../../modal/historyItem'

const HistoryModal = ({ modal, toggleModal, cocExport }) => {
  return (
    <>
      {modal && (
        <ModalWrapper
          heading={`History`}
          closeModal={() => toggleModal({ modalType: 'history' })}
        >
          <div className='w-full'>
            <label key="export-created-by" className="w-1/5 z-20 font-bold">
              Chain of Custody Created
              <div className='mb-4 pl-4 text-sm'>
                <p className='font-normal'>
                  <span className='font-semibold'>By:</span> {cocExport?.created_by.email}
                </p>
                <p className='font-normal'>
                  <span className='font-semibold'>At:</span> {formatDateTimeStr(cocExport?.created_at)}
                </p>
              </div>
            </label>
            <label key="email-history" className="w-1/5 z-20 font-bold">
              Email History
              {cocExport?.email_histories.map(history => <HistoryItem key={history.id} history={history} />)}
            </label>
          </div>
        </ModalWrapper>
      )}
    </>
  )
}

export default HistoryModal
