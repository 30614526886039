import React, { useState } from 'react'

const PhotoExportToggleSwitch = ({ onClick = () => {}, options = ['', ''] }) => {
  const [isChecked, setIsChecked] = useState(false)

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked)
    onClick()
  }

  return (
    <>
    <h1 className='text-2xl font-semibold m-1 text-center'>Toggle</h1>
      <label className='flex cursor-pointer select-none items-center'>
        <span className={`font-semibold m-1 px-6 py-3 ${!isChecked ? 'bg-rose-300 rounded-full' : ''}`} >
          {options[0] ? options[0] : ''}
        </span>
        <div className='relative'>
          <input
            type='checkbox'
            checked={isChecked}
            onChange={handleCheckboxChange}
            className='sr-only'
          />
          <div
            className={`box block h-8 w-14 rounded-full ${isChecked ? 'bg-blue-300' : 'bg-rose-300'}`}
          ></div>
          <div
            className={`absolute left-1 top-1 flex h-6 w-6 items-center justify-center rounded-full bg-regular-blue transition ${isChecked ? 'translate-x-full' : ''}`}
          ></div>
        </div>
        <span className={`font-semibold m-1 px-6 py-3 ${isChecked ? 'bg-blue-300 rounded-full' : ''}`} >
          {options[1] ? options[1] : ''}
        </span>
      </label>
    </>
  )
}

export default PhotoExportToggleSwitch
