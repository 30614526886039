import React from 'react'
import Breadcrumbs from '../../layouts/nav/breadcrumbs'
import Breadcrumb from '../../layouts/nav/breadcrumb'

const BreadCrumbs = () => {
  return (
    <Breadcrumbs>
      <Breadcrumb linkName={'User View'} firstLink={true} />
    </Breadcrumbs>)
}
export default BreadCrumbs
