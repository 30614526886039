import React, { useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import 'react-toastify/dist/ReactToastify.css';
import '../../css/toastify-success.css';
import '../../css/toastify-error.css';

export function notifyAlert(notice) {
  toast.success(
      <p className='ml-1'>{notice}</p>,
    {
      icon: faCheckCircle
    }
  )
}

export function notifyError(alert) {
  toast.error(
    <p className='ml-1'>{alert}</p>,
    {
      icon: faTimesCircle,
      autoClose: false
    }
  )
}

export function notifyInfo(alert) {
  toast.info(
    <p className='ml-1'>{alert}</p>,
    {
      icon: faTimesCircle
    }
  )
}

export default function Notice({ notice, alert }) {

  const notify = () => {
    if (notice) notifyAlert(notice);
    else if (alert) notifyError(alert);
  };

  useEffect(() => {
    if (notice || alert) notify()
  }, [])

  return (
    <ToastContainer
      style={notice && { marginTop: '4em' }}
      bodyClassName={"text-white text-base"}
      position='top-center'
      autoClose={alert? false : 5000}
      hideProgressBar={true}
      closeOnClick={true}
      theme="colored"
    />
  )
}
