import React, { useEffect, useState } from 'react'
import BoreholeDetails from "../boreholeDetails";
import FormTemplate from "../../formData/formTemplate";
import axios from "axios";
import { useParams } from "react-router-dom";

const FormTemplates = ({ note }) => {
  const [formTemplates, setFormTemplates] = useState()

  useEffect(() => {
    axios.get(note.routes.formTemplatesRoute).then(res => {
      setFormTemplates(res.data)
    })
  }, [note])

  return formTemplates ? formTemplates.map(formTemplate =>
    <FormTemplate
      key={formTemplate.id}
      formTemplate={formTemplate}
      formDataRoute={note.routes.formDataRoute}
    />) : <></>
}

const ForemanNote = ({ noteId }) => {
  let [note, setNote] = useState()
  let { projectId } = useParams()

  useEffect(() => {
    noteId && axios.get(`/projects/${projectId}/notes/${noteId}.json`).then(res => {
      setNote(res.data)
    })
  }, [noteId])

  return note ? <>
    <div className='flex flex-row flex-1 bg-gray-100'>
      <BoreholeDetails note={note} />
      <div className='flex flex-col flex-grow overflow-x-hidden pl-4 p-2 lg:p-4'>
        <FormTemplates note={note} />
      </div>
    </div>
  </> : <></>
}

export default ForemanNote
