import React, { useMemo } from "react"
import Breadcrumb from "../../../layouts/nav/breadcrumb"
import Breadcrumbs from "../../../layouts/nav/breadcrumbs"
import { usePortfolioContext } from "../../../contexts/portfolioContext"

const ReportExportBreadcrumbs = () => {
  const { portfolio, project } = usePortfolioContext()
  const portfolioUrl = useMemo(() => `/portfolios/${portfolio?.id}`, [portfolio])
  const projectUrl = useMemo(() => `/projects/${project?.id}`, [project])
  const scopeUrl = useMemo(() => project ? projectUrl : portfolioUrl, [portfolio, project])

  return (
    <Breadcrumbs>
      {portfolio && <Breadcrumb linkPath={portfolioUrl} linkName={portfolio?.name} firstLink={true} />}
      {project && <Breadcrumb linkPath={projectUrl} linkName={project?.name} />}
      {scopeUrl && <Breadcrumb linkPath={`${scopeUrl}/reports-export`} linkName="Reports Export" linkStyle="" />}
    </Breadcrumbs>
  )
}

export default ReportExportBreadcrumbs
