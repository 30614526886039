import React, { useCallback, useMemo, useRef } from "react"
import Select from "react-select"
import { useTimesheetContext } from "../../../contexts/timesheetContext"
import { useTimeTableApi, useTimeTableContext } from "../../../contexts/timeTableContext"
import RenderModal from "../../modal/renderModal"
import ModalWrapper from "../../modal/modalWrapper"
import TimesheetModalHourInput from "./hourInput"
import { inputClass } from "../../../utilities/stylesHelpers"
import { projectOptionsValues, reduceProjects, optionizeTasks, reducePortfolios } from "../../../utilities/timesheetHelpers"
import { classNames, pipe } from "../../../utilities/utils"
import { formatDateString, getWeekEnd } from "../../../utilities/dateHelpers"

const requiredStyle = (errors, value) => (errors && !value) ? "border-red-500" : ""

const TimesheetEntryModal = () => {
  const formRef = useRef()
  const { toggleState, updateEditEntryState, updateEntry, createEntry, deleteEntry } = useTimeTableApi()
  const { userId, editModal, editEntry: {
    id, summary, project_id, portfolio_id, timesheet_task_id, week_start, sunday, monday,
    tuesday, wednesday, thursday, friday, saturday, errors,
  } } = useTimeTableContext()

  const { projects, portfolios, tasks, weekStart } = useTimesheetContext()

  const formattedWeekStart = formatDateString(weekStart)
  const weekEnd = useMemo(() => pipe(getWeekEnd, formatDateString)(weekStart), [weekStart])
  const portfolioOptions = useMemo(() => reducePortfolios(portfolios), [portfolios])
  const [jobNumberOptions, projectOptions, historicalJobNumberOptions] = useMemo(() => reduceProjects(projects, portfolio_id), [projects, portfolio_id])
  const taskOptions = useMemo(() => optionizeTasks(tasks, timesheet_task_id), [tasks])
  const portfolioValue = useMemo(() => portfolioOptions.find(option => option.value === portfolio_id), [portfolio_id])
  const [jobNumberValue, projectValue, historicalJobNumberValue] = useMemo(() => projectOptionsValues(projects, project_id), [project_id, projects])
  const taskValue = useMemo(() => taskOptions.find(task => task.value === timesheet_task_id), [taskOptions, timesheet_task_id])
  const updateProjectId = useCallback(event => updateEditEntryState('project_id', event?.value), [updateEditEntryState])

  const onSubmitHandler = useMemo(() => id ? updateEntry : createEntry, [id])
  const entryDate = useMemo(() => week_start || weekStart, [weekStart, week_start])


  return (<>
    {editModal && (<RenderModal>
      <ModalWrapper
        heading={`${formattedWeekStart} - ${weekEnd}`}
        closeModal={() => toggleState()}
        onSend={() => onSubmitHandler(formRef)}
        submitButtonText='Submit'
      >
        <form ref={formRef} className='flex flex-col gap-5'>
          {id && <input type="hidden" name="id" value={id} />}
          <input name="week_start" type="hidden" value={entryDate} />
          <input name="user_id" type="hidden" value={userId} />

          {/* Project Information */}
          <div className="flex flex-col lg:flex-row gap-4 w-full justify-between">
            <label htmlFor="job_number" className="w-full">
              Job Number <span className="text-red-700">*</span>
              <Select
                options={jobNumberOptions}
                onChange={updateProjectId}
                className={classNames(inputClass, requiredStyle(errors, jobNumberValue))}
                value={jobNumberValue}
                placeholder="Job Number"
                name="job_number"
                isClearable="true"
              />
            </label>
            <label htmlFor="historical_job_number" className="w-full">
              Historical Job Number
              <Select
                options={historicalJobNumberOptions}
                onChange={updateProjectId}
                className={inputClass}
                value={historicalJobNumberValue}
                placeholder="Historical"
                name="historical_job_number"
                isClearable="true"
              />
            </label>
          </div>
          <div className="flex flex-col lg:flex-row gap-4 w-full justify-between">
            <label htmlFor="project_id" className="w-full">
              Portfolio
              <Select
                options={portfolioOptions}
                onChange={event => updateEditEntryState('portfolio_id', event?.value)}
                className={inputClass}
                value={portfolioValue}
                placeholder="Portfolio"
                isClearable="true"
              />
            </label>
            <label htmlFor="project_id" className="w-full">
              Project <span className="text-red-700">*</span>
              <Select
                options={projectOptions}
                onChange={updateProjectId}
                className={classNames(inputClass, requiredStyle(errors, projectValue))}
                value={projectValue}
                placeholder="Project"
                name="project_id"
                isClearable="true"
              />
            </label>
          </div>

          {/* Task */}
          <label htmlFor="timesheet_task_id">
            Task <span className="text-red-700">*</span>
            <Select
              options={taskOptions}
              onChange={event => updateEditEntryState('timesheet_task_id', event.value)}
              className={classNames(inputClass, requiredStyle(errors, taskValue))}
              value={taskValue}
              placeholder="Task"
              name="timesheet_task_id"
            />
          </label>

          {/* Hours/Days */}
          <div className="grid grid-cols-3 gap-3 justify-center justify-items-center">
            <TimesheetModalHourInput field="sunday" value={sunday} />
            <TimesheetModalHourInput field="monday" value={monday} />
            <TimesheetModalHourInput field="tuesday" value={tuesday} />
            <TimesheetModalHourInput field="wednesday" value={wednesday} />
            <TimesheetModalHourInput field="thursday" value={thursday} />
            <TimesheetModalHourInput field="friday" value={friday} />
            <TimesheetModalHourInput field="saturday" value={saturday} />
          </div>

          <label htmlFor="summary">
            Summary
            <textarea
              className="border-solid border-2 border-gray-300 rounded-lg h-full min-h-fit w-full relative z-10 p-3"
              placeholder="Enter summary..."
              onChange={event => updateEditEntryState('summary', event.target.value)}
              value={summary}
              name="summary"
            />
          </label>
        </form>
        {id && <button
          onClick={() => deleteEntry(id)}
          className="bg-red-600 font-semibold px-4 py-2 text-white rounded mt-5"
        >
          Delete Entry
        </button>}
      </ModalWrapper>
    </RenderModal>)}
  </>)
}

export default TimesheetEntryModal
