import React from "react"

const SiteTypes = () => {
  return <div>
    <h1>Site Types:</h1>
    <p className='italic'>* Must be spelt exactly as listed below</p>
    <ul className="list-disc mx-6">
      <li>WellSite</li>
      <li>Facility</li>
      <li>Pipeline</li>
    </ul>
  </div>
}

export default SiteTypes
