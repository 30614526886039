import React, { forwardRef, useMemo } from "react"
import DropdownInput from "./dropdownInput"
import {defaultFormatDropdownOptions} from "../../../../utilities/tableHelpers";

const DefaultDropdown = forwardRef(({ checklistColumn, optionsParam = null , otherOptionLabel}, inputRef) => {
  const options = useMemo(() => {
    return optionsParam || checklistColumn?.checklist_dropdown_options?.map(defaultFormatDropdownOptions) || []
  }, [optionsParam, checklistColumn])

  return <>
    {options && <DropdownInput
      paramOptions={options}
      ref={inputRef}
      otherOptionLabel={otherOptionLabel}
    />}
  </>
})

export default DefaultDropdown
