import React from 'react'
import CheckBoxField from "../shared/checkBoxField";
import { useCocExport } from "../../contexts/cocExport";

const CocCheckBoxes = ({ hsv, setHsv, ssv, setSsv }) => {
  const { includeChangeOfControl } = useCocExport()

  return <>
    {includeChangeOfControl && <>
      <td className='p-4'>
        <CheckBoxField className='text-center' checked={hsv} setChecked={setHsv} />
      </td>
      <td className='p-4'>
        <CheckBoxField className='text-center' checked={ssv} setChecked={setSsv} />
      </td>
    </>}
  </>
}

export default CocCheckBoxes
