import React, { useEffect, useRef, useState } from 'react'
import Modal from "../../shared/modal"
import { isValid, parseISO } from "date-fns"
import DatePicker from "react-datepicker"
import Select from "react-select";
import { notifyAlert, notifyError } from "../../shared/notice"
import { updateChecklist } from "../../../api/checklist"
import { useChecklistAPI } from '../../../contexts/checklistContext'
import { checklistStatusOptions } from '../../../utilities/checklistHelpers';
import RenderModal from "../../modal/renderModal"

const ChecklistUpdateModal = ({ projectChecklist, show, onClose }) => {
  const { updateChecklists } = useChecklistAPI()
  const inputRef = useRef()
  const [selectedDate, setSelectedDate] = useState(new Date)
  const [selectedOption, setSelectedOption] = useState(null)

  const { date, status, description } = projectChecklist

  const [descriptionText, setDescriptionText] = useState(description ? description : '')

  const onChangeHandler = (date) => setSelectedDate(date)

  const onDropdownSelect = (event) => setSelectedOption(event)

  useEffect(() => setSelectedOption(checklistStatusOptions.find((option) => option.value === status)), [status])

  const onSave = async () => {
    if (!selectedDate || !selectedOption) return notifyError("Please select a valid date and status option.")

    try {
      const response = await updateChecklist(projectChecklist.id, {
        status: selectedOption.value,
        date: selectedDate,
        description: descriptionText
      })
      updateChecklists(response)
    } catch (error) {
      console.error("Error updating checklist:", error)
      notifyError("Error in updating a checklist", error)
      onClose()
    }

    notifyAlert("Checklist has been updated")
    onClose()
  }

  useEffect(() => {
    const parsedContent = parseISO(selectedDate)
    if (isValid(parsedContent)) {
      setSelectedDate(parsedContent)
    }
  }, [])

  useEffect(() => inputRef.current && inputRef.current.focus(), [inputRef.current])

  return (
    <> { show && (
      <RenderModal>
        <Modal heading='Update Checklist' onSave={ onSave } closeModal={ onClose }>
          <div className='flex flex-col lg:justify-between gap-y-4 lg:gap-y-0 lg:gap-x-4'>
            <div className='w-full'>
              DATE:
              <DatePicker
                className='block w-full p-1 text-xs text-gray-700 appearance-none focus:outline-none border border-gray-200 focus:shadow-inner m-0 rounded z-1000'
                selected={ selectedDate }
                onChange={ onChangeHandler }
                defaultValue={ date ? parseISO(date) : null }
              />
              STATUS:
              <Select
                options={ checklistStatusOptions }
                onChange={ event => onDropdownSelect(event) }
                className='block w-full p-1 text-xs text-gray-700 appearance-none focus:outline-none border border-gray-200 focus:shadow-inner rounded'
                value={ selectedOption }
              />
              DESCRIPTION:
              <textarea
                className='block w-full p-1 text-xs text-gray-700 appearance-none focus:outline-none border border-gray-200 focus:shadow-inner m-0 rounded'
                ref={ inputRef }
                value={ descriptionText }
                onChange={ e => setDescriptionText(e.target.value) }
              />
            </div>
          </div>
        </Modal>
      </RenderModal>
    ) }
    </>
  )
}

export default ChecklistUpdateModal
