import React from 'react'
import ProjectChecklist from "./projectChecklist"
import PortfolioView from '../../layouts/tablePage/portfolioView'
import ChecklistModal from "./checklistModal"
import { useChecklistContext } from "../../contexts/checklistContext"
import { usePortfolioContext } from "../../contexts/portfolioContext"
import HideAllChecklistButton from './checklistButtons/hideAllChecklistButton'
import { useChecklistFilterContext } from '../../contexts/checklistFilterContext'
import ChecklistFilters from './checklistFilters'
import InfoModal from './checklistModal/infoModal'
import ChecklistToolTip from './checklistToolTip'
import CollapsedStyleButton from './checklistButtons/collapsedStyleButton'

function PortfolioChecklistView() {
  const { template, projectChecklists } = useChecklistContext()
  const { portfolio } = usePortfolioContext()
  const { checklistIds } = useChecklistFilterContext()

  const filteredProjectChecklists = projectChecklists.filter(checklist => checklistIds.flat().includes(checklist.id))
  const checklists = filteredProjectChecklists.length > 0 ? filteredProjectChecklists : projectChecklists
  const projectChecklistIds = checklists.map((projectChecklist) => projectChecklist.id)

  const projectChecklistComponent = checklists?.map((projectChecklist) => {
    if (projectChecklist.status !== 'Deactivate_Checklist') {
      return <ProjectChecklist key={projectChecklist.id} projectChecklist={projectChecklist} />
    }
    return null
  })

  return (
    <>
      <PortfolioView>
        <div className='flex flex-col w-full h-full overflow-scroll'>
          <ChecklistFilters/>
          <div className="m-4 sticky left-4 flex flex-row items-center">
            <h1 className="text-3xl font-bold">Portfolio: { portfolio?.name } - { template.name }</h1>
            <HideAllChecklistButton projectChecklistIds={ projectChecklistIds }/>
            <CollapsedStyleButton/>
          </div>
          { projectChecklistComponent }
          <ChecklistModal/>
          <InfoModal/>
          <ChecklistToolTip/>
        </div>
      </PortfolioView>
    </>
  )
}

export default PortfolioChecklistView
