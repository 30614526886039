import React, { createContext, useContext, useMemo, useReducer, useEffect, useCallback } from "react"
import { useTrackerContext } from "./trackerContext"
import { pipe } from "../utilities/utils"
import {
  valuesWithHeaderId,
  entriesWithHeader,
  optionizeHeaders,
  sortTrackerEntries,
  sortFilterOptions,
} from "../utilities/trackerHelpers"

/* Actions */
const UPDATE_STATE = "UPDATE_STATE"
const UPDATE_CACHE = "UPDATE_CACHE"
const INCREMENT_STATE = "INCREMENT_STATE"
const PAGE_SIZE = 50

/* Initial State */
const initialState = {
  trackerEntries: [],
  headerOptions: [],
  valueOptions: [],
  currentHeaderFilter: {},
  currentValueFilter: "",
  valueOptionsCache: {},
  orderedBy: null,
  page: 1,
  loadedAll: false,
}

/* Reducer */
const trackerFilterReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_CACHE:
      return {
        ...state,
        [action.cache]:
          {
            ...state[action.cache],
            [action.header]: action.values
          }
      }
    case UPDATE_STATE:
      return { ...state, [action.field]: action.value }
    case INCREMENT_STATE:
      return { ...state, [action.field]: (action.loadAll ? 9999999 : state[action.field] + 1) }
    default:
      return state
  }
}

/* Contexts */
const TrackerFilterContext = createContext(initialState)
const TrackerFilterAPIContext = createContext({
  updateState: () => { },
  updateHeaderFilter: () => { },
  updateValueFilter: () => { },
  updateValueOptions: () => { },
  updateTrackerEntries: () => { },
  orderTrackerEntries: () => { },
  incrementPage: () => {},
})

/* Providers */
export const TrackerFilterProvider = ({ children }) => {
  const { headers: contextTrackerHeaders, trackerEntries: contextTrackerEntries } = useTrackerContext()
  const [state, dispatch] = useReducer(trackerFilterReducer, initialState, i => i)

  const api = useMemo(() => {
    const updateState = (field, value) => dispatch({ type: UPDATE_STATE, field, value })

    const incrementState = (field, loadAll) => dispatch({ type: INCREMENT_STATE, field, loadAll })

    const updateCache = (header, values, cache) => dispatch({ type: UPDATE_CACHE, header, values, cache })

    const updateHeaderFilter = (headerFilter) => api.updateState('currentHeaderFilter', headerFilter)

    const updateValueFilter = (valueFilter) => api.updateState('currentValueFilter', valueFilter)

    const updateValueOptions = (valueOptions) => api.updateState('valueOptions', valueOptions)

    const setOrderedBy = headerID => api.updateState('orderedBy', headerID)

    const incrementPage = ({ loadAll = false }) => api.incrementState('page', loadAll)

    return {
      updateState, incrementState, updateCache, updateHeaderFilter, updateValueFilter, updateValueOptions,
      setOrderedBy, incrementPage
    }
  }, [])

  api.updateTrackerEntries = useCallback((newTrackerEntries) => {
    api.updateState('trackerEntries', newTrackerEntries.slice(0, state.page * PAGE_SIZE))
  }, [state.page])

  api.orderTrackerEntries = useCallback((headerId, isAscending) => {
    const sortedEntries = sortTrackerEntries(headerId, contextTrackerEntries, isAscending)
    api.setOrderedBy(headerId)
    api.updateTrackerEntries(sortedEntries)
  }, [contextTrackerEntries])

  // When context tracker entries are updated
  useEffect(() => {
    api.updateTrackerEntries(contextTrackerEntries)
    api.updateState('loadedAll', (state.page * PAGE_SIZE) >= contextTrackerEntries.length)
  }, [contextTrackerEntries, state.page])

  useEffect(() => {
    if (!state.currentHeaderFilter?.id) return api.updateValueOptions([])
    if (state.valueOptionsCache?.[state.currentHeaderFilter.id]) return api.updateValueOptions(state.valueOptionsCache[state.currentHeaderFilter.id])

    const valuesWithHeader = valuesWithHeaderId(state.currentHeaderFilter)(contextTrackerEntries)

    api.updateCache(state.currentHeaderFilter.id, valuesWithHeader, 'valueOptionsCache')
    api.updateValueOptions(valuesWithHeader)
  }, [state.currentHeaderFilter, contextTrackerEntries])

  useEffect(() => {
    pipe(
      entriesWithHeader(state.currentHeaderFilter?.id, state.currentHeaderFilter?.type, state?.currentValueFilter),
      api.updateTrackerEntries
    )(contextTrackerEntries)
  }, [state.currentValueFilter])

  useEffect(() => {
    api.updateState("headerOptions", sortFilterOptions(optionizeHeaders(contextTrackerHeaders)))
  }, [contextTrackerHeaders])

  return (
    <TrackerFilterAPIContext.Provider value={api}>
      <TrackerFilterContext.Provider value={state}>
        {children}
      </TrackerFilterContext.Provider>
    </TrackerFilterAPIContext.Provider>
  )
}

/* Custom Context Hooks */
export const useTrackerFilterContext = () => useContext(TrackerFilterContext)
export const useTrackerFilterAPI = () => useContext(TrackerFilterAPIContext)
