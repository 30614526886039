import React from "react"
import { usePermitTableAPI } from "../../../contexts/permitTableContext"
import { first } from "lodash"

const NewPermitButton = ({ createPermit, headers }) => {
  const { onContentInit, toggleModal } = usePermitTableAPI()

  const onAddNewPermit = (e) => {
    e.preventDefault()

    createPermit()
      .then(({ data }) => {
        toggleModal()
        onContentInit('', first(headers), first(data).id)
      })
  }

  return (
    <button
      className="btn btn-primary font-semibold mx-4 mb-4 relative cursor-pointer z-10"
      id="add-permit-row-btn"
      name="add-permit-row-btn"
      onClick={onAddNewPermit}
    >
      Add New
    </button>
  )
}

export default NewPermitButton
