import React, { useMemo } from "react"
import { isEmpty } from "lodash"
import { classNames } from "../../utilities/utils"
import { useReportAPI, useReportContext } from "../../contexts/reportContext"

const TrackerTableTabs = () => {
  const { trackerTemplates, activeTracker } = useReportContext()
  const { updateActiveTrackerById } = useReportAPI()
  
  const tabs = useMemo(() => {
    return trackerTemplates.map(option => ({ name: option?.name, id: option?.id, current: option?.id === activeTracker.id }))
  }, [trackerTemplates, activeTracker])

  const onTabChange = (tab) => () => updateActiveTrackerById(tab.id)
  return (
    <div>
      {!isEmpty(tabs) && <div className="hidden sm:block">
        <nav className="isolate flex divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">
          {tabs.map((tab, tabIdx) => (
            <button
              key={tab.name}
              className={classNames(
                tab.current ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
                tabIdx === 0 ? 'rounded-l-lg' : '',
                tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10'
              )}
              aria-current={tab.current ? 'page' : undefined}
              onClick={onTabChange(tab)}
            >
              <span>{tab.name}</span>
              <span
                aria-hidden="true"
                className={classNames(
                  tab.current ? 'bg-indigo-500' : 'bg-transparent',
                  'absolute inset-x-0 bottom-0 h-0.5'
                )}
              />
            </button>
          ))}
        </nav>
      </div>}
    </div>
  )
}

export default TrackerTableTabs
