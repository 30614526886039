import React from 'react'
import { AiDocumentsProvider } from '../../../contexts/aiDocumentsContext'
import { AiDocumentsFilterProvider } from '../../../contexts/aiDocumentsFilterContext'
import DocumentGrid from './documentGrid'
import DocumentModal from './documentModal'
import StatusModal from './statusModal'
import EditModal from "./editModal"
import AiDocumentBreadcrumbs from "../shared/aiDocumentBreadcrumbs"

const AiDocuments = () => {
  return (
    <AiDocumentsFilterProvider>
      <AiDocumentsProvider>
        <div className="py-4 px-8">
          <AiDocumentBreadcrumbs/>
          <h1 className="text-3xl font-bold">AI DOCUMENTS</h1>
          <p className="text-lg">Documents larger than 15mb will not be able to be summarized</p>
          <DocumentGrid/>
          <DocumentModal/>
          <EditModal/>
          <StatusModal/>
        </div>
      </AiDocumentsProvider>
    </AiDocumentsFilterProvider>
  )
}

export default AiDocuments
