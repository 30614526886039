import React from "react"
import FormTemplates from "./formTemplates";
import TrackerTemplates from "./trackerTemplates";
import ChecklistTemplates from "./checklistTemplates";

const Portfolio = ({ portfolio }) => {
  const urlHost = window.location.origin

  return <>
    <table className='mx-4 my-8 text-left w-11/12'>
      <thead>
        <tr className='bg-regular-orange w-full text-white text-2xl'><th className='px-2 py-3' colSpan='3'>{portfolio.name}</th></tr>
      </thead>
      <tbody>
        <tr><td className='py-6'></td></tr>
        <tr className='bg-regular-blue text-white'>
          <td colSpan='3' className='font-semibold px-2 py-3 w-1/5'>Portfolio Projects Information</td>
        </tr>
        <tr className='border-b border-gray-400'>
          <td className='font-semibold px-2 py-3 w-1/5'>JSON URL:</td>
          <td className='px-2 py-3' colSpan='2'>{`${urlHost}/api/portfolios/${portfolio.id}.json`}</td>
        </tr>
        <tr><td className='py-6'></td></tr>
        <FormTemplates portfolio={portfolio} />
        <TrackerTemplates portfolio={portfolio} />
        <ChecklistTemplates portfolio={portfolio} />
      </tbody>
    </table>
  </>
}

export default Portfolio
