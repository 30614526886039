import React from 'react'
import { formatDateYearMonthDay } from '../../utilities/dateHelpers'

const ExpiryComponent = ({ dateString }) => {
  const expiryDate = dateString ? new Date(dateString) : null;
  const daysUntilExpiry = expiryDate ? Math.floor((expiryDate - new Date()) / (1000 * 60 * 60 * 24)) : null;

  return (
    <>
      {expiryDate ? <>
        <div>
          Expires on: {formatDateYearMonthDay(expiryDate)}
        </div>
        <div>
          Expires in: {daysUntilExpiry} days
        </div>
      </> : ''}
    </>
  )
}

export default ExpiryComponent
