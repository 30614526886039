import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { uploadPermitsCsv, uploadConditionsCsv } from '../../api/permits'

/**
 * Currently, the user can upload a permit CSV or a conditions CSV.
 * The conditions CSV should only be uploadable from a specific permits page.
 * We're determining if we're on a specific permits page if the permitId is present.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const UploadCsv = ({ getPermits }) => {
  const { permitId } = useParams()
  const [uploadType] = useState( permitId ? "conditions_csv" : "permit_csv" )
  const [buttonText] = useState( permitId ? "Submit Permit Conditions CSV" : "Submit Permit CSV" )

  const [displayName, setDisplayName] = useState( 'Upload CSV' )
  const [selectedFile, setSelectedFile] = useState()
  const [uploading, setUploading] = useState(false)
  const currentLocation = window.location.pathname

  const handleFileChange = (event) => {
    event.persist()
    const file = event.target.files[0];
    setDisplayName( _.truncate(file.name) )
    setSelectedFile(file)
  }

  const onFormSubmit = (e) => {
    e.preventDefault()
    setUploading(true)
    const formData = new FormData()
    formData.append(uploadType, selectedFile)

    const token = document.querySelector('[name=csrf-token]').content

    return (
      permitId ?
        uploadConditionsCsv({ csrfToken: token, formData: formData, permitPath: currentLocation }) :
        uploadPermitsCsv({ csrfToken: token, formData: formData, permitsPath: currentLocation })
    )
      .then((_) => {
        setUploading(false)
        getPermits()
      })
      .catch((err) => console.error(err))
  }

  return (
    <form onSubmit={onFormSubmit}>
      <label htmlFor={uploadType} className="btn btn-primary btn-outline font-semibold mr-4 relative cursor-pointer z-10">
        {displayName}
        <input type="file" name={uploadType} id={uploadType} onChange={handleFileChange} accept=".csv" className="opacity-0 absolute inset-0 z-0 cursor-pointer top-full h-px" required />
      </label>
      <input type="submit" className="btn btn-primary cursor-pointer" value={uploading ? "Uploading..." : buttonText}/>
    </form>
  )
}

export default UploadCsv
