import React from "react"
import StandardButton from "../../components/shared/standardButton"
import { openInNewTab } from "../../utilities/helpers"

const ToggleWidgetButtons = ({ toggleWidget, widget, buttonName, vertical = false }) => {
  const name = buttonName ? buttonName : "Map"

  return (
    <div className={ vertical ? 'flex-row w-full' : 'flex flex-row w-full' }>
      <StandardButton buttonText={`Toggle ${name}`} onClickHandler={toggleWidget} buttonStyles={vertical ? "h-1/2" : "w-1/2"} vertical={vertical} />
      <StandardButton buttonText={`Window ${name}`} onClickHandler={() => openInNewTab(widget)} buttonStyles={vertical ? "h-1/2" : "w-1/2"} vertical={vertical} />
    </div>
  )
}

export default ToggleWidgetButtons
