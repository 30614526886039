import axios from "axios"

export const getExports = (projectId) => {
  try {
    return axios({
      method: 'GET',
      url: `/projects/${projectId}/coc_exports.json`,
      params: { project_id: projectId }
    })
  }
  catch (e) {
    console.error(e)
  }
}

export const getExport = (project, currentExportID) => {
  try {
    return axios.get(`/projects/${project.id}/coc_exports/${currentExportID}.json`)
  }
  catch (e) {
    console.error(e)
  }
}

export const createExport = (exportPath, payload) => {
  try {
    return axios.post(exportPath, payload)
  }
  catch (e) {
    console.error(e)
  }
}
