import React from "react";

const Projects = () => {
  const urlHost = window.location.origin

  return <>
    <table className='m-4 text-left w-11/12'>
      <thead>
        <tr className='bg-regular-orange text-white w-full px-2 py-1'>
          <th colSpan='2' className='px-2 py-3 text-2xl'>All Projects Data Connections</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className='font-semibold px-2 py-3 w-1/5'>JSON URL:</td>
          <td className='px-2 py-1'>{`${urlHost}/api/projects.json`}</td>
        </tr>
        <tr className='border-t border-gray-400 bg-gray-200'>
          <td className='font-semibold px-2 py-3 w-1/5'>GeoJSON URL:</td>
          <td className='px-2 py-1'>{`${urlHost}/api/projects/geo_json`}</td>
        </tr>
        <tr><td className='py-3'></td></tr>
      </tbody>
    </table>
  </>
}

export default Projects
