import React from "react"
import Select from "react-select"
import { isEmpty } from "lodash"
import { useChecklistFilterContext, useChecklistFilterAPI } from "../../contexts/checklistFilterContext"

const ChecklistFilters = () => {
  const { checklistOptions, valueOptions, currentChecklistFilter } = useChecklistFilterContext()
  const { updateChecklistOptionFilter, updateValueFilter } = useChecklistFilterAPI()

  const onFilterSelect = (e) => { updateChecklistOptionFilter(e?.value) }
  const onColumnSelect = (e) => { updateValueFilter(e?.label) }

  return (
    <div className="m-4 z-50 flex flex-row gap-x-4 sticky left-4">
      <Select
        options={checklistOptions}
        onChange={onFilterSelect}
        className='w-1/2, z-50'
        isClearable='true'
        placeholder={'Choose a Filter Option'}
      />
      <Select
        options={valueOptions}
        onChange={onColumnSelect}
        className='w-1/2 z-50'
        isClearable
        isDisabled={isEmpty(currentChecklistFilter)}
        placeholder={'Choose Value to Filter for'}
      />
    </div>
  )
}

export default ChecklistFilters
