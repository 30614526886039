import React, { useEffect, useState } from "react";
import Select from "react-select";

const BoreholeDropDownSelect = ({ boreholes, onBoreholeSelect }) => {
  const [boreholeOptions, setBoreholeOptions] = useState([])

  useEffect(() => {
    const formattedOptions = boreholes?.map((borehole) => {
      const boreholeLabel = <div><span className='font-bold'>{borehole.title}</span> - <span className='italic'>Collected on: {borehole.sample_date}</span></div>

      return { value: borehole, label: boreholeLabel }
    })

    setBoreholeOptions(formattedOptions)
  }, [boreholes])

  return (<>
    <h3 className='font-semibold'>Select specific boreholes below:</h3>
    <Select
      isMulti
      options={boreholeOptions}
      onChange={(selectedOption) => {
        const boreholes = selectedOption.map(option => option.value)
        onBoreholeSelect(boreholes)
      }}
      className={"w-2/5"}
    />
  </>)
}

export default BoreholeDropDownSelect
