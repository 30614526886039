import React, { useMemo } from 'react'
import { useAnalyticSummaryContext } from '../../../contexts/analyticSummaryContext'
import { backgroundResultMapping } from '../../../utilities/labAnalyticHelpers'

const BackgroundTable = () => {
  const { analyticSummary } = useAnalyticSummaryContext()
  const { backgroundHeader, values } = useMemo(() => backgroundResultMapping(analyticSummary)
    , [analyticSummary])

  return (
    <div>BackgroundTable
      <table>
        <thead>
          <tr>
            {backgroundHeader.map((header, index) => {
              return <th key={index}>{header}</th>
            })}
          </tr>
        </thead>
        <tbody>
          <tr>
            {values.map((value, index) => {
              return <th key={index}>{value}</th>
            })}
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default BackgroundTable
