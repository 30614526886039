import { useEffect, useRef } from 'react'
import { $getRoot } from 'lexical'
import { $generateNodesFromDOM } from '@lexical/html'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'

const LoadInitialContentPlugin = ({ initialContent }) => {
  const [editor] = useLexicalComposerContext()
  const isMountedRef = useRef(false)
  useEffect(() => {
    if (!initialContent || isMountedRef.current) return

    editor.update(() => {
      $getRoot().getChildren().forEach((n) => n.remove())
      const parser = new DOMParser()
      const dom = parser.parseFromString(initialContent, 'text/html')
      const nodes = $generateNodesFromDOM(editor, dom)
      nodes.forEach((n) => $getRoot().append(n))
    })
    isMountedRef.current = true
  }, [editor, initialContent])
  return null
}

export default LoadInitialContentPlugin
