import React, { useMemo } from "react"
import { tableStyles } from "../../../utilities/stylesHelpers"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit } from "@fortawesome/free-solid-svg-icons"

const NumberCell = ({ content, styler, onClickHandler, collapsed = false }) => {
    const cellContent = useMemo(() => content?.value && new Intl.NumberFormat().format(content.value), [content?.value])
    const tdTextStyles = collapsed ? tableStyles.tdTextTruncate : tableStyles.tdText
    const tdStyles = collapsed ? tableStyles.tdCollapsed : tableStyles.td

    return (
        <td className={tdStyles}
            style={{ ...styler }}
            onClick={onClickHandler}>
            <p className={tdTextStyles}>{cellContent}</p>
            <FontAwesomeIcon
                className={tableStyles.hoverIcon}
                icon={faEdit}
            />
        </td>
    )
}

export default NumberCell
