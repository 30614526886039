import React from "react"
import { isEmpty } from "lodash"
import { useAiPluginContext } from "../../../contexts/aiPluginContext"
import ChatBubble from "./chatBubble"

const ChatBox = () => {
  const { messages, loadingMessage } = useAiPluginContext()

  return (
    <div className="flex flex-col gap-y-4 max-w-6xl w-full h-full overflow-scroll">
      { isEmpty(messages) && (
        <div className="h-full w-full flex justify-center items-center">
          <p className="font-bold text-4xl">How can I help you today?</p>
        </div>
      ) }
      { messages.map((message, i) => <ChatBubble key={ i } message={ message }/>) }
      { loadingMessage && (
        <div className="w-full flex justify-center items-center">
          <div className="loader"></div>
        </div>
      ) }
      <div className="overflow-anchor h-px"></div>
    </div>
  )
}

export default ChatBox
