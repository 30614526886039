import { isEmpty } from "lodash"
import { getPortfolioWidgets } from "../api/portfoliosApi";
import { getUserToken } from "../api/lizmapTokenApi"

export const formatWidgetOption = (widget) => ({ value: widget.full_url || widget.url, label: widget.name})

export const portfolioWidgetOptions = (portfolioId, defaultWidget) => {
  return getPortfolioWidgets(portfolioId).then(res => {
    const formattedWidgetOptions = res.map(option => formatWidgetOption(option))
    const newWidgetsArray = [defaultWidget, ...formattedWidgetOptions]

    return newWidgetsArray.filter((v, i, a) => a.findIndex(v2 => (v2.value === v.value)) === i)
  })
}

export const safeDomainList = [
  'www.prodeltamap.com/lizmap',
  'maps4free.ca/lizmap',
  'map.fielddms.app/lizmap'
]

export const isSafeDomain = (widgetUrl) => !isEmpty(safeDomainList.filter(domain => widgetUrl.includes(domain)))

export const widgetParams = (lon, lat) => {
  const siteLon = parseFloat(lon)
  const siteLat = parseFloat(lat)
  const boxFactor = 0.0015

  return `&crs=EPSG%3A4326&bbox=${siteLon - boxFactor}%2C${siteLat - boxFactor}%2C${siteLon + boxFactor}%2C${siteLat + boxFactor}`
}

export const projectWidgetParams = (project) => {
  if (!project?.site) return ""

  return widgetParams(project.site.lon, project.site.lat)
}

export const userTokenHandling = async (widgetUrl) => {
  if (widgetUrl?.includes("user_token")) {
    return await getUserToken().then(res => widgetUrl.replace('USER_TOKEN_HERE', res.token))
  }

  return widgetUrl
}

const portfolioParamHandling = (widgetUrl, portfolioName) => widgetUrl?.replace('PORTFOLIO_NAME_HERE', encodeURIComponent(portfolioName))

export const setParams = ( widgetUrl, setWidget, portfolioName ) => {
  userTokenHandling(widgetUrl).then(res => portfolioParamHandling(res, portfolioName)).then(res => setWidget(res))
}

export const widgetLinks = {
  'power bI': 'https://app.powerbi.com/view?r=eyJrIjoiOWQ1NTU2ODAtZTdlNC00ZGNiLTllZWEtZmZjNGQxZjE4YTI5IiwidCI6IjJhZj80YWY3LTg1MmMtNDMzNy1hYTQ4LWQyMmYyNjg1ZWEyOCJ9',
  'construction demo': 'https://www.prodeltamap.com/lizmap/lizmap/www/index.php/view/map/?repository=prodeltademos&project=Construction',
  'direct georeferencing demo': 'https://www.prodeltamap.com/lizmap/lizmap/www/index.php/view/map/?repository=prodeltademos&project=Direct_Georeferencing',
  'grading demo': 'https://www.prodeltamap.com/lizmap/lizmap/www/index.php/view/map/?repository=prodeltademos&project=Grading',
  'integrity inspection demo': 'https://www.prodeltamap.com/lizmap/lizmap/www/index.php/view/map/?repository=prodeltademos&project=Integrity_Inspection',
  'digital twin demo': 'https://www.prodeltamap.com/lizmap/lizmap/www/index.php/view/map/?repository=prodeltademos&project=Digital_Twin',
  'prodelta dms demo': 'https://www.prodeltamap.com/lizmap/lizmap/www/index.php/view/map/?repository=prodeltademos&project=zDMS',
  'keyera chinchaga': 'https://www.prodeltamap.com/lizmap/lizmap/www/index.php/view/map/?repository=keyera&project=Keyera_Chinchaga_Soil_Remediation',
  'keyera chinchaga powerBI': 'https://app.powerbi.com/view?r=eyJrIjoiZTk5MThlZjAtNjA0Zi00OTk4LTk1MmItY2FiMzg4MjJlMDQ1IiwidCI6IjJhZj80YWY3LTg1MmMtNDMzNy1hYTQ4LWQyMmYyNjg1ZWEyOCJ9',
  'powerbi prodelta dms demo': 'https://app.powerbi.com/reportEmbed?reportId=85368a97-724e-4bfc-a286-5c5aa3f8833a&autoAuth=true&ctid=2af80af7-852c-4337-aa48-d22f2685ea28',
  'powerbi report section': 'https://app.powerbi.com/view?r=eyJrIjoiZmIzMmI1OGItNTkwOS00NzA3LWEzODMtMmVkNzI1NGI4ZjA3IiwidCI6IjJhZj80YWY3LTg1MmMtNDMzNy1hYTQ4LWQyMmYyNjg1ZWEyOCJ9',
}
